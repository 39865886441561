import { Box, Container, Typography } from "@material-ui/core";
import { Main } from "layouts";

const DataPrivacyPolicyPage = () => (
  <Main>
    <Box sx={{ pt: 10, pb: 10 }}>
      <Container maxWidth="md">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Data Privacy Policy</strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          The protection of personal data of users of the website and of
          Confidential Insights customers (hereinafter "users") is an important
          concern for Confidential Insights and its partners.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          Confidential Insights collects, processes and uses personal data only
          in compliance with the applicable data protection law and exclusively
          for the stated purposes.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          Confidential Insights is constantly working to ensure that personal
          data is protected by technical and organizational measures and that it
          is not accessible to third parties.
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Processing of personal data</strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          Personal data are all details and information that relate to a
          specific or identifiable person. In addition to contact details such
          as name, telephone number, address or e-mail address, this also
          includes other information that users provide confidential insights,
          for example during registration, in the context of a customer
          relationship, service provision or surveys and the like. Under certain
          circumstances, the IP address that is used when visiting the website
          can also be saved and combined with other information such as the
          websites accessed and reactions to offers from Confidential Insights.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          After registration and registration, Confidential Insights can
          transfer online usage data such as the way in which the websites are
          used and the services used or data that the user discloses via the
          websites or when using the services, with other customer data that is
          in connection with the use of the products and services of
          Confidential Insights were collected, link and process for the
          provision of the services and functions, for marketing purposes as
          well as for the evaluation, improvement and new development of
          services and functions.
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>
            Processing of data for marketing purposes and for the provision of
            services and contract processing
          </strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          Confidential Insights collects, stores and processes the personal data
          of users insofar as this is necessary for the management of the
          customer relationship, the provision of services, the processing of
          orders and contracts, the invoicing, the answering of questions and
          concerns, the support in technical matters and the evaluation,
          Improvement and redevelopment of Confidential Insights products,
          services and functions is required.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          Confidential Insights processes personal data for marketing purposes
          such as general customer communication and the personalized adaptation
          of the offers from Confidential Insights, Swisscom (Schweiz) AG or dq
          technologies AG, or other companies of the Swisscom Group and selected
          business partners.
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Data processing for marketing purposes</strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          The following data disclosed by users in the context of the
          contractual relationship, data on the use of products and services and
          data on the use of the websites and online services of Confidential
          Insights can be used for marketing purposes:
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          <ul>
            <li>
              Identification and contact details such as surname, first name;
              Gender; Address; Telephone number, email address;
            </li>
            <li>
              Online data such as the time of access to our website; Name of our
              accessed Internet pages; IP address of your computer or mobile
              device; Information in forms.
            </li>
          </ul>
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Transfer of marketing data</strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          By accepting this declaration, the user allows the personal data
          processed for marketing and advertising purposes to be passed on to
          Swisscom (Schweiz) AG, affiliates of the Swisscom Group and dq
          technologies AG for their own use for marketing purposes.
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Right to object</strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          Users have the option at any time to object to the receipt of
          advertising and the processing of their data for marketing and
          advertising purposes. In this case, no data will be obtained or
          processed for this purpose. The data already obtained and combined for
          this purpose will be deleted within two working days. There is no
          possibility of objection to general advertisements on websites.
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Transfer of data</strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          In addition to the transfers explained in the above provisions,
          personal data of users can be processed and passed on to third parties
          if this is necessary or appropriate in order to comply with applicable
          laws and regulations or to check compliance and to answer inquiries
          from the responsible authorities. In addition, such personal data can
          be processed and made available to service providers as part of the
          commissioning of collection service providers or for the purpose of
          checking the customer's creditworthiness. Furthermore, personal data
          in connection with indications of the unlawful use of services can be
          passed on to third parties or obtained from third parties, provided
          this is necessary for the detection, prevention or elimination of
          fraudulent or improper use of the services from Confidential Insights,
          Swisscom or dq technologies AG or third parties are suitable.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          If users purchase services that Confidential Insights provides jointly
          with third parties or users purchase services from third parties,
          Confidential Insights can pass on the data to third parties that are
          necessary for the provision of such a service and for collection.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          Technical usage data can be processed personally for the following
          purposes:
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          <ul>
            <li>
              As far as this is necessary for the security and operation of the
              technical infrastructure, the guarantee of the service and in
              particular the connection quality and the further development of
              services,
            </li>
            <li>
              If this is stated in this declaration or if the user has consented
              to the use of a service.
            </li>
          </ul>
        </Typography>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Rights in relation to your personal data</strong>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          gutterBottom
        >
          The user has the right to receive information about his personal data
          processed by Confidential Insights at any time in writing and free of
          charge. He can send the request for information in writing and with a
          copy of his identity card or passport to the postal address listed
          below. He also has the right to request the correction of incorrect
          personal data. He is also entitled to the deletion of his personal
          data, provided Confidential Insights is not obliged to store personal
          data due to the applicable laws and regulations. Finally, he has the
          right to revoke his consent to the processing of his personal data at
          any time and / or to object to the processing of his personal data.
        </Typography>
        <Typography
          component="h3"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Address</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" component="p">
          Gutstrasse 73, 8055 Zürich, Switzerland
        </Typography>
      </Container>
    </Box>
  </Main>
);

export default DataPrivacyPolicyPage;
