import { faTrashAlt as faTrashAltDuotone } from "@fortawesome/pro-duotone-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const useDialogActionsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3, 2, 3),
    justifyContent: "space-between",
  },
}));

const useLoadingButtonStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1rem",
    fontWeight: 900,
    letterSpacing: "-0.25px",
    lineHeight: 1.5,
    // borderColor: theme.palette.text.disabled,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: theme.spacing(0.5),
      "& > *:first-child": {
        fontSize: "1.125rem",
      },
    },
  },
  pendingIndicatorStart: {
    left: theme.spacing(1),
  },
}));

const useButtonClasses = makeStyles((theme) => ({
  root: {
    fontSize: "1rem",
    lineHeight: 1.5,
    // borderColor: theme.palette.text.disabled,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
  },
}));

const DeleteAccountDialog = ({ open, loading, onCancel, onConfirm }) => {
  const dialogClasses = useDialogStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const buttonClasses = useButtonClasses();
  const loadingButtonClasses = useLoadingButtonStyles();
  return (
    <Dialog
      classes={dialogClasses}
      open={open}
      onClose={!loading ? onCancel : () => {}}
    >
      <DialogContent>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon fixedWidth icon={faTrashAltDuotone} size="3x" />
        </Box>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          style={{ lineHeight: 1.25 }}
        >
          <strong>
            Are you sure
            <br />
            you want to delete
            <br />
            your account?
          </strong>
        </Typography>
        <Typography variant="body2" align="center" sx={{ display: "none" }}>
          You can't restore the information, all data will be lost.
        </Typography>
      </DialogContent>
      <DialogActions classes={dialogActionsClasses}>
        <Button
          classes={buttonClasses}
          color="inherit"
          variant="outlined"
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <LoadingButton
          classes={loadingButtonClasses}
          color="inherit"
          variant="outlined"
          onClick={onConfirm}
          pending={loading}
          pendingPosition="start"
          startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountDialog;
