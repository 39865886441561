import {
  faFileAlt,
  faFileCode,
  faFileContract,
  faFileEdit,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { useBoolean } from "ahooks";
import { saveAs } from "file-saver";
import {
  useErrors,
  useFieldValue,
  useIsSubmitting,
  useIsValid,
  useStatus,
  useSubmitForm,
  useTouched,
} from "formik";
import { has } from "lodash";
import { memo } from "react";
import { SurveyPublishDialog, SurveySubmitDialog } from "components";

const getLeaves = function (tree) {
  const leaves = [];
  const walk = function (obj, path) {
    path = path || "";
    for (const n in obj) {
      if (obj[n]) {
        if (Object.prototype.hasOwnProperty.call(obj, n)) {
          if (typeof obj[n] === "object" || obj[n] instanceof Array) {
            walk(obj[n], [path, n].filter(Boolean).join("."));
          } else {
            leaves.push([path, n].filter(Boolean).join("."));
          }
        }
      }
    }
  };
  walk(tree);
  return leaves;
};

const useAppBarStyles = makeStyles((theme) => ({
  root: {
    top: "auto",
    bottom: 0,
  },
  borderTopRed: {
    borderTop: "2px solid #f44336",
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
  label: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 900,
    letterSpacing: "-0.25px",
    "& > svg": {
      marginRight: theme.spacing(0.5),
      fontSize: "1.5em",
    },
  },
}));

const SurveyFormActions = memo(
  ({ isOwner, isPublished, hasSubmitted, results }) => {
    const [{ isEditing, canSubmit, hasResult }, setStatus] = useStatus();
    const [name] = useFieldValue("name");
    const [participants] = useFieldValue("participants");
    const [questions] = useFieldValue("questions");
    const [touched] = useTouched();
    const [errors] = useErrors();
    const isValidating = false; // TODO: implement isValidating() hook
    const isValid = useIsValid();
    const isSubmitting = useIsSubmitting();
    const isPosting = !isValidating && isSubmitting;
    const submitForm = useSubmitForm();
    const hasParticipants = participants.length > 0;
    const hasQuestions = questions.length > 0;
    const appBarClasses = useAppBarStyles({ isValid });
    const buttonClasses = useButtonStyles();
    const [
      isPublishDialogOpen,
      { setTrue: openPublishDialog, setFalse: closePublishDialog },
    ] = useBoolean(false);
    const publish = () => {
      closePublishDialog();
      submitForm();
    };
    const [
      isSubmitDialogOpen,
      { setTrue: openSubmitDialog, setFalse: closeSubmitDialog },
    ] = useBoolean(false);
    const submit = () => {
      closeSubmitDialog();
      submitForm();
    };
    const downloadAsJson = () => {
      const file = new File(
        [
          JSON.stringify(
            results.map((result) => {
              const cleanResult = { ...result };
              delete cleanResult["loading"];
              return cleanResult;
            }),
            null,
            2
          ),
        ],
        `${name} — Results.json`,
        { type: "application/octet-stream;charset=utf-8" }
      );
      saveAs(file);
    };
    const switchModeToPreview = () =>
      setStatus({ isEditing: false, isViewing: true });
    const switchModeToEdit = () =>
      setStatus({ isEditing: true, isViewing: false });
    const someTouchedHaveErrors = getLeaves(touched).some((path) =>
      has(errors, path)
    );
    return (
      <AppBar
        elevation={0}
        color="default"
        position="sticky"
        classes={appBarClasses}
        className={
          !isValid && someTouchedHaveErrors ? appBarClasses.borderTopRed : null
        }
      >
        <Divider />
        {isEditing ? (
          <Box
            sx={{
              paddingTop: 2,
              paddingRight: 8,
              paddingBottom: 2,
              paddingLeft: 8,
              backdropFilter: "blur(6px)",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="inherit"
              type="button"
              variant="text"
              classes={buttonClasses}
              disabled={isValidating || !isValid || isSubmitting}
              onClick={switchModeToPreview}
            >
              {isValidating || isSubmitting ? (
                <CircularProgress
                  size={24}
                  color="inherit"
                  thickness={3}
                  style={{ marginRight: 4 }}
                />
              ) : (
                <FontAwesomeIcon icon={faFileAlt} />
              )}
              Preview
            </Button>
          </Box>
        ) : null}
        {!isEditing && isOwner && !isPublished ? (
          <Box
            sx={{
              paddingTop: 2,
              paddingRight: 8,
              paddingBottom: 2,
              paddingLeft: 8,
              backdropFilter: "blur(6px)",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              type="button"
              variant="text"
              classes={buttonClasses}
              disabled={!isOwner || isPublished || isValidating || isSubmitting}
              onClick={switchModeToEdit}
            >
              <FontAwesomeIcon icon={faFileEdit} />
              Edit
            </Button>
            <SurveyPublishDialog
              name={name}
              open={isPublishDialogOpen}
              onCancel={closePublishDialog}
              onConfirm={publish}
            />
            <Button
              color="inherit"
              type="button"
              variant="text"
              classes={buttonClasses}
              disabled={
                !isOwner ||
                isPublished ||
                isValidating ||
                !isValid ||
                !hasParticipants ||
                !hasQuestions ||
                isSubmitting
              }
              onClick={openPublishDialog}
            >
              {isSubmitting ? (
                <CircularProgress
                  size={24}
                  color="inherit"
                  thickness={3}
                  style={{ marginRight: 4 }}
                />
              ) : (
                <FontAwesomeIcon icon={faFileContract} />
              )}
              Encrypt and publish
            </Button>
          </Box>
        ) : null}
        {hasSubmitted && !hasResult ? (
          <Box
            sx={{
              paddingTop: 2,
              paddingRight: 8,
              paddingBottom: 2,
              paddingLeft: 5,
              backdropFilter: "blur(6px)",
              backgroundColor: "white",
              display: "flex",
            }}
          >
            <Box
              sx={{
                marginRight: "0.5rem",
              }}
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faCheckCircle}
                color={teal["A400"]}
              />
            </Box>
            You have already submitted your answers, check back for results
            later
          </Box>
        ) : null}
        {canSubmit ? (
          <Box
            sx={{
              paddingTop: 2,
              paddingRight: 8,
              paddingBottom: 2,
              paddingLeft: 8,
              backdropFilter: "blur(6px)",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SurveySubmitDialog
              name={name}
              open={isSubmitDialogOpen}
              onCancel={closeSubmitDialog}
              onConfirm={submit}
            />
            <Button
              color="inherit"
              type="button"
              onClick={openSubmitDialog}
              variant="text"
              classes={buttonClasses}
              disabled={!isPublished || !isValid || isPosting}
            >
              {isPosting ? (
                <CircularProgress
                  size={24}
                  color="inherit"
                  thickness={3}
                  style={{ marginRight: 4 }}
                />
              ) : (
                <FontAwesomeIcon icon={faFileContract} />
              )}
              Encrypt and submit
            </Button>
          </Box>
        ) : null}
        {hasResult && results ? (
          <Box
            sx={{
              paddingTop: 2,
              paddingRight: 8,
              paddingBottom: 2,
              paddingLeft: 8,
              backdropFilter: "blur(6px)",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="inherit"
              type="button"
              variant="text"
              classes={buttonClasses}
              onClick={downloadAsJson}
            >
              <FontAwesomeIcon icon={faFileCode} />
              Download results as JSON
            </Button>
          </Box>
        ) : null}
      </AppBar>
    );
  }
);

export default SurveyFormActions;
