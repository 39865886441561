import { faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FastField } from "formik";
import { memo } from "react";

const useSelectIconStyles = makeStyles({
  selectIcon: {
    margin: "0.375rem 0.25rem",
    position: "absolute",
    top: "1rem",
    right: 0,
    pointerEvents: "none",
  },
});

const SelectIcon = memo(() => {
  const selectIconClasses = useSelectIconStyles();
  return (
    <FontAwesomeIcon
      fixedWidth
      icon={faCaretDown}
      className={selectIconClasses.selectIcon}
    />
  );
});

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useSelectStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0.5, 0),
    paddingBottom: "3px",
    minHeight: "1.25rem !important",
    lineHeight: "1.25rem !important",
  },
}));

const MinimumNumberOfValidSubmissionsField = memo(({ name }) => {
  const inputLabelClasses = useInputLabelStyles();
  const selectClasses = useSelectStyles();
  return (
    <FastField name={name}>
      {({ field: { value, ...rest }, meta: { touched, error } }) => (
        <FormControl fullWidth>
          <InputLabel
            color="secondary"
            shrink
            required
            classes={inputLabelClasses}
            error={Boolean(error)}
          >
            Aggregations
          </InputLabel>
          <Select
            color="secondary"
            classes={selectClasses}
            IconComponent={SelectIcon}
            multiple
            value={value || []}
            {...rest}
            error={Boolean(error)}
          >
            <MenuItem value="count">Count</MenuItem>
            <MenuItem value="sum">Sum</MenuItem>
            <MenuItem value="avg">Avg</MenuItem>
            <MenuItem value="min">Min</MenuItem>
            <MenuItem value="max">Max</MenuItem>
          </Select>
          {Boolean(error) && (
            <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
});

export default MinimumNumberOfValidSubmissionsField;
