import { surveyParticipation } from "constants/index";
import { faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FastField, useStatus } from "formik";
import { memo } from "react";

const useSelectIconStyles = makeStyles({
  selectIcon: {
    margin: "0.375rem 0.25rem",
    position: "absolute",
    top: 0,
    right: 0,
    pointerEvents: "none",
  },
});

const SelectIcon = memo(() => {
  const selectIconClasses = useSelectIconStyles();
  return (
    <FontAwesomeIcon
      fixedWidth
      icon={faCaretDown}
      className={selectIconClasses.selectIcon}
    />
  );
});

const useSelectStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0.5, 0),
    paddingBottom: "3px",
    minHeight: "1.25rem !important",
    lineHeight: "1.25rem !important",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const SurveyAccessedByField = () => {
  const [{ isEditing }] = useStatus();
  const inputLabelClasses = useInputLabelStyles();
  const selectClasses = useSelectStyles();
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="survey-accessed-by"
        color="secondary"
        shrink
        required={isEditing}
        disabled={!isEditing}
        classes={inputLabelClasses}
      >
        Participants
      </InputLabel>
      <FastField id="survey-accessed-by" name="surveyAccessedBy">
        {({ field }) => (
          <Select
            color="secondary"
            classes={selectClasses}
            IconComponent={SelectIcon}
            SelectDisplayProps={{ style: { minHeight: "1.1428575em" } }}
            disableUnderline={!isEditing}
            readOnly={!isEditing}
            // disabled={!isEditing}
            disabled
            {...field}
          >
            <MenuItem value={surveyParticipation.ALL}>
              Unsealed (not enforced by enclave)
            </MenuItem>
            <MenuItem value={surveyParticipation.AUTHENTICATED}>
              Invited only (enforced by enclave)
            </MenuItem>
          </Select>
        )}
      </FastField>
    </FormControl>
  );
};

export default SurveyAccessedByField;
