export const DEFAULT_REFERER = "/";
export const FEATURE_DARK_THEME = "dark_theme";

export const surveyParticipation = {
  ALL: "ALL",
  AUTHENTICATED: "AUTHENTICATED",
};

export const surveyComputing = {
  SUBMISSION_END_REACHED: "SUBMISSION_END_REACHED",
  SURVEY_MINIMUM_RESPONSES_REACHED: "SURVEY_MINIMUM_RESPONSES_REACHED",
};

export const surveyResultVisibility = {
  ALL: "ALL",
  OWNER: "OWNER",
  OWNER_AND_PARTICIPANTS: "OWNER_AND_PARTICIPANTS",
  PARTICIPANTS: "PARTICIPANTS",
};

export const questionKind = {
  NUMERICAL: "NumericQuestion",
  CATEGORICAL: "CategoricalQuestion",
};

export const numericalQuestionAggregation = {
  COUNT: "count",
  SUM: "sum",
  AVG: "avg",
  MIN: "min",
  MAX: "max",
};

export const answerKind = {
  NUMERICAL: "NumericAnswer",
  CATEGORICAL: "CategoricalAnswer",
};

export const surveyMode = {
  CREATE: "create",
  SUBMIT: "submit",
  RESULTS: "results",
  VIEW: "view",
};

export const actionType = {
  EXPORT_TO_JSON: "EXPORT_TO_JSON",
  IMPORT_FROM_JSON: "IMPORT_FROM_JSON",
  DELETE_ALL: "DELETE_ALL",
  TOGGLE_ALL: "TOGGLE_ALL",
};
