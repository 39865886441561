import { answerKind, questionKind } from "constants/index";
import { isDate, parse } from "date-fns";
import { get, isNumber } from "lodash";
import * as yup from "yup";

yup.addMethod(yup.array, "unique", function (message, path) {
  return this.test("unique", message, function (list) {
    const mapper = (x) => get(x, path);
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }
    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({ path: `${this.path}[${idx}].${path}`, message });
  });
});

const description = yup.string();

const submissionStart = yup.date();

// known issue: if user enters date and time and presses number once more - date string becames something like '26.03.2021 04:244'
// which results into error - Invalid Date
const submissionEnd = yup
  .date()
  .transform((_, originalValue) => {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd.MM.yyyy HH:mm", new Date());
    return parsedDate;
  })
  .typeError("Submission end must be a date time")
  .test({
    name: "is-in-future",
    test: (value, context) => {
      const { createError, path } = context;
      return value > new Date()
        ? true
        : createError({
            message: "Must be in the future",
            path,
          });
    },
  })
  .required("Must specify submission end");

const surveyAccessedBy = yup
  .string()
  .oneOf(["ALL", "AUTHENTICATED"])
  .required("Must specify who can access the insight");

const surveyMinimumResponses = yup
  .number()
  .min(1)
  .required("Must specify min. responses");

const resultsComputedAfter = yup
  .string()
  .oneOf(["SUBMISSION_END_REACHED", "SURVEY_MINIMUM_RESPONSES_REACHED"])
  .required("Must specify when to compute results");

const resultsAccessedBy = yup
  .string()
  .oneOf(["ALL", "OWNER", "OWNER_AND_PARTICIPANTS", "PARTICIPANTS"])
  .required("Must specify who can access results");

const participants = yup
  .array()
  .max(50, "Maximum 50 participants")
  .of(
    yup.string().email(({ path }) => {
      const match = path.match(/participants\[(\d)\]/);
      return [
        "Participant",
        match && match[1] ? ++match[1] : null,
        "must have a valid email",
      ]
        .filter(Boolean)
        .join(" ");
    })
  )
  .required("Must specify participants");

const kind = yup.string().required("Must specify question kind");

const name = yup.string().required("Must specify question");

const minimumNumberOfValidSubmissions = yup
  .number()
  .integer("Must be an integer")
  .min(1, "Must be at least 1")
  .required("Must specify min. submissions");

const isRequired = yup
  .boolean()
  .required("Must specify if question is required");

const allowMultiAnswer = yup.mixed().when("kind", {
  is: (value) => value === questionKind.CATEGORICAL,
  then: yup.boolean().required("Must specify if multi choice is accepted"),
  otherwise: undefined,
});

const submissionOptions = yup.mixed().when("kind", {
  is: (value) => value === questionKind.CATEGORICAL,
  then: yup
    .array()
    .of(yup.string())
    .min(1, "Must specify at least one option")
    .required("Must specify at least one option"),
  otherwise: undefined,
});

const unit = yup.mixed().when("kind", {
  is: (value) => value === questionKind.NUMERICAL,
  then: yup.string(),
  otherwise: undefined,
});

const minValueValidation = yup.mixed().when("kind", {
  is: (value) => value === questionKind.NUMERICAL,
  then: yup
    .number()
    .nullable()
    .when("maxValueValidation", (maxValueValidation, schema) =>
      isNumber(maxValueValidation)
        ? schema.lessThan(maxValueValidation, "Should be less than max")
        : undefined
    ),
  otherwise: undefined,
});

const maxValueValidation = yup.mixed().when("kind", {
  is: (value) => value === questionKind.NUMERICAL,
  then: yup
    .number()
    .nullable()
    .when("minValueValidation", (minValueValidation, schema) =>
      isNumber(minValueValidation)
        ? schema.moreThan(minValueValidation, "Should be more than min")
        : undefined
    ),
  otherwise: undefined,
});

const lowerPercentileFilter = yup.mixed().when("kind", {
  is: (value) => value === questionKind.NUMERICAL,
  then: yup.number().min(0).required("Must specify lower percentile filter"),
  otherwise: undefined,
});

const upperPercentileFilter = yup.mixed().when("kind", {
  is: (value) => value === questionKind.NUMERICAL,
  then: yup.number().max(1).required("Must specify upper percentile filter"),
  otherwise: undefined,
});

const rejectionFeedback = yup.mixed().when("kind", {
  is: (value) => value === questionKind.NUMERICAL,
  then: yup.number().oneOf([0, 1]).required("Must specify rejection feedback"),
  otherwise: undefined,
});

const aggregations = yup.mixed().when("kind", {
  is: (value) => value === questionKind.NUMERICAL,
  then: yup
    .array()
    .of(yup.string())
    .min(1, "Must specify at least one aggregation")
    .required("Must specify at least one aggregation"),
  otherwise: undefined,
});

const questions = yup
  .array()
  .of(
    yup
      .object()
      .shape({
        kind,
        name,
        description: yup.string(),
        minimumNumberOfValidSubmissions,
        isRequired,
        allowMultiAnswer,
        submissionOptions,
        unit,
        minValueValidation,
        maxValueValidation,
        lowerPercentileFilter,
        upperPercentileFilter,
        rejectionFeedback,
        aggregations,
      })
      .noUnknown()
  )
  .unique("Question must be unique", "name");

// TODO: Move validation higher, validate whole object at once
const answers = yup.array().of(
  yup
    .object()
    .shape({
      kind: yup.string().required("Must specify answer kind"),
      value: yup.mixed().when("kind", {
        is: (value) => value === answerKind.CATEGORICAL,
        then: yup.mixed().test({
          name: "is-required",
          test: (value, context) => {
            const { createError, path, options, from } = context;
            const index = options?.index;
            const question = from[1]?.value?.questions[index];
            const { isRequired, allowMultiAnswer } = question || {};
            return !isRequired || value?.length > 0
              ? true
              : createError({
                  message: allowMultiAnswer
                    ? "Select one or more options"
                    : "Select one option",
                  path,
                });
          },
        }),
        otherwise: yup
          .number("Must be a number")
          .test({
            name: "is-in-range",
            test: (value, context) => {
              const isNumberValue = isNumber(value);
              if (!isNumberValue) {
                return true;
              }
              const { createError, path, options, from } = context;
              const index = options?.index;
              const question = from[1]?.value?.questions[index];
              const { minValueValidation, maxValueValidation } = question || {};
              const min = isNumber(minValueValidation)
                ? minValueValidation
                : -Infinity;
              const max = isNumber(maxValueValidation)
                ? maxValueValidation
                : Infinity;
              return value >= min && value <= max
                ? true
                : createError({
                    message: `Number must be ${[
                      typeof minValueValidation === "number"
                        ? `at least ${minValueValidation}`
                        : null,
                      typeof maxValueValidation === "number"
                        ? `at most ${maxValueValidation}`
                        : null,
                    ]
                      .filter(Boolean)
                      .join(", ")}`,
                    path,
                  });
            },
          })
          .test({
            name: "is-required",
            test: (value, context) => {
              const { createError, path, options, from } = context;
              const index = options?.index;
              const question = from[1]?.value?.questions[index];
              const { isRequired, minValueValidation, maxValueValidation } =
                question || {};
              return !isRequired || Boolean(value) || value === 0
                ? true
                : createError({
                    message: [
                      "Input a number",
                      typeof minValueValidation === "number"
                        ? `at least ${minValueValidation}`
                        : null,
                      typeof maxValueValidation === "number"
                        ? `at most ${maxValueValidation}`
                        : null,
                    ]
                      .filter(Boolean)
                      .join(", "),
                    path,
                  });
            },
          }),
      }),
    })
    .noUnknown()
);

export const surveyValidationSchema = yup
  .object()
  .shape({
    name: yup.string().required("Must specify insight title"),
    description,
    submissionStart,
    submissionEnd,
    surveyAccessedBy,
    surveyMinimumResponses,
    resultsComputedAfter,
    resultsAccessedBy,
    participants,
    questions,
    // answers,
  })
  .noUnknown();

export const surveyAnswersValidationSchema = yup
  .object()
  .shape({
    answers: answers.required(),
  })
  .noUnknown();
