import { Grid } from "@material-ui/core";
import { SurveyCard } from "components";

const SurveysCards = ({ loading, nodes = [] }) => (
  <Grid container spacing={3}>
    {(nodes.length === 0 ? [...Array(12).keys()] : nodes).map(
      (survey, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <SurveyCard loading={loading} survey={survey} />
        </Grid>
      )
    )}
  </Grid>
);

export default SurveysCards;
