import { makeStyles, TextField } from "@material-ui/core";
import { FastField } from "formik";
import { memo } from "react";

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const MaxValueValidationField = memo(({ name, ...props }) => {
  const inputLabelClasses = useInputLabelStyles();
  const inputClasses = useInputStyles();
  return (
    <FastField name={name} type="number">
      {({ field, meta: { error, touched } }) => (
        <TextField
          fullWidth
          variant="standard"
          color="secondary"
          type="number"
          autoComplete="off"
          label="Max."
          InputLabelProps={{
            shrink: true,
            classes: inputLabelClasses,
          }}
          InputProps={{
            disabled: false,
            classes: inputClasses,
          }}
          error={Boolean(error)}
          helperText={error}
          {...field}
          {...props}
          value={field.value === null ? "" : field.value}
        />
      )}
    </FastField>
  );
});

export default MaxValueValidationField;
