import { gql } from "@apollo/client";

export const SURVEY_EVERYTHING = gql`
  fragment SurveyEverything on Survey {
    id
    surveyId
    name
    description
    dataRoomHash
    questions
    submissionStart
    submissionEnd
    surveyAccessedBy
    surveyMinimumResponses
    resultsComputedAfter
    resultsAccessedBy
    updatedAt
    createdAt
    ownerEmail
    surveyShares(orderBy: [USER_EMAIL_ASC]) {
      nodes {
        surveyShareId
        userEmail
        hasSubmitted
      }
    }
    isPublished
    isOwner
    isParticipant
    surveyShareId
    hasSubmitted
    hasParticipants
    haveAllSubmitted
  }
`;
