import { ConstraintError as SurveySubmissionErorrs } from "@decentriq/survey";

export const surveySubmissionErrorPresentationMap = new Map([
  [
    SurveySubmissionErorrs.MinSubmissionsMissing,
    {
      title: "Not enough submission",
      description:
        "This question did not receive the minimum amount of submissions to guarantee the required level of confidentiality, thus the result cannot be retrieved.",
    },
  ],
  [
    SurveySubmissionErorrs.UserHasNotSubmitted,
    {
      title: "You did not submit an answer to this question",
      description:
        "As you did not submit an answer to this optional question, you are not allowed to get its result",
    },
  ],
]);
