/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.delta_enclave_api = (function() {
    
        /**
         * Namespace delta_enclave_api.
         * @exports delta_enclave_api
         * @namespace
         */
        var delta_enclave_api = {};
    
        delta_enclave_api.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof delta_enclave_api
             * @interface IRequest
             * @property {Uint8Array|null} [avatoRequest] Request avatoRequest
             * @property {delta_enclave_api.IExtensionMessage|null} [extensionMessage] Request extensionMessage
             */
    
            /**
             * Constructs a new Request.
             * @memberof delta_enclave_api
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {delta_enclave_api.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request avatoRequest.
             * @member {Uint8Array} avatoRequest
             * @memberof delta_enclave_api.Request
             * @instance
             */
            Request.prototype.avatoRequest = $util.newBuffer([]);
    
            /**
             * Request extensionMessage.
             * @member {delta_enclave_api.IExtensionMessage|null|undefined} extensionMessage
             * @memberof delta_enclave_api.Request
             * @instance
             */
            Request.prototype.extensionMessage = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Request request.
             * @member {"avatoRequest"|"extensionMessage"|undefined} request
             * @memberof delta_enclave_api.Request
             * @instance
             */
            Object.defineProperty(Request.prototype, "request", {
                get: $util.oneOfGetter($oneOfFields = ["avatoRequest", "extensionMessage"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.Request
             * @static
             * @param {delta_enclave_api.IRequest=} [properties] Properties to set
             * @returns {delta_enclave_api.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link delta_enclave_api.Request.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.Request
             * @static
             * @param {delta_enclave_api.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.avatoRequest != null && Object.hasOwnProperty.call(message, "avatoRequest"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.avatoRequest);
                if (message.extensionMessage != null && Object.hasOwnProperty.call(message, "extensionMessage"))
                    $root.delta_enclave_api.ExtensionMessage.encode(message.extensionMessage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link delta_enclave_api.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.Request
             * @static
             * @param {delta_enclave_api.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.avatoRequest = reader.bytes();
                        break;
                    case 2:
                        message.extensionMessage = $root.delta_enclave_api.ExtensionMessage.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof delta_enclave_api.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.avatoRequest != null && message.hasOwnProperty("avatoRequest")) {
                    properties.request = 1;
                    if (!(message.avatoRequest && typeof message.avatoRequest.length === "number" || $util.isString(message.avatoRequest)))
                        return "avatoRequest: buffer expected";
                }
                if (message.extensionMessage != null && message.hasOwnProperty("extensionMessage")) {
                    if (properties.request === 1)
                        return "request: multiple values";
                    properties.request = 1;
                    {
                        var error = $root.delta_enclave_api.ExtensionMessage.verify(message.extensionMessage);
                        if (error)
                            return "extensionMessage." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.Request)
                    return object;
                var message = new $root.delta_enclave_api.Request();
                if (object.avatoRequest != null)
                    if (typeof object.avatoRequest === "string")
                        $util.base64.decode(object.avatoRequest, message.avatoRequest = $util.newBuffer($util.base64.length(object.avatoRequest)), 0);
                    else if (object.avatoRequest.length)
                        message.avatoRequest = object.avatoRequest;
                if (object.extensionMessage != null) {
                    if (typeof object.extensionMessage !== "object")
                        throw TypeError(".delta_enclave_api.Request.extensionMessage: object expected");
                    message.extensionMessage = $root.delta_enclave_api.ExtensionMessage.fromObject(object.extensionMessage);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.Request
             * @static
             * @param {delta_enclave_api.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.avatoRequest != null && message.hasOwnProperty("avatoRequest")) {
                    object.avatoRequest = options.bytes === String ? $util.base64.encode(message.avatoRequest, 0, message.avatoRequest.length) : options.bytes === Array ? Array.prototype.slice.call(message.avatoRequest) : message.avatoRequest;
                    if (options.oneofs)
                        object.request = "avatoRequest";
                }
                if (message.extensionMessage != null && message.hasOwnProperty("extensionMessage")) {
                    object.extensionMessage = $root.delta_enclave_api.ExtensionMessage.toObject(message.extensionMessage, options);
                    if (options.oneofs)
                        object.request = "extensionMessage";
                }
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Request;
        })();
    
        delta_enclave_api.ExtensionMessage = (function() {
    
            /**
             * Properties of an ExtensionMessage.
             * @memberof delta_enclave_api
             * @interface IExtensionMessage
             * @property {string} name ExtensionMessage name
             * @property {Uint8Array} payload ExtensionMessage payload
             */
    
            /**
             * Constructs a new ExtensionMessage.
             * @memberof delta_enclave_api
             * @classdesc Represents an ExtensionMessage.
             * @implements IExtensionMessage
             * @constructor
             * @param {delta_enclave_api.IExtensionMessage=} [properties] Properties to set
             */
            function ExtensionMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ExtensionMessage name.
             * @member {string} name
             * @memberof delta_enclave_api.ExtensionMessage
             * @instance
             */
            ExtensionMessage.prototype.name = "";
    
            /**
             * ExtensionMessage payload.
             * @member {Uint8Array} payload
             * @memberof delta_enclave_api.ExtensionMessage
             * @instance
             */
            ExtensionMessage.prototype.payload = $util.newBuffer([]);
    
            /**
             * Creates a new ExtensionMessage instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {delta_enclave_api.IExtensionMessage=} [properties] Properties to set
             * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage instance
             */
            ExtensionMessage.create = function create(properties) {
                return new ExtensionMessage(properties);
            };
    
            /**
             * Encodes the specified ExtensionMessage message. Does not implicitly {@link delta_enclave_api.ExtensionMessage.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {delta_enclave_api.IExtensionMessage} message ExtensionMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExtensionMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.payload);
                return writer;
            };
    
            /**
             * Encodes the specified ExtensionMessage message, length delimited. Does not implicitly {@link delta_enclave_api.ExtensionMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {delta_enclave_api.IExtensionMessage} message ExtensionMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExtensionMessage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ExtensionMessage message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExtensionMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.ExtensionMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.payload = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("name"))
                    throw $util.ProtocolError("missing required 'name'", { instance: message });
                if (!message.hasOwnProperty("payload"))
                    throw $util.ProtocolError("missing required 'payload'", { instance: message });
                return message;
            };
    
            /**
             * Decodes an ExtensionMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExtensionMessage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ExtensionMessage message.
             * @function verify
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExtensionMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.name))
                    return "name: string expected";
                if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                    return "payload: buffer expected";
                return null;
            };
    
            /**
             * Creates an ExtensionMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.ExtensionMessage} ExtensionMessage
             */
            ExtensionMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.ExtensionMessage)
                    return object;
                var message = new $root.delta_enclave_api.ExtensionMessage();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.payload != null)
                    if (typeof object.payload === "string")
                        $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                    else if (object.payload.length)
                        message.payload = object.payload;
                return message;
            };
    
            /**
             * Creates a plain object from an ExtensionMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.ExtensionMessage
             * @static
             * @param {delta_enclave_api.ExtensionMessage} message ExtensionMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExtensionMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    if (options.bytes === String)
                        object.payload = "";
                    else {
                        object.payload = [];
                        if (options.bytes !== Array)
                            object.payload = $util.newBuffer(object.payload);
                    }
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.payload != null && message.hasOwnProperty("payload"))
                    object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                return object;
            };
    
            /**
             * Converts this ExtensionMessage to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.ExtensionMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExtensionMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ExtensionMessage;
        })();
    
        delta_enclave_api.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof delta_enclave_api
             * @interface IResponse
             * @property {Uint8Array|null} [successfulResponse] Response successfulResponse
             * @property {string|null} [unsuccessfulResponse] Response unsuccessfulResponse
             */
    
            /**
             * Constructs a new Response.
             * @memberof delta_enclave_api
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {delta_enclave_api.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response successfulResponse.
             * @member {Uint8Array} successfulResponse
             * @memberof delta_enclave_api.Response
             * @instance
             */
            Response.prototype.successfulResponse = $util.newBuffer([]);
    
            /**
             * Response unsuccessfulResponse.
             * @member {string} unsuccessfulResponse
             * @memberof delta_enclave_api.Response
             * @instance
             */
            Response.prototype.unsuccessfulResponse = "";
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Response response.
             * @member {"successfulResponse"|"unsuccessfulResponse"|undefined} response
             * @memberof delta_enclave_api.Response
             * @instance
             */
            Object.defineProperty(Response.prototype, "response", {
                get: $util.oneOfGetter($oneOfFields = ["successfulResponse", "unsuccessfulResponse"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.Response
             * @static
             * @param {delta_enclave_api.IResponse=} [properties] Properties to set
             * @returns {delta_enclave_api.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link delta_enclave_api.Response.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.Response
             * @static
             * @param {delta_enclave_api.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.successfulResponse != null && Object.hasOwnProperty.call(message, "successfulResponse"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.successfulResponse);
                if (message.unsuccessfulResponse != null && Object.hasOwnProperty.call(message, "unsuccessfulResponse"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.unsuccessfulResponse);
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link delta_enclave_api.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.Response
             * @static
             * @param {delta_enclave_api.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.successfulResponse = reader.bytes();
                        break;
                    case 2:
                        message.unsuccessfulResponse = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof delta_enclave_api.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.successfulResponse != null && message.hasOwnProperty("successfulResponse")) {
                    properties.response = 1;
                    if (!(message.successfulResponse && typeof message.successfulResponse.length === "number" || $util.isString(message.successfulResponse)))
                        return "successfulResponse: buffer expected";
                }
                if (message.unsuccessfulResponse != null && message.hasOwnProperty("unsuccessfulResponse")) {
                    if (properties.response === 1)
                        return "response: multiple values";
                    properties.response = 1;
                    if (!$util.isString(message.unsuccessfulResponse))
                        return "unsuccessfulResponse: string expected";
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.Response)
                    return object;
                var message = new $root.delta_enclave_api.Response();
                if (object.successfulResponse != null)
                    if (typeof object.successfulResponse === "string")
                        $util.base64.decode(object.successfulResponse, message.successfulResponse = $util.newBuffer($util.base64.length(object.successfulResponse)), 0);
                    else if (object.successfulResponse.length)
                        message.successfulResponse = object.successfulResponse;
                if (object.unsuccessfulResponse != null)
                    message.unsuccessfulResponse = String(object.unsuccessfulResponse);
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.Response
             * @static
             * @param {delta_enclave_api.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.successfulResponse != null && message.hasOwnProperty("successfulResponse")) {
                    object.successfulResponse = options.bytes === String ? $util.base64.encode(message.successfulResponse, 0, message.successfulResponse.length) : options.bytes === Array ? Array.prototype.slice.call(message.successfulResponse) : message.successfulResponse;
                    if (options.oneofs)
                        object.response = "successfulResponse";
                }
                if (message.unsuccessfulResponse != null && message.hasOwnProperty("unsuccessfulResponse")) {
                    object.unsuccessfulResponse = message.unsuccessfulResponse;
                    if (options.oneofs)
                        object.response = "unsuccessfulResponse";
                }
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Response;
        })();
    
        delta_enclave_api.DataNoncePubkey = (function() {
    
            /**
             * Properties of a DataNoncePubkey.
             * @memberof delta_enclave_api
             * @interface IDataNoncePubkey
             * @property {Uint8Array} data DataNoncePubkey data
             * @property {Uint8Array} nonce DataNoncePubkey nonce
             * @property {Uint8Array} pubkey DataNoncePubkey pubkey
             * @property {delta_enclave_api.IAuthentication|null} [auth] DataNoncePubkey auth
             */
    
            /**
             * Constructs a new DataNoncePubkey.
             * @memberof delta_enclave_api
             * @classdesc Represents a DataNoncePubkey.
             * @implements IDataNoncePubkey
             * @constructor
             * @param {delta_enclave_api.IDataNoncePubkey=} [properties] Properties to set
             */
            function DataNoncePubkey(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DataNoncePubkey data.
             * @member {Uint8Array} data
             * @memberof delta_enclave_api.DataNoncePubkey
             * @instance
             */
            DataNoncePubkey.prototype.data = $util.newBuffer([]);
    
            /**
             * DataNoncePubkey nonce.
             * @member {Uint8Array} nonce
             * @memberof delta_enclave_api.DataNoncePubkey
             * @instance
             */
            DataNoncePubkey.prototype.nonce = $util.newBuffer([]);
    
            /**
             * DataNoncePubkey pubkey.
             * @member {Uint8Array} pubkey
             * @memberof delta_enclave_api.DataNoncePubkey
             * @instance
             */
            DataNoncePubkey.prototype.pubkey = $util.newBuffer([]);
    
            /**
             * DataNoncePubkey auth.
             * @member {delta_enclave_api.IAuthentication|null|undefined} auth
             * @memberof delta_enclave_api.DataNoncePubkey
             * @instance
             */
            DataNoncePubkey.prototype.auth = null;
    
            /**
             * Creates a new DataNoncePubkey instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {delta_enclave_api.IDataNoncePubkey=} [properties] Properties to set
             * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey instance
             */
            DataNoncePubkey.create = function create(properties) {
                return new DataNoncePubkey(properties);
            };
    
            /**
             * Encodes the specified DataNoncePubkey message. Does not implicitly {@link delta_enclave_api.DataNoncePubkey.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {delta_enclave_api.IDataNoncePubkey} message DataNoncePubkey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataNoncePubkey.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nonce);
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.pubkey);
                if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                    $root.delta_enclave_api.Authentication.encode(message.auth, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified DataNoncePubkey message, length delimited. Does not implicitly {@link delta_enclave_api.DataNoncePubkey.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {delta_enclave_api.IDataNoncePubkey} message DataNoncePubkey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataNoncePubkey.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DataNoncePubkey message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataNoncePubkey.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.DataNoncePubkey();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    case 2:
                        message.nonce = reader.bytes();
                        break;
                    case 3:
                        message.pubkey = reader.bytes();
                        break;
                    case 4:
                        message.auth = $root.delta_enclave_api.Authentication.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("data"))
                    throw $util.ProtocolError("missing required 'data'", { instance: message });
                if (!message.hasOwnProperty("nonce"))
                    throw $util.ProtocolError("missing required 'nonce'", { instance: message });
                if (!message.hasOwnProperty("pubkey"))
                    throw $util.ProtocolError("missing required 'pubkey'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a DataNoncePubkey message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataNoncePubkey.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DataNoncePubkey message.
             * @function verify
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataNoncePubkey.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
                if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                    return "nonce: buffer expected";
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
                if (message.auth != null && message.hasOwnProperty("auth")) {
                    var error = $root.delta_enclave_api.Authentication.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a DataNoncePubkey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.DataNoncePubkey} DataNoncePubkey
             */
            DataNoncePubkey.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.DataNoncePubkey)
                    return object;
                var message = new $root.delta_enclave_api.DataNoncePubkey();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                if (object.nonce != null)
                    if (typeof object.nonce === "string")
                        $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                    else if (object.nonce.length)
                        message.nonce = object.nonce;
                if (object.pubkey != null)
                    if (typeof object.pubkey === "string")
                        $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                    else if (object.pubkey.length)
                        message.pubkey = object.pubkey;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".delta_enclave_api.DataNoncePubkey.auth: object expected");
                    message.auth = $root.delta_enclave_api.Authentication.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DataNoncePubkey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.DataNoncePubkey
             * @static
             * @param {delta_enclave_api.DataNoncePubkey} message DataNoncePubkey
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataNoncePubkey.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                    if (options.bytes === String)
                        object.nonce = "";
                    else {
                        object.nonce = [];
                        if (options.bytes !== Array)
                            object.nonce = $util.newBuffer(object.nonce);
                    }
                    if (options.bytes === String)
                        object.pubkey = "";
                    else {
                        object.pubkey = [];
                        if (options.bytes !== Array)
                            object.pubkey = $util.newBuffer(object.pubkey);
                    }
                    object.auth = null;
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.nonce != null && message.hasOwnProperty("nonce"))
                    object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
                if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                    object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.delta_enclave_api.Authentication.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this DataNoncePubkey to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.DataNoncePubkey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataNoncePubkey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DataNoncePubkey;
        })();
    
        delta_enclave_api.Authentication = (function() {
    
            /**
             * Properties of an Authentication.
             * @memberof delta_enclave_api
             * @interface IAuthentication
             * @property {delta_enclave_api.IPki} pki Authentication pki
             */
    
            /**
             * Constructs a new Authentication.
             * @memberof delta_enclave_api
             * @classdesc Represents an Authentication.
             * @implements IAuthentication
             * @constructor
             * @param {delta_enclave_api.IAuthentication=} [properties] Properties to set
             */
            function Authentication(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Authentication pki.
             * @member {delta_enclave_api.IPki} pki
             * @memberof delta_enclave_api.Authentication
             * @instance
             */
            Authentication.prototype.pki = null;
    
            /**
             * Creates a new Authentication instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {delta_enclave_api.IAuthentication=} [properties] Properties to set
             * @returns {delta_enclave_api.Authentication} Authentication instance
             */
            Authentication.create = function create(properties) {
                return new Authentication(properties);
            };
    
            /**
             * Encodes the specified Authentication message. Does not implicitly {@link delta_enclave_api.Authentication.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {delta_enclave_api.IAuthentication} message Authentication message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Authentication.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                $root.delta_enclave_api.Pki.encode(message.pki, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Authentication message, length delimited. Does not implicitly {@link delta_enclave_api.Authentication.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {delta_enclave_api.IAuthentication} message Authentication message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Authentication.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Authentication message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.Authentication} Authentication
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Authentication.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.Authentication();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.pki = $root.delta_enclave_api.Pki.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("pki"))
                    throw $util.ProtocolError("missing required 'pki'", { instance: message });
                return message;
            };
    
            /**
             * Decodes an Authentication message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.Authentication} Authentication
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Authentication.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Authentication message.
             * @function verify
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Authentication.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                {
                    var error = $root.delta_enclave_api.Pki.verify(message.pki);
                    if (error)
                        return "pki." + error;
                }
                return null;
            };
    
            /**
             * Creates an Authentication message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.Authentication} Authentication
             */
            Authentication.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.Authentication)
                    return object;
                var message = new $root.delta_enclave_api.Authentication();
                if (object.pki != null) {
                    if (typeof object.pki !== "object")
                        throw TypeError(".delta_enclave_api.Authentication.pki: object expected");
                    message.pki = $root.delta_enclave_api.Pki.fromObject(object.pki);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an Authentication message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.Authentication
             * @static
             * @param {delta_enclave_api.Authentication} message Authentication
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Authentication.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.pki = null;
                if (message.pki != null && message.hasOwnProperty("pki"))
                    object.pki = $root.delta_enclave_api.Pki.toObject(message.pki, options);
                return object;
            };
    
            /**
             * Converts this Authentication to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.Authentication
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Authentication.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Authentication;
        })();
    
        delta_enclave_api.Pki = (function() {
    
            /**
             * Properties of a Pki.
             * @memberof delta_enclave_api
             * @interface IPki
             * @property {Uint8Array} certChain Pki certChain
             * @property {Uint8Array} signature Pki signature
             * @property {Uint8Array} idMac Pki idMac
             */
    
            /**
             * Constructs a new Pki.
             * @memberof delta_enclave_api
             * @classdesc Represents a Pki.
             * @implements IPki
             * @constructor
             * @param {delta_enclave_api.IPki=} [properties] Properties to set
             */
            function Pki(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Pki certChain.
             * @member {Uint8Array} certChain
             * @memberof delta_enclave_api.Pki
             * @instance
             */
            Pki.prototype.certChain = $util.newBuffer([]);
    
            /**
             * Pki signature.
             * @member {Uint8Array} signature
             * @memberof delta_enclave_api.Pki
             * @instance
             */
            Pki.prototype.signature = $util.newBuffer([]);
    
            /**
             * Pki idMac.
             * @member {Uint8Array} idMac
             * @memberof delta_enclave_api.Pki
             * @instance
             */
            Pki.prototype.idMac = $util.newBuffer([]);
    
            /**
             * Creates a new Pki instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {delta_enclave_api.IPki=} [properties] Properties to set
             * @returns {delta_enclave_api.Pki} Pki instance
             */
            Pki.create = function create(properties) {
                return new Pki(properties);
            };
    
            /**
             * Encodes the specified Pki message. Does not implicitly {@link delta_enclave_api.Pki.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {delta_enclave_api.IPki} message Pki message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pki.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.certChain);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.signature);
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.idMac);
                return writer;
            };
    
            /**
             * Encodes the specified Pki message, length delimited. Does not implicitly {@link delta_enclave_api.Pki.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {delta_enclave_api.IPki} message Pki message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pki.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Pki message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.Pki} Pki
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pki.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.Pki();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.certChain = reader.bytes();
                        break;
                    case 2:
                        message.signature = reader.bytes();
                        break;
                    case 3:
                        message.idMac = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("certChain"))
                    throw $util.ProtocolError("missing required 'certChain'", { instance: message });
                if (!message.hasOwnProperty("signature"))
                    throw $util.ProtocolError("missing required 'signature'", { instance: message });
                if (!message.hasOwnProperty("idMac"))
                    throw $util.ProtocolError("missing required 'idMac'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a Pki message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.Pki} Pki
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pki.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Pki message.
             * @function verify
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Pki.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.certChain && typeof message.certChain.length === "number" || $util.isString(message.certChain)))
                    return "certChain: buffer expected";
                if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                    return "signature: buffer expected";
                if (!(message.idMac && typeof message.idMac.length === "number" || $util.isString(message.idMac)))
                    return "idMac: buffer expected";
                return null;
            };
    
            /**
             * Creates a Pki message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.Pki} Pki
             */
            Pki.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.Pki)
                    return object;
                var message = new $root.delta_enclave_api.Pki();
                if (object.certChain != null)
                    if (typeof object.certChain === "string")
                        $util.base64.decode(object.certChain, message.certChain = $util.newBuffer($util.base64.length(object.certChain)), 0);
                    else if (object.certChain.length)
                        message.certChain = object.certChain;
                if (object.signature != null)
                    if (typeof object.signature === "string")
                        $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                    else if (object.signature.length)
                        message.signature = object.signature;
                if (object.idMac != null)
                    if (typeof object.idMac === "string")
                        $util.base64.decode(object.idMac, message.idMac = $util.newBuffer($util.base64.length(object.idMac)), 0);
                    else if (object.idMac.length)
                        message.idMac = object.idMac;
                return message;
            };
    
            /**
             * Creates a plain object from a Pki message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.Pki
             * @static
             * @param {delta_enclave_api.Pki} message Pki
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Pki.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.certChain = "";
                    else {
                        object.certChain = [];
                        if (options.bytes !== Array)
                            object.certChain = $util.newBuffer(object.certChain);
                    }
                    if (options.bytes === String)
                        object.signature = "";
                    else {
                        object.signature = [];
                        if (options.bytes !== Array)
                            object.signature = $util.newBuffer(object.signature);
                    }
                    if (options.bytes === String)
                        object.idMac = "";
                    else {
                        object.idMac = [];
                        if (options.bytes !== Array)
                            object.idMac = $util.newBuffer(object.idMac);
                    }
                }
                if (message.certChain != null && message.hasOwnProperty("certChain"))
                    object.certChain = options.bytes === String ? $util.base64.encode(message.certChain, 0, message.certChain.length) : options.bytes === Array ? Array.prototype.slice.call(message.certChain) : message.certChain;
                if (message.signature != null && message.hasOwnProperty("signature"))
                    object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
                if (message.idMac != null && message.hasOwnProperty("idMac"))
                    object.idMac = options.bytes === String ? $util.base64.encode(message.idMac, 0, message.idMac.length) : options.bytes === Array ? Array.prototype.slice.call(message.idMac) : message.idMac;
                return object;
            };
    
            /**
             * Converts this Pki to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.Pki
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Pki.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Pki;
        })();
    
        delta_enclave_api.DataNonce = (function() {
    
            /**
             * Properties of a DataNonce.
             * @memberof delta_enclave_api
             * @interface IDataNonce
             * @property {Uint8Array} data DataNonce data
             * @property {Uint8Array} nonce DataNonce nonce
             */
    
            /**
             * Constructs a new DataNonce.
             * @memberof delta_enclave_api
             * @classdesc Represents a DataNonce.
             * @implements IDataNonce
             * @constructor
             * @param {delta_enclave_api.IDataNonce=} [properties] Properties to set
             */
            function DataNonce(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DataNonce data.
             * @member {Uint8Array} data
             * @memberof delta_enclave_api.DataNonce
             * @instance
             */
            DataNonce.prototype.data = $util.newBuffer([]);
    
            /**
             * DataNonce nonce.
             * @member {Uint8Array} nonce
             * @memberof delta_enclave_api.DataNonce
             * @instance
             */
            DataNonce.prototype.nonce = $util.newBuffer([]);
    
            /**
             * Creates a new DataNonce instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {delta_enclave_api.IDataNonce=} [properties] Properties to set
             * @returns {delta_enclave_api.DataNonce} DataNonce instance
             */
            DataNonce.create = function create(properties) {
                return new DataNonce(properties);
            };
    
            /**
             * Encodes the specified DataNonce message. Does not implicitly {@link delta_enclave_api.DataNonce.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {delta_enclave_api.IDataNonce} message DataNonce message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataNonce.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nonce);
                return writer;
            };
    
            /**
             * Encodes the specified DataNonce message, length delimited. Does not implicitly {@link delta_enclave_api.DataNonce.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {delta_enclave_api.IDataNonce} message DataNonce message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataNonce.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DataNonce message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.DataNonce} DataNonce
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataNonce.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.DataNonce();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    case 2:
                        message.nonce = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("data"))
                    throw $util.ProtocolError("missing required 'data'", { instance: message });
                if (!message.hasOwnProperty("nonce"))
                    throw $util.ProtocolError("missing required 'nonce'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a DataNonce message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.DataNonce} DataNonce
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataNonce.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DataNonce message.
             * @function verify
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataNonce.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
                if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                    return "nonce: buffer expected";
                return null;
            };
    
            /**
             * Creates a DataNonce message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.DataNonce} DataNonce
             */
            DataNonce.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.DataNonce)
                    return object;
                var message = new $root.delta_enclave_api.DataNonce();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                if (object.nonce != null)
                    if (typeof object.nonce === "string")
                        $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                    else if (object.nonce.length)
                        message.nonce = object.nonce;
                return message;
            };
    
            /**
             * Creates a plain object from a DataNonce message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.DataNonce
             * @static
             * @param {delta_enclave_api.DataNonce} message DataNonce
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataNonce.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                    if (options.bytes === String)
                        object.nonce = "";
                    else {
                        object.nonce = [];
                        if (options.bytes !== Array)
                            object.nonce = $util.newBuffer(object.nonce);
                    }
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.nonce != null && message.hasOwnProperty("nonce"))
                    object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
                return object;
            };
    
            /**
             * Converts this DataNonce to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.DataNonce
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataNonce.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DataNonce;
        })();
    
        delta_enclave_api.SealedEncryptedMessage = (function() {
    
            /**
             * Properties of a SealedEncryptedMessage.
             * @memberof delta_enclave_api
             * @interface ISealedEncryptedMessage
             * @property {delta_enclave_api.IDataNonce} encryptedMessage SealedEncryptedMessage encryptedMessage
             * @property {Uint8Array} sealingKeyParams SealedEncryptedMessage sealingKeyParams
             */
    
            /**
             * Constructs a new SealedEncryptedMessage.
             * @memberof delta_enclave_api
             * @classdesc Represents a SealedEncryptedMessage.
             * @implements ISealedEncryptedMessage
             * @constructor
             * @param {delta_enclave_api.ISealedEncryptedMessage=} [properties] Properties to set
             */
            function SealedEncryptedMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SealedEncryptedMessage encryptedMessage.
             * @member {delta_enclave_api.IDataNonce} encryptedMessage
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @instance
             */
            SealedEncryptedMessage.prototype.encryptedMessage = null;
    
            /**
             * SealedEncryptedMessage sealingKeyParams.
             * @member {Uint8Array} sealingKeyParams
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @instance
             */
            SealedEncryptedMessage.prototype.sealingKeyParams = $util.newBuffer([]);
    
            /**
             * Creates a new SealedEncryptedMessage instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {delta_enclave_api.ISealedEncryptedMessage=} [properties] Properties to set
             * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage instance
             */
            SealedEncryptedMessage.create = function create(properties) {
                return new SealedEncryptedMessage(properties);
            };
    
            /**
             * Encodes the specified SealedEncryptedMessage message. Does not implicitly {@link delta_enclave_api.SealedEncryptedMessage.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {delta_enclave_api.ISealedEncryptedMessage} message SealedEncryptedMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SealedEncryptedMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                $root.delta_enclave_api.DataNonce.encode(message.encryptedMessage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.sealingKeyParams);
                return writer;
            };
    
            /**
             * Encodes the specified SealedEncryptedMessage message, length delimited. Does not implicitly {@link delta_enclave_api.SealedEncryptedMessage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {delta_enclave_api.ISealedEncryptedMessage} message SealedEncryptedMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SealedEncryptedMessage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SealedEncryptedMessage message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SealedEncryptedMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.SealedEncryptedMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.encryptedMessage = $root.delta_enclave_api.DataNonce.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.sealingKeyParams = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("encryptedMessage"))
                    throw $util.ProtocolError("missing required 'encryptedMessage'", { instance: message });
                if (!message.hasOwnProperty("sealingKeyParams"))
                    throw $util.ProtocolError("missing required 'sealingKeyParams'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a SealedEncryptedMessage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SealedEncryptedMessage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SealedEncryptedMessage message.
             * @function verify
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SealedEncryptedMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                {
                    var error = $root.delta_enclave_api.DataNonce.verify(message.encryptedMessage);
                    if (error)
                        return "encryptedMessage." + error;
                }
                if (!(message.sealingKeyParams && typeof message.sealingKeyParams.length === "number" || $util.isString(message.sealingKeyParams)))
                    return "sealingKeyParams: buffer expected";
                return null;
            };
    
            /**
             * Creates a SealedEncryptedMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.SealedEncryptedMessage} SealedEncryptedMessage
             */
            SealedEncryptedMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.SealedEncryptedMessage)
                    return object;
                var message = new $root.delta_enclave_api.SealedEncryptedMessage();
                if (object.encryptedMessage != null) {
                    if (typeof object.encryptedMessage !== "object")
                        throw TypeError(".delta_enclave_api.SealedEncryptedMessage.encryptedMessage: object expected");
                    message.encryptedMessage = $root.delta_enclave_api.DataNonce.fromObject(object.encryptedMessage);
                }
                if (object.sealingKeyParams != null)
                    if (typeof object.sealingKeyParams === "string")
                        $util.base64.decode(object.sealingKeyParams, message.sealingKeyParams = $util.newBuffer($util.base64.length(object.sealingKeyParams)), 0);
                    else if (object.sealingKeyParams.length)
                        message.sealingKeyParams = object.sealingKeyParams;
                return message;
            };
    
            /**
             * Creates a plain object from a SealedEncryptedMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @static
             * @param {delta_enclave_api.SealedEncryptedMessage} message SealedEncryptedMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SealedEncryptedMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.encryptedMessage = null;
                    if (options.bytes === String)
                        object.sealingKeyParams = "";
                    else {
                        object.sealingKeyParams = [];
                        if (options.bytes !== Array)
                            object.sealingKeyParams = $util.newBuffer(object.sealingKeyParams);
                    }
                }
                if (message.encryptedMessage != null && message.hasOwnProperty("encryptedMessage"))
                    object.encryptedMessage = $root.delta_enclave_api.DataNonce.toObject(message.encryptedMessage, options);
                if (message.sealingKeyParams != null && message.hasOwnProperty("sealingKeyParams"))
                    object.sealingKeyParams = options.bytes === String ? $util.base64.encode(message.sealingKeyParams, 0, message.sealingKeyParams.length) : options.bytes === Array ? Array.prototype.slice.call(message.sealingKeyParams) : message.sealingKeyParams;
                return object;
            };
    
            /**
             * Converts this SealedEncryptedMessage to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.SealedEncryptedMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SealedEncryptedMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return SealedEncryptedMessage;
        })();
    
        delta_enclave_api.EncryptionHeader = (function() {
    
            /**
             * Properties of an EncryptionHeader.
             * @memberof delta_enclave_api
             * @interface IEncryptionHeader
             * @property {delta_enclave_api.IChilyKey|null} [chilyKey] EncryptionHeader chilyKey
             */
    
            /**
             * Constructs a new EncryptionHeader.
             * @memberof delta_enclave_api
             * @classdesc Represents an EncryptionHeader.
             * @implements IEncryptionHeader
             * @constructor
             * @param {delta_enclave_api.IEncryptionHeader=} [properties] Properties to set
             */
            function EncryptionHeader(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EncryptionHeader chilyKey.
             * @member {delta_enclave_api.IChilyKey|null|undefined} chilyKey
             * @memberof delta_enclave_api.EncryptionHeader
             * @instance
             */
            EncryptionHeader.prototype.chilyKey = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * EncryptionHeader encryptionHeader.
             * @member {"chilyKey"|undefined} encryptionHeader
             * @memberof delta_enclave_api.EncryptionHeader
             * @instance
             */
            Object.defineProperty(EncryptionHeader.prototype, "encryptionHeader", {
                get: $util.oneOfGetter($oneOfFields = ["chilyKey"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new EncryptionHeader instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {delta_enclave_api.IEncryptionHeader=} [properties] Properties to set
             * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader instance
             */
            EncryptionHeader.create = function create(properties) {
                return new EncryptionHeader(properties);
            };
    
            /**
             * Encodes the specified EncryptionHeader message. Does not implicitly {@link delta_enclave_api.EncryptionHeader.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {delta_enclave_api.IEncryptionHeader} message EncryptionHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EncryptionHeader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chilyKey != null && Object.hasOwnProperty.call(message, "chilyKey"))
                    $root.delta_enclave_api.ChilyKey.encode(message.chilyKey, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified EncryptionHeader message, length delimited. Does not implicitly {@link delta_enclave_api.EncryptionHeader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {delta_enclave_api.IEncryptionHeader} message EncryptionHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EncryptionHeader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EncryptionHeader message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EncryptionHeader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.EncryptionHeader();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.chilyKey = $root.delta_enclave_api.ChilyKey.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EncryptionHeader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EncryptionHeader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EncryptionHeader message.
             * @function verify
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EncryptionHeader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.chilyKey != null && message.hasOwnProperty("chilyKey")) {
                    properties.encryptionHeader = 1;
                    {
                        var error = $root.delta_enclave_api.ChilyKey.verify(message.chilyKey);
                        if (error)
                            return "chilyKey." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an EncryptionHeader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.EncryptionHeader} EncryptionHeader
             */
            EncryptionHeader.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.EncryptionHeader)
                    return object;
                var message = new $root.delta_enclave_api.EncryptionHeader();
                if (object.chilyKey != null) {
                    if (typeof object.chilyKey !== "object")
                        throw TypeError(".delta_enclave_api.EncryptionHeader.chilyKey: object expected");
                    message.chilyKey = $root.delta_enclave_api.ChilyKey.fromObject(object.chilyKey);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EncryptionHeader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.EncryptionHeader
             * @static
             * @param {delta_enclave_api.EncryptionHeader} message EncryptionHeader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EncryptionHeader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.chilyKey != null && message.hasOwnProperty("chilyKey")) {
                    object.chilyKey = $root.delta_enclave_api.ChilyKey.toObject(message.chilyKey, options);
                    if (options.oneofs)
                        object.encryptionHeader = "chilyKey";
                }
                return object;
            };
    
            /**
             * Converts this EncryptionHeader to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.EncryptionHeader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EncryptionHeader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return EncryptionHeader;
        })();
    
        delta_enclave_api.ChilyKey = (function() {
    
            /**
             * Properties of a ChilyKey.
             * @memberof delta_enclave_api
             * @interface IChilyKey
             * @property {Uint8Array} keyId ChilyKey keyId
             * @property {Uint8Array} encryptionNonce ChilyKey encryptionNonce
             */
    
            /**
             * Constructs a new ChilyKey.
             * @memberof delta_enclave_api
             * @classdesc Represents a ChilyKey.
             * @implements IChilyKey
             * @constructor
             * @param {delta_enclave_api.IChilyKey=} [properties] Properties to set
             */
            function ChilyKey(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ChilyKey keyId.
             * @member {Uint8Array} keyId
             * @memberof delta_enclave_api.ChilyKey
             * @instance
             */
            ChilyKey.prototype.keyId = $util.newBuffer([]);
    
            /**
             * ChilyKey encryptionNonce.
             * @member {Uint8Array} encryptionNonce
             * @memberof delta_enclave_api.ChilyKey
             * @instance
             */
            ChilyKey.prototype.encryptionNonce = $util.newBuffer([]);
    
            /**
             * Creates a new ChilyKey instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {delta_enclave_api.IChilyKey=} [properties] Properties to set
             * @returns {delta_enclave_api.ChilyKey} ChilyKey instance
             */
            ChilyKey.create = function create(properties) {
                return new ChilyKey(properties);
            };
    
            /**
             * Encodes the specified ChilyKey message. Does not implicitly {@link delta_enclave_api.ChilyKey.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {delta_enclave_api.IChilyKey} message ChilyKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChilyKey.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.keyId);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.encryptionNonce);
                return writer;
            };
    
            /**
             * Encodes the specified ChilyKey message, length delimited. Does not implicitly {@link delta_enclave_api.ChilyKey.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {delta_enclave_api.IChilyKey} message ChilyKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChilyKey.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ChilyKey message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.ChilyKey} ChilyKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChilyKey.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.ChilyKey();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.keyId = reader.bytes();
                        break;
                    case 2:
                        message.encryptionNonce = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("keyId"))
                    throw $util.ProtocolError("missing required 'keyId'", { instance: message });
                if (!message.hasOwnProperty("encryptionNonce"))
                    throw $util.ProtocolError("missing required 'encryptionNonce'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a ChilyKey message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.ChilyKey} ChilyKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChilyKey.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ChilyKey message.
             * @function verify
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChilyKey.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.keyId && typeof message.keyId.length === "number" || $util.isString(message.keyId)))
                    return "keyId: buffer expected";
                if (!(message.encryptionNonce && typeof message.encryptionNonce.length === "number" || $util.isString(message.encryptionNonce)))
                    return "encryptionNonce: buffer expected";
                return null;
            };
    
            /**
             * Creates a ChilyKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.ChilyKey} ChilyKey
             */
            ChilyKey.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.ChilyKey)
                    return object;
                var message = new $root.delta_enclave_api.ChilyKey();
                if (object.keyId != null)
                    if (typeof object.keyId === "string")
                        $util.base64.decode(object.keyId, message.keyId = $util.newBuffer($util.base64.length(object.keyId)), 0);
                    else if (object.keyId.length)
                        message.keyId = object.keyId;
                if (object.encryptionNonce != null)
                    if (typeof object.encryptionNonce === "string")
                        $util.base64.decode(object.encryptionNonce, message.encryptionNonce = $util.newBuffer($util.base64.length(object.encryptionNonce)), 0);
                    else if (object.encryptionNonce.length)
                        message.encryptionNonce = object.encryptionNonce;
                return message;
            };
    
            /**
             * Creates a plain object from a ChilyKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.ChilyKey
             * @static
             * @param {delta_enclave_api.ChilyKey} message ChilyKey
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChilyKey.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.keyId = "";
                    else {
                        object.keyId = [];
                        if (options.bytes !== Array)
                            object.keyId = $util.newBuffer(object.keyId);
                    }
                    if (options.bytes === String)
                        object.encryptionNonce = "";
                    else {
                        object.encryptionNonce = [];
                        if (options.bytes !== Array)
                            object.encryptionNonce = $util.newBuffer(object.encryptionNonce);
                    }
                }
                if (message.keyId != null && message.hasOwnProperty("keyId"))
                    object.keyId = options.bytes === String ? $util.base64.encode(message.keyId, 0, message.keyId.length) : options.bytes === Array ? Array.prototype.slice.call(message.keyId) : message.keyId;
                if (message.encryptionNonce != null && message.hasOwnProperty("encryptionNonce"))
                    object.encryptionNonce = options.bytes === String ? $util.base64.encode(message.encryptionNonce, 0, message.encryptionNonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.encryptionNonce) : message.encryptionNonce;
                return object;
            };
    
            /**
             * Converts this ChilyKey to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.ChilyKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChilyKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ChilyKey;
        })();
    
        delta_enclave_api.IntegrityHeader = (function() {
    
            /**
             * Properties of an IntegrityHeader.
             * @memberof delta_enclave_api
             * @interface IIntegrityHeader
             * @property {delta_enclave_api.IBodySha256|null} [bodySha256] IntegrityHeader bodySha256
             * @property {delta_enclave_api.IResultSha256|null} [resultSha256] IntegrityHeader resultSha256
             */
    
            /**
             * Constructs a new IntegrityHeader.
             * @memberof delta_enclave_api
             * @classdesc Represents an IntegrityHeader.
             * @implements IIntegrityHeader
             * @constructor
             * @param {delta_enclave_api.IIntegrityHeader=} [properties] Properties to set
             */
            function IntegrityHeader(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * IntegrityHeader bodySha256.
             * @member {delta_enclave_api.IBodySha256|null|undefined} bodySha256
             * @memberof delta_enclave_api.IntegrityHeader
             * @instance
             */
            IntegrityHeader.prototype.bodySha256 = null;
    
            /**
             * IntegrityHeader resultSha256.
             * @member {delta_enclave_api.IResultSha256|null|undefined} resultSha256
             * @memberof delta_enclave_api.IntegrityHeader
             * @instance
             */
            IntegrityHeader.prototype.resultSha256 = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * IntegrityHeader integrityHeader.
             * @member {"bodySha256"|"resultSha256"|undefined} integrityHeader
             * @memberof delta_enclave_api.IntegrityHeader
             * @instance
             */
            Object.defineProperty(IntegrityHeader.prototype, "integrityHeader", {
                get: $util.oneOfGetter($oneOfFields = ["bodySha256", "resultSha256"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new IntegrityHeader instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {delta_enclave_api.IIntegrityHeader=} [properties] Properties to set
             * @returns {delta_enclave_api.IntegrityHeader} IntegrityHeader instance
             */
            IntegrityHeader.create = function create(properties) {
                return new IntegrityHeader(properties);
            };
    
            /**
             * Encodes the specified IntegrityHeader message. Does not implicitly {@link delta_enclave_api.IntegrityHeader.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {delta_enclave_api.IIntegrityHeader} message IntegrityHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrityHeader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bodySha256 != null && Object.hasOwnProperty.call(message, "bodySha256"))
                    $root.delta_enclave_api.BodySha256.encode(message.bodySha256, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.resultSha256 != null && Object.hasOwnProperty.call(message, "resultSha256"))
                    $root.delta_enclave_api.ResultSha256.encode(message.resultSha256, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified IntegrityHeader message, length delimited. Does not implicitly {@link delta_enclave_api.IntegrityHeader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {delta_enclave_api.IIntegrityHeader} message IntegrityHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrityHeader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an IntegrityHeader message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.IntegrityHeader} IntegrityHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrityHeader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.IntegrityHeader();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.bodySha256 = $root.delta_enclave_api.BodySha256.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.resultSha256 = $root.delta_enclave_api.ResultSha256.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an IntegrityHeader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.IntegrityHeader} IntegrityHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrityHeader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an IntegrityHeader message.
             * @function verify
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntegrityHeader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.bodySha256 != null && message.hasOwnProperty("bodySha256")) {
                    properties.integrityHeader = 1;
                    {
                        var error = $root.delta_enclave_api.BodySha256.verify(message.bodySha256);
                        if (error)
                            return "bodySha256." + error;
                    }
                }
                if (message.resultSha256 != null && message.hasOwnProperty("resultSha256")) {
                    if (properties.integrityHeader === 1)
                        return "integrityHeader: multiple values";
                    properties.integrityHeader = 1;
                    {
                        var error = $root.delta_enclave_api.ResultSha256.verify(message.resultSha256);
                        if (error)
                            return "resultSha256." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an IntegrityHeader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.IntegrityHeader} IntegrityHeader
             */
            IntegrityHeader.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.IntegrityHeader)
                    return object;
                var message = new $root.delta_enclave_api.IntegrityHeader();
                if (object.bodySha256 != null) {
                    if (typeof object.bodySha256 !== "object")
                        throw TypeError(".delta_enclave_api.IntegrityHeader.bodySha256: object expected");
                    message.bodySha256 = $root.delta_enclave_api.BodySha256.fromObject(object.bodySha256);
                }
                if (object.resultSha256 != null) {
                    if (typeof object.resultSha256 !== "object")
                        throw TypeError(".delta_enclave_api.IntegrityHeader.resultSha256: object expected");
                    message.resultSha256 = $root.delta_enclave_api.ResultSha256.fromObject(object.resultSha256);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an IntegrityHeader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.IntegrityHeader
             * @static
             * @param {delta_enclave_api.IntegrityHeader} message IntegrityHeader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntegrityHeader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.bodySha256 != null && message.hasOwnProperty("bodySha256")) {
                    object.bodySha256 = $root.delta_enclave_api.BodySha256.toObject(message.bodySha256, options);
                    if (options.oneofs)
                        object.integrityHeader = "bodySha256";
                }
                if (message.resultSha256 != null && message.hasOwnProperty("resultSha256")) {
                    object.resultSha256 = $root.delta_enclave_api.ResultSha256.toObject(message.resultSha256, options);
                    if (options.oneofs)
                        object.integrityHeader = "resultSha256";
                }
                return object;
            };
    
            /**
             * Converts this IntegrityHeader to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.IntegrityHeader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntegrityHeader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return IntegrityHeader;
        })();
    
        delta_enclave_api.BodySha256 = (function() {
    
            /**
             * Properties of a BodySha256.
             * @memberof delta_enclave_api
             * @interface IBodySha256
             */
    
            /**
             * Constructs a new BodySha256.
             * @memberof delta_enclave_api
             * @classdesc Represents a BodySha256.
             * @implements IBodySha256
             * @constructor
             * @param {delta_enclave_api.IBodySha256=} [properties] Properties to set
             */
            function BodySha256(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new BodySha256 instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {delta_enclave_api.IBodySha256=} [properties] Properties to set
             * @returns {delta_enclave_api.BodySha256} BodySha256 instance
             */
            BodySha256.create = function create(properties) {
                return new BodySha256(properties);
            };
    
            /**
             * Encodes the specified BodySha256 message. Does not implicitly {@link delta_enclave_api.BodySha256.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {delta_enclave_api.IBodySha256} message BodySha256 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BodySha256.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified BodySha256 message, length delimited. Does not implicitly {@link delta_enclave_api.BodySha256.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {delta_enclave_api.IBodySha256} message BodySha256 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BodySha256.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BodySha256 message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.BodySha256} BodySha256
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BodySha256.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.BodySha256();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BodySha256 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.BodySha256} BodySha256
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BodySha256.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BodySha256 message.
             * @function verify
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BodySha256.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a BodySha256 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.BodySha256} BodySha256
             */
            BodySha256.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.BodySha256)
                    return object;
                return new $root.delta_enclave_api.BodySha256();
            };
    
            /**
             * Creates a plain object from a BodySha256 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.BodySha256
             * @static
             * @param {delta_enclave_api.BodySha256} message BodySha256
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BodySha256.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this BodySha256 to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.BodySha256
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BodySha256.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return BodySha256;
        })();
    
        delta_enclave_api.ResultSha256 = (function() {
    
            /**
             * Properties of a ResultSha256.
             * @memberof delta_enclave_api
             * @interface IResultSha256
             * @property {Uint8Array} computationSha256 ResultSha256 computationSha256
             */
    
            /**
             * Constructs a new ResultSha256.
             * @memberof delta_enclave_api
             * @classdesc Represents a ResultSha256.
             * @implements IResultSha256
             * @constructor
             * @param {delta_enclave_api.IResultSha256=} [properties] Properties to set
             */
            function ResultSha256(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ResultSha256 computationSha256.
             * @member {Uint8Array} computationSha256
             * @memberof delta_enclave_api.ResultSha256
             * @instance
             */
            ResultSha256.prototype.computationSha256 = $util.newBuffer([]);
    
            /**
             * Creates a new ResultSha256 instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {delta_enclave_api.IResultSha256=} [properties] Properties to set
             * @returns {delta_enclave_api.ResultSha256} ResultSha256 instance
             */
            ResultSha256.create = function create(properties) {
                return new ResultSha256(properties);
            };
    
            /**
             * Encodes the specified ResultSha256 message. Does not implicitly {@link delta_enclave_api.ResultSha256.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {delta_enclave_api.IResultSha256} message ResultSha256 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResultSha256.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.computationSha256);
                return writer;
            };
    
            /**
             * Encodes the specified ResultSha256 message, length delimited. Does not implicitly {@link delta_enclave_api.ResultSha256.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {delta_enclave_api.IResultSha256} message ResultSha256 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResultSha256.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ResultSha256 message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.ResultSha256} ResultSha256
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResultSha256.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.ResultSha256();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.computationSha256 = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("computationSha256"))
                    throw $util.ProtocolError("missing required 'computationSha256'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a ResultSha256 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.ResultSha256} ResultSha256
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResultSha256.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ResultSha256 message.
             * @function verify
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResultSha256.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.computationSha256 && typeof message.computationSha256.length === "number" || $util.isString(message.computationSha256)))
                    return "computationSha256: buffer expected";
                return null;
            };
    
            /**
             * Creates a ResultSha256 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.ResultSha256} ResultSha256
             */
            ResultSha256.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.ResultSha256)
                    return object;
                var message = new $root.delta_enclave_api.ResultSha256();
                if (object.computationSha256 != null)
                    if (typeof object.computationSha256 === "string")
                        $util.base64.decode(object.computationSha256, message.computationSha256 = $util.newBuffer($util.base64.length(object.computationSha256)), 0);
                    else if (object.computationSha256.length)
                        message.computationSha256 = object.computationSha256;
                return message;
            };
    
            /**
             * Creates a plain object from a ResultSha256 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.ResultSha256
             * @static
             * @param {delta_enclave_api.ResultSha256} message ResultSha256
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResultSha256.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.computationSha256 = "";
                    else {
                        object.computationSha256 = [];
                        if (options.bytes !== Array)
                            object.computationSha256 = $util.newBuffer(object.computationSha256);
                    }
                if (message.computationSha256 != null && message.hasOwnProperty("computationSha256"))
                    object.computationSha256 = options.bytes === String ? $util.base64.encode(message.computationSha256, 0, message.computationSha256.length) : options.bytes === Array ? Array.prototype.slice.call(message.computationSha256) : message.computationSha256;
                return object;
            };
    
            /**
             * Converts this ResultSha256 to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.ResultSha256
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResultSha256.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ResultSha256;
        })();
    
        delta_enclave_api.VersionHeader = (function() {
    
            /**
             * Properties of a VersionHeader.
             * @memberof delta_enclave_api
             * @interface IVersionHeader
             * @property {number|Long} version VersionHeader version
             */
    
            /**
             * Constructs a new VersionHeader.
             * @memberof delta_enclave_api
             * @classdesc Represents a VersionHeader.
             * @implements IVersionHeader
             * @constructor
             * @param {delta_enclave_api.IVersionHeader=} [properties] Properties to set
             */
            function VersionHeader(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VersionHeader version.
             * @member {number|Long} version
             * @memberof delta_enclave_api.VersionHeader
             * @instance
             */
            VersionHeader.prototype.version = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new VersionHeader instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {delta_enclave_api.IVersionHeader=} [properties] Properties to set
             * @returns {delta_enclave_api.VersionHeader} VersionHeader instance
             */
            VersionHeader.create = function create(properties) {
                return new VersionHeader(properties);
            };
    
            /**
             * Encodes the specified VersionHeader message. Does not implicitly {@link delta_enclave_api.VersionHeader.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {delta_enclave_api.IVersionHeader} message VersionHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VersionHeader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.version);
                return writer;
            };
    
            /**
             * Encodes the specified VersionHeader message, length delimited. Does not implicitly {@link delta_enclave_api.VersionHeader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {delta_enclave_api.IVersionHeader} message VersionHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VersionHeader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VersionHeader message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.VersionHeader} VersionHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VersionHeader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.VersionHeader();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.version = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("version"))
                    throw $util.ProtocolError("missing required 'version'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a VersionHeader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.VersionHeader} VersionHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VersionHeader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VersionHeader message.
             * @function verify
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VersionHeader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isInteger(message.version) && !(message.version && $util.isInteger(message.version.low) && $util.isInteger(message.version.high)))
                    return "version: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a VersionHeader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.VersionHeader} VersionHeader
             */
            VersionHeader.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.VersionHeader)
                    return object;
                var message = new $root.delta_enclave_api.VersionHeader();
                if (object.version != null)
                    if ($util.Long)
                        (message.version = $util.Long.fromValue(object.version)).unsigned = false;
                    else if (typeof object.version === "string")
                        message.version = parseInt(object.version, 10);
                    else if (typeof object.version === "number")
                        message.version = object.version;
                    else if (typeof object.version === "object")
                        message.version = new $util.LongBits(object.version.low >>> 0, object.version.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a VersionHeader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.VersionHeader
             * @static
             * @param {delta_enclave_api.VersionHeader} message VersionHeader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VersionHeader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.version = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.version = options.longs === String ? "0" : 0;
                if (message.version != null && message.hasOwnProperty("version"))
                    if (typeof message.version === "number")
                        object.version = options.longs === String ? String(message.version) : message.version;
                    else
                        object.version = options.longs === String ? $util.Long.prototype.toString.call(message.version) : options.longs === Number ? new $util.LongBits(message.version.low >>> 0, message.version.high >>> 0).toNumber() : message.version;
                return object;
            };
    
            /**
             * Converts this VersionHeader to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.VersionHeader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VersionHeader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return VersionHeader;
        })();
    
        delta_enclave_api.ChunkHeader = (function() {
    
            /**
             * Properties of a ChunkHeader.
             * @memberof delta_enclave_api
             * @interface IChunkHeader
             * @property {Uint8Array} extraEntropy ChunkHeader extraEntropy
             * @property {string} formatIdentifier ChunkHeader formatIdentifier
             */
    
            /**
             * Constructs a new ChunkHeader.
             * @memberof delta_enclave_api
             * @classdesc Represents a ChunkHeader.
             * @implements IChunkHeader
             * @constructor
             * @param {delta_enclave_api.IChunkHeader=} [properties] Properties to set
             */
            function ChunkHeader(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ChunkHeader extraEntropy.
             * @member {Uint8Array} extraEntropy
             * @memberof delta_enclave_api.ChunkHeader
             * @instance
             */
            ChunkHeader.prototype.extraEntropy = $util.newBuffer([]);
    
            /**
             * ChunkHeader formatIdentifier.
             * @member {string} formatIdentifier
             * @memberof delta_enclave_api.ChunkHeader
             * @instance
             */
            ChunkHeader.prototype.formatIdentifier = "";
    
            /**
             * Creates a new ChunkHeader instance using the specified properties.
             * @function create
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {delta_enclave_api.IChunkHeader=} [properties] Properties to set
             * @returns {delta_enclave_api.ChunkHeader} ChunkHeader instance
             */
            ChunkHeader.create = function create(properties) {
                return new ChunkHeader(properties);
            };
    
            /**
             * Encodes the specified ChunkHeader message. Does not implicitly {@link delta_enclave_api.ChunkHeader.verify|verify} messages.
             * @function encode
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {delta_enclave_api.IChunkHeader} message ChunkHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChunkHeader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.extraEntropy);
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.formatIdentifier);
                return writer;
            };
    
            /**
             * Encodes the specified ChunkHeader message, length delimited. Does not implicitly {@link delta_enclave_api.ChunkHeader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {delta_enclave_api.IChunkHeader} message ChunkHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChunkHeader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ChunkHeader message from the specified reader or buffer.
             * @function decode
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {delta_enclave_api.ChunkHeader} ChunkHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChunkHeader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.delta_enclave_api.ChunkHeader();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.extraEntropy = reader.bytes();
                        break;
                    case 2:
                        message.formatIdentifier = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("extraEntropy"))
                    throw $util.ProtocolError("missing required 'extraEntropy'", { instance: message });
                if (!message.hasOwnProperty("formatIdentifier"))
                    throw $util.ProtocolError("missing required 'formatIdentifier'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a ChunkHeader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {delta_enclave_api.ChunkHeader} ChunkHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChunkHeader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ChunkHeader message.
             * @function verify
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChunkHeader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.extraEntropy && typeof message.extraEntropy.length === "number" || $util.isString(message.extraEntropy)))
                    return "extraEntropy: buffer expected";
                if (!$util.isString(message.formatIdentifier))
                    return "formatIdentifier: string expected";
                return null;
            };
    
            /**
             * Creates a ChunkHeader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {delta_enclave_api.ChunkHeader} ChunkHeader
             */
            ChunkHeader.fromObject = function fromObject(object) {
                if (object instanceof $root.delta_enclave_api.ChunkHeader)
                    return object;
                var message = new $root.delta_enclave_api.ChunkHeader();
                if (object.extraEntropy != null)
                    if (typeof object.extraEntropy === "string")
                        $util.base64.decode(object.extraEntropy, message.extraEntropy = $util.newBuffer($util.base64.length(object.extraEntropy)), 0);
                    else if (object.extraEntropy.length)
                        message.extraEntropy = object.extraEntropy;
                if (object.formatIdentifier != null)
                    message.formatIdentifier = String(object.formatIdentifier);
                return message;
            };
    
            /**
             * Creates a plain object from a ChunkHeader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof delta_enclave_api.ChunkHeader
             * @static
             * @param {delta_enclave_api.ChunkHeader} message ChunkHeader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChunkHeader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.extraEntropy = "";
                    else {
                        object.extraEntropy = [];
                        if (options.bytes !== Array)
                            object.extraEntropy = $util.newBuffer(object.extraEntropy);
                    }
                    object.formatIdentifier = "";
                }
                if (message.extraEntropy != null && message.hasOwnProperty("extraEntropy"))
                    object.extraEntropy = options.bytes === String ? $util.base64.encode(message.extraEntropy, 0, message.extraEntropy.length) : options.bytes === Array ? Array.prototype.slice.call(message.extraEntropy) : message.extraEntropy;
                if (message.formatIdentifier != null && message.hasOwnProperty("formatIdentifier"))
                    object.formatIdentifier = message.formatIdentifier;
                return object;
            };
    
            /**
             * Converts this ChunkHeader to JSON.
             * @function toJSON
             * @memberof delta_enclave_api.ChunkHeader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChunkHeader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ChunkHeader;
        })();
    
        return delta_enclave_api;
    })();
    
    $root.waterfront = (function() {
    
        /**
         * Namespace waterfront.
         * @exports waterfront
         * @namespace
         */
        var waterfront = {};
    
        waterfront.WaterfrontRequest = (function() {
    
            /**
             * Properties of a WaterfrontRequest.
             * @memberof waterfront
             * @interface IWaterfrontRequest
             * @property {waterfront.ISqlQueryRequest|null} [sqlQueryRequest] WaterfrontRequest sqlQueryRequest
             * @property {waterfront.ICreateDataRoomRequest|null} [createDataRoomRequest] WaterfrontRequest createDataRoomRequest
             * @property {waterfront.IPublishDatasetToDataRoomRequest|null} [publishDatasetToDataRoomRequest] WaterfrontRequest publishDatasetToDataRoomRequest
             * @property {waterfront.IRetrieveDataRoomRequest|null} [retrieveDataRoomRequest] WaterfrontRequest retrieveDataRoomRequest
             * @property {waterfront.IValidateDatasetRequest|null} [validateDatasetRequest] WaterfrontRequest validateDatasetRequest
             * @property {waterfront.IRetrieveAuditLogRequest|null} [retrieveAuditLogRequest] WaterfrontRequest retrieveAuditLogRequest
             * @property {waterfront.IRetrieveDataRoomStatusRequest|null} [retrieveDataRoomStatusRequest] WaterfrontRequest retrieveDataRoomStatusRequest
             * @property {waterfront.IUpdateDataRoomStatusRequest|null} [updateDataRoomStatusRequest] WaterfrontRequest updateDataRoomStatusRequest
             * @property {waterfront.IRetrievePublishedDatasetRequest|null} [retrievePublishedDatasetRequest] WaterfrontRequest retrievePublishedDatasetRequest
             * @property {waterfront.IRemovePublishedDatasetRequest|null} [removePublishedDatasetRequest] WaterfrontRequest removePublishedDatasetRequest
             * @property {waterfront.IJobStatusRequest|null} [jobStatusRequest] WaterfrontRequest jobStatusRequest
             * @property {waterfront.IGetResultsRequest|null} [getResultsRequest] WaterfrontRequest getResultsRequest
             */
    
            /**
             * Constructs a new WaterfrontRequest.
             * @memberof waterfront
             * @classdesc Represents a WaterfrontRequest.
             * @implements IWaterfrontRequest
             * @constructor
             * @param {waterfront.IWaterfrontRequest=} [properties] Properties to set
             */
            function WaterfrontRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * WaterfrontRequest sqlQueryRequest.
             * @member {waterfront.ISqlQueryRequest|null|undefined} sqlQueryRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.sqlQueryRequest = null;
    
            /**
             * WaterfrontRequest createDataRoomRequest.
             * @member {waterfront.ICreateDataRoomRequest|null|undefined} createDataRoomRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.createDataRoomRequest = null;
    
            /**
             * WaterfrontRequest publishDatasetToDataRoomRequest.
             * @member {waterfront.IPublishDatasetToDataRoomRequest|null|undefined} publishDatasetToDataRoomRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.publishDatasetToDataRoomRequest = null;
    
            /**
             * WaterfrontRequest retrieveDataRoomRequest.
             * @member {waterfront.IRetrieveDataRoomRequest|null|undefined} retrieveDataRoomRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.retrieveDataRoomRequest = null;
    
            /**
             * WaterfrontRequest validateDatasetRequest.
             * @member {waterfront.IValidateDatasetRequest|null|undefined} validateDatasetRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.validateDatasetRequest = null;
    
            /**
             * WaterfrontRequest retrieveAuditLogRequest.
             * @member {waterfront.IRetrieveAuditLogRequest|null|undefined} retrieveAuditLogRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.retrieveAuditLogRequest = null;
    
            /**
             * WaterfrontRequest retrieveDataRoomStatusRequest.
             * @member {waterfront.IRetrieveDataRoomStatusRequest|null|undefined} retrieveDataRoomStatusRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.retrieveDataRoomStatusRequest = null;
    
            /**
             * WaterfrontRequest updateDataRoomStatusRequest.
             * @member {waterfront.IUpdateDataRoomStatusRequest|null|undefined} updateDataRoomStatusRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.updateDataRoomStatusRequest = null;
    
            /**
             * WaterfrontRequest retrievePublishedDatasetRequest.
             * @member {waterfront.IRetrievePublishedDatasetRequest|null|undefined} retrievePublishedDatasetRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.retrievePublishedDatasetRequest = null;
    
            /**
             * WaterfrontRequest removePublishedDatasetRequest.
             * @member {waterfront.IRemovePublishedDatasetRequest|null|undefined} removePublishedDatasetRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.removePublishedDatasetRequest = null;
    
            /**
             * WaterfrontRequest jobStatusRequest.
             * @member {waterfront.IJobStatusRequest|null|undefined} jobStatusRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.jobStatusRequest = null;
    
            /**
             * WaterfrontRequest getResultsRequest.
             * @member {waterfront.IGetResultsRequest|null|undefined} getResultsRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            WaterfrontRequest.prototype.getResultsRequest = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * WaterfrontRequest waterfrontRequest.
             * @member {"sqlQueryRequest"|"createDataRoomRequest"|"publishDatasetToDataRoomRequest"|"retrieveDataRoomRequest"|"validateDatasetRequest"|"retrieveAuditLogRequest"|"retrieveDataRoomStatusRequest"|"updateDataRoomStatusRequest"|"retrievePublishedDatasetRequest"|"removePublishedDatasetRequest"|"jobStatusRequest"|"getResultsRequest"|undefined} waterfrontRequest
             * @memberof waterfront.WaterfrontRequest
             * @instance
             */
            Object.defineProperty(WaterfrontRequest.prototype, "waterfrontRequest", {
                get: $util.oneOfGetter($oneOfFields = ["sqlQueryRequest", "createDataRoomRequest", "publishDatasetToDataRoomRequest", "retrieveDataRoomRequest", "validateDatasetRequest", "retrieveAuditLogRequest", "retrieveDataRoomStatusRequest", "updateDataRoomStatusRequest", "retrievePublishedDatasetRequest", "removePublishedDatasetRequest", "jobStatusRequest", "getResultsRequest"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new WaterfrontRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {waterfront.IWaterfrontRequest=} [properties] Properties to set
             * @returns {waterfront.WaterfrontRequest} WaterfrontRequest instance
             */
            WaterfrontRequest.create = function create(properties) {
                return new WaterfrontRequest(properties);
            };
    
            /**
             * Encodes the specified WaterfrontRequest message. Does not implicitly {@link waterfront.WaterfrontRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {waterfront.IWaterfrontRequest} message WaterfrontRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WaterfrontRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sqlQueryRequest != null && Object.hasOwnProperty.call(message, "sqlQueryRequest"))
                    $root.waterfront.SqlQueryRequest.encode(message.sqlQueryRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.createDataRoomRequest != null && Object.hasOwnProperty.call(message, "createDataRoomRequest"))
                    $root.waterfront.CreateDataRoomRequest.encode(message.createDataRoomRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.publishDatasetToDataRoomRequest != null && Object.hasOwnProperty.call(message, "publishDatasetToDataRoomRequest"))
                    $root.waterfront.PublishDatasetToDataRoomRequest.encode(message.publishDatasetToDataRoomRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.retrieveDataRoomRequest != null && Object.hasOwnProperty.call(message, "retrieveDataRoomRequest"))
                    $root.waterfront.RetrieveDataRoomRequest.encode(message.retrieveDataRoomRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.validateDatasetRequest != null && Object.hasOwnProperty.call(message, "validateDatasetRequest"))
                    $root.waterfront.ValidateDatasetRequest.encode(message.validateDatasetRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.retrieveAuditLogRequest != null && Object.hasOwnProperty.call(message, "retrieveAuditLogRequest"))
                    $root.waterfront.RetrieveAuditLogRequest.encode(message.retrieveAuditLogRequest, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.retrieveDataRoomStatusRequest != null && Object.hasOwnProperty.call(message, "retrieveDataRoomStatusRequest"))
                    $root.waterfront.RetrieveDataRoomStatusRequest.encode(message.retrieveDataRoomStatusRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.updateDataRoomStatusRequest != null && Object.hasOwnProperty.call(message, "updateDataRoomStatusRequest"))
                    $root.waterfront.UpdateDataRoomStatusRequest.encode(message.updateDataRoomStatusRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.retrievePublishedDatasetRequest != null && Object.hasOwnProperty.call(message, "retrievePublishedDatasetRequest"))
                    $root.waterfront.RetrievePublishedDatasetRequest.encode(message.retrievePublishedDatasetRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.removePublishedDatasetRequest != null && Object.hasOwnProperty.call(message, "removePublishedDatasetRequest"))
                    $root.waterfront.RemovePublishedDatasetRequest.encode(message.removePublishedDatasetRequest, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.jobStatusRequest != null && Object.hasOwnProperty.call(message, "jobStatusRequest"))
                    $root.waterfront.JobStatusRequest.encode(message.jobStatusRequest, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.getResultsRequest != null && Object.hasOwnProperty.call(message, "getResultsRequest"))
                    $root.waterfront.GetResultsRequest.encode(message.getResultsRequest, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified WaterfrontRequest message, length delimited. Does not implicitly {@link waterfront.WaterfrontRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {waterfront.IWaterfrontRequest} message WaterfrontRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WaterfrontRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a WaterfrontRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.WaterfrontRequest} WaterfrontRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WaterfrontRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.WaterfrontRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.sqlQueryRequest = $root.waterfront.SqlQueryRequest.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.createDataRoomRequest = $root.waterfront.CreateDataRoomRequest.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.publishDatasetToDataRoomRequest = $root.waterfront.PublishDatasetToDataRoomRequest.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.retrieveDataRoomRequest = $root.waterfront.RetrieveDataRoomRequest.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.validateDatasetRequest = $root.waterfront.ValidateDatasetRequest.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.retrieveAuditLogRequest = $root.waterfront.RetrieveAuditLogRequest.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.retrieveDataRoomStatusRequest = $root.waterfront.RetrieveDataRoomStatusRequest.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.updateDataRoomStatusRequest = $root.waterfront.UpdateDataRoomStatusRequest.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.retrievePublishedDatasetRequest = $root.waterfront.RetrievePublishedDatasetRequest.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.removePublishedDatasetRequest = $root.waterfront.RemovePublishedDatasetRequest.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.jobStatusRequest = $root.waterfront.JobStatusRequest.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.getResultsRequest = $root.waterfront.GetResultsRequest.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a WaterfrontRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.WaterfrontRequest} WaterfrontRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WaterfrontRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a WaterfrontRequest message.
             * @function verify
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WaterfrontRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.sqlQueryRequest != null && message.hasOwnProperty("sqlQueryRequest")) {
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.SqlQueryRequest.verify(message.sqlQueryRequest);
                        if (error)
                            return "sqlQueryRequest." + error;
                    }
                }
                if (message.createDataRoomRequest != null && message.hasOwnProperty("createDataRoomRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.CreateDataRoomRequest.verify(message.createDataRoomRequest);
                        if (error)
                            return "createDataRoomRequest." + error;
                    }
                }
                if (message.publishDatasetToDataRoomRequest != null && message.hasOwnProperty("publishDatasetToDataRoomRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.PublishDatasetToDataRoomRequest.verify(message.publishDatasetToDataRoomRequest);
                        if (error)
                            return "publishDatasetToDataRoomRequest." + error;
                    }
                }
                if (message.retrieveDataRoomRequest != null && message.hasOwnProperty("retrieveDataRoomRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.RetrieveDataRoomRequest.verify(message.retrieveDataRoomRequest);
                        if (error)
                            return "retrieveDataRoomRequest." + error;
                    }
                }
                if (message.validateDatasetRequest != null && message.hasOwnProperty("validateDatasetRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.ValidateDatasetRequest.verify(message.validateDatasetRequest);
                        if (error)
                            return "validateDatasetRequest." + error;
                    }
                }
                if (message.retrieveAuditLogRequest != null && message.hasOwnProperty("retrieveAuditLogRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.RetrieveAuditLogRequest.verify(message.retrieveAuditLogRequest);
                        if (error)
                            return "retrieveAuditLogRequest." + error;
                    }
                }
                if (message.retrieveDataRoomStatusRequest != null && message.hasOwnProperty("retrieveDataRoomStatusRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.RetrieveDataRoomStatusRequest.verify(message.retrieveDataRoomStatusRequest);
                        if (error)
                            return "retrieveDataRoomStatusRequest." + error;
                    }
                }
                if (message.updateDataRoomStatusRequest != null && message.hasOwnProperty("updateDataRoomStatusRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.UpdateDataRoomStatusRequest.verify(message.updateDataRoomStatusRequest);
                        if (error)
                            return "updateDataRoomStatusRequest." + error;
                    }
                }
                if (message.retrievePublishedDatasetRequest != null && message.hasOwnProperty("retrievePublishedDatasetRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.RetrievePublishedDatasetRequest.verify(message.retrievePublishedDatasetRequest);
                        if (error)
                            return "retrievePublishedDatasetRequest." + error;
                    }
                }
                if (message.removePublishedDatasetRequest != null && message.hasOwnProperty("removePublishedDatasetRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.RemovePublishedDatasetRequest.verify(message.removePublishedDatasetRequest);
                        if (error)
                            return "removePublishedDatasetRequest." + error;
                    }
                }
                if (message.jobStatusRequest != null && message.hasOwnProperty("jobStatusRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.JobStatusRequest.verify(message.jobStatusRequest);
                        if (error)
                            return "jobStatusRequest." + error;
                    }
                }
                if (message.getResultsRequest != null && message.hasOwnProperty("getResultsRequest")) {
                    if (properties.waterfrontRequest === 1)
                        return "waterfrontRequest: multiple values";
                    properties.waterfrontRequest = 1;
                    {
                        var error = $root.waterfront.GetResultsRequest.verify(message.getResultsRequest);
                        if (error)
                            return "getResultsRequest." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a WaterfrontRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.WaterfrontRequest} WaterfrontRequest
             */
            WaterfrontRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.WaterfrontRequest)
                    return object;
                var message = new $root.waterfront.WaterfrontRequest();
                if (object.sqlQueryRequest != null) {
                    if (typeof object.sqlQueryRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.sqlQueryRequest: object expected");
                    message.sqlQueryRequest = $root.waterfront.SqlQueryRequest.fromObject(object.sqlQueryRequest);
                }
                if (object.createDataRoomRequest != null) {
                    if (typeof object.createDataRoomRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.createDataRoomRequest: object expected");
                    message.createDataRoomRequest = $root.waterfront.CreateDataRoomRequest.fromObject(object.createDataRoomRequest);
                }
                if (object.publishDatasetToDataRoomRequest != null) {
                    if (typeof object.publishDatasetToDataRoomRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.publishDatasetToDataRoomRequest: object expected");
                    message.publishDatasetToDataRoomRequest = $root.waterfront.PublishDatasetToDataRoomRequest.fromObject(object.publishDatasetToDataRoomRequest);
                }
                if (object.retrieveDataRoomRequest != null) {
                    if (typeof object.retrieveDataRoomRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.retrieveDataRoomRequest: object expected");
                    message.retrieveDataRoomRequest = $root.waterfront.RetrieveDataRoomRequest.fromObject(object.retrieveDataRoomRequest);
                }
                if (object.validateDatasetRequest != null) {
                    if (typeof object.validateDatasetRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.validateDatasetRequest: object expected");
                    message.validateDatasetRequest = $root.waterfront.ValidateDatasetRequest.fromObject(object.validateDatasetRequest);
                }
                if (object.retrieveAuditLogRequest != null) {
                    if (typeof object.retrieveAuditLogRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.retrieveAuditLogRequest: object expected");
                    message.retrieveAuditLogRequest = $root.waterfront.RetrieveAuditLogRequest.fromObject(object.retrieveAuditLogRequest);
                }
                if (object.retrieveDataRoomStatusRequest != null) {
                    if (typeof object.retrieveDataRoomStatusRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.retrieveDataRoomStatusRequest: object expected");
                    message.retrieveDataRoomStatusRequest = $root.waterfront.RetrieveDataRoomStatusRequest.fromObject(object.retrieveDataRoomStatusRequest);
                }
                if (object.updateDataRoomStatusRequest != null) {
                    if (typeof object.updateDataRoomStatusRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.updateDataRoomStatusRequest: object expected");
                    message.updateDataRoomStatusRequest = $root.waterfront.UpdateDataRoomStatusRequest.fromObject(object.updateDataRoomStatusRequest);
                }
                if (object.retrievePublishedDatasetRequest != null) {
                    if (typeof object.retrievePublishedDatasetRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.retrievePublishedDatasetRequest: object expected");
                    message.retrievePublishedDatasetRequest = $root.waterfront.RetrievePublishedDatasetRequest.fromObject(object.retrievePublishedDatasetRequest);
                }
                if (object.removePublishedDatasetRequest != null) {
                    if (typeof object.removePublishedDatasetRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.removePublishedDatasetRequest: object expected");
                    message.removePublishedDatasetRequest = $root.waterfront.RemovePublishedDatasetRequest.fromObject(object.removePublishedDatasetRequest);
                }
                if (object.jobStatusRequest != null) {
                    if (typeof object.jobStatusRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.jobStatusRequest: object expected");
                    message.jobStatusRequest = $root.waterfront.JobStatusRequest.fromObject(object.jobStatusRequest);
                }
                if (object.getResultsRequest != null) {
                    if (typeof object.getResultsRequest !== "object")
                        throw TypeError(".waterfront.WaterfrontRequest.getResultsRequest: object expected");
                    message.getResultsRequest = $root.waterfront.GetResultsRequest.fromObject(object.getResultsRequest);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a WaterfrontRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.WaterfrontRequest
             * @static
             * @param {waterfront.WaterfrontRequest} message WaterfrontRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WaterfrontRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.sqlQueryRequest != null && message.hasOwnProperty("sqlQueryRequest")) {
                    object.sqlQueryRequest = $root.waterfront.SqlQueryRequest.toObject(message.sqlQueryRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "sqlQueryRequest";
                }
                if (message.createDataRoomRequest != null && message.hasOwnProperty("createDataRoomRequest")) {
                    object.createDataRoomRequest = $root.waterfront.CreateDataRoomRequest.toObject(message.createDataRoomRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "createDataRoomRequest";
                }
                if (message.publishDatasetToDataRoomRequest != null && message.hasOwnProperty("publishDatasetToDataRoomRequest")) {
                    object.publishDatasetToDataRoomRequest = $root.waterfront.PublishDatasetToDataRoomRequest.toObject(message.publishDatasetToDataRoomRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "publishDatasetToDataRoomRequest";
                }
                if (message.retrieveDataRoomRequest != null && message.hasOwnProperty("retrieveDataRoomRequest")) {
                    object.retrieveDataRoomRequest = $root.waterfront.RetrieveDataRoomRequest.toObject(message.retrieveDataRoomRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "retrieveDataRoomRequest";
                }
                if (message.validateDatasetRequest != null && message.hasOwnProperty("validateDatasetRequest")) {
                    object.validateDatasetRequest = $root.waterfront.ValidateDatasetRequest.toObject(message.validateDatasetRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "validateDatasetRequest";
                }
                if (message.retrieveAuditLogRequest != null && message.hasOwnProperty("retrieveAuditLogRequest")) {
                    object.retrieveAuditLogRequest = $root.waterfront.RetrieveAuditLogRequest.toObject(message.retrieveAuditLogRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "retrieveAuditLogRequest";
                }
                if (message.retrieveDataRoomStatusRequest != null && message.hasOwnProperty("retrieveDataRoomStatusRequest")) {
                    object.retrieveDataRoomStatusRequest = $root.waterfront.RetrieveDataRoomStatusRequest.toObject(message.retrieveDataRoomStatusRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "retrieveDataRoomStatusRequest";
                }
                if (message.updateDataRoomStatusRequest != null && message.hasOwnProperty("updateDataRoomStatusRequest")) {
                    object.updateDataRoomStatusRequest = $root.waterfront.UpdateDataRoomStatusRequest.toObject(message.updateDataRoomStatusRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "updateDataRoomStatusRequest";
                }
                if (message.retrievePublishedDatasetRequest != null && message.hasOwnProperty("retrievePublishedDatasetRequest")) {
                    object.retrievePublishedDatasetRequest = $root.waterfront.RetrievePublishedDatasetRequest.toObject(message.retrievePublishedDatasetRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "retrievePublishedDatasetRequest";
                }
                if (message.removePublishedDatasetRequest != null && message.hasOwnProperty("removePublishedDatasetRequest")) {
                    object.removePublishedDatasetRequest = $root.waterfront.RemovePublishedDatasetRequest.toObject(message.removePublishedDatasetRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "removePublishedDatasetRequest";
                }
                if (message.jobStatusRequest != null && message.hasOwnProperty("jobStatusRequest")) {
                    object.jobStatusRequest = $root.waterfront.JobStatusRequest.toObject(message.jobStatusRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "jobStatusRequest";
                }
                if (message.getResultsRequest != null && message.hasOwnProperty("getResultsRequest")) {
                    object.getResultsRequest = $root.waterfront.GetResultsRequest.toObject(message.getResultsRequest, options);
                    if (options.oneofs)
                        object.waterfrontRequest = "getResultsRequest";
                }
                return object;
            };
    
            /**
             * Converts this WaterfrontRequest to JSON.
             * @function toJSON
             * @memberof waterfront.WaterfrontRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WaterfrontRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return WaterfrontRequest;
        })();
    
        waterfront.WaterfrontResponse = (function() {
    
            /**
             * Properties of a WaterfrontResponse.
             * @memberof waterfront
             * @interface IWaterfrontResponse
             * @property {string|null} [failure] WaterfrontResponse failure
             * @property {waterfront.ISqlQueryResponse|null} [sqlQueryResponse] WaterfrontResponse sqlQueryResponse
             * @property {waterfront.ICreateDataRoomResponse|null} [createDataRoomResponse] WaterfrontResponse createDataRoomResponse
             * @property {waterfront.IPublishDatasetToDataRoomResponse|null} [publishDatasetToDataRoomResponse] WaterfrontResponse publishDatasetToDataRoomResponse
             * @property {waterfront.IRetrieveDataRoomResponse|null} [retrieveDataRoomResponse] WaterfrontResponse retrieveDataRoomResponse
             * @property {waterfront.IValidateDatasetResponse|null} [validateDatasetResponse] WaterfrontResponse validateDatasetResponse
             * @property {waterfront.IRetrieveAuditLogResponse|null} [retrieveAuditLogResponse] WaterfrontResponse retrieveAuditLogResponse
             * @property {waterfront.IRetrieveDataRoomStatusResponse|null} [retrieveDataRoomStatusResponse] WaterfrontResponse retrieveDataRoomStatusResponse
             * @property {waterfront.IUpdateDataRoomStatusResponse|null} [updateDataRoomStatusResponse] WaterfrontResponse updateDataRoomStatusResponse
             * @property {waterfront.IRetrievePublishedDatasetResponse|null} [retrievePublishedDatasetResponse] WaterfrontResponse retrievePublishedDatasetResponse
             * @property {waterfront.IRemovePublishedDatasetResponse|null} [removePublishedDatasetResponse] WaterfrontResponse removePublishedDatasetResponse
             * @property {waterfront.IJobStatusResponse|null} [jobStatusResponse] WaterfrontResponse jobStatusResponse
             */
    
            /**
             * Constructs a new WaterfrontResponse.
             * @memberof waterfront
             * @classdesc Represents a WaterfrontResponse.
             * @implements IWaterfrontResponse
             * @constructor
             * @param {waterfront.IWaterfrontResponse=} [properties] Properties to set
             */
            function WaterfrontResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * WaterfrontResponse failure.
             * @member {string} failure
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.failure = "";
    
            /**
             * WaterfrontResponse sqlQueryResponse.
             * @member {waterfront.ISqlQueryResponse|null|undefined} sqlQueryResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.sqlQueryResponse = null;
    
            /**
             * WaterfrontResponse createDataRoomResponse.
             * @member {waterfront.ICreateDataRoomResponse|null|undefined} createDataRoomResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.createDataRoomResponse = null;
    
            /**
             * WaterfrontResponse publishDatasetToDataRoomResponse.
             * @member {waterfront.IPublishDatasetToDataRoomResponse|null|undefined} publishDatasetToDataRoomResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.publishDatasetToDataRoomResponse = null;
    
            /**
             * WaterfrontResponse retrieveDataRoomResponse.
             * @member {waterfront.IRetrieveDataRoomResponse|null|undefined} retrieveDataRoomResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.retrieveDataRoomResponse = null;
    
            /**
             * WaterfrontResponse validateDatasetResponse.
             * @member {waterfront.IValidateDatasetResponse|null|undefined} validateDatasetResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.validateDatasetResponse = null;
    
            /**
             * WaterfrontResponse retrieveAuditLogResponse.
             * @member {waterfront.IRetrieveAuditLogResponse|null|undefined} retrieveAuditLogResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.retrieveAuditLogResponse = null;
    
            /**
             * WaterfrontResponse retrieveDataRoomStatusResponse.
             * @member {waterfront.IRetrieveDataRoomStatusResponse|null|undefined} retrieveDataRoomStatusResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.retrieveDataRoomStatusResponse = null;
    
            /**
             * WaterfrontResponse updateDataRoomStatusResponse.
             * @member {waterfront.IUpdateDataRoomStatusResponse|null|undefined} updateDataRoomStatusResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.updateDataRoomStatusResponse = null;
    
            /**
             * WaterfrontResponse retrievePublishedDatasetResponse.
             * @member {waterfront.IRetrievePublishedDatasetResponse|null|undefined} retrievePublishedDatasetResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.retrievePublishedDatasetResponse = null;
    
            /**
             * WaterfrontResponse removePublishedDatasetResponse.
             * @member {waterfront.IRemovePublishedDatasetResponse|null|undefined} removePublishedDatasetResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.removePublishedDatasetResponse = null;
    
            /**
             * WaterfrontResponse jobStatusResponse.
             * @member {waterfront.IJobStatusResponse|null|undefined} jobStatusResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            WaterfrontResponse.prototype.jobStatusResponse = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * WaterfrontResponse waterfrontResponse.
             * @member {"failure"|"sqlQueryResponse"|"createDataRoomResponse"|"publishDatasetToDataRoomResponse"|"retrieveDataRoomResponse"|"validateDatasetResponse"|"retrieveAuditLogResponse"|"retrieveDataRoomStatusResponse"|"updateDataRoomStatusResponse"|"retrievePublishedDatasetResponse"|"removePublishedDatasetResponse"|"jobStatusResponse"|undefined} waterfrontResponse
             * @memberof waterfront.WaterfrontResponse
             * @instance
             */
            Object.defineProperty(WaterfrontResponse.prototype, "waterfrontResponse", {
                get: $util.oneOfGetter($oneOfFields = ["failure", "sqlQueryResponse", "createDataRoomResponse", "publishDatasetToDataRoomResponse", "retrieveDataRoomResponse", "validateDatasetResponse", "retrieveAuditLogResponse", "retrieveDataRoomStatusResponse", "updateDataRoomStatusResponse", "retrievePublishedDatasetResponse", "removePublishedDatasetResponse", "jobStatusResponse"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new WaterfrontResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {waterfront.IWaterfrontResponse=} [properties] Properties to set
             * @returns {waterfront.WaterfrontResponse} WaterfrontResponse instance
             */
            WaterfrontResponse.create = function create(properties) {
                return new WaterfrontResponse(properties);
            };
    
            /**
             * Encodes the specified WaterfrontResponse message. Does not implicitly {@link waterfront.WaterfrontResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {waterfront.IWaterfrontResponse} message WaterfrontResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WaterfrontResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.failure != null && Object.hasOwnProperty.call(message, "failure"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.failure);
                if (message.sqlQueryResponse != null && Object.hasOwnProperty.call(message, "sqlQueryResponse"))
                    $root.waterfront.SqlQueryResponse.encode(message.sqlQueryResponse, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.createDataRoomResponse != null && Object.hasOwnProperty.call(message, "createDataRoomResponse"))
                    $root.waterfront.CreateDataRoomResponse.encode(message.createDataRoomResponse, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.publishDatasetToDataRoomResponse != null && Object.hasOwnProperty.call(message, "publishDatasetToDataRoomResponse"))
                    $root.waterfront.PublishDatasetToDataRoomResponse.encode(message.publishDatasetToDataRoomResponse, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.retrieveDataRoomResponse != null && Object.hasOwnProperty.call(message, "retrieveDataRoomResponse"))
                    $root.waterfront.RetrieveDataRoomResponse.encode(message.retrieveDataRoomResponse, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.validateDatasetResponse != null && Object.hasOwnProperty.call(message, "validateDatasetResponse"))
                    $root.waterfront.ValidateDatasetResponse.encode(message.validateDatasetResponse, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.retrieveAuditLogResponse != null && Object.hasOwnProperty.call(message, "retrieveAuditLogResponse"))
                    $root.waterfront.RetrieveAuditLogResponse.encode(message.retrieveAuditLogResponse, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.retrieveDataRoomStatusResponse != null && Object.hasOwnProperty.call(message, "retrieveDataRoomStatusResponse"))
                    $root.waterfront.RetrieveDataRoomStatusResponse.encode(message.retrieveDataRoomStatusResponse, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.updateDataRoomStatusResponse != null && Object.hasOwnProperty.call(message, "updateDataRoomStatusResponse"))
                    $root.waterfront.UpdateDataRoomStatusResponse.encode(message.updateDataRoomStatusResponse, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.retrievePublishedDatasetResponse != null && Object.hasOwnProperty.call(message, "retrievePublishedDatasetResponse"))
                    $root.waterfront.RetrievePublishedDatasetResponse.encode(message.retrievePublishedDatasetResponse, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.removePublishedDatasetResponse != null && Object.hasOwnProperty.call(message, "removePublishedDatasetResponse"))
                    $root.waterfront.RemovePublishedDatasetResponse.encode(message.removePublishedDatasetResponse, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.jobStatusResponse != null && Object.hasOwnProperty.call(message, "jobStatusResponse"))
                    $root.waterfront.JobStatusResponse.encode(message.jobStatusResponse, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified WaterfrontResponse message, length delimited. Does not implicitly {@link waterfront.WaterfrontResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {waterfront.IWaterfrontResponse} message WaterfrontResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WaterfrontResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a WaterfrontResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.WaterfrontResponse} WaterfrontResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WaterfrontResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.WaterfrontResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.failure = reader.string();
                        break;
                    case 3:
                        message.sqlQueryResponse = $root.waterfront.SqlQueryResponse.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.createDataRoomResponse = $root.waterfront.CreateDataRoomResponse.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.publishDatasetToDataRoomResponse = $root.waterfront.PublishDatasetToDataRoomResponse.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.retrieveDataRoomResponse = $root.waterfront.RetrieveDataRoomResponse.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.validateDatasetResponse = $root.waterfront.ValidateDatasetResponse.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.retrieveAuditLogResponse = $root.waterfront.RetrieveAuditLogResponse.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.retrieveDataRoomStatusResponse = $root.waterfront.RetrieveDataRoomStatusResponse.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.updateDataRoomStatusResponse = $root.waterfront.UpdateDataRoomStatusResponse.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.retrievePublishedDatasetResponse = $root.waterfront.RetrievePublishedDatasetResponse.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.removePublishedDatasetResponse = $root.waterfront.RemovePublishedDatasetResponse.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.jobStatusResponse = $root.waterfront.JobStatusResponse.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a WaterfrontResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.WaterfrontResponse} WaterfrontResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WaterfrontResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a WaterfrontResponse message.
             * @function verify
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WaterfrontResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.failure != null && message.hasOwnProperty("failure")) {
                    properties.waterfrontResponse = 1;
                    if (!$util.isString(message.failure))
                        return "failure: string expected";
                }
                if (message.sqlQueryResponse != null && message.hasOwnProperty("sqlQueryResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.SqlQueryResponse.verify(message.sqlQueryResponse);
                        if (error)
                            return "sqlQueryResponse." + error;
                    }
                }
                if (message.createDataRoomResponse != null && message.hasOwnProperty("createDataRoomResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.CreateDataRoomResponse.verify(message.createDataRoomResponse);
                        if (error)
                            return "createDataRoomResponse." + error;
                    }
                }
                if (message.publishDatasetToDataRoomResponse != null && message.hasOwnProperty("publishDatasetToDataRoomResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.PublishDatasetToDataRoomResponse.verify(message.publishDatasetToDataRoomResponse);
                        if (error)
                            return "publishDatasetToDataRoomResponse." + error;
                    }
                }
                if (message.retrieveDataRoomResponse != null && message.hasOwnProperty("retrieveDataRoomResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.RetrieveDataRoomResponse.verify(message.retrieveDataRoomResponse);
                        if (error)
                            return "retrieveDataRoomResponse." + error;
                    }
                }
                if (message.validateDatasetResponse != null && message.hasOwnProperty("validateDatasetResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.ValidateDatasetResponse.verify(message.validateDatasetResponse);
                        if (error)
                            return "validateDatasetResponse." + error;
                    }
                }
                if (message.retrieveAuditLogResponse != null && message.hasOwnProperty("retrieveAuditLogResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.RetrieveAuditLogResponse.verify(message.retrieveAuditLogResponse);
                        if (error)
                            return "retrieveAuditLogResponse." + error;
                    }
                }
                if (message.retrieveDataRoomStatusResponse != null && message.hasOwnProperty("retrieveDataRoomStatusResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.RetrieveDataRoomStatusResponse.verify(message.retrieveDataRoomStatusResponse);
                        if (error)
                            return "retrieveDataRoomStatusResponse." + error;
                    }
                }
                if (message.updateDataRoomStatusResponse != null && message.hasOwnProperty("updateDataRoomStatusResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.UpdateDataRoomStatusResponse.verify(message.updateDataRoomStatusResponse);
                        if (error)
                            return "updateDataRoomStatusResponse." + error;
                    }
                }
                if (message.retrievePublishedDatasetResponse != null && message.hasOwnProperty("retrievePublishedDatasetResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.RetrievePublishedDatasetResponse.verify(message.retrievePublishedDatasetResponse);
                        if (error)
                            return "retrievePublishedDatasetResponse." + error;
                    }
                }
                if (message.removePublishedDatasetResponse != null && message.hasOwnProperty("removePublishedDatasetResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.RemovePublishedDatasetResponse.verify(message.removePublishedDatasetResponse);
                        if (error)
                            return "removePublishedDatasetResponse." + error;
                    }
                }
                if (message.jobStatusResponse != null && message.hasOwnProperty("jobStatusResponse")) {
                    if (properties.waterfrontResponse === 1)
                        return "waterfrontResponse: multiple values";
                    properties.waterfrontResponse = 1;
                    {
                        var error = $root.waterfront.JobStatusResponse.verify(message.jobStatusResponse);
                        if (error)
                            return "jobStatusResponse." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a WaterfrontResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.WaterfrontResponse} WaterfrontResponse
             */
            WaterfrontResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.WaterfrontResponse)
                    return object;
                var message = new $root.waterfront.WaterfrontResponse();
                if (object.failure != null)
                    message.failure = String(object.failure);
                if (object.sqlQueryResponse != null) {
                    if (typeof object.sqlQueryResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.sqlQueryResponse: object expected");
                    message.sqlQueryResponse = $root.waterfront.SqlQueryResponse.fromObject(object.sqlQueryResponse);
                }
                if (object.createDataRoomResponse != null) {
                    if (typeof object.createDataRoomResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.createDataRoomResponse: object expected");
                    message.createDataRoomResponse = $root.waterfront.CreateDataRoomResponse.fromObject(object.createDataRoomResponse);
                }
                if (object.publishDatasetToDataRoomResponse != null) {
                    if (typeof object.publishDatasetToDataRoomResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.publishDatasetToDataRoomResponse: object expected");
                    message.publishDatasetToDataRoomResponse = $root.waterfront.PublishDatasetToDataRoomResponse.fromObject(object.publishDatasetToDataRoomResponse);
                }
                if (object.retrieveDataRoomResponse != null) {
                    if (typeof object.retrieveDataRoomResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.retrieveDataRoomResponse: object expected");
                    message.retrieveDataRoomResponse = $root.waterfront.RetrieveDataRoomResponse.fromObject(object.retrieveDataRoomResponse);
                }
                if (object.validateDatasetResponse != null) {
                    if (typeof object.validateDatasetResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.validateDatasetResponse: object expected");
                    message.validateDatasetResponse = $root.waterfront.ValidateDatasetResponse.fromObject(object.validateDatasetResponse);
                }
                if (object.retrieveAuditLogResponse != null) {
                    if (typeof object.retrieveAuditLogResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.retrieveAuditLogResponse: object expected");
                    message.retrieveAuditLogResponse = $root.waterfront.RetrieveAuditLogResponse.fromObject(object.retrieveAuditLogResponse);
                }
                if (object.retrieveDataRoomStatusResponse != null) {
                    if (typeof object.retrieveDataRoomStatusResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.retrieveDataRoomStatusResponse: object expected");
                    message.retrieveDataRoomStatusResponse = $root.waterfront.RetrieveDataRoomStatusResponse.fromObject(object.retrieveDataRoomStatusResponse);
                }
                if (object.updateDataRoomStatusResponse != null) {
                    if (typeof object.updateDataRoomStatusResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.updateDataRoomStatusResponse: object expected");
                    message.updateDataRoomStatusResponse = $root.waterfront.UpdateDataRoomStatusResponse.fromObject(object.updateDataRoomStatusResponse);
                }
                if (object.retrievePublishedDatasetResponse != null) {
                    if (typeof object.retrievePublishedDatasetResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.retrievePublishedDatasetResponse: object expected");
                    message.retrievePublishedDatasetResponse = $root.waterfront.RetrievePublishedDatasetResponse.fromObject(object.retrievePublishedDatasetResponse);
                }
                if (object.removePublishedDatasetResponse != null) {
                    if (typeof object.removePublishedDatasetResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.removePublishedDatasetResponse: object expected");
                    message.removePublishedDatasetResponse = $root.waterfront.RemovePublishedDatasetResponse.fromObject(object.removePublishedDatasetResponse);
                }
                if (object.jobStatusResponse != null) {
                    if (typeof object.jobStatusResponse !== "object")
                        throw TypeError(".waterfront.WaterfrontResponse.jobStatusResponse: object expected");
                    message.jobStatusResponse = $root.waterfront.JobStatusResponse.fromObject(object.jobStatusResponse);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a WaterfrontResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.WaterfrontResponse
             * @static
             * @param {waterfront.WaterfrontResponse} message WaterfrontResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WaterfrontResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.failure != null && message.hasOwnProperty("failure")) {
                    object.failure = message.failure;
                    if (options.oneofs)
                        object.waterfrontResponse = "failure";
                }
                if (message.sqlQueryResponse != null && message.hasOwnProperty("sqlQueryResponse")) {
                    object.sqlQueryResponse = $root.waterfront.SqlQueryResponse.toObject(message.sqlQueryResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "sqlQueryResponse";
                }
                if (message.createDataRoomResponse != null && message.hasOwnProperty("createDataRoomResponse")) {
                    object.createDataRoomResponse = $root.waterfront.CreateDataRoomResponse.toObject(message.createDataRoomResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "createDataRoomResponse";
                }
                if (message.publishDatasetToDataRoomResponse != null && message.hasOwnProperty("publishDatasetToDataRoomResponse")) {
                    object.publishDatasetToDataRoomResponse = $root.waterfront.PublishDatasetToDataRoomResponse.toObject(message.publishDatasetToDataRoomResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "publishDatasetToDataRoomResponse";
                }
                if (message.retrieveDataRoomResponse != null && message.hasOwnProperty("retrieveDataRoomResponse")) {
                    object.retrieveDataRoomResponse = $root.waterfront.RetrieveDataRoomResponse.toObject(message.retrieveDataRoomResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "retrieveDataRoomResponse";
                }
                if (message.validateDatasetResponse != null && message.hasOwnProperty("validateDatasetResponse")) {
                    object.validateDatasetResponse = $root.waterfront.ValidateDatasetResponse.toObject(message.validateDatasetResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "validateDatasetResponse";
                }
                if (message.retrieveAuditLogResponse != null && message.hasOwnProperty("retrieveAuditLogResponse")) {
                    object.retrieveAuditLogResponse = $root.waterfront.RetrieveAuditLogResponse.toObject(message.retrieveAuditLogResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "retrieveAuditLogResponse";
                }
                if (message.retrieveDataRoomStatusResponse != null && message.hasOwnProperty("retrieveDataRoomStatusResponse")) {
                    object.retrieveDataRoomStatusResponse = $root.waterfront.RetrieveDataRoomStatusResponse.toObject(message.retrieveDataRoomStatusResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "retrieveDataRoomStatusResponse";
                }
                if (message.updateDataRoomStatusResponse != null && message.hasOwnProperty("updateDataRoomStatusResponse")) {
                    object.updateDataRoomStatusResponse = $root.waterfront.UpdateDataRoomStatusResponse.toObject(message.updateDataRoomStatusResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "updateDataRoomStatusResponse";
                }
                if (message.retrievePublishedDatasetResponse != null && message.hasOwnProperty("retrievePublishedDatasetResponse")) {
                    object.retrievePublishedDatasetResponse = $root.waterfront.RetrievePublishedDatasetResponse.toObject(message.retrievePublishedDatasetResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "retrievePublishedDatasetResponse";
                }
                if (message.removePublishedDatasetResponse != null && message.hasOwnProperty("removePublishedDatasetResponse")) {
                    object.removePublishedDatasetResponse = $root.waterfront.RemovePublishedDatasetResponse.toObject(message.removePublishedDatasetResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "removePublishedDatasetResponse";
                }
                if (message.jobStatusResponse != null && message.hasOwnProperty("jobStatusResponse")) {
                    object.jobStatusResponse = $root.waterfront.JobStatusResponse.toObject(message.jobStatusResponse, options);
                    if (options.oneofs)
                        object.waterfrontResponse = "jobStatusResponse";
                }
                return object;
            };
    
            /**
             * Converts this WaterfrontResponse to JSON.
             * @function toJSON
             * @memberof waterfront.WaterfrontResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WaterfrontResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return WaterfrontResponse;
        })();
    
        waterfront.AuthenticationData = (function() {
    
            /**
             * Properties of an AuthenticationData.
             * @memberof waterfront
             * @interface IAuthenticationData
             * @property {string} role AuthenticationData role
             * @property {string|null} [passwordSha256] AuthenticationData passwordSha256
             */
    
            /**
             * Constructs a new AuthenticationData.
             * @memberof waterfront
             * @classdesc Represents an AuthenticationData.
             * @implements IAuthenticationData
             * @constructor
             * @param {waterfront.IAuthenticationData=} [properties] Properties to set
             */
            function AuthenticationData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AuthenticationData role.
             * @member {string} role
             * @memberof waterfront.AuthenticationData
             * @instance
             */
            AuthenticationData.prototype.role = "";
    
            /**
             * AuthenticationData passwordSha256.
             * @member {string} passwordSha256
             * @memberof waterfront.AuthenticationData
             * @instance
             */
            AuthenticationData.prototype.passwordSha256 = "";
    
            /**
             * Creates a new AuthenticationData instance using the specified properties.
             * @function create
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {waterfront.IAuthenticationData=} [properties] Properties to set
             * @returns {waterfront.AuthenticationData} AuthenticationData instance
             */
            AuthenticationData.create = function create(properties) {
                return new AuthenticationData(properties);
            };
    
            /**
             * Encodes the specified AuthenticationData message. Does not implicitly {@link waterfront.AuthenticationData.verify|verify} messages.
             * @function encode
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {waterfront.IAuthenticationData} message AuthenticationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthenticationData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.role);
                if (message.passwordSha256 != null && Object.hasOwnProperty.call(message, "passwordSha256"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.passwordSha256);
                return writer;
            };
    
            /**
             * Encodes the specified AuthenticationData message, length delimited. Does not implicitly {@link waterfront.AuthenticationData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {waterfront.IAuthenticationData} message AuthenticationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthenticationData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuthenticationData message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.AuthenticationData} AuthenticationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthenticationData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.AuthenticationData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.role = reader.string();
                        break;
                    case 2:
                        message.passwordSha256 = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("role"))
                    throw $util.ProtocolError("missing required 'role'", { instance: message });
                return message;
            };
    
            /**
             * Decodes an AuthenticationData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.AuthenticationData} AuthenticationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthenticationData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuthenticationData message.
             * @function verify
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthenticationData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.role))
                    return "role: string expected";
                if (message.passwordSha256 != null && message.hasOwnProperty("passwordSha256"))
                    if (!$util.isString(message.passwordSha256))
                        return "passwordSha256: string expected";
                return null;
            };
    
            /**
             * Creates an AuthenticationData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.AuthenticationData} AuthenticationData
             */
            AuthenticationData.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.AuthenticationData)
                    return object;
                var message = new $root.waterfront.AuthenticationData();
                if (object.role != null)
                    message.role = String(object.role);
                if (object.passwordSha256 != null)
                    message.passwordSha256 = String(object.passwordSha256);
                return message;
            };
    
            /**
             * Creates a plain object from an AuthenticationData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.AuthenticationData
             * @static
             * @param {waterfront.AuthenticationData} message AuthenticationData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthenticationData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.role = "";
                    object.passwordSha256 = "";
                }
                if (message.role != null && message.hasOwnProperty("role"))
                    object.role = message.role;
                if (message.passwordSha256 != null && message.hasOwnProperty("passwordSha256"))
                    object.passwordSha256 = message.passwordSha256;
                return object;
            };
    
            /**
             * Converts this AuthenticationData to JSON.
             * @function toJSON
             * @memberof waterfront.AuthenticationData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthenticationData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AuthenticationData;
        })();
    
        waterfront.CreateDataRoomRequest = (function() {
    
            /**
             * Properties of a CreateDataRoomRequest.
             * @memberof waterfront
             * @interface ICreateDataRoomRequest
             * @property {data_room.IDataRoom} dataRoom CreateDataRoomRequest dataRoom
             */
    
            /**
             * Constructs a new CreateDataRoomRequest.
             * @memberof waterfront
             * @classdesc Represents a CreateDataRoomRequest.
             * @implements ICreateDataRoomRequest
             * @constructor
             * @param {waterfront.ICreateDataRoomRequest=} [properties] Properties to set
             */
            function CreateDataRoomRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateDataRoomRequest dataRoom.
             * @member {data_room.IDataRoom} dataRoom
             * @memberof waterfront.CreateDataRoomRequest
             * @instance
             */
            CreateDataRoomRequest.prototype.dataRoom = null;
    
            /**
             * Creates a new CreateDataRoomRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {waterfront.ICreateDataRoomRequest=} [properties] Properties to set
             * @returns {waterfront.CreateDataRoomRequest} CreateDataRoomRequest instance
             */
            CreateDataRoomRequest.create = function create(properties) {
                return new CreateDataRoomRequest(properties);
            };
    
            /**
             * Encodes the specified CreateDataRoomRequest message. Does not implicitly {@link waterfront.CreateDataRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {waterfront.ICreateDataRoomRequest} message CreateDataRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateDataRoomRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                $root.data_room.DataRoom.encode(message.dataRoom, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateDataRoomRequest message, length delimited. Does not implicitly {@link waterfront.CreateDataRoomRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {waterfront.ICreateDataRoomRequest} message CreateDataRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateDataRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateDataRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.CreateDataRoomRequest} CreateDataRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateDataRoomRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.CreateDataRoomRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoom = $root.data_room.DataRoom.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("dataRoom"))
                    throw $util.ProtocolError("missing required 'dataRoom'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a CreateDataRoomRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.CreateDataRoomRequest} CreateDataRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateDataRoomRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateDataRoomRequest message.
             * @function verify
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateDataRoomRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                {
                    var error = $root.data_room.DataRoom.verify(message.dataRoom);
                    if (error)
                        return "dataRoom." + error;
                }
                return null;
            };
    
            /**
             * Creates a CreateDataRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.CreateDataRoomRequest} CreateDataRoomRequest
             */
            CreateDataRoomRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.CreateDataRoomRequest)
                    return object;
                var message = new $root.waterfront.CreateDataRoomRequest();
                if (object.dataRoom != null) {
                    if (typeof object.dataRoom !== "object")
                        throw TypeError(".waterfront.CreateDataRoomRequest.dataRoom: object expected");
                    message.dataRoom = $root.data_room.DataRoom.fromObject(object.dataRoom);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateDataRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.CreateDataRoomRequest
             * @static
             * @param {waterfront.CreateDataRoomRequest} message CreateDataRoomRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateDataRoomRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.dataRoom = null;
                if (message.dataRoom != null && message.hasOwnProperty("dataRoom"))
                    object.dataRoom = $root.data_room.DataRoom.toObject(message.dataRoom, options);
                return object;
            };
    
            /**
             * Converts this CreateDataRoomRequest to JSON.
             * @function toJSON
             * @memberof waterfront.CreateDataRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateDataRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateDataRoomRequest;
        })();
    
        waterfront.CreateDataRoomResponse = (function() {
    
            /**
             * Properties of a CreateDataRoomResponse.
             * @memberof waterfront
             * @interface ICreateDataRoomResponse
             * @property {Uint8Array|null} [dataRoomHash] CreateDataRoomResponse dataRoomHash
             * @property {waterfront.IDataRoomValidationError|null} [dataRoomValidationError] CreateDataRoomResponse dataRoomValidationError
             */
    
            /**
             * Constructs a new CreateDataRoomResponse.
             * @memberof waterfront
             * @classdesc Represents a CreateDataRoomResponse.
             * @implements ICreateDataRoomResponse
             * @constructor
             * @param {waterfront.ICreateDataRoomResponse=} [properties] Properties to set
             */
            function CreateDataRoomResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateDataRoomResponse dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.CreateDataRoomResponse
             * @instance
             */
            CreateDataRoomResponse.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * CreateDataRoomResponse dataRoomValidationError.
             * @member {waterfront.IDataRoomValidationError|null|undefined} dataRoomValidationError
             * @memberof waterfront.CreateDataRoomResponse
             * @instance
             */
            CreateDataRoomResponse.prototype.dataRoomValidationError = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * CreateDataRoomResponse createDataRoomResponse.
             * @member {"dataRoomHash"|"dataRoomValidationError"|undefined} createDataRoomResponse
             * @memberof waterfront.CreateDataRoomResponse
             * @instance
             */
            Object.defineProperty(CreateDataRoomResponse.prototype, "createDataRoomResponse", {
                get: $util.oneOfGetter($oneOfFields = ["dataRoomHash", "dataRoomValidationError"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new CreateDataRoomResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {waterfront.ICreateDataRoomResponse=} [properties] Properties to set
             * @returns {waterfront.CreateDataRoomResponse} CreateDataRoomResponse instance
             */
            CreateDataRoomResponse.create = function create(properties) {
                return new CreateDataRoomResponse(properties);
            };
    
            /**
             * Encodes the specified CreateDataRoomResponse message. Does not implicitly {@link waterfront.CreateDataRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {waterfront.ICreateDataRoomResponse} message CreateDataRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateDataRoomResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dataRoomHash != null && Object.hasOwnProperty.call(message, "dataRoomHash"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomHash);
                if (message.dataRoomValidationError != null && Object.hasOwnProperty.call(message, "dataRoomValidationError"))
                    $root.waterfront.DataRoomValidationError.encode(message.dataRoomValidationError, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateDataRoomResponse message, length delimited. Does not implicitly {@link waterfront.CreateDataRoomResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {waterfront.ICreateDataRoomResponse} message CreateDataRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateDataRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateDataRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.CreateDataRoomResponse} CreateDataRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateDataRoomResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.CreateDataRoomResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 2:
                        message.dataRoomValidationError = $root.waterfront.DataRoomValidationError.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateDataRoomResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.CreateDataRoomResponse} CreateDataRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateDataRoomResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateDataRoomResponse message.
             * @function verify
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateDataRoomResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash")) {
                    properties.createDataRoomResponse = 1;
                    if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                        return "dataRoomHash: buffer expected";
                }
                if (message.dataRoomValidationError != null && message.hasOwnProperty("dataRoomValidationError")) {
                    if (properties.createDataRoomResponse === 1)
                        return "createDataRoomResponse: multiple values";
                    properties.createDataRoomResponse = 1;
                    {
                        var error = $root.waterfront.DataRoomValidationError.verify(message.dataRoomValidationError);
                        if (error)
                            return "dataRoomValidationError." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CreateDataRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.CreateDataRoomResponse} CreateDataRoomResponse
             */
            CreateDataRoomResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.CreateDataRoomResponse)
                    return object;
                var message = new $root.waterfront.CreateDataRoomResponse();
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.dataRoomValidationError != null) {
                    if (typeof object.dataRoomValidationError !== "object")
                        throw TypeError(".waterfront.CreateDataRoomResponse.dataRoomValidationError: object expected");
                    message.dataRoomValidationError = $root.waterfront.DataRoomValidationError.fromObject(object.dataRoomValidationError);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateDataRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.CreateDataRoomResponse
             * @static
             * @param {waterfront.CreateDataRoomResponse} message CreateDataRoomResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateDataRoomResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash")) {
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                    if (options.oneofs)
                        object.createDataRoomResponse = "dataRoomHash";
                }
                if (message.dataRoomValidationError != null && message.hasOwnProperty("dataRoomValidationError")) {
                    object.dataRoomValidationError = $root.waterfront.DataRoomValidationError.toObject(message.dataRoomValidationError, options);
                    if (options.oneofs)
                        object.createDataRoomResponse = "dataRoomValidationError";
                }
                return object;
            };
    
            /**
             * Converts this CreateDataRoomResponse to JSON.
             * @function toJSON
             * @memberof waterfront.CreateDataRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateDataRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CreateDataRoomResponse;
        })();
    
        waterfront.DataRoomValidationError = (function() {
    
            /**
             * Properties of a DataRoomValidationError.
             * @memberof waterfront
             * @interface IDataRoomValidationError
             * @property {string} message DataRoomValidationError message
             * @property {number|Long|null} [queryIndex] DataRoomValidationError queryIndex
             * @property {number|Long|null} [columnIndex] DataRoomValidationError columnIndex
             * @property {number|Long|null} [tableIndex] DataRoomValidationError tableIndex
             * @property {number|Long|null} [roleIndex] DataRoomValidationError roleIndex
             * @property {number|Long|null} [permissionIndex] DataRoomValidationError permissionIndex
             * @property {number|Long|null} [constraintIndex] DataRoomValidationError constraintIndex
             */
    
            /**
             * Constructs a new DataRoomValidationError.
             * @memberof waterfront
             * @classdesc Represents a DataRoomValidationError.
             * @implements IDataRoomValidationError
             * @constructor
             * @param {waterfront.IDataRoomValidationError=} [properties] Properties to set
             */
            function DataRoomValidationError(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DataRoomValidationError message.
             * @member {string} message
             * @memberof waterfront.DataRoomValidationError
             * @instance
             */
            DataRoomValidationError.prototype.message = "";
    
            /**
             * DataRoomValidationError queryIndex.
             * @member {number|Long} queryIndex
             * @memberof waterfront.DataRoomValidationError
             * @instance
             */
            DataRoomValidationError.prototype.queryIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * DataRoomValidationError columnIndex.
             * @member {number|Long} columnIndex
             * @memberof waterfront.DataRoomValidationError
             * @instance
             */
            DataRoomValidationError.prototype.columnIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * DataRoomValidationError tableIndex.
             * @member {number|Long} tableIndex
             * @memberof waterfront.DataRoomValidationError
             * @instance
             */
            DataRoomValidationError.prototype.tableIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * DataRoomValidationError roleIndex.
             * @member {number|Long} roleIndex
             * @memberof waterfront.DataRoomValidationError
             * @instance
             */
            DataRoomValidationError.prototype.roleIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * DataRoomValidationError permissionIndex.
             * @member {number|Long} permissionIndex
             * @memberof waterfront.DataRoomValidationError
             * @instance
             */
            DataRoomValidationError.prototype.permissionIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * DataRoomValidationError constraintIndex.
             * @member {number|Long} constraintIndex
             * @memberof waterfront.DataRoomValidationError
             * @instance
             */
            DataRoomValidationError.prototype.constraintIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Creates a new DataRoomValidationError instance using the specified properties.
             * @function create
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {waterfront.IDataRoomValidationError=} [properties] Properties to set
             * @returns {waterfront.DataRoomValidationError} DataRoomValidationError instance
             */
            DataRoomValidationError.create = function create(properties) {
                return new DataRoomValidationError(properties);
            };
    
            /**
             * Encodes the specified DataRoomValidationError message. Does not implicitly {@link waterfront.DataRoomValidationError.verify|verify} messages.
             * @function encode
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {waterfront.IDataRoomValidationError} message DataRoomValidationError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataRoomValidationError.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
                if (message.queryIndex != null && Object.hasOwnProperty.call(message, "queryIndex"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.queryIndex);
                if (message.columnIndex != null && Object.hasOwnProperty.call(message, "columnIndex"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.columnIndex);
                if (message.tableIndex != null && Object.hasOwnProperty.call(message, "tableIndex"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.tableIndex);
                if (message.roleIndex != null && Object.hasOwnProperty.call(message, "roleIndex"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.roleIndex);
                if (message.permissionIndex != null && Object.hasOwnProperty.call(message, "permissionIndex"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.permissionIndex);
                if (message.constraintIndex != null && Object.hasOwnProperty.call(message, "constraintIndex"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.constraintIndex);
                return writer;
            };
    
            /**
             * Encodes the specified DataRoomValidationError message, length delimited. Does not implicitly {@link waterfront.DataRoomValidationError.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {waterfront.IDataRoomValidationError} message DataRoomValidationError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataRoomValidationError.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DataRoomValidationError message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.DataRoomValidationError} DataRoomValidationError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataRoomValidationError.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.DataRoomValidationError();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.message = reader.string();
                        break;
                    case 2:
                        message.queryIndex = reader.uint64();
                        break;
                    case 3:
                        message.columnIndex = reader.uint64();
                        break;
                    case 4:
                        message.tableIndex = reader.uint64();
                        break;
                    case 5:
                        message.roleIndex = reader.uint64();
                        break;
                    case 6:
                        message.permissionIndex = reader.uint64();
                        break;
                    case 7:
                        message.constraintIndex = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("message"))
                    throw $util.ProtocolError("missing required 'message'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a DataRoomValidationError message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.DataRoomValidationError} DataRoomValidationError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataRoomValidationError.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DataRoomValidationError message.
             * @function verify
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataRoomValidationError.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.message))
                    return "message: string expected";
                if (message.queryIndex != null && message.hasOwnProperty("queryIndex"))
                    if (!$util.isInteger(message.queryIndex) && !(message.queryIndex && $util.isInteger(message.queryIndex.low) && $util.isInteger(message.queryIndex.high)))
                        return "queryIndex: integer|Long expected";
                if (message.columnIndex != null && message.hasOwnProperty("columnIndex"))
                    if (!$util.isInteger(message.columnIndex) && !(message.columnIndex && $util.isInteger(message.columnIndex.low) && $util.isInteger(message.columnIndex.high)))
                        return "columnIndex: integer|Long expected";
                if (message.tableIndex != null && message.hasOwnProperty("tableIndex"))
                    if (!$util.isInteger(message.tableIndex) && !(message.tableIndex && $util.isInteger(message.tableIndex.low) && $util.isInteger(message.tableIndex.high)))
                        return "tableIndex: integer|Long expected";
                if (message.roleIndex != null && message.hasOwnProperty("roleIndex"))
                    if (!$util.isInteger(message.roleIndex) && !(message.roleIndex && $util.isInteger(message.roleIndex.low) && $util.isInteger(message.roleIndex.high)))
                        return "roleIndex: integer|Long expected";
                if (message.permissionIndex != null && message.hasOwnProperty("permissionIndex"))
                    if (!$util.isInteger(message.permissionIndex) && !(message.permissionIndex && $util.isInteger(message.permissionIndex.low) && $util.isInteger(message.permissionIndex.high)))
                        return "permissionIndex: integer|Long expected";
                if (message.constraintIndex != null && message.hasOwnProperty("constraintIndex"))
                    if (!$util.isInteger(message.constraintIndex) && !(message.constraintIndex && $util.isInteger(message.constraintIndex.low) && $util.isInteger(message.constraintIndex.high)))
                        return "constraintIndex: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a DataRoomValidationError message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.DataRoomValidationError} DataRoomValidationError
             */
            DataRoomValidationError.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.DataRoomValidationError)
                    return object;
                var message = new $root.waterfront.DataRoomValidationError();
                if (object.message != null)
                    message.message = String(object.message);
                if (object.queryIndex != null)
                    if ($util.Long)
                        (message.queryIndex = $util.Long.fromValue(object.queryIndex)).unsigned = true;
                    else if (typeof object.queryIndex === "string")
                        message.queryIndex = parseInt(object.queryIndex, 10);
                    else if (typeof object.queryIndex === "number")
                        message.queryIndex = object.queryIndex;
                    else if (typeof object.queryIndex === "object")
                        message.queryIndex = new $util.LongBits(object.queryIndex.low >>> 0, object.queryIndex.high >>> 0).toNumber(true);
                if (object.columnIndex != null)
                    if ($util.Long)
                        (message.columnIndex = $util.Long.fromValue(object.columnIndex)).unsigned = true;
                    else if (typeof object.columnIndex === "string")
                        message.columnIndex = parseInt(object.columnIndex, 10);
                    else if (typeof object.columnIndex === "number")
                        message.columnIndex = object.columnIndex;
                    else if (typeof object.columnIndex === "object")
                        message.columnIndex = new $util.LongBits(object.columnIndex.low >>> 0, object.columnIndex.high >>> 0).toNumber(true);
                if (object.tableIndex != null)
                    if ($util.Long)
                        (message.tableIndex = $util.Long.fromValue(object.tableIndex)).unsigned = true;
                    else if (typeof object.tableIndex === "string")
                        message.tableIndex = parseInt(object.tableIndex, 10);
                    else if (typeof object.tableIndex === "number")
                        message.tableIndex = object.tableIndex;
                    else if (typeof object.tableIndex === "object")
                        message.tableIndex = new $util.LongBits(object.tableIndex.low >>> 0, object.tableIndex.high >>> 0).toNumber(true);
                if (object.roleIndex != null)
                    if ($util.Long)
                        (message.roleIndex = $util.Long.fromValue(object.roleIndex)).unsigned = true;
                    else if (typeof object.roleIndex === "string")
                        message.roleIndex = parseInt(object.roleIndex, 10);
                    else if (typeof object.roleIndex === "number")
                        message.roleIndex = object.roleIndex;
                    else if (typeof object.roleIndex === "object")
                        message.roleIndex = new $util.LongBits(object.roleIndex.low >>> 0, object.roleIndex.high >>> 0).toNumber(true);
                if (object.permissionIndex != null)
                    if ($util.Long)
                        (message.permissionIndex = $util.Long.fromValue(object.permissionIndex)).unsigned = true;
                    else if (typeof object.permissionIndex === "string")
                        message.permissionIndex = parseInt(object.permissionIndex, 10);
                    else if (typeof object.permissionIndex === "number")
                        message.permissionIndex = object.permissionIndex;
                    else if (typeof object.permissionIndex === "object")
                        message.permissionIndex = new $util.LongBits(object.permissionIndex.low >>> 0, object.permissionIndex.high >>> 0).toNumber(true);
                if (object.constraintIndex != null)
                    if ($util.Long)
                        (message.constraintIndex = $util.Long.fromValue(object.constraintIndex)).unsigned = true;
                    else if (typeof object.constraintIndex === "string")
                        message.constraintIndex = parseInt(object.constraintIndex, 10);
                    else if (typeof object.constraintIndex === "number")
                        message.constraintIndex = object.constraintIndex;
                    else if (typeof object.constraintIndex === "object")
                        message.constraintIndex = new $util.LongBits(object.constraintIndex.low >>> 0, object.constraintIndex.high >>> 0).toNumber(true);
                return message;
            };
    
            /**
             * Creates a plain object from a DataRoomValidationError message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.DataRoomValidationError
             * @static
             * @param {waterfront.DataRoomValidationError} message DataRoomValidationError
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataRoomValidationError.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.message = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.queryIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.queryIndex = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.columnIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.columnIndex = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tableIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tableIndex = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.roleIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.roleIndex = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.permissionIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.permissionIndex = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.constraintIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.constraintIndex = options.longs === String ? "0" : 0;
                }
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = message.message;
                if (message.queryIndex != null && message.hasOwnProperty("queryIndex"))
                    if (typeof message.queryIndex === "number")
                        object.queryIndex = options.longs === String ? String(message.queryIndex) : message.queryIndex;
                    else
                        object.queryIndex = options.longs === String ? $util.Long.prototype.toString.call(message.queryIndex) : options.longs === Number ? new $util.LongBits(message.queryIndex.low >>> 0, message.queryIndex.high >>> 0).toNumber(true) : message.queryIndex;
                if (message.columnIndex != null && message.hasOwnProperty("columnIndex"))
                    if (typeof message.columnIndex === "number")
                        object.columnIndex = options.longs === String ? String(message.columnIndex) : message.columnIndex;
                    else
                        object.columnIndex = options.longs === String ? $util.Long.prototype.toString.call(message.columnIndex) : options.longs === Number ? new $util.LongBits(message.columnIndex.low >>> 0, message.columnIndex.high >>> 0).toNumber(true) : message.columnIndex;
                if (message.tableIndex != null && message.hasOwnProperty("tableIndex"))
                    if (typeof message.tableIndex === "number")
                        object.tableIndex = options.longs === String ? String(message.tableIndex) : message.tableIndex;
                    else
                        object.tableIndex = options.longs === String ? $util.Long.prototype.toString.call(message.tableIndex) : options.longs === Number ? new $util.LongBits(message.tableIndex.low >>> 0, message.tableIndex.high >>> 0).toNumber(true) : message.tableIndex;
                if (message.roleIndex != null && message.hasOwnProperty("roleIndex"))
                    if (typeof message.roleIndex === "number")
                        object.roleIndex = options.longs === String ? String(message.roleIndex) : message.roleIndex;
                    else
                        object.roleIndex = options.longs === String ? $util.Long.prototype.toString.call(message.roleIndex) : options.longs === Number ? new $util.LongBits(message.roleIndex.low >>> 0, message.roleIndex.high >>> 0).toNumber(true) : message.roleIndex;
                if (message.permissionIndex != null && message.hasOwnProperty("permissionIndex"))
                    if (typeof message.permissionIndex === "number")
                        object.permissionIndex = options.longs === String ? String(message.permissionIndex) : message.permissionIndex;
                    else
                        object.permissionIndex = options.longs === String ? $util.Long.prototype.toString.call(message.permissionIndex) : options.longs === Number ? new $util.LongBits(message.permissionIndex.low >>> 0, message.permissionIndex.high >>> 0).toNumber(true) : message.permissionIndex;
                if (message.constraintIndex != null && message.hasOwnProperty("constraintIndex"))
                    if (typeof message.constraintIndex === "number")
                        object.constraintIndex = options.longs === String ? String(message.constraintIndex) : message.constraintIndex;
                    else
                        object.constraintIndex = options.longs === String ? $util.Long.prototype.toString.call(message.constraintIndex) : options.longs === Number ? new $util.LongBits(message.constraintIndex.low >>> 0, message.constraintIndex.high >>> 0).toNumber(true) : message.constraintIndex;
                return object;
            };
    
            /**
             * Converts this DataRoomValidationError to JSON.
             * @function toJSON
             * @memberof waterfront.DataRoomValidationError
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataRoomValidationError.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DataRoomValidationError;
        })();
    
        waterfront.PublishDatasetToDataRoomRequest = (function() {
    
            /**
             * Properties of a PublishDatasetToDataRoomRequest.
             * @memberof waterfront
             * @interface IPublishDatasetToDataRoomRequest
             * @property {Uint8Array} manifestHash PublishDatasetToDataRoomRequest manifestHash
             * @property {Uint8Array} dataRoomHash PublishDatasetToDataRoomRequest dataRoomHash
             * @property {string} dataRoomTableName PublishDatasetToDataRoomRequest dataRoomTableName
             * @property {waterfront.IEncryptionKey} encryptionKey PublishDatasetToDataRoomRequest encryptionKey
             * @property {waterfront.IAuthenticationData} auth PublishDatasetToDataRoomRequest auth
             */
    
            /**
             * Constructs a new PublishDatasetToDataRoomRequest.
             * @memberof waterfront
             * @classdesc Represents a PublishDatasetToDataRoomRequest.
             * @implements IPublishDatasetToDataRoomRequest
             * @constructor
             * @param {waterfront.IPublishDatasetToDataRoomRequest=} [properties] Properties to set
             */
            function PublishDatasetToDataRoomRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PublishDatasetToDataRoomRequest manifestHash.
             * @member {Uint8Array} manifestHash
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @instance
             */
            PublishDatasetToDataRoomRequest.prototype.manifestHash = $util.newBuffer([]);
    
            /**
             * PublishDatasetToDataRoomRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @instance
             */
            PublishDatasetToDataRoomRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * PublishDatasetToDataRoomRequest dataRoomTableName.
             * @member {string} dataRoomTableName
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @instance
             */
            PublishDatasetToDataRoomRequest.prototype.dataRoomTableName = "";
    
            /**
             * PublishDatasetToDataRoomRequest encryptionKey.
             * @member {waterfront.IEncryptionKey} encryptionKey
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @instance
             */
            PublishDatasetToDataRoomRequest.prototype.encryptionKey = null;
    
            /**
             * PublishDatasetToDataRoomRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @instance
             */
            PublishDatasetToDataRoomRequest.prototype.auth = null;
    
            /**
             * Creates a new PublishDatasetToDataRoomRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {waterfront.IPublishDatasetToDataRoomRequest=} [properties] Properties to set
             * @returns {waterfront.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest instance
             */
            PublishDatasetToDataRoomRequest.create = function create(properties) {
                return new PublishDatasetToDataRoomRequest(properties);
            };
    
            /**
             * Encodes the specified PublishDatasetToDataRoomRequest message. Does not implicitly {@link waterfront.PublishDatasetToDataRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {waterfront.IPublishDatasetToDataRoomRequest} message PublishDatasetToDataRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishDatasetToDataRoomRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.manifestHash);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.dataRoomHash);
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.dataRoomTableName);
                $root.waterfront.EncryptionKey.encode(message.encryptionKey, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PublishDatasetToDataRoomRequest message, length delimited. Does not implicitly {@link waterfront.PublishDatasetToDataRoomRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {waterfront.IPublishDatasetToDataRoomRequest} message PublishDatasetToDataRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishDatasetToDataRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PublishDatasetToDataRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishDatasetToDataRoomRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.PublishDatasetToDataRoomRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.manifestHash = reader.bytes();
                        break;
                    case 2:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 3:
                        message.dataRoomTableName = reader.string();
                        break;
                    case 4:
                        message.encryptionKey = $root.waterfront.EncryptionKey.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("manifestHash"))
                    throw $util.ProtocolError("missing required 'manifestHash'", { instance: message });
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("dataRoomTableName"))
                    throw $util.ProtocolError("missing required 'dataRoomTableName'", { instance: message });
                if (!message.hasOwnProperty("encryptionKey"))
                    throw $util.ProtocolError("missing required 'encryptionKey'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a PublishDatasetToDataRoomRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishDatasetToDataRoomRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PublishDatasetToDataRoomRequest message.
             * @function verify
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PublishDatasetToDataRoomRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.manifestHash && typeof message.manifestHash.length === "number" || $util.isString(message.manifestHash)))
                    return "manifestHash: buffer expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                if (!$util.isString(message.dataRoomTableName))
                    return "dataRoomTableName: string expected";
                {
                    var error = $root.waterfront.EncryptionKey.verify(message.encryptionKey);
                    if (error)
                        return "encryptionKey." + error;
                }
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a PublishDatasetToDataRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.PublishDatasetToDataRoomRequest} PublishDatasetToDataRoomRequest
             */
            PublishDatasetToDataRoomRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.PublishDatasetToDataRoomRequest)
                    return object;
                var message = new $root.waterfront.PublishDatasetToDataRoomRequest();
                if (object.manifestHash != null)
                    if (typeof object.manifestHash === "string")
                        $util.base64.decode(object.manifestHash, message.manifestHash = $util.newBuffer($util.base64.length(object.manifestHash)), 0);
                    else if (object.manifestHash.length)
                        message.manifestHash = object.manifestHash;
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.dataRoomTableName != null)
                    message.dataRoomTableName = String(object.dataRoomTableName);
                if (object.encryptionKey != null) {
                    if (typeof object.encryptionKey !== "object")
                        throw TypeError(".waterfront.PublishDatasetToDataRoomRequest.encryptionKey: object expected");
                    message.encryptionKey = $root.waterfront.EncryptionKey.fromObject(object.encryptionKey);
                }
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.PublishDatasetToDataRoomRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PublishDatasetToDataRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @static
             * @param {waterfront.PublishDatasetToDataRoomRequest} message PublishDatasetToDataRoomRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PublishDatasetToDataRoomRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.manifestHash = "";
                    else {
                        object.manifestHash = [];
                        if (options.bytes !== Array)
                            object.manifestHash = $util.newBuffer(object.manifestHash);
                    }
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.dataRoomTableName = "";
                    object.encryptionKey = null;
                    object.auth = null;
                }
                if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                    object.manifestHash = options.bytes === String ? $util.base64.encode(message.manifestHash, 0, message.manifestHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.manifestHash) : message.manifestHash;
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.dataRoomTableName != null && message.hasOwnProperty("dataRoomTableName"))
                    object.dataRoomTableName = message.dataRoomTableName;
                if (message.encryptionKey != null && message.hasOwnProperty("encryptionKey"))
                    object.encryptionKey = $root.waterfront.EncryptionKey.toObject(message.encryptionKey, options);
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this PublishDatasetToDataRoomRequest to JSON.
             * @function toJSON
             * @memberof waterfront.PublishDatasetToDataRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PublishDatasetToDataRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PublishDatasetToDataRoomRequest;
        })();
    
        waterfront.PublishDatasetToDataRoomResponse = (function() {
    
            /**
             * Properties of a PublishDatasetToDataRoomResponse.
             * @memberof waterfront
             * @interface IPublishDatasetToDataRoomResponse
             */
    
            /**
             * Constructs a new PublishDatasetToDataRoomResponse.
             * @memberof waterfront
             * @classdesc Represents a PublishDatasetToDataRoomResponse.
             * @implements IPublishDatasetToDataRoomResponse
             * @constructor
             * @param {waterfront.IPublishDatasetToDataRoomResponse=} [properties] Properties to set
             */
            function PublishDatasetToDataRoomResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new PublishDatasetToDataRoomResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {waterfront.IPublishDatasetToDataRoomResponse=} [properties] Properties to set
             * @returns {waterfront.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse instance
             */
            PublishDatasetToDataRoomResponse.create = function create(properties) {
                return new PublishDatasetToDataRoomResponse(properties);
            };
    
            /**
             * Encodes the specified PublishDatasetToDataRoomResponse message. Does not implicitly {@link waterfront.PublishDatasetToDataRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {waterfront.IPublishDatasetToDataRoomResponse} message PublishDatasetToDataRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishDatasetToDataRoomResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified PublishDatasetToDataRoomResponse message, length delimited. Does not implicitly {@link waterfront.PublishDatasetToDataRoomResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {waterfront.IPublishDatasetToDataRoomResponse} message PublishDatasetToDataRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishDatasetToDataRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PublishDatasetToDataRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishDatasetToDataRoomResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.PublishDatasetToDataRoomResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PublishDatasetToDataRoomResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishDatasetToDataRoomResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PublishDatasetToDataRoomResponse message.
             * @function verify
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PublishDatasetToDataRoomResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a PublishDatasetToDataRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.PublishDatasetToDataRoomResponse} PublishDatasetToDataRoomResponse
             */
            PublishDatasetToDataRoomResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.PublishDatasetToDataRoomResponse)
                    return object;
                return new $root.waterfront.PublishDatasetToDataRoomResponse();
            };
    
            /**
             * Creates a plain object from a PublishDatasetToDataRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @static
             * @param {waterfront.PublishDatasetToDataRoomResponse} message PublishDatasetToDataRoomResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PublishDatasetToDataRoomResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this PublishDatasetToDataRoomResponse to JSON.
             * @function toJSON
             * @memberof waterfront.PublishDatasetToDataRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PublishDatasetToDataRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PublishDatasetToDataRoomResponse;
        })();
    
        waterfront.ValidateDatasetRequest = (function() {
    
            /**
             * Properties of a ValidateDatasetRequest.
             * @memberof waterfront
             * @interface IValidateDatasetRequest
             * @property {Uint8Array} manifestHash ValidateDatasetRequest manifestHash
             * @property {waterfront.IEncryptionKey} encryptionKey ValidateDatasetRequest encryptionKey
             */
    
            /**
             * Constructs a new ValidateDatasetRequest.
             * @memberof waterfront
             * @classdesc Represents a ValidateDatasetRequest.
             * @implements IValidateDatasetRequest
             * @constructor
             * @param {waterfront.IValidateDatasetRequest=} [properties] Properties to set
             */
            function ValidateDatasetRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ValidateDatasetRequest manifestHash.
             * @member {Uint8Array} manifestHash
             * @memberof waterfront.ValidateDatasetRequest
             * @instance
             */
            ValidateDatasetRequest.prototype.manifestHash = $util.newBuffer([]);
    
            /**
             * ValidateDatasetRequest encryptionKey.
             * @member {waterfront.IEncryptionKey} encryptionKey
             * @memberof waterfront.ValidateDatasetRequest
             * @instance
             */
            ValidateDatasetRequest.prototype.encryptionKey = null;
    
            /**
             * Creates a new ValidateDatasetRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {waterfront.IValidateDatasetRequest=} [properties] Properties to set
             * @returns {waterfront.ValidateDatasetRequest} ValidateDatasetRequest instance
             */
            ValidateDatasetRequest.create = function create(properties) {
                return new ValidateDatasetRequest(properties);
            };
    
            /**
             * Encodes the specified ValidateDatasetRequest message. Does not implicitly {@link waterfront.ValidateDatasetRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {waterfront.IValidateDatasetRequest} message ValidateDatasetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateDatasetRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.manifestHash);
                $root.waterfront.EncryptionKey.encode(message.encryptionKey, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ValidateDatasetRequest message, length delimited. Does not implicitly {@link waterfront.ValidateDatasetRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {waterfront.IValidateDatasetRequest} message ValidateDatasetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateDatasetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ValidateDatasetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.ValidateDatasetRequest} ValidateDatasetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateDatasetRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.ValidateDatasetRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.manifestHash = reader.bytes();
                        break;
                    case 2:
                        message.encryptionKey = $root.waterfront.EncryptionKey.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("manifestHash"))
                    throw $util.ProtocolError("missing required 'manifestHash'", { instance: message });
                if (!message.hasOwnProperty("encryptionKey"))
                    throw $util.ProtocolError("missing required 'encryptionKey'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a ValidateDatasetRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.ValidateDatasetRequest} ValidateDatasetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateDatasetRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ValidateDatasetRequest message.
             * @function verify
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ValidateDatasetRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.manifestHash && typeof message.manifestHash.length === "number" || $util.isString(message.manifestHash)))
                    return "manifestHash: buffer expected";
                {
                    var error = $root.waterfront.EncryptionKey.verify(message.encryptionKey);
                    if (error)
                        return "encryptionKey." + error;
                }
                return null;
            };
    
            /**
             * Creates a ValidateDatasetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.ValidateDatasetRequest} ValidateDatasetRequest
             */
            ValidateDatasetRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.ValidateDatasetRequest)
                    return object;
                var message = new $root.waterfront.ValidateDatasetRequest();
                if (object.manifestHash != null)
                    if (typeof object.manifestHash === "string")
                        $util.base64.decode(object.manifestHash, message.manifestHash = $util.newBuffer($util.base64.length(object.manifestHash)), 0);
                    else if (object.manifestHash.length)
                        message.manifestHash = object.manifestHash;
                if (object.encryptionKey != null) {
                    if (typeof object.encryptionKey !== "object")
                        throw TypeError(".waterfront.ValidateDatasetRequest.encryptionKey: object expected");
                    message.encryptionKey = $root.waterfront.EncryptionKey.fromObject(object.encryptionKey);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ValidateDatasetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.ValidateDatasetRequest
             * @static
             * @param {waterfront.ValidateDatasetRequest} message ValidateDatasetRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ValidateDatasetRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.manifestHash = "";
                    else {
                        object.manifestHash = [];
                        if (options.bytes !== Array)
                            object.manifestHash = $util.newBuffer(object.manifestHash);
                    }
                    object.encryptionKey = null;
                }
                if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                    object.manifestHash = options.bytes === String ? $util.base64.encode(message.manifestHash, 0, message.manifestHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.manifestHash) : message.manifestHash;
                if (message.encryptionKey != null && message.hasOwnProperty("encryptionKey"))
                    object.encryptionKey = $root.waterfront.EncryptionKey.toObject(message.encryptionKey, options);
                return object;
            };
    
            /**
             * Converts this ValidateDatasetRequest to JSON.
             * @function toJSON
             * @memberof waterfront.ValidateDatasetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ValidateDatasetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ValidateDatasetRequest;
        })();
    
        waterfront.ValidateDatasetFailure = (function() {
    
            /**
             * Properties of a ValidateDatasetFailure.
             * @memberof waterfront
             * @interface IValidateDatasetFailure
             * @property {string} message ValidateDatasetFailure message
             * @property {number|Long|null} [row] ValidateDatasetFailure row
             */
    
            /**
             * Constructs a new ValidateDatasetFailure.
             * @memberof waterfront
             * @classdesc Represents a ValidateDatasetFailure.
             * @implements IValidateDatasetFailure
             * @constructor
             * @param {waterfront.IValidateDatasetFailure=} [properties] Properties to set
             */
            function ValidateDatasetFailure(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ValidateDatasetFailure message.
             * @member {string} message
             * @memberof waterfront.ValidateDatasetFailure
             * @instance
             */
            ValidateDatasetFailure.prototype.message = "";
    
            /**
             * ValidateDatasetFailure row.
             * @member {number|Long} row
             * @memberof waterfront.ValidateDatasetFailure
             * @instance
             */
            ValidateDatasetFailure.prototype.row = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Creates a new ValidateDatasetFailure instance using the specified properties.
             * @function create
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {waterfront.IValidateDatasetFailure=} [properties] Properties to set
             * @returns {waterfront.ValidateDatasetFailure} ValidateDatasetFailure instance
             */
            ValidateDatasetFailure.create = function create(properties) {
                return new ValidateDatasetFailure(properties);
            };
    
            /**
             * Encodes the specified ValidateDatasetFailure message. Does not implicitly {@link waterfront.ValidateDatasetFailure.verify|verify} messages.
             * @function encode
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {waterfront.IValidateDatasetFailure} message ValidateDatasetFailure message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateDatasetFailure.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
                if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.row);
                return writer;
            };
    
            /**
             * Encodes the specified ValidateDatasetFailure message, length delimited. Does not implicitly {@link waterfront.ValidateDatasetFailure.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {waterfront.IValidateDatasetFailure} message ValidateDatasetFailure message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateDatasetFailure.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ValidateDatasetFailure message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.ValidateDatasetFailure} ValidateDatasetFailure
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateDatasetFailure.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.ValidateDatasetFailure();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.message = reader.string();
                        break;
                    case 2:
                        message.row = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("message"))
                    throw $util.ProtocolError("missing required 'message'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a ValidateDatasetFailure message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.ValidateDatasetFailure} ValidateDatasetFailure
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateDatasetFailure.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ValidateDatasetFailure message.
             * @function verify
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ValidateDatasetFailure.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.message))
                    return "message: string expected";
                if (message.row != null && message.hasOwnProperty("row"))
                    if (!$util.isInteger(message.row) && !(message.row && $util.isInteger(message.row.low) && $util.isInteger(message.row.high)))
                        return "row: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ValidateDatasetFailure message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.ValidateDatasetFailure} ValidateDatasetFailure
             */
            ValidateDatasetFailure.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.ValidateDatasetFailure)
                    return object;
                var message = new $root.waterfront.ValidateDatasetFailure();
                if (object.message != null)
                    message.message = String(object.message);
                if (object.row != null)
                    if ($util.Long)
                        (message.row = $util.Long.fromValue(object.row)).unsigned = true;
                    else if (typeof object.row === "string")
                        message.row = parseInt(object.row, 10);
                    else if (typeof object.row === "number")
                        message.row = object.row;
                    else if (typeof object.row === "object")
                        message.row = new $util.LongBits(object.row.low >>> 0, object.row.high >>> 0).toNumber(true);
                return message;
            };
    
            /**
             * Creates a plain object from a ValidateDatasetFailure message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.ValidateDatasetFailure
             * @static
             * @param {waterfront.ValidateDatasetFailure} message ValidateDatasetFailure
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ValidateDatasetFailure.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.message = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.row = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.row = options.longs === String ? "0" : 0;
                }
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = message.message;
                if (message.row != null && message.hasOwnProperty("row"))
                    if (typeof message.row === "number")
                        object.row = options.longs === String ? String(message.row) : message.row;
                    else
                        object.row = options.longs === String ? $util.Long.prototype.toString.call(message.row) : options.longs === Number ? new $util.LongBits(message.row.low >>> 0, message.row.high >>> 0).toNumber(true) : message.row;
                return object;
            };
    
            /**
             * Converts this ValidateDatasetFailure to JSON.
             * @function toJSON
             * @memberof waterfront.ValidateDatasetFailure
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ValidateDatasetFailure.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ValidateDatasetFailure;
        })();
    
        waterfront.ValidateDatasetResponse = (function() {
    
            /**
             * Properties of a ValidateDatasetResponse.
             * @memberof waterfront
             * @interface IValidateDatasetResponse
             * @property {waterfront.IValidateDatasetFailure|null} [failure] ValidateDatasetResponse failure
             */
    
            /**
             * Constructs a new ValidateDatasetResponse.
             * @memberof waterfront
             * @classdesc Represents a ValidateDatasetResponse.
             * @implements IValidateDatasetResponse
             * @constructor
             * @param {waterfront.IValidateDatasetResponse=} [properties] Properties to set
             */
            function ValidateDatasetResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ValidateDatasetResponse failure.
             * @member {waterfront.IValidateDatasetFailure|null|undefined} failure
             * @memberof waterfront.ValidateDatasetResponse
             * @instance
             */
            ValidateDatasetResponse.prototype.failure = null;
    
            /**
             * Creates a new ValidateDatasetResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {waterfront.IValidateDatasetResponse=} [properties] Properties to set
             * @returns {waterfront.ValidateDatasetResponse} ValidateDatasetResponse instance
             */
            ValidateDatasetResponse.create = function create(properties) {
                return new ValidateDatasetResponse(properties);
            };
    
            /**
             * Encodes the specified ValidateDatasetResponse message. Does not implicitly {@link waterfront.ValidateDatasetResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {waterfront.IValidateDatasetResponse} message ValidateDatasetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateDatasetResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.failure != null && Object.hasOwnProperty.call(message, "failure"))
                    $root.waterfront.ValidateDatasetFailure.encode(message.failure, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ValidateDatasetResponse message, length delimited. Does not implicitly {@link waterfront.ValidateDatasetResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {waterfront.IValidateDatasetResponse} message ValidateDatasetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateDatasetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ValidateDatasetResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.ValidateDatasetResponse} ValidateDatasetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateDatasetResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.ValidateDatasetResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.failure = $root.waterfront.ValidateDatasetFailure.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ValidateDatasetResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.ValidateDatasetResponse} ValidateDatasetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateDatasetResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ValidateDatasetResponse message.
             * @function verify
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ValidateDatasetResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.failure != null && message.hasOwnProperty("failure")) {
                    var error = $root.waterfront.ValidateDatasetFailure.verify(message.failure);
                    if (error)
                        return "failure." + error;
                }
                return null;
            };
    
            /**
             * Creates a ValidateDatasetResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.ValidateDatasetResponse} ValidateDatasetResponse
             */
            ValidateDatasetResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.ValidateDatasetResponse)
                    return object;
                var message = new $root.waterfront.ValidateDatasetResponse();
                if (object.failure != null) {
                    if (typeof object.failure !== "object")
                        throw TypeError(".waterfront.ValidateDatasetResponse.failure: object expected");
                    message.failure = $root.waterfront.ValidateDatasetFailure.fromObject(object.failure);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ValidateDatasetResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.ValidateDatasetResponse
             * @static
             * @param {waterfront.ValidateDatasetResponse} message ValidateDatasetResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ValidateDatasetResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.failure = null;
                if (message.failure != null && message.hasOwnProperty("failure"))
                    object.failure = $root.waterfront.ValidateDatasetFailure.toObject(message.failure, options);
                return object;
            };
    
            /**
             * Converts this ValidateDatasetResponse to JSON.
             * @function toJSON
             * @memberof waterfront.ValidateDatasetResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ValidateDatasetResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ValidateDatasetResponse;
        })();
    
        waterfront.SqlQueryRequest = (function() {
    
            /**
             * Properties of a SqlQueryRequest.
             * @memberof waterfront
             * @interface ISqlQueryRequest
             * @property {string} queryName SqlQueryRequest queryName
             * @property {Uint8Array} dataRoomHash SqlQueryRequest dataRoomHash
             * @property {waterfront.IAuthenticationData} auth SqlQueryRequest auth
             */
    
            /**
             * Constructs a new SqlQueryRequest.
             * @memberof waterfront
             * @classdesc Represents a SqlQueryRequest.
             * @implements ISqlQueryRequest
             * @constructor
             * @param {waterfront.ISqlQueryRequest=} [properties] Properties to set
             */
            function SqlQueryRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SqlQueryRequest queryName.
             * @member {string} queryName
             * @memberof waterfront.SqlQueryRequest
             * @instance
             */
            SqlQueryRequest.prototype.queryName = "";
    
            /**
             * SqlQueryRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.SqlQueryRequest
             * @instance
             */
            SqlQueryRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * SqlQueryRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.SqlQueryRequest
             * @instance
             */
            SqlQueryRequest.prototype.auth = null;
    
            /**
             * Creates a new SqlQueryRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {waterfront.ISqlQueryRequest=} [properties] Properties to set
             * @returns {waterfront.SqlQueryRequest} SqlQueryRequest instance
             */
            SqlQueryRequest.create = function create(properties) {
                return new SqlQueryRequest(properties);
            };
    
            /**
             * Encodes the specified SqlQueryRequest message. Does not implicitly {@link waterfront.SqlQueryRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {waterfront.ISqlQueryRequest} message SqlQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.queryName);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.dataRoomHash);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified SqlQueryRequest message, length delimited. Does not implicitly {@link waterfront.SqlQueryRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {waterfront.ISqlQueryRequest} message SqlQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SqlQueryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.SqlQueryRequest} SqlQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.SqlQueryRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.queryName = reader.string();
                        break;
                    case 2:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 5:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("queryName"))
                    throw $util.ProtocolError("missing required 'queryName'", { instance: message });
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a SqlQueryRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.SqlQueryRequest} SqlQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SqlQueryRequest message.
             * @function verify
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SqlQueryRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.queryName))
                    return "queryName: string expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a SqlQueryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.SqlQueryRequest} SqlQueryRequest
             */
            SqlQueryRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.SqlQueryRequest)
                    return object;
                var message = new $root.waterfront.SqlQueryRequest();
                if (object.queryName != null)
                    message.queryName = String(object.queryName);
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.SqlQueryRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a SqlQueryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.SqlQueryRequest
             * @static
             * @param {waterfront.SqlQueryRequest} message SqlQueryRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SqlQueryRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.queryName = "";
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.auth = null;
                }
                if (message.queryName != null && message.hasOwnProperty("queryName"))
                    object.queryName = message.queryName;
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this SqlQueryRequest to JSON.
             * @function toJSON
             * @memberof waterfront.SqlQueryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SqlQueryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return SqlQueryRequest;
        })();
    
        waterfront.SqlQueryResponse = (function() {
    
            /**
             * Properties of a SqlQueryResponse.
             * @memberof waterfront
             * @interface ISqlQueryResponse
             * @property {waterfront.ISqlQueryInProgress|null} [inProgress] SqlQueryResponse inProgress
             * @property {waterfront.ISqlQueryFinished|null} [finished] SqlQueryResponse finished
             */
    
            /**
             * Constructs a new SqlQueryResponse.
             * @memberof waterfront
             * @classdesc Represents a SqlQueryResponse.
             * @implements ISqlQueryResponse
             * @constructor
             * @param {waterfront.ISqlQueryResponse=} [properties] Properties to set
             */
            function SqlQueryResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SqlQueryResponse inProgress.
             * @member {waterfront.ISqlQueryInProgress|null|undefined} inProgress
             * @memberof waterfront.SqlQueryResponse
             * @instance
             */
            SqlQueryResponse.prototype.inProgress = null;
    
            /**
             * SqlQueryResponse finished.
             * @member {waterfront.ISqlQueryFinished|null|undefined} finished
             * @memberof waterfront.SqlQueryResponse
             * @instance
             */
            SqlQueryResponse.prototype.finished = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * SqlQueryResponse sqlQueryResponse.
             * @member {"inProgress"|"finished"|undefined} sqlQueryResponse
             * @memberof waterfront.SqlQueryResponse
             * @instance
             */
            Object.defineProperty(SqlQueryResponse.prototype, "sqlQueryResponse", {
                get: $util.oneOfGetter($oneOfFields = ["inProgress", "finished"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new SqlQueryResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {waterfront.ISqlQueryResponse=} [properties] Properties to set
             * @returns {waterfront.SqlQueryResponse} SqlQueryResponse instance
             */
            SqlQueryResponse.create = function create(properties) {
                return new SqlQueryResponse(properties);
            };
    
            /**
             * Encodes the specified SqlQueryResponse message. Does not implicitly {@link waterfront.SqlQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {waterfront.ISqlQueryResponse} message SqlQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.inProgress != null && Object.hasOwnProperty.call(message, "inProgress"))
                    $root.waterfront.SqlQueryInProgress.encode(message.inProgress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.finished != null && Object.hasOwnProperty.call(message, "finished"))
                    $root.waterfront.SqlQueryFinished.encode(message.finished, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified SqlQueryResponse message, length delimited. Does not implicitly {@link waterfront.SqlQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {waterfront.ISqlQueryResponse} message SqlQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SqlQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.SqlQueryResponse} SqlQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.SqlQueryResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.inProgress = $root.waterfront.SqlQueryInProgress.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.finished = $root.waterfront.SqlQueryFinished.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a SqlQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.SqlQueryResponse} SqlQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SqlQueryResponse message.
             * @function verify
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SqlQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.inProgress != null && message.hasOwnProperty("inProgress")) {
                    properties.sqlQueryResponse = 1;
                    {
                        var error = $root.waterfront.SqlQueryInProgress.verify(message.inProgress);
                        if (error)
                            return "inProgress." + error;
                    }
                }
                if (message.finished != null && message.hasOwnProperty("finished")) {
                    if (properties.sqlQueryResponse === 1)
                        return "sqlQueryResponse: multiple values";
                    properties.sqlQueryResponse = 1;
                    {
                        var error = $root.waterfront.SqlQueryFinished.verify(message.finished);
                        if (error)
                            return "finished." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a SqlQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.SqlQueryResponse} SqlQueryResponse
             */
            SqlQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.SqlQueryResponse)
                    return object;
                var message = new $root.waterfront.SqlQueryResponse();
                if (object.inProgress != null) {
                    if (typeof object.inProgress !== "object")
                        throw TypeError(".waterfront.SqlQueryResponse.inProgress: object expected");
                    message.inProgress = $root.waterfront.SqlQueryInProgress.fromObject(object.inProgress);
                }
                if (object.finished != null) {
                    if (typeof object.finished !== "object")
                        throw TypeError(".waterfront.SqlQueryResponse.finished: object expected");
                    message.finished = $root.waterfront.SqlQueryFinished.fromObject(object.finished);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a SqlQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.SqlQueryResponse
             * @static
             * @param {waterfront.SqlQueryResponse} message SqlQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SqlQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.inProgress != null && message.hasOwnProperty("inProgress")) {
                    object.inProgress = $root.waterfront.SqlQueryInProgress.toObject(message.inProgress, options);
                    if (options.oneofs)
                        object.sqlQueryResponse = "inProgress";
                }
                if (message.finished != null && message.hasOwnProperty("finished")) {
                    object.finished = $root.waterfront.SqlQueryFinished.toObject(message.finished, options);
                    if (options.oneofs)
                        object.sqlQueryResponse = "finished";
                }
                return object;
            };
    
            /**
             * Converts this SqlQueryResponse to JSON.
             * @function toJSON
             * @memberof waterfront.SqlQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SqlQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return SqlQueryResponse;
        })();
    
        waterfront.SqlQueryInProgress = (function() {
    
            /**
             * Properties of a SqlQueryInProgress.
             * @memberof waterfront
             * @interface ISqlQueryInProgress
             * @property {Uint8Array} jobId SqlQueryInProgress jobId
             */
    
            /**
             * Constructs a new SqlQueryInProgress.
             * @memberof waterfront
             * @classdesc Represents a SqlQueryInProgress.
             * @implements ISqlQueryInProgress
             * @constructor
             * @param {waterfront.ISqlQueryInProgress=} [properties] Properties to set
             */
            function SqlQueryInProgress(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SqlQueryInProgress jobId.
             * @member {Uint8Array} jobId
             * @memberof waterfront.SqlQueryInProgress
             * @instance
             */
            SqlQueryInProgress.prototype.jobId = $util.newBuffer([]);
    
            /**
             * Creates a new SqlQueryInProgress instance using the specified properties.
             * @function create
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {waterfront.ISqlQueryInProgress=} [properties] Properties to set
             * @returns {waterfront.SqlQueryInProgress} SqlQueryInProgress instance
             */
            SqlQueryInProgress.create = function create(properties) {
                return new SqlQueryInProgress(properties);
            };
    
            /**
             * Encodes the specified SqlQueryInProgress message. Does not implicitly {@link waterfront.SqlQueryInProgress.verify|verify} messages.
             * @function encode
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {waterfront.ISqlQueryInProgress} message SqlQueryInProgress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryInProgress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.jobId);
                return writer;
            };
    
            /**
             * Encodes the specified SqlQueryInProgress message, length delimited. Does not implicitly {@link waterfront.SqlQueryInProgress.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {waterfront.ISqlQueryInProgress} message SqlQueryInProgress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryInProgress.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SqlQueryInProgress message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.SqlQueryInProgress} SqlQueryInProgress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryInProgress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.SqlQueryInProgress();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.jobId = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("jobId"))
                    throw $util.ProtocolError("missing required 'jobId'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a SqlQueryInProgress message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.SqlQueryInProgress} SqlQueryInProgress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryInProgress.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SqlQueryInProgress message.
             * @function verify
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SqlQueryInProgress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.jobId && typeof message.jobId.length === "number" || $util.isString(message.jobId)))
                    return "jobId: buffer expected";
                return null;
            };
    
            /**
             * Creates a SqlQueryInProgress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.SqlQueryInProgress} SqlQueryInProgress
             */
            SqlQueryInProgress.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.SqlQueryInProgress)
                    return object;
                var message = new $root.waterfront.SqlQueryInProgress();
                if (object.jobId != null)
                    if (typeof object.jobId === "string")
                        $util.base64.decode(object.jobId, message.jobId = $util.newBuffer($util.base64.length(object.jobId)), 0);
                    else if (object.jobId.length)
                        message.jobId = object.jobId;
                return message;
            };
    
            /**
             * Creates a plain object from a SqlQueryInProgress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.SqlQueryInProgress
             * @static
             * @param {waterfront.SqlQueryInProgress} message SqlQueryInProgress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SqlQueryInProgress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.jobId = "";
                    else {
                        object.jobId = [];
                        if (options.bytes !== Array)
                            object.jobId = $util.newBuffer(object.jobId);
                    }
                if (message.jobId != null && message.hasOwnProperty("jobId"))
                    object.jobId = options.bytes === String ? $util.base64.encode(message.jobId, 0, message.jobId.length) : options.bytes === Array ? Array.prototype.slice.call(message.jobId) : message.jobId;
                return object;
            };
    
            /**
             * Converts this SqlQueryInProgress to JSON.
             * @function toJSON
             * @memberof waterfront.SqlQueryInProgress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SqlQueryInProgress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return SqlQueryInProgress;
        })();
    
        waterfront.SqlQueryFinished = (function() {
    
            /**
             * Properties of a SqlQueryFinished.
             * @memberof waterfront
             * @interface ISqlQueryFinished
             * @property {Uint8Array} data SqlQueryFinished data
             * @property {Array.<string>|null} [header] SqlQueryFinished header
             */
    
            /**
             * Constructs a new SqlQueryFinished.
             * @memberof waterfront
             * @classdesc Represents a SqlQueryFinished.
             * @implements ISqlQueryFinished
             * @constructor
             * @param {waterfront.ISqlQueryFinished=} [properties] Properties to set
             */
            function SqlQueryFinished(properties) {
                this.header = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SqlQueryFinished data.
             * @member {Uint8Array} data
             * @memberof waterfront.SqlQueryFinished
             * @instance
             */
            SqlQueryFinished.prototype.data = $util.newBuffer([]);
    
            /**
             * SqlQueryFinished header.
             * @member {Array.<string>} header
             * @memberof waterfront.SqlQueryFinished
             * @instance
             */
            SqlQueryFinished.prototype.header = $util.emptyArray;
    
            /**
             * Creates a new SqlQueryFinished instance using the specified properties.
             * @function create
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {waterfront.ISqlQueryFinished=} [properties] Properties to set
             * @returns {waterfront.SqlQueryFinished} SqlQueryFinished instance
             */
            SqlQueryFinished.create = function create(properties) {
                return new SqlQueryFinished(properties);
            };
    
            /**
             * Encodes the specified SqlQueryFinished message. Does not implicitly {@link waterfront.SqlQueryFinished.verify|verify} messages.
             * @function encode
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {waterfront.ISqlQueryFinished} message SqlQueryFinished message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryFinished.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                if (message.header != null && message.header.length)
                    for (var i = 0; i < message.header.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.header[i]);
                return writer;
            };
    
            /**
             * Encodes the specified SqlQueryFinished message, length delimited. Does not implicitly {@link waterfront.SqlQueryFinished.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {waterfront.ISqlQueryFinished} message SqlQueryFinished message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlQueryFinished.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SqlQueryFinished message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.SqlQueryFinished} SqlQueryFinished
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryFinished.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.SqlQueryFinished();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    case 2:
                        if (!(message.header && message.header.length))
                            message.header = [];
                        message.header.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("data"))
                    throw $util.ProtocolError("missing required 'data'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a SqlQueryFinished message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.SqlQueryFinished} SqlQueryFinished
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlQueryFinished.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SqlQueryFinished message.
             * @function verify
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SqlQueryFinished.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    if (!Array.isArray(message.header))
                        return "header: array expected";
                    for (var i = 0; i < message.header.length; ++i)
                        if (!$util.isString(message.header[i]))
                            return "header: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a SqlQueryFinished message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.SqlQueryFinished} SqlQueryFinished
             */
            SqlQueryFinished.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.SqlQueryFinished)
                    return object;
                var message = new $root.waterfront.SqlQueryFinished();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                if (object.header) {
                    if (!Array.isArray(object.header))
                        throw TypeError(".waterfront.SqlQueryFinished.header: array expected");
                    message.header = [];
                    for (var i = 0; i < object.header.length; ++i)
                        message.header[i] = String(object.header[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a SqlQueryFinished message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.SqlQueryFinished
             * @static
             * @param {waterfront.SqlQueryFinished} message SqlQueryFinished
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SqlQueryFinished.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.header = [];
                if (options.defaults)
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.header && message.header.length) {
                    object.header = [];
                    for (var j = 0; j < message.header.length; ++j)
                        object.header[j] = message.header[j];
                }
                return object;
            };
    
            /**
             * Converts this SqlQueryFinished to JSON.
             * @function toJSON
             * @memberof waterfront.SqlQueryFinished
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SqlQueryFinished.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return SqlQueryFinished;
        })();
    
        waterfront.JobStatusRequest = (function() {
    
            /**
             * Properties of a JobStatusRequest.
             * @memberof waterfront
             * @interface IJobStatusRequest
             * @property {Uint8Array} jobId JobStatusRequest jobId
             * @property {waterfront.IAuthenticationData} auth JobStatusRequest auth
             */
    
            /**
             * Constructs a new JobStatusRequest.
             * @memberof waterfront
             * @classdesc Represents a JobStatusRequest.
             * @implements IJobStatusRequest
             * @constructor
             * @param {waterfront.IJobStatusRequest=} [properties] Properties to set
             */
            function JobStatusRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * JobStatusRequest jobId.
             * @member {Uint8Array} jobId
             * @memberof waterfront.JobStatusRequest
             * @instance
             */
            JobStatusRequest.prototype.jobId = $util.newBuffer([]);
    
            /**
             * JobStatusRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.JobStatusRequest
             * @instance
             */
            JobStatusRequest.prototype.auth = null;
    
            /**
             * Creates a new JobStatusRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {waterfront.IJobStatusRequest=} [properties] Properties to set
             * @returns {waterfront.JobStatusRequest} JobStatusRequest instance
             */
            JobStatusRequest.create = function create(properties) {
                return new JobStatusRequest(properties);
            };
    
            /**
             * Encodes the specified JobStatusRequest message. Does not implicitly {@link waterfront.JobStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {waterfront.IJobStatusRequest} message JobStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.jobId);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified JobStatusRequest message, length delimited. Does not implicitly {@link waterfront.JobStatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {waterfront.IJobStatusRequest} message JobStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a JobStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.JobStatusRequest} JobStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.JobStatusRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.jobId = reader.bytes();
                        break;
                    case 2:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("jobId"))
                    throw $util.ProtocolError("missing required 'jobId'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a JobStatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.JobStatusRequest} JobStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a JobStatusRequest message.
             * @function verify
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JobStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.jobId && typeof message.jobId.length === "number" || $util.isString(message.jobId)))
                    return "jobId: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a JobStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.JobStatusRequest} JobStatusRequest
             */
            JobStatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.JobStatusRequest)
                    return object;
                var message = new $root.waterfront.JobStatusRequest();
                if (object.jobId != null)
                    if (typeof object.jobId === "string")
                        $util.base64.decode(object.jobId, message.jobId = $util.newBuffer($util.base64.length(object.jobId)), 0);
                    else if (object.jobId.length)
                        message.jobId = object.jobId;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.JobStatusRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a JobStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.JobStatusRequest
             * @static
             * @param {waterfront.JobStatusRequest} message JobStatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JobStatusRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.jobId = "";
                    else {
                        object.jobId = [];
                        if (options.bytes !== Array)
                            object.jobId = $util.newBuffer(object.jobId);
                    }
                    object.auth = null;
                }
                if (message.jobId != null && message.hasOwnProperty("jobId"))
                    object.jobId = options.bytes === String ? $util.base64.encode(message.jobId, 0, message.jobId.length) : options.bytes === Array ? Array.prototype.slice.call(message.jobId) : message.jobId;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this JobStatusRequest to JSON.
             * @function toJSON
             * @memberof waterfront.JobStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JobStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return JobStatusRequest;
        })();
    
        waterfront.JobStatusResponse = (function() {
    
            /**
             * Properties of a JobStatusResponse.
             * @memberof waterfront
             * @interface IJobStatusResponse
             * @property {boolean} completed JobStatusResponse completed
             */
    
            /**
             * Constructs a new JobStatusResponse.
             * @memberof waterfront
             * @classdesc Represents a JobStatusResponse.
             * @implements IJobStatusResponse
             * @constructor
             * @param {waterfront.IJobStatusResponse=} [properties] Properties to set
             */
            function JobStatusResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * JobStatusResponse completed.
             * @member {boolean} completed
             * @memberof waterfront.JobStatusResponse
             * @instance
             */
            JobStatusResponse.prototype.completed = false;
    
            /**
             * Creates a new JobStatusResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {waterfront.IJobStatusResponse=} [properties] Properties to set
             * @returns {waterfront.JobStatusResponse} JobStatusResponse instance
             */
            JobStatusResponse.create = function create(properties) {
                return new JobStatusResponse(properties);
            };
    
            /**
             * Encodes the specified JobStatusResponse message. Does not implicitly {@link waterfront.JobStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {waterfront.IJobStatusResponse} message JobStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.completed);
                return writer;
            };
    
            /**
             * Encodes the specified JobStatusResponse message, length delimited. Does not implicitly {@link waterfront.JobStatusResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {waterfront.IJobStatusResponse} message JobStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            JobStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a JobStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.JobStatusResponse} JobStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.JobStatusResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.completed = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("completed"))
                    throw $util.ProtocolError("missing required 'completed'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a JobStatusResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.JobStatusResponse} JobStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            JobStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a JobStatusResponse message.
             * @function verify
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            JobStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (typeof message.completed !== "boolean")
                    return "completed: boolean expected";
                return null;
            };
    
            /**
             * Creates a JobStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.JobStatusResponse} JobStatusResponse
             */
            JobStatusResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.JobStatusResponse)
                    return object;
                var message = new $root.waterfront.JobStatusResponse();
                if (object.completed != null)
                    message.completed = Boolean(object.completed);
                return message;
            };
    
            /**
             * Creates a plain object from a JobStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.JobStatusResponse
             * @static
             * @param {waterfront.JobStatusResponse} message JobStatusResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            JobStatusResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.completed = false;
                if (message.completed != null && message.hasOwnProperty("completed"))
                    object.completed = message.completed;
                return object;
            };
    
            /**
             * Converts this JobStatusResponse to JSON.
             * @function toJSON
             * @memberof waterfront.JobStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            JobStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return JobStatusResponse;
        })();
    
        waterfront.GetResultsRequest = (function() {
    
            /**
             * Properties of a GetResultsRequest.
             * @memberof waterfront
             * @interface IGetResultsRequest
             * @property {Uint8Array} jobId GetResultsRequest jobId
             * @property {waterfront.IAuthenticationData} auth GetResultsRequest auth
             */
    
            /**
             * Constructs a new GetResultsRequest.
             * @memberof waterfront
             * @classdesc Represents a GetResultsRequest.
             * @implements IGetResultsRequest
             * @constructor
             * @param {waterfront.IGetResultsRequest=} [properties] Properties to set
             */
            function GetResultsRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetResultsRequest jobId.
             * @member {Uint8Array} jobId
             * @memberof waterfront.GetResultsRequest
             * @instance
             */
            GetResultsRequest.prototype.jobId = $util.newBuffer([]);
    
            /**
             * GetResultsRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.GetResultsRequest
             * @instance
             */
            GetResultsRequest.prototype.auth = null;
    
            /**
             * Creates a new GetResultsRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {waterfront.IGetResultsRequest=} [properties] Properties to set
             * @returns {waterfront.GetResultsRequest} GetResultsRequest instance
             */
            GetResultsRequest.create = function create(properties) {
                return new GetResultsRequest(properties);
            };
    
            /**
             * Encodes the specified GetResultsRequest message. Does not implicitly {@link waterfront.GetResultsRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {waterfront.IGetResultsRequest} message GetResultsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetResultsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.jobId);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GetResultsRequest message, length delimited. Does not implicitly {@link waterfront.GetResultsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {waterfront.IGetResultsRequest} message GetResultsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetResultsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetResultsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.GetResultsRequest} GetResultsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetResultsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.GetResultsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.jobId = reader.bytes();
                        break;
                    case 2:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("jobId"))
                    throw $util.ProtocolError("missing required 'jobId'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a GetResultsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.GetResultsRequest} GetResultsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetResultsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetResultsRequest message.
             * @function verify
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetResultsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.jobId && typeof message.jobId.length === "number" || $util.isString(message.jobId)))
                    return "jobId: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a GetResultsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.GetResultsRequest} GetResultsRequest
             */
            GetResultsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.GetResultsRequest)
                    return object;
                var message = new $root.waterfront.GetResultsRequest();
                if (object.jobId != null)
                    if (typeof object.jobId === "string")
                        $util.base64.decode(object.jobId, message.jobId = $util.newBuffer($util.base64.length(object.jobId)), 0);
                    else if (object.jobId.length)
                        message.jobId = object.jobId;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.GetResultsRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetResultsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.GetResultsRequest
             * @static
             * @param {waterfront.GetResultsRequest} message GetResultsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetResultsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.jobId = "";
                    else {
                        object.jobId = [];
                        if (options.bytes !== Array)
                            object.jobId = $util.newBuffer(object.jobId);
                    }
                    object.auth = null;
                }
                if (message.jobId != null && message.hasOwnProperty("jobId"))
                    object.jobId = options.bytes === String ? $util.base64.encode(message.jobId, 0, message.jobId.length) : options.bytes === Array ? Array.prototype.slice.call(message.jobId) : message.jobId;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this GetResultsRequest to JSON.
             * @function toJSON
             * @memberof waterfront.GetResultsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetResultsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return GetResultsRequest;
        })();
    
        waterfront.RetrieveDataRoomRequest = (function() {
    
            /**
             * Properties of a RetrieveDataRoomRequest.
             * @memberof waterfront
             * @interface IRetrieveDataRoomRequest
             * @property {Uint8Array} dataRoomHash RetrieveDataRoomRequest dataRoomHash
             * @property {waterfront.IAuthenticationData} auth RetrieveDataRoomRequest auth
             */
    
            /**
             * Constructs a new RetrieveDataRoomRequest.
             * @memberof waterfront
             * @classdesc Represents a RetrieveDataRoomRequest.
             * @implements IRetrieveDataRoomRequest
             * @constructor
             * @param {waterfront.IRetrieveDataRoomRequest=} [properties] Properties to set
             */
            function RetrieveDataRoomRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrieveDataRoomRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.RetrieveDataRoomRequest
             * @instance
             */
            RetrieveDataRoomRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * RetrieveDataRoomRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.RetrieveDataRoomRequest
             * @instance
             */
            RetrieveDataRoomRequest.prototype.auth = null;
    
            /**
             * Creates a new RetrieveDataRoomRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {waterfront.IRetrieveDataRoomRequest=} [properties] Properties to set
             * @returns {waterfront.RetrieveDataRoomRequest} RetrieveDataRoomRequest instance
             */
            RetrieveDataRoomRequest.create = function create(properties) {
                return new RetrieveDataRoomRequest(properties);
            };
    
            /**
             * Encodes the specified RetrieveDataRoomRequest message. Does not implicitly {@link waterfront.RetrieveDataRoomRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {waterfront.IRetrieveDataRoomRequest} message RetrieveDataRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomHash);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RetrieveDataRoomRequest message, length delimited. Does not implicitly {@link waterfront.RetrieveDataRoomRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {waterfront.IRetrieveDataRoomRequest} message RetrieveDataRoomRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrieveDataRoomRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrieveDataRoomRequest} RetrieveDataRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrieveDataRoomRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 5:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a RetrieveDataRoomRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrieveDataRoomRequest} RetrieveDataRoomRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrieveDataRoomRequest message.
             * @function verify
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrieveDataRoomRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a RetrieveDataRoomRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrieveDataRoomRequest} RetrieveDataRoomRequest
             */
            RetrieveDataRoomRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrieveDataRoomRequest)
                    return object;
                var message = new $root.waterfront.RetrieveDataRoomRequest();
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.RetrieveDataRoomRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RetrieveDataRoomRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrieveDataRoomRequest
             * @static
             * @param {waterfront.RetrieveDataRoomRequest} message RetrieveDataRoomRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrieveDataRoomRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.auth = null;
                }
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this RetrieveDataRoomRequest to JSON.
             * @function toJSON
             * @memberof waterfront.RetrieveDataRoomRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrieveDataRoomRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrieveDataRoomRequest;
        })();
    
        waterfront.RetrieveDataRoomResponse = (function() {
    
            /**
             * Properties of a RetrieveDataRoomResponse.
             * @memberof waterfront
             * @interface IRetrieveDataRoomResponse
             * @property {data_room.IDataRoom} dataRoom RetrieveDataRoomResponse dataRoom
             */
    
            /**
             * Constructs a new RetrieveDataRoomResponse.
             * @memberof waterfront
             * @classdesc Represents a RetrieveDataRoomResponse.
             * @implements IRetrieveDataRoomResponse
             * @constructor
             * @param {waterfront.IRetrieveDataRoomResponse=} [properties] Properties to set
             */
            function RetrieveDataRoomResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrieveDataRoomResponse dataRoom.
             * @member {data_room.IDataRoom} dataRoom
             * @memberof waterfront.RetrieveDataRoomResponse
             * @instance
             */
            RetrieveDataRoomResponse.prototype.dataRoom = null;
    
            /**
             * Creates a new RetrieveDataRoomResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {waterfront.IRetrieveDataRoomResponse=} [properties] Properties to set
             * @returns {waterfront.RetrieveDataRoomResponse} RetrieveDataRoomResponse instance
             */
            RetrieveDataRoomResponse.create = function create(properties) {
                return new RetrieveDataRoomResponse(properties);
            };
    
            /**
             * Encodes the specified RetrieveDataRoomResponse message. Does not implicitly {@link waterfront.RetrieveDataRoomResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {waterfront.IRetrieveDataRoomResponse} message RetrieveDataRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                $root.data_room.DataRoom.encode(message.dataRoom, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RetrieveDataRoomResponse message, length delimited. Does not implicitly {@link waterfront.RetrieveDataRoomResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {waterfront.IRetrieveDataRoomResponse} message RetrieveDataRoomResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrieveDataRoomResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrieveDataRoomResponse} RetrieveDataRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrieveDataRoomResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoom = $root.data_room.DataRoom.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("dataRoom"))
                    throw $util.ProtocolError("missing required 'dataRoom'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a RetrieveDataRoomResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrieveDataRoomResponse} RetrieveDataRoomResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrieveDataRoomResponse message.
             * @function verify
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrieveDataRoomResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                {
                    var error = $root.data_room.DataRoom.verify(message.dataRoom);
                    if (error)
                        return "dataRoom." + error;
                }
                return null;
            };
    
            /**
             * Creates a RetrieveDataRoomResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrieveDataRoomResponse} RetrieveDataRoomResponse
             */
            RetrieveDataRoomResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrieveDataRoomResponse)
                    return object;
                var message = new $root.waterfront.RetrieveDataRoomResponse();
                if (object.dataRoom != null) {
                    if (typeof object.dataRoom !== "object")
                        throw TypeError(".waterfront.RetrieveDataRoomResponse.dataRoom: object expected");
                    message.dataRoom = $root.data_room.DataRoom.fromObject(object.dataRoom);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RetrieveDataRoomResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrieveDataRoomResponse
             * @static
             * @param {waterfront.RetrieveDataRoomResponse} message RetrieveDataRoomResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrieveDataRoomResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.dataRoom = null;
                if (message.dataRoom != null && message.hasOwnProperty("dataRoom"))
                    object.dataRoom = $root.data_room.DataRoom.toObject(message.dataRoom, options);
                return object;
            };
    
            /**
             * Converts this RetrieveDataRoomResponse to JSON.
             * @function toJSON
             * @memberof waterfront.RetrieveDataRoomResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrieveDataRoomResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrieveDataRoomResponse;
        })();
    
        waterfront.RetrieveAuditLogRequest = (function() {
    
            /**
             * Properties of a RetrieveAuditLogRequest.
             * @memberof waterfront
             * @interface IRetrieveAuditLogRequest
             * @property {Uint8Array} dataRoomHash RetrieveAuditLogRequest dataRoomHash
             * @property {waterfront.IAuthenticationData} auth RetrieveAuditLogRequest auth
             */
    
            /**
             * Constructs a new RetrieveAuditLogRequest.
             * @memberof waterfront
             * @classdesc Represents a RetrieveAuditLogRequest.
             * @implements IRetrieveAuditLogRequest
             * @constructor
             * @param {waterfront.IRetrieveAuditLogRequest=} [properties] Properties to set
             */
            function RetrieveAuditLogRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrieveAuditLogRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.RetrieveAuditLogRequest
             * @instance
             */
            RetrieveAuditLogRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * RetrieveAuditLogRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.RetrieveAuditLogRequest
             * @instance
             */
            RetrieveAuditLogRequest.prototype.auth = null;
    
            /**
             * Creates a new RetrieveAuditLogRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {waterfront.IRetrieveAuditLogRequest=} [properties] Properties to set
             * @returns {waterfront.RetrieveAuditLogRequest} RetrieveAuditLogRequest instance
             */
            RetrieveAuditLogRequest.create = function create(properties) {
                return new RetrieveAuditLogRequest(properties);
            };
    
            /**
             * Encodes the specified RetrieveAuditLogRequest message. Does not implicitly {@link waterfront.RetrieveAuditLogRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {waterfront.IRetrieveAuditLogRequest} message RetrieveAuditLogRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveAuditLogRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomHash);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RetrieveAuditLogRequest message, length delimited. Does not implicitly {@link waterfront.RetrieveAuditLogRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {waterfront.IRetrieveAuditLogRequest} message RetrieveAuditLogRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveAuditLogRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrieveAuditLogRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrieveAuditLogRequest} RetrieveAuditLogRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveAuditLogRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrieveAuditLogRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 2:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a RetrieveAuditLogRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrieveAuditLogRequest} RetrieveAuditLogRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveAuditLogRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrieveAuditLogRequest message.
             * @function verify
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrieveAuditLogRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a RetrieveAuditLogRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrieveAuditLogRequest} RetrieveAuditLogRequest
             */
            RetrieveAuditLogRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrieveAuditLogRequest)
                    return object;
                var message = new $root.waterfront.RetrieveAuditLogRequest();
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.RetrieveAuditLogRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RetrieveAuditLogRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrieveAuditLogRequest
             * @static
             * @param {waterfront.RetrieveAuditLogRequest} message RetrieveAuditLogRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrieveAuditLogRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.auth = null;
                }
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this RetrieveAuditLogRequest to JSON.
             * @function toJSON
             * @memberof waterfront.RetrieveAuditLogRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrieveAuditLogRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrieveAuditLogRequest;
        })();
    
        waterfront.RetrieveAuditLogResponse = (function() {
    
            /**
             * Properties of a RetrieveAuditLogResponse.
             * @memberof waterfront
             * @interface IRetrieveAuditLogResponse
             * @property {Uint8Array|null} [data] RetrieveAuditLogResponse data
             */
    
            /**
             * Constructs a new RetrieveAuditLogResponse.
             * @memberof waterfront
             * @classdesc Represents a RetrieveAuditLogResponse.
             * @implements IRetrieveAuditLogResponse
             * @constructor
             * @param {waterfront.IRetrieveAuditLogResponse=} [properties] Properties to set
             */
            function RetrieveAuditLogResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrieveAuditLogResponse data.
             * @member {Uint8Array} data
             * @memberof waterfront.RetrieveAuditLogResponse
             * @instance
             */
            RetrieveAuditLogResponse.prototype.data = $util.newBuffer([]);
    
            /**
             * Creates a new RetrieveAuditLogResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {waterfront.IRetrieveAuditLogResponse=} [properties] Properties to set
             * @returns {waterfront.RetrieveAuditLogResponse} RetrieveAuditLogResponse instance
             */
            RetrieveAuditLogResponse.create = function create(properties) {
                return new RetrieveAuditLogResponse(properties);
            };
    
            /**
             * Encodes the specified RetrieveAuditLogResponse message. Does not implicitly {@link waterfront.RetrieveAuditLogResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {waterfront.IRetrieveAuditLogResponse} message RetrieveAuditLogResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveAuditLogResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                return writer;
            };
    
            /**
             * Encodes the specified RetrieveAuditLogResponse message, length delimited. Does not implicitly {@link waterfront.RetrieveAuditLogResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {waterfront.IRetrieveAuditLogResponse} message RetrieveAuditLogResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveAuditLogResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrieveAuditLogResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrieveAuditLogResponse} RetrieveAuditLogResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveAuditLogResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrieveAuditLogResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.data = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RetrieveAuditLogResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrieveAuditLogResponse} RetrieveAuditLogResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveAuditLogResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrieveAuditLogResponse message.
             * @function verify
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrieveAuditLogResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                return null;
            };
    
            /**
             * Creates a RetrieveAuditLogResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrieveAuditLogResponse} RetrieveAuditLogResponse
             */
            RetrieveAuditLogResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrieveAuditLogResponse)
                    return object;
                var message = new $root.waterfront.RetrieveAuditLogResponse();
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                return message;
            };
    
            /**
             * Creates a plain object from a RetrieveAuditLogResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrieveAuditLogResponse
             * @static
             * @param {waterfront.RetrieveAuditLogResponse} message RetrieveAuditLogResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrieveAuditLogResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                return object;
            };
    
            /**
             * Converts this RetrieveAuditLogResponse to JSON.
             * @function toJSON
             * @memberof waterfront.RetrieveAuditLogResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrieveAuditLogResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrieveAuditLogResponse;
        })();
    
        /**
         * DataRoomStatus enum.
         * @name waterfront.DataRoomStatus
         * @enum {number}
         * @property {number} Active=1 Active value
         * @property {number} Stopped=2 Stopped value
         */
        waterfront.DataRoomStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[1] = "Active"] = 1;
            values[valuesById[2] = "Stopped"] = 2;
            return values;
        })();
    
        waterfront.RetrieveDataRoomStatusRequest = (function() {
    
            /**
             * Properties of a RetrieveDataRoomStatusRequest.
             * @memberof waterfront
             * @interface IRetrieveDataRoomStatusRequest
             * @property {Uint8Array} dataRoomHash RetrieveDataRoomStatusRequest dataRoomHash
             * @property {waterfront.IAuthenticationData} auth RetrieveDataRoomStatusRequest auth
             */
    
            /**
             * Constructs a new RetrieveDataRoomStatusRequest.
             * @memberof waterfront
             * @classdesc Represents a RetrieveDataRoomStatusRequest.
             * @implements IRetrieveDataRoomStatusRequest
             * @constructor
             * @param {waterfront.IRetrieveDataRoomStatusRequest=} [properties] Properties to set
             */
            function RetrieveDataRoomStatusRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrieveDataRoomStatusRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @instance
             */
            RetrieveDataRoomStatusRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * RetrieveDataRoomStatusRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @instance
             */
            RetrieveDataRoomStatusRequest.prototype.auth = null;
    
            /**
             * Creates a new RetrieveDataRoomStatusRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {waterfront.IRetrieveDataRoomStatusRequest=} [properties] Properties to set
             * @returns {waterfront.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest instance
             */
            RetrieveDataRoomStatusRequest.create = function create(properties) {
                return new RetrieveDataRoomStatusRequest(properties);
            };
    
            /**
             * Encodes the specified RetrieveDataRoomStatusRequest message. Does not implicitly {@link waterfront.RetrieveDataRoomStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {waterfront.IRetrieveDataRoomStatusRequest} message RetrieveDataRoomStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomHash);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RetrieveDataRoomStatusRequest message, length delimited. Does not implicitly {@link waterfront.RetrieveDataRoomStatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {waterfront.IRetrieveDataRoomStatusRequest} message RetrieveDataRoomStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrieveDataRoomStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrieveDataRoomStatusRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 2:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a RetrieveDataRoomStatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrieveDataRoomStatusRequest message.
             * @function verify
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrieveDataRoomStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a RetrieveDataRoomStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrieveDataRoomStatusRequest} RetrieveDataRoomStatusRequest
             */
            RetrieveDataRoomStatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrieveDataRoomStatusRequest)
                    return object;
                var message = new $root.waterfront.RetrieveDataRoomStatusRequest();
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.RetrieveDataRoomStatusRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RetrieveDataRoomStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @static
             * @param {waterfront.RetrieveDataRoomStatusRequest} message RetrieveDataRoomStatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrieveDataRoomStatusRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.auth = null;
                }
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this RetrieveDataRoomStatusRequest to JSON.
             * @function toJSON
             * @memberof waterfront.RetrieveDataRoomStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrieveDataRoomStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrieveDataRoomStatusRequest;
        })();
    
        waterfront.RetrieveDataRoomStatusResponse = (function() {
    
            /**
             * Properties of a RetrieveDataRoomStatusResponse.
             * @memberof waterfront
             * @interface IRetrieveDataRoomStatusResponse
             * @property {waterfront.DataRoomStatus} status RetrieveDataRoomStatusResponse status
             */
    
            /**
             * Constructs a new RetrieveDataRoomStatusResponse.
             * @memberof waterfront
             * @classdesc Represents a RetrieveDataRoomStatusResponse.
             * @implements IRetrieveDataRoomStatusResponse
             * @constructor
             * @param {waterfront.IRetrieveDataRoomStatusResponse=} [properties] Properties to set
             */
            function RetrieveDataRoomStatusResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrieveDataRoomStatusResponse status.
             * @member {waterfront.DataRoomStatus} status
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @instance
             */
            RetrieveDataRoomStatusResponse.prototype.status = 1;
    
            /**
             * Creates a new RetrieveDataRoomStatusResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {waterfront.IRetrieveDataRoomStatusResponse=} [properties] Properties to set
             * @returns {waterfront.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse instance
             */
            RetrieveDataRoomStatusResponse.create = function create(properties) {
                return new RetrieveDataRoomStatusResponse(properties);
            };
    
            /**
             * Encodes the specified RetrieveDataRoomStatusResponse message. Does not implicitly {@link waterfront.RetrieveDataRoomStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {waterfront.IRetrieveDataRoomStatusResponse} message RetrieveDataRoomStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                return writer;
            };
    
            /**
             * Encodes the specified RetrieveDataRoomStatusResponse message, length delimited. Does not implicitly {@link waterfront.RetrieveDataRoomStatusResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {waterfront.IRetrieveDataRoomStatusResponse} message RetrieveDataRoomStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrieveDataRoomStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrieveDataRoomStatusResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.status = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("status"))
                    throw $util.ProtocolError("missing required 'status'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a RetrieveDataRoomStatusResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrieveDataRoomStatusResponse message.
             * @function verify
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrieveDataRoomStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 1:
                case 2:
                    break;
                }
                return null;
            };
    
            /**
             * Creates a RetrieveDataRoomStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrieveDataRoomStatusResponse} RetrieveDataRoomStatusResponse
             */
            RetrieveDataRoomStatusResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrieveDataRoomStatusResponse)
                    return object;
                var message = new $root.waterfront.RetrieveDataRoomStatusResponse();
                switch (object.status) {
                case "Active":
                case 1:
                    message.status = 1;
                    break;
                case "Stopped":
                case 2:
                    message.status = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RetrieveDataRoomStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @static
             * @param {waterfront.RetrieveDataRoomStatusResponse} message RetrieveDataRoomStatusResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrieveDataRoomStatusResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.status = options.enums === String ? "Active" : 1;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.waterfront.DataRoomStatus[message.status] : message.status;
                return object;
            };
    
            /**
             * Converts this RetrieveDataRoomStatusResponse to JSON.
             * @function toJSON
             * @memberof waterfront.RetrieveDataRoomStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrieveDataRoomStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrieveDataRoomStatusResponse;
        })();
    
        waterfront.UpdateDataRoomStatusRequest = (function() {
    
            /**
             * Properties of an UpdateDataRoomStatusRequest.
             * @memberof waterfront
             * @interface IUpdateDataRoomStatusRequest
             * @property {Uint8Array} dataRoomHash UpdateDataRoomStatusRequest dataRoomHash
             * @property {waterfront.IAuthenticationData} auth UpdateDataRoomStatusRequest auth
             * @property {waterfront.DataRoomStatus} status UpdateDataRoomStatusRequest status
             */
    
            /**
             * Constructs a new UpdateDataRoomStatusRequest.
             * @memberof waterfront
             * @classdesc Represents an UpdateDataRoomStatusRequest.
             * @implements IUpdateDataRoomStatusRequest
             * @constructor
             * @param {waterfront.IUpdateDataRoomStatusRequest=} [properties] Properties to set
             */
            function UpdateDataRoomStatusRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UpdateDataRoomStatusRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @instance
             */
            UpdateDataRoomStatusRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * UpdateDataRoomStatusRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @instance
             */
            UpdateDataRoomStatusRequest.prototype.auth = null;
    
            /**
             * UpdateDataRoomStatusRequest status.
             * @member {waterfront.DataRoomStatus} status
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @instance
             */
            UpdateDataRoomStatusRequest.prototype.status = 1;
    
            /**
             * Creates a new UpdateDataRoomStatusRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {waterfront.IUpdateDataRoomStatusRequest=} [properties] Properties to set
             * @returns {waterfront.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest instance
             */
            UpdateDataRoomStatusRequest.create = function create(properties) {
                return new UpdateDataRoomStatusRequest(properties);
            };
    
            /**
             * Encodes the specified UpdateDataRoomStatusRequest message. Does not implicitly {@link waterfront.UpdateDataRoomStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {waterfront.IUpdateDataRoomStatusRequest} message UpdateDataRoomStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDataRoomStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomHash);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                return writer;
            };
    
            /**
             * Encodes the specified UpdateDataRoomStatusRequest message, length delimited. Does not implicitly {@link waterfront.UpdateDataRoomStatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {waterfront.IUpdateDataRoomStatusRequest} message UpdateDataRoomStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDataRoomStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateDataRoomStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDataRoomStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.UpdateDataRoomStatusRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 2:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.status = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                if (!message.hasOwnProperty("status"))
                    throw $util.ProtocolError("missing required 'status'", { instance: message });
                return message;
            };
    
            /**
             * Decodes an UpdateDataRoomStatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDataRoomStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateDataRoomStatusRequest message.
             * @function verify
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateDataRoomStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 1:
                case 2:
                    break;
                }
                return null;
            };
    
            /**
             * Creates an UpdateDataRoomStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.UpdateDataRoomStatusRequest} UpdateDataRoomStatusRequest
             */
            UpdateDataRoomStatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.UpdateDataRoomStatusRequest)
                    return object;
                var message = new $root.waterfront.UpdateDataRoomStatusRequest();
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.UpdateDataRoomStatusRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                switch (object.status) {
                case "Active":
                case 1:
                    message.status = 1;
                    break;
                case "Stopped":
                case 2:
                    message.status = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UpdateDataRoomStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @static
             * @param {waterfront.UpdateDataRoomStatusRequest} message UpdateDataRoomStatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateDataRoomStatusRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.auth = null;
                    object.status = options.enums === String ? "Active" : 1;
                }
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.waterfront.DataRoomStatus[message.status] : message.status;
                return object;
            };
    
            /**
             * Converts this UpdateDataRoomStatusRequest to JSON.
             * @function toJSON
             * @memberof waterfront.UpdateDataRoomStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateDataRoomStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return UpdateDataRoomStatusRequest;
        })();
    
        waterfront.UpdateDataRoomStatusResponse = (function() {
    
            /**
             * Properties of an UpdateDataRoomStatusResponse.
             * @memberof waterfront
             * @interface IUpdateDataRoomStatusResponse
             */
    
            /**
             * Constructs a new UpdateDataRoomStatusResponse.
             * @memberof waterfront
             * @classdesc Represents an UpdateDataRoomStatusResponse.
             * @implements IUpdateDataRoomStatusResponse
             * @constructor
             * @param {waterfront.IUpdateDataRoomStatusResponse=} [properties] Properties to set
             */
            function UpdateDataRoomStatusResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new UpdateDataRoomStatusResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {waterfront.IUpdateDataRoomStatusResponse=} [properties] Properties to set
             * @returns {waterfront.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse instance
             */
            UpdateDataRoomStatusResponse.create = function create(properties) {
                return new UpdateDataRoomStatusResponse(properties);
            };
    
            /**
             * Encodes the specified UpdateDataRoomStatusResponse message. Does not implicitly {@link waterfront.UpdateDataRoomStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {waterfront.IUpdateDataRoomStatusResponse} message UpdateDataRoomStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDataRoomStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified UpdateDataRoomStatusResponse message, length delimited. Does not implicitly {@link waterfront.UpdateDataRoomStatusResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {waterfront.IUpdateDataRoomStatusResponse} message UpdateDataRoomStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDataRoomStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateDataRoomStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDataRoomStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.UpdateDataRoomStatusResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateDataRoomStatusResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDataRoomStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateDataRoomStatusResponse message.
             * @function verify
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateDataRoomStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an UpdateDataRoomStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.UpdateDataRoomStatusResponse} UpdateDataRoomStatusResponse
             */
            UpdateDataRoomStatusResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.UpdateDataRoomStatusResponse)
                    return object;
                return new $root.waterfront.UpdateDataRoomStatusResponse();
            };
    
            /**
             * Creates a plain object from an UpdateDataRoomStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @static
             * @param {waterfront.UpdateDataRoomStatusResponse} message UpdateDataRoomStatusResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateDataRoomStatusResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this UpdateDataRoomStatusResponse to JSON.
             * @function toJSON
             * @memberof waterfront.UpdateDataRoomStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateDataRoomStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return UpdateDataRoomStatusResponse;
        })();
    
        waterfront.RetrievePublishedDatasetRequest = (function() {
    
            /**
             * Properties of a RetrievePublishedDatasetRequest.
             * @memberof waterfront
             * @interface IRetrievePublishedDatasetRequest
             * @property {Uint8Array} dataRoomHash RetrievePublishedDatasetRequest dataRoomHash
             * @property {waterfront.IAuthenticationData} auth RetrievePublishedDatasetRequest auth
             */
    
            /**
             * Constructs a new RetrievePublishedDatasetRequest.
             * @memberof waterfront
             * @classdesc Represents a RetrievePublishedDatasetRequest.
             * @implements IRetrievePublishedDatasetRequest
             * @constructor
             * @param {waterfront.IRetrievePublishedDatasetRequest=} [properties] Properties to set
             */
            function RetrievePublishedDatasetRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrievePublishedDatasetRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @instance
             */
            RetrievePublishedDatasetRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * RetrievePublishedDatasetRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @instance
             */
            RetrievePublishedDatasetRequest.prototype.auth = null;
    
            /**
             * Creates a new RetrievePublishedDatasetRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {waterfront.IRetrievePublishedDatasetRequest=} [properties] Properties to set
             * @returns {waterfront.RetrievePublishedDatasetRequest} RetrievePublishedDatasetRequest instance
             */
            RetrievePublishedDatasetRequest.create = function create(properties) {
                return new RetrievePublishedDatasetRequest(properties);
            };
    
            /**
             * Encodes the specified RetrievePublishedDatasetRequest message. Does not implicitly {@link waterfront.RetrievePublishedDatasetRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {waterfront.IRetrievePublishedDatasetRequest} message RetrievePublishedDatasetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrievePublishedDatasetRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dataRoomHash);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RetrievePublishedDatasetRequest message, length delimited. Does not implicitly {@link waterfront.RetrievePublishedDatasetRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {waterfront.IRetrievePublishedDatasetRequest} message RetrievePublishedDatasetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrievePublishedDatasetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrievePublishedDatasetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrievePublishedDatasetRequest} RetrievePublishedDatasetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrievePublishedDatasetRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrievePublishedDatasetRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 2:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a RetrievePublishedDatasetRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrievePublishedDatasetRequest} RetrievePublishedDatasetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrievePublishedDatasetRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrievePublishedDatasetRequest message.
             * @function verify
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrievePublishedDatasetRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a RetrievePublishedDatasetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrievePublishedDatasetRequest} RetrievePublishedDatasetRequest
             */
            RetrievePublishedDatasetRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrievePublishedDatasetRequest)
                    return object;
                var message = new $root.waterfront.RetrievePublishedDatasetRequest();
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.RetrievePublishedDatasetRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RetrievePublishedDatasetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @static
             * @param {waterfront.RetrievePublishedDatasetRequest} message RetrievePublishedDatasetRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrievePublishedDatasetRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.auth = null;
                }
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this RetrievePublishedDatasetRequest to JSON.
             * @function toJSON
             * @memberof waterfront.RetrievePublishedDatasetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrievePublishedDatasetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrievePublishedDatasetRequest;
        })();
    
        waterfront.PublishedDataset = (function() {
    
            /**
             * Properties of a PublishedDataset.
             * @memberof waterfront
             * @interface IPublishedDataset
             * @property {string} table PublishedDataset table
             * @property {string} user PublishedDataset user
             * @property {number|Long} timestamp PublishedDataset timestamp
             * @property {Uint8Array} datasetHash PublishedDataset datasetHash
             */
    
            /**
             * Constructs a new PublishedDataset.
             * @memberof waterfront
             * @classdesc Represents a PublishedDataset.
             * @implements IPublishedDataset
             * @constructor
             * @param {waterfront.IPublishedDataset=} [properties] Properties to set
             */
            function PublishedDataset(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PublishedDataset table.
             * @member {string} table
             * @memberof waterfront.PublishedDataset
             * @instance
             */
            PublishedDataset.prototype.table = "";
    
            /**
             * PublishedDataset user.
             * @member {string} user
             * @memberof waterfront.PublishedDataset
             * @instance
             */
            PublishedDataset.prototype.user = "";
    
            /**
             * PublishedDataset timestamp.
             * @member {number|Long} timestamp
             * @memberof waterfront.PublishedDataset
             * @instance
             */
            PublishedDataset.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * PublishedDataset datasetHash.
             * @member {Uint8Array} datasetHash
             * @memberof waterfront.PublishedDataset
             * @instance
             */
            PublishedDataset.prototype.datasetHash = $util.newBuffer([]);
    
            /**
             * Creates a new PublishedDataset instance using the specified properties.
             * @function create
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {waterfront.IPublishedDataset=} [properties] Properties to set
             * @returns {waterfront.PublishedDataset} PublishedDataset instance
             */
            PublishedDataset.create = function create(properties) {
                return new PublishedDataset(properties);
            };
    
            /**
             * Encodes the specified PublishedDataset message. Does not implicitly {@link waterfront.PublishedDataset.verify|verify} messages.
             * @function encode
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {waterfront.IPublishedDataset} message PublishedDataset message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishedDataset.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.table);
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.user);
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.timestamp);
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.datasetHash);
                return writer;
            };
    
            /**
             * Encodes the specified PublishedDataset message, length delimited. Does not implicitly {@link waterfront.PublishedDataset.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {waterfront.IPublishedDataset} message PublishedDataset message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishedDataset.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PublishedDataset message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.PublishedDataset} PublishedDataset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishedDataset.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.PublishedDataset();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.table = reader.string();
                        break;
                    case 2:
                        message.user = reader.string();
                        break;
                    case 3:
                        message.timestamp = reader.uint64();
                        break;
                    case 4:
                        message.datasetHash = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("table"))
                    throw $util.ProtocolError("missing required 'table'", { instance: message });
                if (!message.hasOwnProperty("user"))
                    throw $util.ProtocolError("missing required 'user'", { instance: message });
                if (!message.hasOwnProperty("timestamp"))
                    throw $util.ProtocolError("missing required 'timestamp'", { instance: message });
                if (!message.hasOwnProperty("datasetHash"))
                    throw $util.ProtocolError("missing required 'datasetHash'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a PublishedDataset message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.PublishedDataset} PublishedDataset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishedDataset.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PublishedDataset message.
             * @function verify
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PublishedDataset.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.table))
                    return "table: string expected";
                if (!$util.isString(message.user))
                    return "user: string expected";
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
                if (!(message.datasetHash && typeof message.datasetHash.length === "number" || $util.isString(message.datasetHash)))
                    return "datasetHash: buffer expected";
                return null;
            };
    
            /**
             * Creates a PublishedDataset message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.PublishedDataset} PublishedDataset
             */
            PublishedDataset.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.PublishedDataset)
                    return object;
                var message = new $root.waterfront.PublishedDataset();
                if (object.table != null)
                    message.table = String(object.table);
                if (object.user != null)
                    message.user = String(object.user);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
                if (object.datasetHash != null)
                    if (typeof object.datasetHash === "string")
                        $util.base64.decode(object.datasetHash, message.datasetHash = $util.newBuffer($util.base64.length(object.datasetHash)), 0);
                    else if (object.datasetHash.length)
                        message.datasetHash = object.datasetHash;
                return message;
            };
    
            /**
             * Creates a plain object from a PublishedDataset message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.PublishedDataset
             * @static
             * @param {waterfront.PublishedDataset} message PublishedDataset
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PublishedDataset.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.table = "";
                    object.user = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.datasetHash = "";
                    else {
                        object.datasetHash = [];
                        if (options.bytes !== Array)
                            object.datasetHash = $util.newBuffer(object.datasetHash);
                    }
                }
                if (message.table != null && message.hasOwnProperty("table"))
                    object.table = message.table;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = message.user;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
                if (message.datasetHash != null && message.hasOwnProperty("datasetHash"))
                    object.datasetHash = options.bytes === String ? $util.base64.encode(message.datasetHash, 0, message.datasetHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.datasetHash) : message.datasetHash;
                return object;
            };
    
            /**
             * Converts this PublishedDataset to JSON.
             * @function toJSON
             * @memberof waterfront.PublishedDataset
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PublishedDataset.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PublishedDataset;
        })();
    
        waterfront.RetrievePublishedDatasetResponse = (function() {
    
            /**
             * Properties of a RetrievePublishedDatasetResponse.
             * @memberof waterfront
             * @interface IRetrievePublishedDatasetResponse
             * @property {Array.<waterfront.IPublishedDataset>|null} [publishedDatasets] RetrievePublishedDatasetResponse publishedDatasets
             */
    
            /**
             * Constructs a new RetrievePublishedDatasetResponse.
             * @memberof waterfront
             * @classdesc Represents a RetrievePublishedDatasetResponse.
             * @implements IRetrievePublishedDatasetResponse
             * @constructor
             * @param {waterfront.IRetrievePublishedDatasetResponse=} [properties] Properties to set
             */
            function RetrievePublishedDatasetResponse(properties) {
                this.publishedDatasets = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RetrievePublishedDatasetResponse publishedDatasets.
             * @member {Array.<waterfront.IPublishedDataset>} publishedDatasets
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @instance
             */
            RetrievePublishedDatasetResponse.prototype.publishedDatasets = $util.emptyArray;
    
            /**
             * Creates a new RetrievePublishedDatasetResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {waterfront.IRetrievePublishedDatasetResponse=} [properties] Properties to set
             * @returns {waterfront.RetrievePublishedDatasetResponse} RetrievePublishedDatasetResponse instance
             */
            RetrievePublishedDatasetResponse.create = function create(properties) {
                return new RetrievePublishedDatasetResponse(properties);
            };
    
            /**
             * Encodes the specified RetrievePublishedDatasetResponse message. Does not implicitly {@link waterfront.RetrievePublishedDatasetResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {waterfront.IRetrievePublishedDatasetResponse} message RetrievePublishedDatasetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrievePublishedDatasetResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.publishedDatasets != null && message.publishedDatasets.length)
                    for (var i = 0; i < message.publishedDatasets.length; ++i)
                        $root.waterfront.PublishedDataset.encode(message.publishedDatasets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RetrievePublishedDatasetResponse message, length delimited. Does not implicitly {@link waterfront.RetrievePublishedDatasetResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {waterfront.IRetrievePublishedDatasetResponse} message RetrievePublishedDatasetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrievePublishedDatasetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrievePublishedDatasetResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RetrievePublishedDatasetResponse} RetrievePublishedDatasetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrievePublishedDatasetResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RetrievePublishedDatasetResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.publishedDatasets && message.publishedDatasets.length))
                            message.publishedDatasets = [];
                        message.publishedDatasets.push($root.waterfront.PublishedDataset.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RetrievePublishedDatasetResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RetrievePublishedDatasetResponse} RetrievePublishedDatasetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrievePublishedDatasetResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrievePublishedDatasetResponse message.
             * @function verify
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrievePublishedDatasetResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.publishedDatasets != null && message.hasOwnProperty("publishedDatasets")) {
                    if (!Array.isArray(message.publishedDatasets))
                        return "publishedDatasets: array expected";
                    for (var i = 0; i < message.publishedDatasets.length; ++i) {
                        var error = $root.waterfront.PublishedDataset.verify(message.publishedDatasets[i]);
                        if (error)
                            return "publishedDatasets." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a RetrievePublishedDatasetResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RetrievePublishedDatasetResponse} RetrievePublishedDatasetResponse
             */
            RetrievePublishedDatasetResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RetrievePublishedDatasetResponse)
                    return object;
                var message = new $root.waterfront.RetrievePublishedDatasetResponse();
                if (object.publishedDatasets) {
                    if (!Array.isArray(object.publishedDatasets))
                        throw TypeError(".waterfront.RetrievePublishedDatasetResponse.publishedDatasets: array expected");
                    message.publishedDatasets = [];
                    for (var i = 0; i < object.publishedDatasets.length; ++i) {
                        if (typeof object.publishedDatasets[i] !== "object")
                            throw TypeError(".waterfront.RetrievePublishedDatasetResponse.publishedDatasets: object expected");
                        message.publishedDatasets[i] = $root.waterfront.PublishedDataset.fromObject(object.publishedDatasets[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RetrievePublishedDatasetResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @static
             * @param {waterfront.RetrievePublishedDatasetResponse} message RetrievePublishedDatasetResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrievePublishedDatasetResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.publishedDatasets = [];
                if (message.publishedDatasets && message.publishedDatasets.length) {
                    object.publishedDatasets = [];
                    for (var j = 0; j < message.publishedDatasets.length; ++j)
                        object.publishedDatasets[j] = $root.waterfront.PublishedDataset.toObject(message.publishedDatasets[j], options);
                }
                return object;
            };
    
            /**
             * Converts this RetrievePublishedDatasetResponse to JSON.
             * @function toJSON
             * @memberof waterfront.RetrievePublishedDatasetResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrievePublishedDatasetResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrievePublishedDatasetResponse;
        })();
    
        waterfront.RemovePublishedDatasetRequest = (function() {
    
            /**
             * Properties of a RemovePublishedDatasetRequest.
             * @memberof waterfront
             * @interface IRemovePublishedDatasetRequest
             * @property {Uint8Array} manifestHash RemovePublishedDatasetRequest manifestHash
             * @property {Uint8Array} dataRoomHash RemovePublishedDatasetRequest dataRoomHash
             * @property {string} dataRoomTableName RemovePublishedDatasetRequest dataRoomTableName
             * @property {waterfront.IAuthenticationData} auth RemovePublishedDatasetRequest auth
             */
    
            /**
             * Constructs a new RemovePublishedDatasetRequest.
             * @memberof waterfront
             * @classdesc Represents a RemovePublishedDatasetRequest.
             * @implements IRemovePublishedDatasetRequest
             * @constructor
             * @param {waterfront.IRemovePublishedDatasetRequest=} [properties] Properties to set
             */
            function RemovePublishedDatasetRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RemovePublishedDatasetRequest manifestHash.
             * @member {Uint8Array} manifestHash
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @instance
             */
            RemovePublishedDatasetRequest.prototype.manifestHash = $util.newBuffer([]);
    
            /**
             * RemovePublishedDatasetRequest dataRoomHash.
             * @member {Uint8Array} dataRoomHash
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @instance
             */
            RemovePublishedDatasetRequest.prototype.dataRoomHash = $util.newBuffer([]);
    
            /**
             * RemovePublishedDatasetRequest dataRoomTableName.
             * @member {string} dataRoomTableName
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @instance
             */
            RemovePublishedDatasetRequest.prototype.dataRoomTableName = "";
    
            /**
             * RemovePublishedDatasetRequest auth.
             * @member {waterfront.IAuthenticationData} auth
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @instance
             */
            RemovePublishedDatasetRequest.prototype.auth = null;
    
            /**
             * Creates a new RemovePublishedDatasetRequest instance using the specified properties.
             * @function create
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {waterfront.IRemovePublishedDatasetRequest=} [properties] Properties to set
             * @returns {waterfront.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest instance
             */
            RemovePublishedDatasetRequest.create = function create(properties) {
                return new RemovePublishedDatasetRequest(properties);
            };
    
            /**
             * Encodes the specified RemovePublishedDatasetRequest message. Does not implicitly {@link waterfront.RemovePublishedDatasetRequest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {waterfront.IRemovePublishedDatasetRequest} message RemovePublishedDatasetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemovePublishedDatasetRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.manifestHash);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.dataRoomHash);
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.dataRoomTableName);
                $root.waterfront.AuthenticationData.encode(message.auth, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RemovePublishedDatasetRequest message, length delimited. Does not implicitly {@link waterfront.RemovePublishedDatasetRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {waterfront.IRemovePublishedDatasetRequest} message RemovePublishedDatasetRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemovePublishedDatasetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RemovePublishedDatasetRequest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemovePublishedDatasetRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RemovePublishedDatasetRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.manifestHash = reader.bytes();
                        break;
                    case 2:
                        message.dataRoomHash = reader.bytes();
                        break;
                    case 3:
                        message.dataRoomTableName = reader.string();
                        break;
                    case 4:
                        message.auth = $root.waterfront.AuthenticationData.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("manifestHash"))
                    throw $util.ProtocolError("missing required 'manifestHash'", { instance: message });
                if (!message.hasOwnProperty("dataRoomHash"))
                    throw $util.ProtocolError("missing required 'dataRoomHash'", { instance: message });
                if (!message.hasOwnProperty("dataRoomTableName"))
                    throw $util.ProtocolError("missing required 'dataRoomTableName'", { instance: message });
                if (!message.hasOwnProperty("auth"))
                    throw $util.ProtocolError("missing required 'auth'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a RemovePublishedDatasetRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemovePublishedDatasetRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RemovePublishedDatasetRequest message.
             * @function verify
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemovePublishedDatasetRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.manifestHash && typeof message.manifestHash.length === "number" || $util.isString(message.manifestHash)))
                    return "manifestHash: buffer expected";
                if (!(message.dataRoomHash && typeof message.dataRoomHash.length === "number" || $util.isString(message.dataRoomHash)))
                    return "dataRoomHash: buffer expected";
                if (!$util.isString(message.dataRoomTableName))
                    return "dataRoomTableName: string expected";
                {
                    var error = $root.waterfront.AuthenticationData.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
                return null;
            };
    
            /**
             * Creates a RemovePublishedDatasetRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RemovePublishedDatasetRequest} RemovePublishedDatasetRequest
             */
            RemovePublishedDatasetRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RemovePublishedDatasetRequest)
                    return object;
                var message = new $root.waterfront.RemovePublishedDatasetRequest();
                if (object.manifestHash != null)
                    if (typeof object.manifestHash === "string")
                        $util.base64.decode(object.manifestHash, message.manifestHash = $util.newBuffer($util.base64.length(object.manifestHash)), 0);
                    else if (object.manifestHash.length)
                        message.manifestHash = object.manifestHash;
                if (object.dataRoomHash != null)
                    if (typeof object.dataRoomHash === "string")
                        $util.base64.decode(object.dataRoomHash, message.dataRoomHash = $util.newBuffer($util.base64.length(object.dataRoomHash)), 0);
                    else if (object.dataRoomHash.length)
                        message.dataRoomHash = object.dataRoomHash;
                if (object.dataRoomTableName != null)
                    message.dataRoomTableName = String(object.dataRoomTableName);
                if (object.auth != null) {
                    if (typeof object.auth !== "object")
                        throw TypeError(".waterfront.RemovePublishedDatasetRequest.auth: object expected");
                    message.auth = $root.waterfront.AuthenticationData.fromObject(object.auth);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RemovePublishedDatasetRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @static
             * @param {waterfront.RemovePublishedDatasetRequest} message RemovePublishedDatasetRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemovePublishedDatasetRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.manifestHash = "";
                    else {
                        object.manifestHash = [];
                        if (options.bytes !== Array)
                            object.manifestHash = $util.newBuffer(object.manifestHash);
                    }
                    if (options.bytes === String)
                        object.dataRoomHash = "";
                    else {
                        object.dataRoomHash = [];
                        if (options.bytes !== Array)
                            object.dataRoomHash = $util.newBuffer(object.dataRoomHash);
                    }
                    object.dataRoomTableName = "";
                    object.auth = null;
                }
                if (message.manifestHash != null && message.hasOwnProperty("manifestHash"))
                    object.manifestHash = options.bytes === String ? $util.base64.encode(message.manifestHash, 0, message.manifestHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.manifestHash) : message.manifestHash;
                if (message.dataRoomHash != null && message.hasOwnProperty("dataRoomHash"))
                    object.dataRoomHash = options.bytes === String ? $util.base64.encode(message.dataRoomHash, 0, message.dataRoomHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataRoomHash) : message.dataRoomHash;
                if (message.dataRoomTableName != null && message.hasOwnProperty("dataRoomTableName"))
                    object.dataRoomTableName = message.dataRoomTableName;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = $root.waterfront.AuthenticationData.toObject(message.auth, options);
                return object;
            };
    
            /**
             * Converts this RemovePublishedDatasetRequest to JSON.
             * @function toJSON
             * @memberof waterfront.RemovePublishedDatasetRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemovePublishedDatasetRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RemovePublishedDatasetRequest;
        })();
    
        waterfront.RemovePublishedDatasetResponse = (function() {
    
            /**
             * Properties of a RemovePublishedDatasetResponse.
             * @memberof waterfront
             * @interface IRemovePublishedDatasetResponse
             */
    
            /**
             * Constructs a new RemovePublishedDatasetResponse.
             * @memberof waterfront
             * @classdesc Represents a RemovePublishedDatasetResponse.
             * @implements IRemovePublishedDatasetResponse
             * @constructor
             * @param {waterfront.IRemovePublishedDatasetResponse=} [properties] Properties to set
             */
            function RemovePublishedDatasetResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new RemovePublishedDatasetResponse instance using the specified properties.
             * @function create
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {waterfront.IRemovePublishedDatasetResponse=} [properties] Properties to set
             * @returns {waterfront.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse instance
             */
            RemovePublishedDatasetResponse.create = function create(properties) {
                return new RemovePublishedDatasetResponse(properties);
            };
    
            /**
             * Encodes the specified RemovePublishedDatasetResponse message. Does not implicitly {@link waterfront.RemovePublishedDatasetResponse.verify|verify} messages.
             * @function encode
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {waterfront.IRemovePublishedDatasetResponse} message RemovePublishedDatasetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemovePublishedDatasetResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified RemovePublishedDatasetResponse message, length delimited. Does not implicitly {@link waterfront.RemovePublishedDatasetResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {waterfront.IRemovePublishedDatasetResponse} message RemovePublishedDatasetResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemovePublishedDatasetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RemovePublishedDatasetResponse message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemovePublishedDatasetResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.RemovePublishedDatasetResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RemovePublishedDatasetResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemovePublishedDatasetResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RemovePublishedDatasetResponse message.
             * @function verify
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemovePublishedDatasetResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a RemovePublishedDatasetResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.RemovePublishedDatasetResponse} RemovePublishedDatasetResponse
             */
            RemovePublishedDatasetResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.RemovePublishedDatasetResponse)
                    return object;
                return new $root.waterfront.RemovePublishedDatasetResponse();
            };
    
            /**
             * Creates a plain object from a RemovePublishedDatasetResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @static
             * @param {waterfront.RemovePublishedDatasetResponse} message RemovePublishedDatasetResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemovePublishedDatasetResponse.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this RemovePublishedDatasetResponse to JSON.
             * @function toJSON
             * @memberof waterfront.RemovePublishedDatasetResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemovePublishedDatasetResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RemovePublishedDatasetResponse;
        })();
    
        waterfront.EncryptionKey = (function() {
    
            /**
             * Properties of an EncryptionKey.
             * @memberof waterfront
             * @interface IEncryptionKey
             * @property {Uint8Array} material EncryptionKey material
             * @property {Uint8Array} salt EncryptionKey salt
             */
    
            /**
             * Constructs a new EncryptionKey.
             * @memberof waterfront
             * @classdesc Represents an EncryptionKey.
             * @implements IEncryptionKey
             * @constructor
             * @param {waterfront.IEncryptionKey=} [properties] Properties to set
             */
            function EncryptionKey(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EncryptionKey material.
             * @member {Uint8Array} material
             * @memberof waterfront.EncryptionKey
             * @instance
             */
            EncryptionKey.prototype.material = $util.newBuffer([]);
    
            /**
             * EncryptionKey salt.
             * @member {Uint8Array} salt
             * @memberof waterfront.EncryptionKey
             * @instance
             */
            EncryptionKey.prototype.salt = $util.newBuffer([]);
    
            /**
             * Creates a new EncryptionKey instance using the specified properties.
             * @function create
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {waterfront.IEncryptionKey=} [properties] Properties to set
             * @returns {waterfront.EncryptionKey} EncryptionKey instance
             */
            EncryptionKey.create = function create(properties) {
                return new EncryptionKey(properties);
            };
    
            /**
             * Encodes the specified EncryptionKey message. Does not implicitly {@link waterfront.EncryptionKey.verify|verify} messages.
             * @function encode
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {waterfront.IEncryptionKey} message EncryptionKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EncryptionKey.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.material);
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.salt);
                return writer;
            };
    
            /**
             * Encodes the specified EncryptionKey message, length delimited. Does not implicitly {@link waterfront.EncryptionKey.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {waterfront.IEncryptionKey} message EncryptionKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EncryptionKey.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EncryptionKey message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.EncryptionKey} EncryptionKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EncryptionKey.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.EncryptionKey();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.material = reader.bytes();
                        break;
                    case 2:
                        message.salt = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("material"))
                    throw $util.ProtocolError("missing required 'material'", { instance: message });
                if (!message.hasOwnProperty("salt"))
                    throw $util.ProtocolError("missing required 'salt'", { instance: message });
                return message;
            };
    
            /**
             * Decodes an EncryptionKey message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.EncryptionKey} EncryptionKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EncryptionKey.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EncryptionKey message.
             * @function verify
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EncryptionKey.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.material && typeof message.material.length === "number" || $util.isString(message.material)))
                    return "material: buffer expected";
                if (!(message.salt && typeof message.salt.length === "number" || $util.isString(message.salt)))
                    return "salt: buffer expected";
                return null;
            };
    
            /**
             * Creates an EncryptionKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.EncryptionKey} EncryptionKey
             */
            EncryptionKey.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.EncryptionKey)
                    return object;
                var message = new $root.waterfront.EncryptionKey();
                if (object.material != null)
                    if (typeof object.material === "string")
                        $util.base64.decode(object.material, message.material = $util.newBuffer($util.base64.length(object.material)), 0);
                    else if (object.material.length)
                        message.material = object.material;
                if (object.salt != null)
                    if (typeof object.salt === "string")
                        $util.base64.decode(object.salt, message.salt = $util.newBuffer($util.base64.length(object.salt)), 0);
                    else if (object.salt.length)
                        message.salt = object.salt;
                return message;
            };
    
            /**
             * Creates a plain object from an EncryptionKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.EncryptionKey
             * @static
             * @param {waterfront.EncryptionKey} message EncryptionKey
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EncryptionKey.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.material = "";
                    else {
                        object.material = [];
                        if (options.bytes !== Array)
                            object.material = $util.newBuffer(object.material);
                    }
                    if (options.bytes === String)
                        object.salt = "";
                    else {
                        object.salt = [];
                        if (options.bytes !== Array)
                            object.salt = $util.newBuffer(object.salt);
                    }
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = options.bytes === String ? $util.base64.encode(message.material, 0, message.material.length) : options.bytes === Array ? Array.prototype.slice.call(message.material) : message.material;
                if (message.salt != null && message.hasOwnProperty("salt"))
                    object.salt = options.bytes === String ? $util.base64.encode(message.salt, 0, message.salt.length) : options.bytes === Array ? Array.prototype.slice.call(message.salt) : message.salt;
                return object;
            };
    
            /**
             * Converts this EncryptionKey to JSON.
             * @function toJSON
             * @memberof waterfront.EncryptionKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EncryptionKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return EncryptionKey;
        })();
    
        waterfront.TableSchema = (function() {
    
            /**
             * Properties of a TableSchema.
             * @memberof waterfront
             * @interface ITableSchema
             * @property {Array.<waterfront.INamedColumn>|null} [namedColumns] TableSchema namedColumns
             */
    
            /**
             * Constructs a new TableSchema.
             * @memberof waterfront
             * @classdesc Represents a TableSchema.
             * @implements ITableSchema
             * @constructor
             * @param {waterfront.ITableSchema=} [properties] Properties to set
             */
            function TableSchema(properties) {
                this.namedColumns = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TableSchema namedColumns.
             * @member {Array.<waterfront.INamedColumn>} namedColumns
             * @memberof waterfront.TableSchema
             * @instance
             */
            TableSchema.prototype.namedColumns = $util.emptyArray;
    
            /**
             * Creates a new TableSchema instance using the specified properties.
             * @function create
             * @memberof waterfront.TableSchema
             * @static
             * @param {waterfront.ITableSchema=} [properties] Properties to set
             * @returns {waterfront.TableSchema} TableSchema instance
             */
            TableSchema.create = function create(properties) {
                return new TableSchema(properties);
            };
    
            /**
             * Encodes the specified TableSchema message. Does not implicitly {@link waterfront.TableSchema.verify|verify} messages.
             * @function encode
             * @memberof waterfront.TableSchema
             * @static
             * @param {waterfront.ITableSchema} message TableSchema message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TableSchema.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.namedColumns != null && message.namedColumns.length)
                    for (var i = 0; i < message.namedColumns.length; ++i)
                        $root.waterfront.NamedColumn.encode(message.namedColumns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TableSchema message, length delimited. Does not implicitly {@link waterfront.TableSchema.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.TableSchema
             * @static
             * @param {waterfront.ITableSchema} message TableSchema message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TableSchema.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TableSchema message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.TableSchema
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.TableSchema} TableSchema
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TableSchema.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.TableSchema();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.namedColumns && message.namedColumns.length))
                            message.namedColumns = [];
                        message.namedColumns.push($root.waterfront.NamedColumn.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TableSchema message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.TableSchema
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.TableSchema} TableSchema
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TableSchema.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TableSchema message.
             * @function verify
             * @memberof waterfront.TableSchema
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TableSchema.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.namedColumns != null && message.hasOwnProperty("namedColumns")) {
                    if (!Array.isArray(message.namedColumns))
                        return "namedColumns: array expected";
                    for (var i = 0; i < message.namedColumns.length; ++i) {
                        var error = $root.waterfront.NamedColumn.verify(message.namedColumns[i]);
                        if (error)
                            return "namedColumns." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TableSchema message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.TableSchema
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.TableSchema} TableSchema
             */
            TableSchema.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.TableSchema)
                    return object;
                var message = new $root.waterfront.TableSchema();
                if (object.namedColumns) {
                    if (!Array.isArray(object.namedColumns))
                        throw TypeError(".waterfront.TableSchema.namedColumns: array expected");
                    message.namedColumns = [];
                    for (var i = 0; i < object.namedColumns.length; ++i) {
                        if (typeof object.namedColumns[i] !== "object")
                            throw TypeError(".waterfront.TableSchema.namedColumns: object expected");
                        message.namedColumns[i] = $root.waterfront.NamedColumn.fromObject(object.namedColumns[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TableSchema message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.TableSchema
             * @static
             * @param {waterfront.TableSchema} message TableSchema
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TableSchema.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.namedColumns = [];
                if (message.namedColumns && message.namedColumns.length) {
                    object.namedColumns = [];
                    for (var j = 0; j < message.namedColumns.length; ++j)
                        object.namedColumns[j] = $root.waterfront.NamedColumn.toObject(message.namedColumns[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TableSchema to JSON.
             * @function toJSON
             * @memberof waterfront.TableSchema
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TableSchema.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TableSchema;
        })();
    
        waterfront.NamedColumn = (function() {
    
            /**
             * Properties of a NamedColumn.
             * @memberof waterfront
             * @interface INamedColumn
             * @property {string} name NamedColumn name
             * @property {column_type.IColumnType} columnType NamedColumn columnType
             */
    
            /**
             * Constructs a new NamedColumn.
             * @memberof waterfront
             * @classdesc Represents a NamedColumn.
             * @implements INamedColumn
             * @constructor
             * @param {waterfront.INamedColumn=} [properties] Properties to set
             */
            function NamedColumn(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * NamedColumn name.
             * @member {string} name
             * @memberof waterfront.NamedColumn
             * @instance
             */
            NamedColumn.prototype.name = "";
    
            /**
             * NamedColumn columnType.
             * @member {column_type.IColumnType} columnType
             * @memberof waterfront.NamedColumn
             * @instance
             */
            NamedColumn.prototype.columnType = null;
    
            /**
             * Creates a new NamedColumn instance using the specified properties.
             * @function create
             * @memberof waterfront.NamedColumn
             * @static
             * @param {waterfront.INamedColumn=} [properties] Properties to set
             * @returns {waterfront.NamedColumn} NamedColumn instance
             */
            NamedColumn.create = function create(properties) {
                return new NamedColumn(properties);
            };
    
            /**
             * Encodes the specified NamedColumn message. Does not implicitly {@link waterfront.NamedColumn.verify|verify} messages.
             * @function encode
             * @memberof waterfront.NamedColumn
             * @static
             * @param {waterfront.INamedColumn} message NamedColumn message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NamedColumn.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                $root.column_type.ColumnType.encode(message.columnType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified NamedColumn message, length delimited. Does not implicitly {@link waterfront.NamedColumn.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.NamedColumn
             * @static
             * @param {waterfront.INamedColumn} message NamedColumn message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NamedColumn.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a NamedColumn message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.NamedColumn
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.NamedColumn} NamedColumn
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NamedColumn.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.NamedColumn();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.columnType = $root.column_type.ColumnType.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("name"))
                    throw $util.ProtocolError("missing required 'name'", { instance: message });
                if (!message.hasOwnProperty("columnType"))
                    throw $util.ProtocolError("missing required 'columnType'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a NamedColumn message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.NamedColumn
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.NamedColumn} NamedColumn
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NamedColumn.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a NamedColumn message.
             * @function verify
             * @memberof waterfront.NamedColumn
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NamedColumn.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.name))
                    return "name: string expected";
                {
                    var error = $root.column_type.ColumnType.verify(message.columnType);
                    if (error)
                        return "columnType." + error;
                }
                return null;
            };
    
            /**
             * Creates a NamedColumn message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.NamedColumn
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.NamedColumn} NamedColumn
             */
            NamedColumn.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.NamedColumn)
                    return object;
                var message = new $root.waterfront.NamedColumn();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.columnType != null) {
                    if (typeof object.columnType !== "object")
                        throw TypeError(".waterfront.NamedColumn.columnType: object expected");
                    message.columnType = $root.column_type.ColumnType.fromObject(object.columnType);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a NamedColumn message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.NamedColumn
             * @static
             * @param {waterfront.NamedColumn} message NamedColumn
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NamedColumn.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.columnType = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.columnType != null && message.hasOwnProperty("columnType"))
                    object.columnType = $root.column_type.ColumnType.toObject(message.columnType, options);
                return object;
            };
    
            /**
             * Converts this NamedColumn to JSON.
             * @function toJSON
             * @memberof waterfront.NamedColumn
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NamedColumn.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return NamedColumn;
        })();
    
        waterfront.DatasetManifest = (function() {
    
            /**
             * Properties of a DatasetManifest.
             * @memberof waterfront
             * @interface IDatasetManifest
             * @property {Uint8Array} digestHash DatasetManifest digestHash
             * @property {waterfront.ITableSchema} schema DatasetManifest schema
             */
    
            /**
             * Constructs a new DatasetManifest.
             * @memberof waterfront
             * @classdesc Represents a DatasetManifest.
             * @implements IDatasetManifest
             * @constructor
             * @param {waterfront.IDatasetManifest=} [properties] Properties to set
             */
            function DatasetManifest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DatasetManifest digestHash.
             * @member {Uint8Array} digestHash
             * @memberof waterfront.DatasetManifest
             * @instance
             */
            DatasetManifest.prototype.digestHash = $util.newBuffer([]);
    
            /**
             * DatasetManifest schema.
             * @member {waterfront.ITableSchema} schema
             * @memberof waterfront.DatasetManifest
             * @instance
             */
            DatasetManifest.prototype.schema = null;
    
            /**
             * Creates a new DatasetManifest instance using the specified properties.
             * @function create
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {waterfront.IDatasetManifest=} [properties] Properties to set
             * @returns {waterfront.DatasetManifest} DatasetManifest instance
             */
            DatasetManifest.create = function create(properties) {
                return new DatasetManifest(properties);
            };
    
            /**
             * Encodes the specified DatasetManifest message. Does not implicitly {@link waterfront.DatasetManifest.verify|verify} messages.
             * @function encode
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {waterfront.IDatasetManifest} message DatasetManifest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DatasetManifest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.digestHash);
                $root.waterfront.TableSchema.encode(message.schema, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified DatasetManifest message, length delimited. Does not implicitly {@link waterfront.DatasetManifest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {waterfront.IDatasetManifest} message DatasetManifest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DatasetManifest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DatasetManifest message from the specified reader or buffer.
             * @function decode
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waterfront.DatasetManifest} DatasetManifest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DatasetManifest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waterfront.DatasetManifest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.digestHash = reader.bytes();
                        break;
                    case 2:
                        message.schema = $root.waterfront.TableSchema.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("digestHash"))
                    throw $util.ProtocolError("missing required 'digestHash'", { instance: message });
                if (!message.hasOwnProperty("schema"))
                    throw $util.ProtocolError("missing required 'schema'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a DatasetManifest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waterfront.DatasetManifest} DatasetManifest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DatasetManifest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DatasetManifest message.
             * @function verify
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DatasetManifest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.digestHash && typeof message.digestHash.length === "number" || $util.isString(message.digestHash)))
                    return "digestHash: buffer expected";
                {
                    var error = $root.waterfront.TableSchema.verify(message.schema);
                    if (error)
                        return "schema." + error;
                }
                return null;
            };
    
            /**
             * Creates a DatasetManifest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waterfront.DatasetManifest} DatasetManifest
             */
            DatasetManifest.fromObject = function fromObject(object) {
                if (object instanceof $root.waterfront.DatasetManifest)
                    return object;
                var message = new $root.waterfront.DatasetManifest();
                if (object.digestHash != null)
                    if (typeof object.digestHash === "string")
                        $util.base64.decode(object.digestHash, message.digestHash = $util.newBuffer($util.base64.length(object.digestHash)), 0);
                    else if (object.digestHash.length)
                        message.digestHash = object.digestHash;
                if (object.schema != null) {
                    if (typeof object.schema !== "object")
                        throw TypeError(".waterfront.DatasetManifest.schema: object expected");
                    message.schema = $root.waterfront.TableSchema.fromObject(object.schema);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a DatasetManifest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waterfront.DatasetManifest
             * @static
             * @param {waterfront.DatasetManifest} message DatasetManifest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DatasetManifest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.digestHash = "";
                    else {
                        object.digestHash = [];
                        if (options.bytes !== Array)
                            object.digestHash = $util.newBuffer(object.digestHash);
                    }
                    object.schema = null;
                }
                if (message.digestHash != null && message.hasOwnProperty("digestHash"))
                    object.digestHash = options.bytes === String ? $util.base64.encode(message.digestHash, 0, message.digestHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.digestHash) : message.digestHash;
                if (message.schema != null && message.hasOwnProperty("schema"))
                    object.schema = $root.waterfront.TableSchema.toObject(message.schema, options);
                return object;
            };
    
            /**
             * Converts this DatasetManifest to JSON.
             * @function toJSON
             * @memberof waterfront.DatasetManifest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DatasetManifest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DatasetManifest;
        })();
    
        return waterfront;
    })();
    
    $root.column_type = (function() {
    
        /**
         * Namespace column_type.
         * @exports column_type
         * @namespace
         */
        var column_type = {};
    
        column_type.ColumnType = (function() {
    
            /**
             * Properties of a ColumnType.
             * @memberof column_type
             * @interface IColumnType
             * @property {column_type.PrimitiveType} primitiveType ColumnType primitiveType
             * @property {boolean} nullable ColumnType nullable
             */
    
            /**
             * Constructs a new ColumnType.
             * @memberof column_type
             * @classdesc Represents a ColumnType.
             * @implements IColumnType
             * @constructor
             * @param {column_type.IColumnType=} [properties] Properties to set
             */
            function ColumnType(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ColumnType primitiveType.
             * @member {column_type.PrimitiveType} primitiveType
             * @memberof column_type.ColumnType
             * @instance
             */
            ColumnType.prototype.primitiveType = 1;
    
            /**
             * ColumnType nullable.
             * @member {boolean} nullable
             * @memberof column_type.ColumnType
             * @instance
             */
            ColumnType.prototype.nullable = false;
    
            /**
             * Creates a new ColumnType instance using the specified properties.
             * @function create
             * @memberof column_type.ColumnType
             * @static
             * @param {column_type.IColumnType=} [properties] Properties to set
             * @returns {column_type.ColumnType} ColumnType instance
             */
            ColumnType.create = function create(properties) {
                return new ColumnType(properties);
            };
    
            /**
             * Encodes the specified ColumnType message. Does not implicitly {@link column_type.ColumnType.verify|verify} messages.
             * @function encode
             * @memberof column_type.ColumnType
             * @static
             * @param {column_type.IColumnType} message ColumnType message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColumnType.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.primitiveType);
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.nullable);
                return writer;
            };
    
            /**
             * Encodes the specified ColumnType message, length delimited. Does not implicitly {@link column_type.ColumnType.verify|verify} messages.
             * @function encodeDelimited
             * @memberof column_type.ColumnType
             * @static
             * @param {column_type.IColumnType} message ColumnType message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ColumnType.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ColumnType message from the specified reader or buffer.
             * @function decode
             * @memberof column_type.ColumnType
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {column_type.ColumnType} ColumnType
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColumnType.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.column_type.ColumnType();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.primitiveType = reader.int32();
                        break;
                    case 2:
                        message.nullable = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("primitiveType"))
                    throw $util.ProtocolError("missing required 'primitiveType'", { instance: message });
                if (!message.hasOwnProperty("nullable"))
                    throw $util.ProtocolError("missing required 'nullable'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a ColumnType message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof column_type.ColumnType
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {column_type.ColumnType} ColumnType
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ColumnType.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ColumnType message.
             * @function verify
             * @memberof column_type.ColumnType
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ColumnType.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                switch (message.primitiveType) {
                default:
                    return "primitiveType: enum value expected";
                case 1:
                case 2:
                case 3:
                    break;
                }
                if (typeof message.nullable !== "boolean")
                    return "nullable: boolean expected";
                return null;
            };
    
            /**
             * Creates a ColumnType message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof column_type.ColumnType
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {column_type.ColumnType} ColumnType
             */
            ColumnType.fromObject = function fromObject(object) {
                if (object instanceof $root.column_type.ColumnType)
                    return object;
                var message = new $root.column_type.ColumnType();
                switch (object.primitiveType) {
                case "INT64":
                case 1:
                    message.primitiveType = 1;
                    break;
                case "STRING":
                case 2:
                    message.primitiveType = 2;
                    break;
                case "FLOAT64":
                case 3:
                    message.primitiveType = 3;
                    break;
                }
                if (object.nullable != null)
                    message.nullable = Boolean(object.nullable);
                return message;
            };
    
            /**
             * Creates a plain object from a ColumnType message. Also converts values to other types if specified.
             * @function toObject
             * @memberof column_type.ColumnType
             * @static
             * @param {column_type.ColumnType} message ColumnType
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ColumnType.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.primitiveType = options.enums === String ? "INT64" : 1;
                    object.nullable = false;
                }
                if (message.primitiveType != null && message.hasOwnProperty("primitiveType"))
                    object.primitiveType = options.enums === String ? $root.column_type.PrimitiveType[message.primitiveType] : message.primitiveType;
                if (message.nullable != null && message.hasOwnProperty("nullable"))
                    object.nullable = message.nullable;
                return object;
            };
    
            /**
             * Converts this ColumnType to JSON.
             * @function toJSON
             * @memberof column_type.ColumnType
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ColumnType.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ColumnType;
        })();
    
        /**
         * PrimitiveType enum.
         * @name column_type.PrimitiveType
         * @enum {number}
         * @property {number} INT64=1 INT64 value
         * @property {number} STRING=2 STRING value
         * @property {number} FLOAT64=3 FLOAT64 value
         */
        column_type.PrimitiveType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[1] = "INT64"] = 1;
            values[valuesById[2] = "STRING"] = 2;
            values[valuesById[3] = "FLOAT64"] = 3;
            return values;
        })();
    
        return column_type;
    })();
    
    $root.data_room = (function() {
    
        /**
         * Namespace data_room.
         * @exports data_room
         * @namespace
         */
        var data_room = {};
    
        data_room.DataRoom = (function() {
    
            /**
             * Properties of a DataRoom.
             * @memberof data_room
             * @interface IDataRoom
             * @property {string} id DataRoom id
             * @property {Array.<data_room.ITable>|null} [tables] DataRoom tables
             * @property {Array.<data_room.IQuery>|null} [queries] DataRoom queries
             * @property {Array.<data_room.IRole>|null} [roles] DataRoom roles
             * @property {string|null} [mrenclave] DataRoom mrenclave
             */
    
            /**
             * Constructs a new DataRoom.
             * @memberof data_room
             * @classdesc Represents a DataRoom.
             * @implements IDataRoom
             * @constructor
             * @param {data_room.IDataRoom=} [properties] Properties to set
             */
            function DataRoom(properties) {
                this.tables = [];
                this.queries = [];
                this.roles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * DataRoom id.
             * @member {string} id
             * @memberof data_room.DataRoom
             * @instance
             */
            DataRoom.prototype.id = "";
    
            /**
             * DataRoom tables.
             * @member {Array.<data_room.ITable>} tables
             * @memberof data_room.DataRoom
             * @instance
             */
            DataRoom.prototype.tables = $util.emptyArray;
    
            /**
             * DataRoom queries.
             * @member {Array.<data_room.IQuery>} queries
             * @memberof data_room.DataRoom
             * @instance
             */
            DataRoom.prototype.queries = $util.emptyArray;
    
            /**
             * DataRoom roles.
             * @member {Array.<data_room.IRole>} roles
             * @memberof data_room.DataRoom
             * @instance
             */
            DataRoom.prototype.roles = $util.emptyArray;
    
            /**
             * DataRoom mrenclave.
             * @member {string} mrenclave
             * @memberof data_room.DataRoom
             * @instance
             */
            DataRoom.prototype.mrenclave = "";
    
            /**
             * Creates a new DataRoom instance using the specified properties.
             * @function create
             * @memberof data_room.DataRoom
             * @static
             * @param {data_room.IDataRoom=} [properties] Properties to set
             * @returns {data_room.DataRoom} DataRoom instance
             */
            DataRoom.create = function create(properties) {
                return new DataRoom(properties);
            };
    
            /**
             * Encodes the specified DataRoom message. Does not implicitly {@link data_room.DataRoom.verify|verify} messages.
             * @function encode
             * @memberof data_room.DataRoom
             * @static
             * @param {data_room.IDataRoom} message DataRoom message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataRoom.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.tables != null && message.tables.length)
                    for (var i = 0; i < message.tables.length; ++i)
                        $root.data_room.Table.encode(message.tables[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.queries != null && message.queries.length)
                    for (var i = 0; i < message.queries.length; ++i)
                        $root.data_room.Query.encode(message.queries[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.roles != null && message.roles.length)
                    for (var i = 0; i < message.roles.length; ++i)
                        $root.data_room.Role.encode(message.roles[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.mrenclave != null && Object.hasOwnProperty.call(message, "mrenclave"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.mrenclave);
                return writer;
            };
    
            /**
             * Encodes the specified DataRoom message, length delimited. Does not implicitly {@link data_room.DataRoom.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.DataRoom
             * @static
             * @param {data_room.IDataRoom} message DataRoom message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataRoom.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DataRoom message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.DataRoom
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.DataRoom} DataRoom
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataRoom.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.DataRoom();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        if (!(message.tables && message.tables.length))
                            message.tables = [];
                        message.tables.push($root.data_room.Table.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.queries && message.queries.length))
                            message.queries = [];
                        message.queries.push($root.data_room.Query.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        message.roles.push($root.data_room.Role.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        message.mrenclave = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("id"))
                    throw $util.ProtocolError("missing required 'id'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a DataRoom message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.DataRoom
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.DataRoom} DataRoom
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataRoom.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DataRoom message.
             * @function verify
             * @memberof data_room.DataRoom
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataRoom.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.id))
                    return "id: string expected";
                if (message.tables != null && message.hasOwnProperty("tables")) {
                    if (!Array.isArray(message.tables))
                        return "tables: array expected";
                    for (var i = 0; i < message.tables.length; ++i) {
                        var error = $root.data_room.Table.verify(message.tables[i]);
                        if (error)
                            return "tables." + error;
                    }
                }
                if (message.queries != null && message.hasOwnProperty("queries")) {
                    if (!Array.isArray(message.queries))
                        return "queries: array expected";
                    for (var i = 0; i < message.queries.length; ++i) {
                        var error = $root.data_room.Query.verify(message.queries[i]);
                        if (error)
                            return "queries." + error;
                    }
                }
                if (message.roles != null && message.hasOwnProperty("roles")) {
                    if (!Array.isArray(message.roles))
                        return "roles: array expected";
                    for (var i = 0; i < message.roles.length; ++i) {
                        var error = $root.data_room.Role.verify(message.roles[i]);
                        if (error)
                            return "roles." + error;
                    }
                }
                if (message.mrenclave != null && message.hasOwnProperty("mrenclave"))
                    if (!$util.isString(message.mrenclave))
                        return "mrenclave: string expected";
                return null;
            };
    
            /**
             * Creates a DataRoom message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.DataRoom
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.DataRoom} DataRoom
             */
            DataRoom.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.DataRoom)
                    return object;
                var message = new $root.data_room.DataRoom();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.tables) {
                    if (!Array.isArray(object.tables))
                        throw TypeError(".data_room.DataRoom.tables: array expected");
                    message.tables = [];
                    for (var i = 0; i < object.tables.length; ++i) {
                        if (typeof object.tables[i] !== "object")
                            throw TypeError(".data_room.DataRoom.tables: object expected");
                        message.tables[i] = $root.data_room.Table.fromObject(object.tables[i]);
                    }
                }
                if (object.queries) {
                    if (!Array.isArray(object.queries))
                        throw TypeError(".data_room.DataRoom.queries: array expected");
                    message.queries = [];
                    for (var i = 0; i < object.queries.length; ++i) {
                        if (typeof object.queries[i] !== "object")
                            throw TypeError(".data_room.DataRoom.queries: object expected");
                        message.queries[i] = $root.data_room.Query.fromObject(object.queries[i]);
                    }
                }
                if (object.roles) {
                    if (!Array.isArray(object.roles))
                        throw TypeError(".data_room.DataRoom.roles: array expected");
                    message.roles = [];
                    for (var i = 0; i < object.roles.length; ++i) {
                        if (typeof object.roles[i] !== "object")
                            throw TypeError(".data_room.DataRoom.roles: object expected");
                        message.roles[i] = $root.data_room.Role.fromObject(object.roles[i]);
                    }
                }
                if (object.mrenclave != null)
                    message.mrenclave = String(object.mrenclave);
                return message;
            };
    
            /**
             * Creates a plain object from a DataRoom message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.DataRoom
             * @static
             * @param {data_room.DataRoom} message DataRoom
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataRoom.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.tables = [];
                    object.queries = [];
                    object.roles = [];
                }
                if (options.defaults) {
                    object.id = "";
                    object.mrenclave = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.tables && message.tables.length) {
                    object.tables = [];
                    for (var j = 0; j < message.tables.length; ++j)
                        object.tables[j] = $root.data_room.Table.toObject(message.tables[j], options);
                }
                if (message.queries && message.queries.length) {
                    object.queries = [];
                    for (var j = 0; j < message.queries.length; ++j)
                        object.queries[j] = $root.data_room.Query.toObject(message.queries[j], options);
                }
                if (message.roles && message.roles.length) {
                    object.roles = [];
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] = $root.data_room.Role.toObject(message.roles[j], options);
                }
                if (message.mrenclave != null && message.hasOwnProperty("mrenclave"))
                    object.mrenclave = message.mrenclave;
                return object;
            };
    
            /**
             * Converts this DataRoom to JSON.
             * @function toJSON
             * @memberof data_room.DataRoom
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataRoom.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DataRoom;
        })();
    
        data_room.Table = (function() {
    
            /**
             * Properties of a Table.
             * @memberof data_room
             * @interface ITable
             * @property {string} sqlCreateTableStatement Table sqlCreateTableStatement
             * @property {Array.<data_room.ITimeConstraint>|null} [submissionConstraints] Table submissionConstraints
             */
    
            /**
             * Constructs a new Table.
             * @memberof data_room
             * @classdesc Represents a Table.
             * @implements ITable
             * @constructor
             * @param {data_room.ITable=} [properties] Properties to set
             */
            function Table(properties) {
                this.submissionConstraints = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Table sqlCreateTableStatement.
             * @member {string} sqlCreateTableStatement
             * @memberof data_room.Table
             * @instance
             */
            Table.prototype.sqlCreateTableStatement = "";
    
            /**
             * Table submissionConstraints.
             * @member {Array.<data_room.ITimeConstraint>} submissionConstraints
             * @memberof data_room.Table
             * @instance
             */
            Table.prototype.submissionConstraints = $util.emptyArray;
    
            /**
             * Creates a new Table instance using the specified properties.
             * @function create
             * @memberof data_room.Table
             * @static
             * @param {data_room.ITable=} [properties] Properties to set
             * @returns {data_room.Table} Table instance
             */
            Table.create = function create(properties) {
                return new Table(properties);
            };
    
            /**
             * Encodes the specified Table message. Does not implicitly {@link data_room.Table.verify|verify} messages.
             * @function encode
             * @memberof data_room.Table
             * @static
             * @param {data_room.ITable} message Table message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Table.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sqlCreateTableStatement);
                if (message.submissionConstraints != null && message.submissionConstraints.length)
                    for (var i = 0; i < message.submissionConstraints.length; ++i)
                        $root.data_room.TimeConstraint.encode(message.submissionConstraints[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Table message, length delimited. Does not implicitly {@link data_room.Table.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.Table
             * @static
             * @param {data_room.ITable} message Table message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Table.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Table message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.Table
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.Table} Table
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Table.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.Table();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.sqlCreateTableStatement = reader.string();
                        break;
                    case 2:
                        if (!(message.submissionConstraints && message.submissionConstraints.length))
                            message.submissionConstraints = [];
                        message.submissionConstraints.push($root.data_room.TimeConstraint.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("sqlCreateTableStatement"))
                    throw $util.ProtocolError("missing required 'sqlCreateTableStatement'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a Table message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.Table
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.Table} Table
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Table.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Table message.
             * @function verify
             * @memberof data_room.Table
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Table.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.sqlCreateTableStatement))
                    return "sqlCreateTableStatement: string expected";
                if (message.submissionConstraints != null && message.hasOwnProperty("submissionConstraints")) {
                    if (!Array.isArray(message.submissionConstraints))
                        return "submissionConstraints: array expected";
                    for (var i = 0; i < message.submissionConstraints.length; ++i) {
                        var error = $root.data_room.TimeConstraint.verify(message.submissionConstraints[i]);
                        if (error)
                            return "submissionConstraints." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Table message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.Table
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.Table} Table
             */
            Table.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.Table)
                    return object;
                var message = new $root.data_room.Table();
                if (object.sqlCreateTableStatement != null)
                    message.sqlCreateTableStatement = String(object.sqlCreateTableStatement);
                if (object.submissionConstraints) {
                    if (!Array.isArray(object.submissionConstraints))
                        throw TypeError(".data_room.Table.submissionConstraints: array expected");
                    message.submissionConstraints = [];
                    for (var i = 0; i < object.submissionConstraints.length; ++i) {
                        if (typeof object.submissionConstraints[i] !== "object")
                            throw TypeError(".data_room.Table.submissionConstraints: object expected");
                        message.submissionConstraints[i] = $root.data_room.TimeConstraint.fromObject(object.submissionConstraints[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Table message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.Table
             * @static
             * @param {data_room.Table} message Table
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Table.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.submissionConstraints = [];
                if (options.defaults)
                    object.sqlCreateTableStatement = "";
                if (message.sqlCreateTableStatement != null && message.hasOwnProperty("sqlCreateTableStatement"))
                    object.sqlCreateTableStatement = message.sqlCreateTableStatement;
                if (message.submissionConstraints && message.submissionConstraints.length) {
                    object.submissionConstraints = [];
                    for (var j = 0; j < message.submissionConstraints.length; ++j)
                        object.submissionConstraints[j] = $root.data_room.TimeConstraint.toObject(message.submissionConstraints[j], options);
                }
                return object;
            };
    
            /**
             * Converts this Table to JSON.
             * @function toJSON
             * @memberof data_room.Table
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Table.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Table;
        })();
    
        data_room.Query = (function() {
    
            /**
             * Properties of a Query.
             * @memberof data_room
             * @interface IQuery
             * @property {string} queryName Query queryName
             * @property {string} sqlSelectStatement Query sqlSelectStatement
             * @property {Array.<data_room.IConstraint>|null} [constraints] Query constraints
             */
    
            /**
             * Constructs a new Query.
             * @memberof data_room
             * @classdesc Represents a Query.
             * @implements IQuery
             * @constructor
             * @param {data_room.IQuery=} [properties] Properties to set
             */
            function Query(properties) {
                this.constraints = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Query queryName.
             * @member {string} queryName
             * @memberof data_room.Query
             * @instance
             */
            Query.prototype.queryName = "";
    
            /**
             * Query sqlSelectStatement.
             * @member {string} sqlSelectStatement
             * @memberof data_room.Query
             * @instance
             */
            Query.prototype.sqlSelectStatement = "";
    
            /**
             * Query constraints.
             * @member {Array.<data_room.IConstraint>} constraints
             * @memberof data_room.Query
             * @instance
             */
            Query.prototype.constraints = $util.emptyArray;
    
            /**
             * Creates a new Query instance using the specified properties.
             * @function create
             * @memberof data_room.Query
             * @static
             * @param {data_room.IQuery=} [properties] Properties to set
             * @returns {data_room.Query} Query instance
             */
            Query.create = function create(properties) {
                return new Query(properties);
            };
    
            /**
             * Encodes the specified Query message. Does not implicitly {@link data_room.Query.verify|verify} messages.
             * @function encode
             * @memberof data_room.Query
             * @static
             * @param {data_room.IQuery} message Query message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Query.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.queryName);
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sqlSelectStatement);
                if (message.constraints != null && message.constraints.length)
                    for (var i = 0; i < message.constraints.length; ++i)
                        $root.data_room.Constraint.encode(message.constraints[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Query message, length delimited. Does not implicitly {@link data_room.Query.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.Query
             * @static
             * @param {data_room.IQuery} message Query message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Query.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Query message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.Query
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.Query} Query
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Query.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.Query();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.queryName = reader.string();
                        break;
                    case 2:
                        message.sqlSelectStatement = reader.string();
                        break;
                    case 3:
                        if (!(message.constraints && message.constraints.length))
                            message.constraints = [];
                        message.constraints.push($root.data_room.Constraint.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("queryName"))
                    throw $util.ProtocolError("missing required 'queryName'", { instance: message });
                if (!message.hasOwnProperty("sqlSelectStatement"))
                    throw $util.ProtocolError("missing required 'sqlSelectStatement'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a Query message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.Query
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.Query} Query
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Query.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Query message.
             * @function verify
             * @memberof data_room.Query
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Query.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.queryName))
                    return "queryName: string expected";
                if (!$util.isString(message.sqlSelectStatement))
                    return "sqlSelectStatement: string expected";
                if (message.constraints != null && message.hasOwnProperty("constraints")) {
                    if (!Array.isArray(message.constraints))
                        return "constraints: array expected";
                    for (var i = 0; i < message.constraints.length; ++i) {
                        var error = $root.data_room.Constraint.verify(message.constraints[i]);
                        if (error)
                            return "constraints." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Query message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.Query
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.Query} Query
             */
            Query.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.Query)
                    return object;
                var message = new $root.data_room.Query();
                if (object.queryName != null)
                    message.queryName = String(object.queryName);
                if (object.sqlSelectStatement != null)
                    message.sqlSelectStatement = String(object.sqlSelectStatement);
                if (object.constraints) {
                    if (!Array.isArray(object.constraints))
                        throw TypeError(".data_room.Query.constraints: array expected");
                    message.constraints = [];
                    for (var i = 0; i < object.constraints.length; ++i) {
                        if (typeof object.constraints[i] !== "object")
                            throw TypeError(".data_room.Query.constraints: object expected");
                        message.constraints[i] = $root.data_room.Constraint.fromObject(object.constraints[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Query message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.Query
             * @static
             * @param {data_room.Query} message Query
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Query.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.constraints = [];
                if (options.defaults) {
                    object.queryName = "";
                    object.sqlSelectStatement = "";
                }
                if (message.queryName != null && message.hasOwnProperty("queryName"))
                    object.queryName = message.queryName;
                if (message.sqlSelectStatement != null && message.hasOwnProperty("sqlSelectStatement"))
                    object.sqlSelectStatement = message.sqlSelectStatement;
                if (message.constraints && message.constraints.length) {
                    object.constraints = [];
                    for (var j = 0; j < message.constraints.length; ++j)
                        object.constraints[j] = $root.data_room.Constraint.toObject(message.constraints[j], options);
                }
                return object;
            };
    
            /**
             * Converts this Query to JSON.
             * @function toJSON
             * @memberof data_room.Query
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Query.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Query;
        })();
    
        data_room.Role = (function() {
    
            /**
             * Properties of a Role.
             * @memberof data_room
             * @interface IRole
             * @property {string} roleName Role roleName
             * @property {string} emailRegex Role emailRegex
             * @property {data_room.IAuthenticationMethod} authenticationMethod Role authenticationMethod
             * @property {Array.<data_room.IPermission>|null} [permissions] Role permissions
             */
    
            /**
             * Constructs a new Role.
             * @memberof data_room
             * @classdesc Represents a Role.
             * @implements IRole
             * @constructor
             * @param {data_room.IRole=} [properties] Properties to set
             */
            function Role(properties) {
                this.permissions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Role roleName.
             * @member {string} roleName
             * @memberof data_room.Role
             * @instance
             */
            Role.prototype.roleName = "";
    
            /**
             * Role emailRegex.
             * @member {string} emailRegex
             * @memberof data_room.Role
             * @instance
             */
            Role.prototype.emailRegex = "";
    
            /**
             * Role authenticationMethod.
             * @member {data_room.IAuthenticationMethod} authenticationMethod
             * @memberof data_room.Role
             * @instance
             */
            Role.prototype.authenticationMethod = null;
    
            /**
             * Role permissions.
             * @member {Array.<data_room.IPermission>} permissions
             * @memberof data_room.Role
             * @instance
             */
            Role.prototype.permissions = $util.emptyArray;
    
            /**
             * Creates a new Role instance using the specified properties.
             * @function create
             * @memberof data_room.Role
             * @static
             * @param {data_room.IRole=} [properties] Properties to set
             * @returns {data_room.Role} Role instance
             */
            Role.create = function create(properties) {
                return new Role(properties);
            };
    
            /**
             * Encodes the specified Role message. Does not implicitly {@link data_room.Role.verify|verify} messages.
             * @function encode
             * @memberof data_room.Role
             * @static
             * @param {data_room.IRole} message Role message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Role.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleName);
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailRegex);
                $root.data_room.AuthenticationMethod.encode(message.authenticationMethod, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.permissions != null && message.permissions.length)
                    for (var i = 0; i < message.permissions.length; ++i)
                        $root.data_room.Permission.encode(message.permissions[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Role message, length delimited. Does not implicitly {@link data_room.Role.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.Role
             * @static
             * @param {data_room.IRole} message Role message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Role.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Role message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.Role
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.Role} Role
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Role.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.Role();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.roleName = reader.string();
                        break;
                    case 2:
                        message.emailRegex = reader.string();
                        break;
                    case 3:
                        message.authenticationMethod = $root.data_room.AuthenticationMethod.decode(reader, reader.uint32());
                        break;
                    case 4:
                        if (!(message.permissions && message.permissions.length))
                            message.permissions = [];
                        message.permissions.push($root.data_room.Permission.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("roleName"))
                    throw $util.ProtocolError("missing required 'roleName'", { instance: message });
                if (!message.hasOwnProperty("emailRegex"))
                    throw $util.ProtocolError("missing required 'emailRegex'", { instance: message });
                if (!message.hasOwnProperty("authenticationMethod"))
                    throw $util.ProtocolError("missing required 'authenticationMethod'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a Role message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.Role
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.Role} Role
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Role.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Role message.
             * @function verify
             * @memberof data_room.Role
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Role.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.roleName))
                    return "roleName: string expected";
                if (!$util.isString(message.emailRegex))
                    return "emailRegex: string expected";
                {
                    var error = $root.data_room.AuthenticationMethod.verify(message.authenticationMethod);
                    if (error)
                        return "authenticationMethod." + error;
                }
                if (message.permissions != null && message.hasOwnProperty("permissions")) {
                    if (!Array.isArray(message.permissions))
                        return "permissions: array expected";
                    for (var i = 0; i < message.permissions.length; ++i) {
                        var error = $root.data_room.Permission.verify(message.permissions[i]);
                        if (error)
                            return "permissions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Role message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.Role
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.Role} Role
             */
            Role.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.Role)
                    return object;
                var message = new $root.data_room.Role();
                if (object.roleName != null)
                    message.roleName = String(object.roleName);
                if (object.emailRegex != null)
                    message.emailRegex = String(object.emailRegex);
                if (object.authenticationMethod != null) {
                    if (typeof object.authenticationMethod !== "object")
                        throw TypeError(".data_room.Role.authenticationMethod: object expected");
                    message.authenticationMethod = $root.data_room.AuthenticationMethod.fromObject(object.authenticationMethod);
                }
                if (object.permissions) {
                    if (!Array.isArray(object.permissions))
                        throw TypeError(".data_room.Role.permissions: array expected");
                    message.permissions = [];
                    for (var i = 0; i < object.permissions.length; ++i) {
                        if (typeof object.permissions[i] !== "object")
                            throw TypeError(".data_room.Role.permissions: object expected");
                        message.permissions[i] = $root.data_room.Permission.fromObject(object.permissions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Role message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.Role
             * @static
             * @param {data_room.Role} message Role
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Role.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.permissions = [];
                if (options.defaults) {
                    object.roleName = "";
                    object.emailRegex = "";
                    object.authenticationMethod = null;
                }
                if (message.roleName != null && message.hasOwnProperty("roleName"))
                    object.roleName = message.roleName;
                if (message.emailRegex != null && message.hasOwnProperty("emailRegex"))
                    object.emailRegex = message.emailRegex;
                if (message.authenticationMethod != null && message.hasOwnProperty("authenticationMethod"))
                    object.authenticationMethod = $root.data_room.AuthenticationMethod.toObject(message.authenticationMethod, options);
                if (message.permissions && message.permissions.length) {
                    object.permissions = [];
                    for (var j = 0; j < message.permissions.length; ++j)
                        object.permissions[j] = $root.data_room.Permission.toObject(message.permissions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this Role to JSON.
             * @function toJSON
             * @memberof data_room.Role
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Role.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Role;
        })();
    
        data_room.AuthenticationMethod = (function() {
    
            /**
             * Properties of an AuthenticationMethod.
             * @memberof data_room
             * @interface IAuthenticationMethod
             * @property {data_room.ITrustedPki} trustedPki AuthenticationMethod trustedPki
             * @property {data_room.IMailPki|null} [mailPki] AuthenticationMethod mailPki
             * @property {string|null} [passwordSha256] AuthenticationMethod passwordSha256
             */
    
            /**
             * Constructs a new AuthenticationMethod.
             * @memberof data_room
             * @classdesc Represents an AuthenticationMethod.
             * @implements IAuthenticationMethod
             * @constructor
             * @param {data_room.IAuthenticationMethod=} [properties] Properties to set
             */
            function AuthenticationMethod(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AuthenticationMethod trustedPki.
             * @member {data_room.ITrustedPki} trustedPki
             * @memberof data_room.AuthenticationMethod
             * @instance
             */
            AuthenticationMethod.prototype.trustedPki = null;
    
            /**
             * AuthenticationMethod mailPki.
             * @member {data_room.IMailPki|null|undefined} mailPki
             * @memberof data_room.AuthenticationMethod
             * @instance
             */
            AuthenticationMethod.prototype.mailPki = null;
    
            /**
             * AuthenticationMethod passwordSha256.
             * @member {string} passwordSha256
             * @memberof data_room.AuthenticationMethod
             * @instance
             */
            AuthenticationMethod.prototype.passwordSha256 = "";
    
            /**
             * Creates a new AuthenticationMethod instance using the specified properties.
             * @function create
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {data_room.IAuthenticationMethod=} [properties] Properties to set
             * @returns {data_room.AuthenticationMethod} AuthenticationMethod instance
             */
            AuthenticationMethod.create = function create(properties) {
                return new AuthenticationMethod(properties);
            };
    
            /**
             * Encodes the specified AuthenticationMethod message. Does not implicitly {@link data_room.AuthenticationMethod.verify|verify} messages.
             * @function encode
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {data_room.IAuthenticationMethod} message AuthenticationMethod message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthenticationMethod.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                $root.data_room.TrustedPki.encode(message.trustedPki, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.mailPki != null && Object.hasOwnProperty.call(message, "mailPki"))
                    $root.data_room.MailPki.encode(message.mailPki, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.passwordSha256 != null && Object.hasOwnProperty.call(message, "passwordSha256"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.passwordSha256);
                return writer;
            };
    
            /**
             * Encodes the specified AuthenticationMethod message, length delimited. Does not implicitly {@link data_room.AuthenticationMethod.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {data_room.IAuthenticationMethod} message AuthenticationMethod message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthenticationMethod.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuthenticationMethod message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.AuthenticationMethod} AuthenticationMethod
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthenticationMethod.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.AuthenticationMethod();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.trustedPki = $root.data_room.TrustedPki.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.mailPki = $root.data_room.MailPki.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.passwordSha256 = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("trustedPki"))
                    throw $util.ProtocolError("missing required 'trustedPki'", { instance: message });
                return message;
            };
    
            /**
             * Decodes an AuthenticationMethod message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.AuthenticationMethod} AuthenticationMethod
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthenticationMethod.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuthenticationMethod message.
             * @function verify
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthenticationMethod.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                {
                    var error = $root.data_room.TrustedPki.verify(message.trustedPki);
                    if (error)
                        return "trustedPki." + error;
                }
                if (message.mailPki != null && message.hasOwnProperty("mailPki")) {
                    var error = $root.data_room.MailPki.verify(message.mailPki);
                    if (error)
                        return "mailPki." + error;
                }
                if (message.passwordSha256 != null && message.hasOwnProperty("passwordSha256"))
                    if (!$util.isString(message.passwordSha256))
                        return "passwordSha256: string expected";
                return null;
            };
    
            /**
             * Creates an AuthenticationMethod message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.AuthenticationMethod} AuthenticationMethod
             */
            AuthenticationMethod.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.AuthenticationMethod)
                    return object;
                var message = new $root.data_room.AuthenticationMethod();
                if (object.trustedPki != null) {
                    if (typeof object.trustedPki !== "object")
                        throw TypeError(".data_room.AuthenticationMethod.trustedPki: object expected");
                    message.trustedPki = $root.data_room.TrustedPki.fromObject(object.trustedPki);
                }
                if (object.mailPki != null) {
                    if (typeof object.mailPki !== "object")
                        throw TypeError(".data_room.AuthenticationMethod.mailPki: object expected");
                    message.mailPki = $root.data_room.MailPki.fromObject(object.mailPki);
                }
                if (object.passwordSha256 != null)
                    message.passwordSha256 = String(object.passwordSha256);
                return message;
            };
    
            /**
             * Creates a plain object from an AuthenticationMethod message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.AuthenticationMethod
             * @static
             * @param {data_room.AuthenticationMethod} message AuthenticationMethod
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthenticationMethod.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.trustedPki = null;
                    object.mailPki = null;
                    object.passwordSha256 = "";
                }
                if (message.trustedPki != null && message.hasOwnProperty("trustedPki"))
                    object.trustedPki = $root.data_room.TrustedPki.toObject(message.trustedPki, options);
                if (message.mailPki != null && message.hasOwnProperty("mailPki"))
                    object.mailPki = $root.data_room.MailPki.toObject(message.mailPki, options);
                if (message.passwordSha256 != null && message.hasOwnProperty("passwordSha256"))
                    object.passwordSha256 = message.passwordSha256;
                return object;
            };
    
            /**
             * Converts this AuthenticationMethod to JSON.
             * @function toJSON
             * @memberof data_room.AuthenticationMethod
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthenticationMethod.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AuthenticationMethod;
        })();
    
        data_room.TrustedPki = (function() {
    
            /**
             * Properties of a TrustedPki.
             * @memberof data_room
             * @interface ITrustedPki
             * @property {Uint8Array} rootCertificate TrustedPki rootCertificate
             */
    
            /**
             * Constructs a new TrustedPki.
             * @memberof data_room
             * @classdesc Represents a TrustedPki.
             * @implements ITrustedPki
             * @constructor
             * @param {data_room.ITrustedPki=} [properties] Properties to set
             */
            function TrustedPki(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TrustedPki rootCertificate.
             * @member {Uint8Array} rootCertificate
             * @memberof data_room.TrustedPki
             * @instance
             */
            TrustedPki.prototype.rootCertificate = $util.newBuffer([]);
    
            /**
             * Creates a new TrustedPki instance using the specified properties.
             * @function create
             * @memberof data_room.TrustedPki
             * @static
             * @param {data_room.ITrustedPki=} [properties] Properties to set
             * @returns {data_room.TrustedPki} TrustedPki instance
             */
            TrustedPki.create = function create(properties) {
                return new TrustedPki(properties);
            };
    
            /**
             * Encodes the specified TrustedPki message. Does not implicitly {@link data_room.TrustedPki.verify|verify} messages.
             * @function encode
             * @memberof data_room.TrustedPki
             * @static
             * @param {data_room.ITrustedPki} message TrustedPki message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TrustedPki.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rootCertificate);
                return writer;
            };
    
            /**
             * Encodes the specified TrustedPki message, length delimited. Does not implicitly {@link data_room.TrustedPki.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.TrustedPki
             * @static
             * @param {data_room.ITrustedPki} message TrustedPki message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TrustedPki.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TrustedPki message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.TrustedPki
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.TrustedPki} TrustedPki
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TrustedPki.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.TrustedPki();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.rootCertificate = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("rootCertificate"))
                    throw $util.ProtocolError("missing required 'rootCertificate'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a TrustedPki message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.TrustedPki
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.TrustedPki} TrustedPki
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TrustedPki.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TrustedPki message.
             * @function verify
             * @memberof data_room.TrustedPki
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TrustedPki.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.rootCertificate && typeof message.rootCertificate.length === "number" || $util.isString(message.rootCertificate)))
                    return "rootCertificate: buffer expected";
                return null;
            };
    
            /**
             * Creates a TrustedPki message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.TrustedPki
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.TrustedPki} TrustedPki
             */
            TrustedPki.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.TrustedPki)
                    return object;
                var message = new $root.data_room.TrustedPki();
                if (object.rootCertificate != null)
                    if (typeof object.rootCertificate === "string")
                        $util.base64.decode(object.rootCertificate, message.rootCertificate = $util.newBuffer($util.base64.length(object.rootCertificate)), 0);
                    else if (object.rootCertificate.length)
                        message.rootCertificate = object.rootCertificate;
                return message;
            };
    
            /**
             * Creates a plain object from a TrustedPki message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.TrustedPki
             * @static
             * @param {data_room.TrustedPki} message TrustedPki
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TrustedPki.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.rootCertificate = "";
                    else {
                        object.rootCertificate = [];
                        if (options.bytes !== Array)
                            object.rootCertificate = $util.newBuffer(object.rootCertificate);
                    }
                if (message.rootCertificate != null && message.hasOwnProperty("rootCertificate"))
                    object.rootCertificate = options.bytes === String ? $util.base64.encode(message.rootCertificate, 0, message.rootCertificate.length) : options.bytes === Array ? Array.prototype.slice.call(message.rootCertificate) : message.rootCertificate;
                return object;
            };
    
            /**
             * Converts this TrustedPki to JSON.
             * @function toJSON
             * @memberof data_room.TrustedPki
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TrustedPki.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TrustedPki;
        })();
    
        data_room.MailPki = (function() {
    
            /**
             * Properties of a MailPki.
             * @memberof data_room
             * @interface IMailPki
             * @property {Uint8Array} rootCaStore MailPki rootCaStore
             */
    
            /**
             * Constructs a new MailPki.
             * @memberof data_room
             * @classdesc Represents a MailPki.
             * @implements IMailPki
             * @constructor
             * @param {data_room.IMailPki=} [properties] Properties to set
             */
            function MailPki(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * MailPki rootCaStore.
             * @member {Uint8Array} rootCaStore
             * @memberof data_room.MailPki
             * @instance
             */
            MailPki.prototype.rootCaStore = $util.newBuffer([]);
    
            /**
             * Creates a new MailPki instance using the specified properties.
             * @function create
             * @memberof data_room.MailPki
             * @static
             * @param {data_room.IMailPki=} [properties] Properties to set
             * @returns {data_room.MailPki} MailPki instance
             */
            MailPki.create = function create(properties) {
                return new MailPki(properties);
            };
    
            /**
             * Encodes the specified MailPki message. Does not implicitly {@link data_room.MailPki.verify|verify} messages.
             * @function encode
             * @memberof data_room.MailPki
             * @static
             * @param {data_room.IMailPki} message MailPki message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MailPki.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rootCaStore);
                return writer;
            };
    
            /**
             * Encodes the specified MailPki message, length delimited. Does not implicitly {@link data_room.MailPki.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.MailPki
             * @static
             * @param {data_room.IMailPki} message MailPki message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MailPki.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a MailPki message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.MailPki
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.MailPki} MailPki
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MailPki.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.MailPki();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.rootCaStore = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("rootCaStore"))
                    throw $util.ProtocolError("missing required 'rootCaStore'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a MailPki message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.MailPki
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.MailPki} MailPki
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MailPki.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a MailPki message.
             * @function verify
             * @memberof data_room.MailPki
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MailPki.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!(message.rootCaStore && typeof message.rootCaStore.length === "number" || $util.isString(message.rootCaStore)))
                    return "rootCaStore: buffer expected";
                return null;
            };
    
            /**
             * Creates a MailPki message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.MailPki
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.MailPki} MailPki
             */
            MailPki.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.MailPki)
                    return object;
                var message = new $root.data_room.MailPki();
                if (object.rootCaStore != null)
                    if (typeof object.rootCaStore === "string")
                        $util.base64.decode(object.rootCaStore, message.rootCaStore = $util.newBuffer($util.base64.length(object.rootCaStore)), 0);
                    else if (object.rootCaStore.length)
                        message.rootCaStore = object.rootCaStore;
                return message;
            };
    
            /**
             * Creates a plain object from a MailPki message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.MailPki
             * @static
             * @param {data_room.MailPki} message MailPki
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MailPki.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.rootCaStore = "";
                    else {
                        object.rootCaStore = [];
                        if (options.bytes !== Array)
                            object.rootCaStore = $util.newBuffer(object.rootCaStore);
                    }
                if (message.rootCaStore != null && message.hasOwnProperty("rootCaStore"))
                    object.rootCaStore = options.bytes === String ? $util.base64.encode(message.rootCaStore, 0, message.rootCaStore.length) : options.bytes === Array ? Array.prototype.slice.call(message.rootCaStore) : message.rootCaStore;
                return object;
            };
    
            /**
             * Converts this MailPki to JSON.
             * @function toJSON
             * @memberof data_room.MailPki
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MailPki.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return MailPki;
        })();
    
        data_room.Permission = (function() {
    
            /**
             * Properties of a Permission.
             * @memberof data_room
             * @interface IPermission
             * @property {data_room.ISubmitQueryPermission|null} [submitQueryPermission] Permission submitQueryPermission
             * @property {data_room.ITableCrudPermission|null} [tableCrudPermission] Permission tableCrudPermission
             * @property {data_room.IDataroomRetrievalPermission|null} [dataRoomRetrievalPermission] Permission dataRoomRetrievalPermission
             * @property {data_room.IAuditLogRetrievalPermission|null} [auditLogRetrievalPermission] Permission auditLogRetrievalPermission
             * @property {data_room.IRetrieveDataRoomStatusPermission|null} [retrieveDataRoomStatusPermission] Permission retrieveDataRoomStatusPermission
             * @property {data_room.IUpdateDataRoomStatusPermission|null} [updateDataRoomStatusPermission] Permission updateDataRoomStatusPermission
             * @property {data_room.IRetrievePublishedDatasetsPermission|null} [retrievePublishedDatasetsPermission] Permission retrievePublishedDatasetsPermission
             */
    
            /**
             * Constructs a new Permission.
             * @memberof data_room
             * @classdesc Represents a Permission.
             * @implements IPermission
             * @constructor
             * @param {data_room.IPermission=} [properties] Properties to set
             */
            function Permission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Permission submitQueryPermission.
             * @member {data_room.ISubmitQueryPermission|null|undefined} submitQueryPermission
             * @memberof data_room.Permission
             * @instance
             */
            Permission.prototype.submitQueryPermission = null;
    
            /**
             * Permission tableCrudPermission.
             * @member {data_room.ITableCrudPermission|null|undefined} tableCrudPermission
             * @memberof data_room.Permission
             * @instance
             */
            Permission.prototype.tableCrudPermission = null;
    
            /**
             * Permission dataRoomRetrievalPermission.
             * @member {data_room.IDataroomRetrievalPermission|null|undefined} dataRoomRetrievalPermission
             * @memberof data_room.Permission
             * @instance
             */
            Permission.prototype.dataRoomRetrievalPermission = null;
    
            /**
             * Permission auditLogRetrievalPermission.
             * @member {data_room.IAuditLogRetrievalPermission|null|undefined} auditLogRetrievalPermission
             * @memberof data_room.Permission
             * @instance
             */
            Permission.prototype.auditLogRetrievalPermission = null;
    
            /**
             * Permission retrieveDataRoomStatusPermission.
             * @member {data_room.IRetrieveDataRoomStatusPermission|null|undefined} retrieveDataRoomStatusPermission
             * @memberof data_room.Permission
             * @instance
             */
            Permission.prototype.retrieveDataRoomStatusPermission = null;
    
            /**
             * Permission updateDataRoomStatusPermission.
             * @member {data_room.IUpdateDataRoomStatusPermission|null|undefined} updateDataRoomStatusPermission
             * @memberof data_room.Permission
             * @instance
             */
            Permission.prototype.updateDataRoomStatusPermission = null;
    
            /**
             * Permission retrievePublishedDatasetsPermission.
             * @member {data_room.IRetrievePublishedDatasetsPermission|null|undefined} retrievePublishedDatasetsPermission
             * @memberof data_room.Permission
             * @instance
             */
            Permission.prototype.retrievePublishedDatasetsPermission = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Permission permission.
             * @member {"submitQueryPermission"|"tableCrudPermission"|"dataRoomRetrievalPermission"|"auditLogRetrievalPermission"|"retrieveDataRoomStatusPermission"|"updateDataRoomStatusPermission"|"retrievePublishedDatasetsPermission"|undefined} permission
             * @memberof data_room.Permission
             * @instance
             */
            Object.defineProperty(Permission.prototype, "permission", {
                get: $util.oneOfGetter($oneOfFields = ["submitQueryPermission", "tableCrudPermission", "dataRoomRetrievalPermission", "auditLogRetrievalPermission", "retrieveDataRoomStatusPermission", "updateDataRoomStatusPermission", "retrievePublishedDatasetsPermission"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Permission instance using the specified properties.
             * @function create
             * @memberof data_room.Permission
             * @static
             * @param {data_room.IPermission=} [properties] Properties to set
             * @returns {data_room.Permission} Permission instance
             */
            Permission.create = function create(properties) {
                return new Permission(properties);
            };
    
            /**
             * Encodes the specified Permission message. Does not implicitly {@link data_room.Permission.verify|verify} messages.
             * @function encode
             * @memberof data_room.Permission
             * @static
             * @param {data_room.IPermission} message Permission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Permission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.submitQueryPermission != null && Object.hasOwnProperty.call(message, "submitQueryPermission"))
                    $root.data_room.SubmitQueryPermission.encode(message.submitQueryPermission, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.tableCrudPermission != null && Object.hasOwnProperty.call(message, "tableCrudPermission"))
                    $root.data_room.TableCrudPermission.encode(message.tableCrudPermission, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.dataRoomRetrievalPermission != null && Object.hasOwnProperty.call(message, "dataRoomRetrievalPermission"))
                    $root.data_room.DataroomRetrievalPermission.encode(message.dataRoomRetrievalPermission, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.auditLogRetrievalPermission != null && Object.hasOwnProperty.call(message, "auditLogRetrievalPermission"))
                    $root.data_room.AuditLogRetrievalPermission.encode(message.auditLogRetrievalPermission, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.retrieveDataRoomStatusPermission != null && Object.hasOwnProperty.call(message, "retrieveDataRoomStatusPermission"))
                    $root.data_room.RetrieveDataRoomStatusPermission.encode(message.retrieveDataRoomStatusPermission, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.updateDataRoomStatusPermission != null && Object.hasOwnProperty.call(message, "updateDataRoomStatusPermission"))
                    $root.data_room.UpdateDataRoomStatusPermission.encode(message.updateDataRoomStatusPermission, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.retrievePublishedDatasetsPermission != null && Object.hasOwnProperty.call(message, "retrievePublishedDatasetsPermission"))
                    $root.data_room.RetrievePublishedDatasetsPermission.encode(message.retrievePublishedDatasetsPermission, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Permission message, length delimited. Does not implicitly {@link data_room.Permission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.Permission
             * @static
             * @param {data_room.IPermission} message Permission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Permission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Permission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.Permission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.Permission} Permission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Permission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.Permission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.submitQueryPermission = $root.data_room.SubmitQueryPermission.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.tableCrudPermission = $root.data_room.TableCrudPermission.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.dataRoomRetrievalPermission = $root.data_room.DataroomRetrievalPermission.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.auditLogRetrievalPermission = $root.data_room.AuditLogRetrievalPermission.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.retrieveDataRoomStatusPermission = $root.data_room.RetrieveDataRoomStatusPermission.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.updateDataRoomStatusPermission = $root.data_room.UpdateDataRoomStatusPermission.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.retrievePublishedDatasetsPermission = $root.data_room.RetrievePublishedDatasetsPermission.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Permission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.Permission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.Permission} Permission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Permission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Permission message.
             * @function verify
             * @memberof data_room.Permission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Permission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.submitQueryPermission != null && message.hasOwnProperty("submitQueryPermission")) {
                    properties.permission = 1;
                    {
                        var error = $root.data_room.SubmitQueryPermission.verify(message.submitQueryPermission);
                        if (error)
                            return "submitQueryPermission." + error;
                    }
                }
                if (message.tableCrudPermission != null && message.hasOwnProperty("tableCrudPermission")) {
                    if (properties.permission === 1)
                        return "permission: multiple values";
                    properties.permission = 1;
                    {
                        var error = $root.data_room.TableCrudPermission.verify(message.tableCrudPermission);
                        if (error)
                            return "tableCrudPermission." + error;
                    }
                }
                if (message.dataRoomRetrievalPermission != null && message.hasOwnProperty("dataRoomRetrievalPermission")) {
                    if (properties.permission === 1)
                        return "permission: multiple values";
                    properties.permission = 1;
                    {
                        var error = $root.data_room.DataroomRetrievalPermission.verify(message.dataRoomRetrievalPermission);
                        if (error)
                            return "dataRoomRetrievalPermission." + error;
                    }
                }
                if (message.auditLogRetrievalPermission != null && message.hasOwnProperty("auditLogRetrievalPermission")) {
                    if (properties.permission === 1)
                        return "permission: multiple values";
                    properties.permission = 1;
                    {
                        var error = $root.data_room.AuditLogRetrievalPermission.verify(message.auditLogRetrievalPermission);
                        if (error)
                            return "auditLogRetrievalPermission." + error;
                    }
                }
                if (message.retrieveDataRoomStatusPermission != null && message.hasOwnProperty("retrieveDataRoomStatusPermission")) {
                    if (properties.permission === 1)
                        return "permission: multiple values";
                    properties.permission = 1;
                    {
                        var error = $root.data_room.RetrieveDataRoomStatusPermission.verify(message.retrieveDataRoomStatusPermission);
                        if (error)
                            return "retrieveDataRoomStatusPermission." + error;
                    }
                }
                if (message.updateDataRoomStatusPermission != null && message.hasOwnProperty("updateDataRoomStatusPermission")) {
                    if (properties.permission === 1)
                        return "permission: multiple values";
                    properties.permission = 1;
                    {
                        var error = $root.data_room.UpdateDataRoomStatusPermission.verify(message.updateDataRoomStatusPermission);
                        if (error)
                            return "updateDataRoomStatusPermission." + error;
                    }
                }
                if (message.retrievePublishedDatasetsPermission != null && message.hasOwnProperty("retrievePublishedDatasetsPermission")) {
                    if (properties.permission === 1)
                        return "permission: multiple values";
                    properties.permission = 1;
                    {
                        var error = $root.data_room.RetrievePublishedDatasetsPermission.verify(message.retrievePublishedDatasetsPermission);
                        if (error)
                            return "retrievePublishedDatasetsPermission." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Permission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.Permission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.Permission} Permission
             */
            Permission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.Permission)
                    return object;
                var message = new $root.data_room.Permission();
                if (object.submitQueryPermission != null) {
                    if (typeof object.submitQueryPermission !== "object")
                        throw TypeError(".data_room.Permission.submitQueryPermission: object expected");
                    message.submitQueryPermission = $root.data_room.SubmitQueryPermission.fromObject(object.submitQueryPermission);
                }
                if (object.tableCrudPermission != null) {
                    if (typeof object.tableCrudPermission !== "object")
                        throw TypeError(".data_room.Permission.tableCrudPermission: object expected");
                    message.tableCrudPermission = $root.data_room.TableCrudPermission.fromObject(object.tableCrudPermission);
                }
                if (object.dataRoomRetrievalPermission != null) {
                    if (typeof object.dataRoomRetrievalPermission !== "object")
                        throw TypeError(".data_room.Permission.dataRoomRetrievalPermission: object expected");
                    message.dataRoomRetrievalPermission = $root.data_room.DataroomRetrievalPermission.fromObject(object.dataRoomRetrievalPermission);
                }
                if (object.auditLogRetrievalPermission != null) {
                    if (typeof object.auditLogRetrievalPermission !== "object")
                        throw TypeError(".data_room.Permission.auditLogRetrievalPermission: object expected");
                    message.auditLogRetrievalPermission = $root.data_room.AuditLogRetrievalPermission.fromObject(object.auditLogRetrievalPermission);
                }
                if (object.retrieveDataRoomStatusPermission != null) {
                    if (typeof object.retrieveDataRoomStatusPermission !== "object")
                        throw TypeError(".data_room.Permission.retrieveDataRoomStatusPermission: object expected");
                    message.retrieveDataRoomStatusPermission = $root.data_room.RetrieveDataRoomStatusPermission.fromObject(object.retrieveDataRoomStatusPermission);
                }
                if (object.updateDataRoomStatusPermission != null) {
                    if (typeof object.updateDataRoomStatusPermission !== "object")
                        throw TypeError(".data_room.Permission.updateDataRoomStatusPermission: object expected");
                    message.updateDataRoomStatusPermission = $root.data_room.UpdateDataRoomStatusPermission.fromObject(object.updateDataRoomStatusPermission);
                }
                if (object.retrievePublishedDatasetsPermission != null) {
                    if (typeof object.retrievePublishedDatasetsPermission !== "object")
                        throw TypeError(".data_room.Permission.retrievePublishedDatasetsPermission: object expected");
                    message.retrievePublishedDatasetsPermission = $root.data_room.RetrievePublishedDatasetsPermission.fromObject(object.retrievePublishedDatasetsPermission);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Permission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.Permission
             * @static
             * @param {data_room.Permission} message Permission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Permission.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.submitQueryPermission != null && message.hasOwnProperty("submitQueryPermission")) {
                    object.submitQueryPermission = $root.data_room.SubmitQueryPermission.toObject(message.submitQueryPermission, options);
                    if (options.oneofs)
                        object.permission = "submitQueryPermission";
                }
                if (message.tableCrudPermission != null && message.hasOwnProperty("tableCrudPermission")) {
                    object.tableCrudPermission = $root.data_room.TableCrudPermission.toObject(message.tableCrudPermission, options);
                    if (options.oneofs)
                        object.permission = "tableCrudPermission";
                }
                if (message.dataRoomRetrievalPermission != null && message.hasOwnProperty("dataRoomRetrievalPermission")) {
                    object.dataRoomRetrievalPermission = $root.data_room.DataroomRetrievalPermission.toObject(message.dataRoomRetrievalPermission, options);
                    if (options.oneofs)
                        object.permission = "dataRoomRetrievalPermission";
                }
                if (message.auditLogRetrievalPermission != null && message.hasOwnProperty("auditLogRetrievalPermission")) {
                    object.auditLogRetrievalPermission = $root.data_room.AuditLogRetrievalPermission.toObject(message.auditLogRetrievalPermission, options);
                    if (options.oneofs)
                        object.permission = "auditLogRetrievalPermission";
                }
                if (message.retrieveDataRoomStatusPermission != null && message.hasOwnProperty("retrieveDataRoomStatusPermission")) {
                    object.retrieveDataRoomStatusPermission = $root.data_room.RetrieveDataRoomStatusPermission.toObject(message.retrieveDataRoomStatusPermission, options);
                    if (options.oneofs)
                        object.permission = "retrieveDataRoomStatusPermission";
                }
                if (message.updateDataRoomStatusPermission != null && message.hasOwnProperty("updateDataRoomStatusPermission")) {
                    object.updateDataRoomStatusPermission = $root.data_room.UpdateDataRoomStatusPermission.toObject(message.updateDataRoomStatusPermission, options);
                    if (options.oneofs)
                        object.permission = "updateDataRoomStatusPermission";
                }
                if (message.retrievePublishedDatasetsPermission != null && message.hasOwnProperty("retrievePublishedDatasetsPermission")) {
                    object.retrievePublishedDatasetsPermission = $root.data_room.RetrievePublishedDatasetsPermission.toObject(message.retrievePublishedDatasetsPermission, options);
                    if (options.oneofs)
                        object.permission = "retrievePublishedDatasetsPermission";
                }
                return object;
            };
    
            /**
             * Converts this Permission to JSON.
             * @function toJSON
             * @memberof data_room.Permission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Permission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Permission;
        })();
    
        data_room.SubmitQueryPermission = (function() {
    
            /**
             * Properties of a SubmitQueryPermission.
             * @memberof data_room
             * @interface ISubmitQueryPermission
             * @property {string} queryName SubmitQueryPermission queryName
             */
    
            /**
             * Constructs a new SubmitQueryPermission.
             * @memberof data_room
             * @classdesc Represents a SubmitQueryPermission.
             * @implements ISubmitQueryPermission
             * @constructor
             * @param {data_room.ISubmitQueryPermission=} [properties] Properties to set
             */
            function SubmitQueryPermission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SubmitQueryPermission queryName.
             * @member {string} queryName
             * @memberof data_room.SubmitQueryPermission
             * @instance
             */
            SubmitQueryPermission.prototype.queryName = "";
    
            /**
             * Creates a new SubmitQueryPermission instance using the specified properties.
             * @function create
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {data_room.ISubmitQueryPermission=} [properties] Properties to set
             * @returns {data_room.SubmitQueryPermission} SubmitQueryPermission instance
             */
            SubmitQueryPermission.create = function create(properties) {
                return new SubmitQueryPermission(properties);
            };
    
            /**
             * Encodes the specified SubmitQueryPermission message. Does not implicitly {@link data_room.SubmitQueryPermission.verify|verify} messages.
             * @function encode
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {data_room.ISubmitQueryPermission} message SubmitQueryPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubmitQueryPermission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.queryName);
                return writer;
            };
    
            /**
             * Encodes the specified SubmitQueryPermission message, length delimited. Does not implicitly {@link data_room.SubmitQueryPermission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {data_room.ISubmitQueryPermission} message SubmitQueryPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubmitQueryPermission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SubmitQueryPermission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.SubmitQueryPermission} SubmitQueryPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubmitQueryPermission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.SubmitQueryPermission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.queryName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("queryName"))
                    throw $util.ProtocolError("missing required 'queryName'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a SubmitQueryPermission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.SubmitQueryPermission} SubmitQueryPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubmitQueryPermission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SubmitQueryPermission message.
             * @function verify
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubmitQueryPermission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.queryName))
                    return "queryName: string expected";
                return null;
            };
    
            /**
             * Creates a SubmitQueryPermission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.SubmitQueryPermission} SubmitQueryPermission
             */
            SubmitQueryPermission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.SubmitQueryPermission)
                    return object;
                var message = new $root.data_room.SubmitQueryPermission();
                if (object.queryName != null)
                    message.queryName = String(object.queryName);
                return message;
            };
    
            /**
             * Creates a plain object from a SubmitQueryPermission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.SubmitQueryPermission
             * @static
             * @param {data_room.SubmitQueryPermission} message SubmitQueryPermission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubmitQueryPermission.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.queryName = "";
                if (message.queryName != null && message.hasOwnProperty("queryName"))
                    object.queryName = message.queryName;
                return object;
            };
    
            /**
             * Converts this SubmitQueryPermission to JSON.
             * @function toJSON
             * @memberof data_room.SubmitQueryPermission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubmitQueryPermission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return SubmitQueryPermission;
        })();
    
        data_room.TableCrudPermission = (function() {
    
            /**
             * Properties of a TableCrudPermission.
             * @memberof data_room
             * @interface ITableCrudPermission
             * @property {string} tableName TableCrudPermission tableName
             */
    
            /**
             * Constructs a new TableCrudPermission.
             * @memberof data_room
             * @classdesc Represents a TableCrudPermission.
             * @implements ITableCrudPermission
             * @constructor
             * @param {data_room.ITableCrudPermission=} [properties] Properties to set
             */
            function TableCrudPermission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TableCrudPermission tableName.
             * @member {string} tableName
             * @memberof data_room.TableCrudPermission
             * @instance
             */
            TableCrudPermission.prototype.tableName = "";
    
            /**
             * Creates a new TableCrudPermission instance using the specified properties.
             * @function create
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {data_room.ITableCrudPermission=} [properties] Properties to set
             * @returns {data_room.TableCrudPermission} TableCrudPermission instance
             */
            TableCrudPermission.create = function create(properties) {
                return new TableCrudPermission(properties);
            };
    
            /**
             * Encodes the specified TableCrudPermission message. Does not implicitly {@link data_room.TableCrudPermission.verify|verify} messages.
             * @function encode
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {data_room.ITableCrudPermission} message TableCrudPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TableCrudPermission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tableName);
                return writer;
            };
    
            /**
             * Encodes the specified TableCrudPermission message, length delimited. Does not implicitly {@link data_room.TableCrudPermission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {data_room.ITableCrudPermission} message TableCrudPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TableCrudPermission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TableCrudPermission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.TableCrudPermission} TableCrudPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TableCrudPermission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.TableCrudPermission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.tableName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("tableName"))
                    throw $util.ProtocolError("missing required 'tableName'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a TableCrudPermission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.TableCrudPermission} TableCrudPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TableCrudPermission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TableCrudPermission message.
             * @function verify
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TableCrudPermission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.tableName))
                    return "tableName: string expected";
                return null;
            };
    
            /**
             * Creates a TableCrudPermission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.TableCrudPermission} TableCrudPermission
             */
            TableCrudPermission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.TableCrudPermission)
                    return object;
                var message = new $root.data_room.TableCrudPermission();
                if (object.tableName != null)
                    message.tableName = String(object.tableName);
                return message;
            };
    
            /**
             * Creates a plain object from a TableCrudPermission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.TableCrudPermission
             * @static
             * @param {data_room.TableCrudPermission} message TableCrudPermission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TableCrudPermission.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.tableName = "";
                if (message.tableName != null && message.hasOwnProperty("tableName"))
                    object.tableName = message.tableName;
                return object;
            };
    
            /**
             * Converts this TableCrudPermission to JSON.
             * @function toJSON
             * @memberof data_room.TableCrudPermission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TableCrudPermission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TableCrudPermission;
        })();
    
        data_room.DataroomRetrievalPermission = (function() {
    
            /**
             * Properties of a DataroomRetrievalPermission.
             * @memberof data_room
             * @interface IDataroomRetrievalPermission
             */
    
            /**
             * Constructs a new DataroomRetrievalPermission.
             * @memberof data_room
             * @classdesc Represents a DataroomRetrievalPermission.
             * @implements IDataroomRetrievalPermission
             * @constructor
             * @param {data_room.IDataroomRetrievalPermission=} [properties] Properties to set
             */
            function DataroomRetrievalPermission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new DataroomRetrievalPermission instance using the specified properties.
             * @function create
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {data_room.IDataroomRetrievalPermission=} [properties] Properties to set
             * @returns {data_room.DataroomRetrievalPermission} DataroomRetrievalPermission instance
             */
            DataroomRetrievalPermission.create = function create(properties) {
                return new DataroomRetrievalPermission(properties);
            };
    
            /**
             * Encodes the specified DataroomRetrievalPermission message. Does not implicitly {@link data_room.DataroomRetrievalPermission.verify|verify} messages.
             * @function encode
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {data_room.IDataroomRetrievalPermission} message DataroomRetrievalPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataroomRetrievalPermission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified DataroomRetrievalPermission message, length delimited. Does not implicitly {@link data_room.DataroomRetrievalPermission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {data_room.IDataroomRetrievalPermission} message DataroomRetrievalPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataroomRetrievalPermission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a DataroomRetrievalPermission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.DataroomRetrievalPermission} DataroomRetrievalPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataroomRetrievalPermission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.DataroomRetrievalPermission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a DataroomRetrievalPermission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.DataroomRetrievalPermission} DataroomRetrievalPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataroomRetrievalPermission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a DataroomRetrievalPermission message.
             * @function verify
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataroomRetrievalPermission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a DataroomRetrievalPermission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.DataroomRetrievalPermission} DataroomRetrievalPermission
             */
            DataroomRetrievalPermission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.DataroomRetrievalPermission)
                    return object;
                return new $root.data_room.DataroomRetrievalPermission();
            };
    
            /**
             * Creates a plain object from a DataroomRetrievalPermission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.DataroomRetrievalPermission
             * @static
             * @param {data_room.DataroomRetrievalPermission} message DataroomRetrievalPermission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataroomRetrievalPermission.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this DataroomRetrievalPermission to JSON.
             * @function toJSON
             * @memberof data_room.DataroomRetrievalPermission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataroomRetrievalPermission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return DataroomRetrievalPermission;
        })();
    
        data_room.AuditLogRetrievalPermission = (function() {
    
            /**
             * Properties of an AuditLogRetrievalPermission.
             * @memberof data_room
             * @interface IAuditLogRetrievalPermission
             */
    
            /**
             * Constructs a new AuditLogRetrievalPermission.
             * @memberof data_room
             * @classdesc Represents an AuditLogRetrievalPermission.
             * @implements IAuditLogRetrievalPermission
             * @constructor
             * @param {data_room.IAuditLogRetrievalPermission=} [properties] Properties to set
             */
            function AuditLogRetrievalPermission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AuditLogRetrievalPermission instance using the specified properties.
             * @function create
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {data_room.IAuditLogRetrievalPermission=} [properties] Properties to set
             * @returns {data_room.AuditLogRetrievalPermission} AuditLogRetrievalPermission instance
             */
            AuditLogRetrievalPermission.create = function create(properties) {
                return new AuditLogRetrievalPermission(properties);
            };
    
            /**
             * Encodes the specified AuditLogRetrievalPermission message. Does not implicitly {@link data_room.AuditLogRetrievalPermission.verify|verify} messages.
             * @function encode
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {data_room.IAuditLogRetrievalPermission} message AuditLogRetrievalPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuditLogRetrievalPermission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AuditLogRetrievalPermission message, length delimited. Does not implicitly {@link data_room.AuditLogRetrievalPermission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {data_room.IAuditLogRetrievalPermission} message AuditLogRetrievalPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuditLogRetrievalPermission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AuditLogRetrievalPermission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.AuditLogRetrievalPermission} AuditLogRetrievalPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuditLogRetrievalPermission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.AuditLogRetrievalPermission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AuditLogRetrievalPermission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.AuditLogRetrievalPermission} AuditLogRetrievalPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuditLogRetrievalPermission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AuditLogRetrievalPermission message.
             * @function verify
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuditLogRetrievalPermission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AuditLogRetrievalPermission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.AuditLogRetrievalPermission} AuditLogRetrievalPermission
             */
            AuditLogRetrievalPermission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.AuditLogRetrievalPermission)
                    return object;
                return new $root.data_room.AuditLogRetrievalPermission();
            };
    
            /**
             * Creates a plain object from an AuditLogRetrievalPermission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.AuditLogRetrievalPermission
             * @static
             * @param {data_room.AuditLogRetrievalPermission} message AuditLogRetrievalPermission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuditLogRetrievalPermission.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AuditLogRetrievalPermission to JSON.
             * @function toJSON
             * @memberof data_room.AuditLogRetrievalPermission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuditLogRetrievalPermission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return AuditLogRetrievalPermission;
        })();
    
        data_room.RetrieveDataRoomStatusPermission = (function() {
    
            /**
             * Properties of a RetrieveDataRoomStatusPermission.
             * @memberof data_room
             * @interface IRetrieveDataRoomStatusPermission
             */
    
            /**
             * Constructs a new RetrieveDataRoomStatusPermission.
             * @memberof data_room
             * @classdesc Represents a RetrieveDataRoomStatusPermission.
             * @implements IRetrieveDataRoomStatusPermission
             * @constructor
             * @param {data_room.IRetrieveDataRoomStatusPermission=} [properties] Properties to set
             */
            function RetrieveDataRoomStatusPermission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new RetrieveDataRoomStatusPermission instance using the specified properties.
             * @function create
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {data_room.IRetrieveDataRoomStatusPermission=} [properties] Properties to set
             * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission instance
             */
            RetrieveDataRoomStatusPermission.create = function create(properties) {
                return new RetrieveDataRoomStatusPermission(properties);
            };
    
            /**
             * Encodes the specified RetrieveDataRoomStatusPermission message. Does not implicitly {@link data_room.RetrieveDataRoomStatusPermission.verify|verify} messages.
             * @function encode
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {data_room.IRetrieveDataRoomStatusPermission} message RetrieveDataRoomStatusPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomStatusPermission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified RetrieveDataRoomStatusPermission message, length delimited. Does not implicitly {@link data_room.RetrieveDataRoomStatusPermission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {data_room.IRetrieveDataRoomStatusPermission} message RetrieveDataRoomStatusPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrieveDataRoomStatusPermission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrieveDataRoomStatusPermission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomStatusPermission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RetrieveDataRoomStatusPermission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RetrieveDataRoomStatusPermission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrieveDataRoomStatusPermission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrieveDataRoomStatusPermission message.
             * @function verify
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrieveDataRoomStatusPermission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a RetrieveDataRoomStatusPermission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.RetrieveDataRoomStatusPermission} RetrieveDataRoomStatusPermission
             */
            RetrieveDataRoomStatusPermission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.RetrieveDataRoomStatusPermission)
                    return object;
                return new $root.data_room.RetrieveDataRoomStatusPermission();
            };
    
            /**
             * Creates a plain object from a RetrieveDataRoomStatusPermission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @static
             * @param {data_room.RetrieveDataRoomStatusPermission} message RetrieveDataRoomStatusPermission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrieveDataRoomStatusPermission.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this RetrieveDataRoomStatusPermission to JSON.
             * @function toJSON
             * @memberof data_room.RetrieveDataRoomStatusPermission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrieveDataRoomStatusPermission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrieveDataRoomStatusPermission;
        })();
    
        data_room.UpdateDataRoomStatusPermission = (function() {
    
            /**
             * Properties of an UpdateDataRoomStatusPermission.
             * @memberof data_room
             * @interface IUpdateDataRoomStatusPermission
             */
    
            /**
             * Constructs a new UpdateDataRoomStatusPermission.
             * @memberof data_room
             * @classdesc Represents an UpdateDataRoomStatusPermission.
             * @implements IUpdateDataRoomStatusPermission
             * @constructor
             * @param {data_room.IUpdateDataRoomStatusPermission=} [properties] Properties to set
             */
            function UpdateDataRoomStatusPermission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new UpdateDataRoomStatusPermission instance using the specified properties.
             * @function create
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {data_room.IUpdateDataRoomStatusPermission=} [properties] Properties to set
             * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission instance
             */
            UpdateDataRoomStatusPermission.create = function create(properties) {
                return new UpdateDataRoomStatusPermission(properties);
            };
    
            /**
             * Encodes the specified UpdateDataRoomStatusPermission message. Does not implicitly {@link data_room.UpdateDataRoomStatusPermission.verify|verify} messages.
             * @function encode
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {data_room.IUpdateDataRoomStatusPermission} message UpdateDataRoomStatusPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDataRoomStatusPermission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified UpdateDataRoomStatusPermission message, length delimited. Does not implicitly {@link data_room.UpdateDataRoomStatusPermission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {data_room.IUpdateDataRoomStatusPermission} message UpdateDataRoomStatusPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateDataRoomStatusPermission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateDataRoomStatusPermission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDataRoomStatusPermission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.UpdateDataRoomStatusPermission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateDataRoomStatusPermission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateDataRoomStatusPermission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateDataRoomStatusPermission message.
             * @function verify
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateDataRoomStatusPermission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an UpdateDataRoomStatusPermission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.UpdateDataRoomStatusPermission} UpdateDataRoomStatusPermission
             */
            UpdateDataRoomStatusPermission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.UpdateDataRoomStatusPermission)
                    return object;
                return new $root.data_room.UpdateDataRoomStatusPermission();
            };
    
            /**
             * Creates a plain object from an UpdateDataRoomStatusPermission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @static
             * @param {data_room.UpdateDataRoomStatusPermission} message UpdateDataRoomStatusPermission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateDataRoomStatusPermission.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this UpdateDataRoomStatusPermission to JSON.
             * @function toJSON
             * @memberof data_room.UpdateDataRoomStatusPermission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateDataRoomStatusPermission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return UpdateDataRoomStatusPermission;
        })();
    
        data_room.RetrievePublishedDatasetsPermission = (function() {
    
            /**
             * Properties of a RetrievePublishedDatasetsPermission.
             * @memberof data_room
             * @interface IRetrievePublishedDatasetsPermission
             */
    
            /**
             * Constructs a new RetrievePublishedDatasetsPermission.
             * @memberof data_room
             * @classdesc Represents a RetrievePublishedDatasetsPermission.
             * @implements IRetrievePublishedDatasetsPermission
             * @constructor
             * @param {data_room.IRetrievePublishedDatasetsPermission=} [properties] Properties to set
             */
            function RetrievePublishedDatasetsPermission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new RetrievePublishedDatasetsPermission instance using the specified properties.
             * @function create
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {data_room.IRetrievePublishedDatasetsPermission=} [properties] Properties to set
             * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission instance
             */
            RetrievePublishedDatasetsPermission.create = function create(properties) {
                return new RetrievePublishedDatasetsPermission(properties);
            };
    
            /**
             * Encodes the specified RetrievePublishedDatasetsPermission message. Does not implicitly {@link data_room.RetrievePublishedDatasetsPermission.verify|verify} messages.
             * @function encode
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {data_room.IRetrievePublishedDatasetsPermission} message RetrievePublishedDatasetsPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrievePublishedDatasetsPermission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified RetrievePublishedDatasetsPermission message, length delimited. Does not implicitly {@link data_room.RetrievePublishedDatasetsPermission.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {data_room.IRetrievePublishedDatasetsPermission} message RetrievePublishedDatasetsPermission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RetrievePublishedDatasetsPermission.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RetrievePublishedDatasetsPermission message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrievePublishedDatasetsPermission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.RetrievePublishedDatasetsPermission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RetrievePublishedDatasetsPermission message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RetrievePublishedDatasetsPermission.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RetrievePublishedDatasetsPermission message.
             * @function verify
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RetrievePublishedDatasetsPermission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a RetrievePublishedDatasetsPermission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.RetrievePublishedDatasetsPermission} RetrievePublishedDatasetsPermission
             */
            RetrievePublishedDatasetsPermission.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.RetrievePublishedDatasetsPermission)
                    return object;
                return new $root.data_room.RetrievePublishedDatasetsPermission();
            };
    
            /**
             * Creates a plain object from a RetrievePublishedDatasetsPermission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @static
             * @param {data_room.RetrievePublishedDatasetsPermission} message RetrievePublishedDatasetsPermission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RetrievePublishedDatasetsPermission.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this RetrievePublishedDatasetsPermission to JSON.
             * @function toJSON
             * @memberof data_room.RetrievePublishedDatasetsPermission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RetrievePublishedDatasetsPermission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return RetrievePublishedDatasetsPermission;
        })();
    
        data_room.Constraint = (function() {
    
            /**
             * Properties of a Constraint.
             * @memberof data_room
             * @interface IConstraint
             * @property {string} description Constraint description
             * @property {data_room.ISqlConstraint|null} [sqlConstraint] Constraint sqlConstraint
             * @property {data_room.ITimeConstraint|null} [timeConstraint] Constraint timeConstraint
             */
    
            /**
             * Constructs a new Constraint.
             * @memberof data_room
             * @classdesc Represents a Constraint.
             * @implements IConstraint
             * @constructor
             * @param {data_room.IConstraint=} [properties] Properties to set
             */
            function Constraint(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Constraint description.
             * @member {string} description
             * @memberof data_room.Constraint
             * @instance
             */
            Constraint.prototype.description = "";
    
            /**
             * Constraint sqlConstraint.
             * @member {data_room.ISqlConstraint|null|undefined} sqlConstraint
             * @memberof data_room.Constraint
             * @instance
             */
            Constraint.prototype.sqlConstraint = null;
    
            /**
             * Constraint timeConstraint.
             * @member {data_room.ITimeConstraint|null|undefined} timeConstraint
             * @memberof data_room.Constraint
             * @instance
             */
            Constraint.prototype.timeConstraint = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Constraint constraint.
             * @member {"sqlConstraint"|"timeConstraint"|undefined} constraint
             * @memberof data_room.Constraint
             * @instance
             */
            Object.defineProperty(Constraint.prototype, "constraint", {
                get: $util.oneOfGetter($oneOfFields = ["sqlConstraint", "timeConstraint"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Constraint instance using the specified properties.
             * @function create
             * @memberof data_room.Constraint
             * @static
             * @param {data_room.IConstraint=} [properties] Properties to set
             * @returns {data_room.Constraint} Constraint instance
             */
            Constraint.create = function create(properties) {
                return new Constraint(properties);
            };
    
            /**
             * Encodes the specified Constraint message. Does not implicitly {@link data_room.Constraint.verify|verify} messages.
             * @function encode
             * @memberof data_room.Constraint
             * @static
             * @param {data_room.IConstraint} message Constraint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Constraint.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.description);
                if (message.sqlConstraint != null && Object.hasOwnProperty.call(message, "sqlConstraint"))
                    $root.data_room.SqlConstraint.encode(message.sqlConstraint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.timeConstraint != null && Object.hasOwnProperty.call(message, "timeConstraint"))
                    $root.data_room.TimeConstraint.encode(message.timeConstraint, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Constraint message, length delimited. Does not implicitly {@link data_room.Constraint.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.Constraint
             * @static
             * @param {data_room.IConstraint} message Constraint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Constraint.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Constraint message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.Constraint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.Constraint} Constraint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Constraint.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.Constraint();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.description = reader.string();
                        break;
                    case 2:
                        message.sqlConstraint = $root.data_room.SqlConstraint.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.timeConstraint = $root.data_room.TimeConstraint.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("description"))
                    throw $util.ProtocolError("missing required 'description'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a Constraint message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.Constraint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.Constraint} Constraint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Constraint.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Constraint message.
             * @function verify
             * @memberof data_room.Constraint
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Constraint.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (!$util.isString(message.description))
                    return "description: string expected";
                if (message.sqlConstraint != null && message.hasOwnProperty("sqlConstraint")) {
                    properties.constraint = 1;
                    {
                        var error = $root.data_room.SqlConstraint.verify(message.sqlConstraint);
                        if (error)
                            return "sqlConstraint." + error;
                    }
                }
                if (message.timeConstraint != null && message.hasOwnProperty("timeConstraint")) {
                    if (properties.constraint === 1)
                        return "constraint: multiple values";
                    properties.constraint = 1;
                    {
                        var error = $root.data_room.TimeConstraint.verify(message.timeConstraint);
                        if (error)
                            return "timeConstraint." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Constraint message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.Constraint
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.Constraint} Constraint
             */
            Constraint.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.Constraint)
                    return object;
                var message = new $root.data_room.Constraint();
                if (object.description != null)
                    message.description = String(object.description);
                if (object.sqlConstraint != null) {
                    if (typeof object.sqlConstraint !== "object")
                        throw TypeError(".data_room.Constraint.sqlConstraint: object expected");
                    message.sqlConstraint = $root.data_room.SqlConstraint.fromObject(object.sqlConstraint);
                }
                if (object.timeConstraint != null) {
                    if (typeof object.timeConstraint !== "object")
                        throw TypeError(".data_room.Constraint.timeConstraint: object expected");
                    message.timeConstraint = $root.data_room.TimeConstraint.fromObject(object.timeConstraint);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Constraint message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.Constraint
             * @static
             * @param {data_room.Constraint} message Constraint
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Constraint.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.description = "";
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.sqlConstraint != null && message.hasOwnProperty("sqlConstraint")) {
                    object.sqlConstraint = $root.data_room.SqlConstraint.toObject(message.sqlConstraint, options);
                    if (options.oneofs)
                        object.constraint = "sqlConstraint";
                }
                if (message.timeConstraint != null && message.hasOwnProperty("timeConstraint")) {
                    object.timeConstraint = $root.data_room.TimeConstraint.toObject(message.timeConstraint, options);
                    if (options.oneofs)
                        object.constraint = "timeConstraint";
                }
                return object;
            };
    
            /**
             * Converts this Constraint to JSON.
             * @function toJSON
             * @memberof data_room.Constraint
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Constraint.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return Constraint;
        })();
    
        data_room.SqlConstraint = (function() {
    
            /**
             * Properties of a SqlConstraint.
             * @memberof data_room
             * @interface ISqlConstraint
             * @property {string} statement SqlConstraint statement
             */
    
            /**
             * Constructs a new SqlConstraint.
             * @memberof data_room
             * @classdesc Represents a SqlConstraint.
             * @implements ISqlConstraint
             * @constructor
             * @param {data_room.ISqlConstraint=} [properties] Properties to set
             */
            function SqlConstraint(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * SqlConstraint statement.
             * @member {string} statement
             * @memberof data_room.SqlConstraint
             * @instance
             */
            SqlConstraint.prototype.statement = "";
    
            /**
             * Creates a new SqlConstraint instance using the specified properties.
             * @function create
             * @memberof data_room.SqlConstraint
             * @static
             * @param {data_room.ISqlConstraint=} [properties] Properties to set
             * @returns {data_room.SqlConstraint} SqlConstraint instance
             */
            SqlConstraint.create = function create(properties) {
                return new SqlConstraint(properties);
            };
    
            /**
             * Encodes the specified SqlConstraint message. Does not implicitly {@link data_room.SqlConstraint.verify|verify} messages.
             * @function encode
             * @memberof data_room.SqlConstraint
             * @static
             * @param {data_room.ISqlConstraint} message SqlConstraint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlConstraint.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.statement);
                return writer;
            };
    
            /**
             * Encodes the specified SqlConstraint message, length delimited. Does not implicitly {@link data_room.SqlConstraint.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.SqlConstraint
             * @static
             * @param {data_room.ISqlConstraint} message SqlConstraint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SqlConstraint.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a SqlConstraint message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.SqlConstraint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.SqlConstraint} SqlConstraint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlConstraint.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.SqlConstraint();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.statement = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("statement"))
                    throw $util.ProtocolError("missing required 'statement'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a SqlConstraint message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.SqlConstraint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.SqlConstraint} SqlConstraint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SqlConstraint.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a SqlConstraint message.
             * @function verify
             * @memberof data_room.SqlConstraint
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SqlConstraint.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isString(message.statement))
                    return "statement: string expected";
                return null;
            };
    
            /**
             * Creates a SqlConstraint message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.SqlConstraint
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.SqlConstraint} SqlConstraint
             */
            SqlConstraint.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.SqlConstraint)
                    return object;
                var message = new $root.data_room.SqlConstraint();
                if (object.statement != null)
                    message.statement = String(object.statement);
                return message;
            };
    
            /**
             * Creates a plain object from a SqlConstraint message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.SqlConstraint
             * @static
             * @param {data_room.SqlConstraint} message SqlConstraint
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SqlConstraint.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.statement = "";
                if (message.statement != null && message.hasOwnProperty("statement"))
                    object.statement = message.statement;
                return object;
            };
    
            /**
             * Converts this SqlConstraint to JSON.
             * @function toJSON
             * @memberof data_room.SqlConstraint
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SqlConstraint.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return SqlConstraint;
        })();
    
        data_room.TimeConstraint = (function() {
    
            /**
             * Properties of a TimeConstraint.
             * @memberof data_room
             * @interface ITimeConstraint
             * @property {number|Long} timestamp TimeConstraint timestamp
             * @property {boolean} before TimeConstraint before
             */
    
            /**
             * Constructs a new TimeConstraint.
             * @memberof data_room
             * @classdesc Represents a TimeConstraint.
             * @implements ITimeConstraint
             * @constructor
             * @param {data_room.ITimeConstraint=} [properties] Properties to set
             */
            function TimeConstraint(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TimeConstraint timestamp.
             * @member {number|Long} timestamp
             * @memberof data_room.TimeConstraint
             * @instance
             */
            TimeConstraint.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TimeConstraint before.
             * @member {boolean} before
             * @memberof data_room.TimeConstraint
             * @instance
             */
            TimeConstraint.prototype.before = false;
    
            /**
             * Creates a new TimeConstraint instance using the specified properties.
             * @function create
             * @memberof data_room.TimeConstraint
             * @static
             * @param {data_room.ITimeConstraint=} [properties] Properties to set
             * @returns {data_room.TimeConstraint} TimeConstraint instance
             */
            TimeConstraint.create = function create(properties) {
                return new TimeConstraint(properties);
            };
    
            /**
             * Encodes the specified TimeConstraint message. Does not implicitly {@link data_room.TimeConstraint.verify|verify} messages.
             * @function encode
             * @memberof data_room.TimeConstraint
             * @static
             * @param {data_room.ITimeConstraint} message TimeConstraint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeConstraint.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestamp);
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.before);
                return writer;
            };
    
            /**
             * Encodes the specified TimeConstraint message, length delimited. Does not implicitly {@link data_room.TimeConstraint.verify|verify} messages.
             * @function encodeDelimited
             * @memberof data_room.TimeConstraint
             * @static
             * @param {data_room.ITimeConstraint} message TimeConstraint message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeConstraint.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TimeConstraint message from the specified reader or buffer.
             * @function decode
             * @memberof data_room.TimeConstraint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {data_room.TimeConstraint} TimeConstraint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeConstraint.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.data_room.TimeConstraint();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timestamp = reader.int64();
                        break;
                    case 2:
                        message.before = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("timestamp"))
                    throw $util.ProtocolError("missing required 'timestamp'", { instance: message });
                if (!message.hasOwnProperty("before"))
                    throw $util.ProtocolError("missing required 'before'", { instance: message });
                return message;
            };
    
            /**
             * Decodes a TimeConstraint message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof data_room.TimeConstraint
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {data_room.TimeConstraint} TimeConstraint
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeConstraint.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TimeConstraint message.
             * @function verify
             * @memberof data_room.TimeConstraint
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TimeConstraint.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
                if (typeof message.before !== "boolean")
                    return "before: boolean expected";
                return null;
            };
    
            /**
             * Creates a TimeConstraint message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof data_room.TimeConstraint
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {data_room.TimeConstraint} TimeConstraint
             */
            TimeConstraint.fromObject = function fromObject(object) {
                if (object instanceof $root.data_room.TimeConstraint)
                    return object;
                var message = new $root.data_room.TimeConstraint();
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.before != null)
                    message.before = Boolean(object.before);
                return message;
            };
    
            /**
             * Creates a plain object from a TimeConstraint message. Also converts values to other types if specified.
             * @function toObject
             * @memberof data_room.TimeConstraint
             * @static
             * @param {data_room.TimeConstraint} message TimeConstraint
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimeConstraint.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.before = false;
                }
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.before != null && message.hasOwnProperty("before"))
                    object.before = message.before;
                return object;
            };
    
            /**
             * Converts this TimeConstraint to JSON.
             * @function toJSON
             * @memberof data_room.TimeConstraint
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimeConstraint.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return TimeConstraint;
        })();
    
        return data_room;
    })();
    
    $root.csv_table_format = (function() {
    
        /**
         * Namespace csv_table_format.
         * @exports csv_table_format
         * @namespace
         */
        var csv_table_format = {};
    
        csv_table_format.CsvTableFormat = (function() {
    
            /**
             * Properties of a CsvTableFormat.
             * @memberof csv_table_format
             * @interface ICsvTableFormat
             * @property {Array.<column_type.IColumnType>|null} [columnTypes] CsvTableFormat columnTypes
             */
    
            /**
             * Constructs a new CsvTableFormat.
             * @memberof csv_table_format
             * @classdesc Represents a CsvTableFormat.
             * @implements ICsvTableFormat
             * @constructor
             * @param {csv_table_format.ICsvTableFormat=} [properties] Properties to set
             */
            function CsvTableFormat(properties) {
                this.columnTypes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CsvTableFormat columnTypes.
             * @member {Array.<column_type.IColumnType>} columnTypes
             * @memberof csv_table_format.CsvTableFormat
             * @instance
             */
            CsvTableFormat.prototype.columnTypes = $util.emptyArray;
    
            /**
             * Creates a new CsvTableFormat instance using the specified properties.
             * @function create
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {csv_table_format.ICsvTableFormat=} [properties] Properties to set
             * @returns {csv_table_format.CsvTableFormat} CsvTableFormat instance
             */
            CsvTableFormat.create = function create(properties) {
                return new CsvTableFormat(properties);
            };
    
            /**
             * Encodes the specified CsvTableFormat message. Does not implicitly {@link csv_table_format.CsvTableFormat.verify|verify} messages.
             * @function encode
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {csv_table_format.ICsvTableFormat} message CsvTableFormat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CsvTableFormat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.columnTypes != null && message.columnTypes.length)
                    for (var i = 0; i < message.columnTypes.length; ++i)
                        $root.column_type.ColumnType.encode(message.columnTypes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CsvTableFormat message, length delimited. Does not implicitly {@link csv_table_format.CsvTableFormat.verify|verify} messages.
             * @function encodeDelimited
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {csv_table_format.ICsvTableFormat} message CsvTableFormat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CsvTableFormat.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CsvTableFormat message from the specified reader or buffer.
             * @function decode
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {csv_table_format.CsvTableFormat} CsvTableFormat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CsvTableFormat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.csv_table_format.CsvTableFormat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.columnTypes && message.columnTypes.length))
                            message.columnTypes = [];
                        message.columnTypes.push($root.column_type.ColumnType.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CsvTableFormat message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {csv_table_format.CsvTableFormat} CsvTableFormat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CsvTableFormat.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CsvTableFormat message.
             * @function verify
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CsvTableFormat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.columnTypes != null && message.hasOwnProperty("columnTypes")) {
                    if (!Array.isArray(message.columnTypes))
                        return "columnTypes: array expected";
                    for (var i = 0; i < message.columnTypes.length; ++i) {
                        var error = $root.column_type.ColumnType.verify(message.columnTypes[i]);
                        if (error)
                            return "columnTypes." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CsvTableFormat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {csv_table_format.CsvTableFormat} CsvTableFormat
             */
            CsvTableFormat.fromObject = function fromObject(object) {
                if (object instanceof $root.csv_table_format.CsvTableFormat)
                    return object;
                var message = new $root.csv_table_format.CsvTableFormat();
                if (object.columnTypes) {
                    if (!Array.isArray(object.columnTypes))
                        throw TypeError(".csv_table_format.CsvTableFormat.columnTypes: array expected");
                    message.columnTypes = [];
                    for (var i = 0; i < object.columnTypes.length; ++i) {
                        if (typeof object.columnTypes[i] !== "object")
                            throw TypeError(".csv_table_format.CsvTableFormat.columnTypes: object expected");
                        message.columnTypes[i] = $root.column_type.ColumnType.fromObject(object.columnTypes[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CsvTableFormat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof csv_table_format.CsvTableFormat
             * @static
             * @param {csv_table_format.CsvTableFormat} message CsvTableFormat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CsvTableFormat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.columnTypes = [];
                if (message.columnTypes && message.columnTypes.length) {
                    object.columnTypes = [];
                    for (var j = 0; j < message.columnTypes.length; ++j)
                        object.columnTypes[j] = $root.column_type.ColumnType.toObject(message.columnTypes[j], options);
                }
                return object;
            };
    
            /**
             * Converts this CsvTableFormat to JSON.
             * @function toJSON
             * @memberof csv_table_format.CsvTableFormat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CsvTableFormat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return CsvTableFormat;
        })();
    
        return csv_table_format;
    })();

    return $root;
});
