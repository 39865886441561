import { Box } from "@material-ui/core";
import { memo } from "react";
import { Status, SurveyActions } from "components";

const SurveyFormToolbar = memo(
  ({ id, name, isPublished, updatedAt, isOwner }) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Status strong isPublished={isPublished} updatedAt={updatedAt} />
        <SurveyActions id={id} name={name} isOwner={isOwner} />
      </Box>
    );
  }
);

export default SurveyFormToolbar;
