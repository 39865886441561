import { faCalendarDay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, TextField } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import { FastField, useStatus } from "formik";
import { memo } from "react";

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
    display: (props) => (props.disabled ? "none" : "inline-flex"),
  },
}));

const SubmissionEndField = memo(() => {
  const [{ isEditing }] = useStatus();
  const inlineIconButtonClasses = useInlineIconButtonStyles({
    disabled: !isEditing,
  });
  const inputLabelClasses = useInputLabelStyles();
  return (
    <FastField name="submissionEnd">
      {({ field, meta: { error, touched } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
          <DateTimePicker
            mask="__.__.____ __:__"
            ampm={false}
            hideTabs={true}
            renderInput={(props) => (
              <TextField
                {...props}
                {...field}
                variant="standard"
                color="secondary"
                fullWidth
                label="Submissions accepted to"
                InputLabelProps={{
                  htmlFor: "submission-end",
                  required: isEditing,
                  shrink: true,
                  classes: inputLabelClasses,
                }}
                InputProps={{
                  ...props.InputProps,
                  disableUnderline: !isEditing,
                }}
                readOnly={!isEditing}
                disabled={!isEditing}
                error={Boolean(error)}
                helperText={error}
              />
            )}
            openPickerIcon={<FontAwesomeIcon fixedWidth icon={faCalendarDay} />}
            OpenPickerButtonProps={{
              classes: inlineIconButtonClasses,
              TouchRippleProps: {
                center: false,
              },
            }}
            InputAdornmentProps={{
              style: {
                width: "24px",
              },
            }}
            readOnly={!isEditing}
            disabled={!isEditing}
            {...field}
          />
        </LocalizationProvider>
      )}
    </FastField>
  );
});

export default SubmissionEndField;
