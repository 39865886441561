import { makeStyles, TextField } from "@material-ui/core";
import { FastField, useFieldValue, useStatus } from "formik";

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const NameField = ({ label, name, startAdornment, endAdornment, ...props }) => {
  const [{ isEditing }] = useStatus();
  const [isRequired] = useFieldValue(name.replace(/name$/g, "isRequired"));
  const inputLabelClasses = useInputLabelStyles();
  const inputClasses = useInputStyles();
  return (
    <FastField name={name}>
      {({ field, meta: { error, touched } }) => (
        <TextField
          variant="standard"
          color="secondary"
          fullWidth
          multiline
          label={label}
          InputLabelProps={{
            required: isEditing ? true : isRequired,
            shrink: true,
            classes: inputLabelClasses,
          }}
          InputProps={{
            classes: inputClasses,
            style: { fontWeight: "bold" },
            startAdornment,
            endAdornment,
            disableUnderline: !isEditing,
            readOnly: !isEditing,
            disabled: !isEditing,
          }}
          error={Boolean(error)}
          helperText={error}
          {...field}
          {...props}
        />
      )}
    </FastField>
  );
};

export default NameField;
