import { Box, Typography } from "@material-ui/core";
import { NewSurveyButton } from "components";

const EmptyData = (props) => {
  return (
    <Box
      sx={{
        mt: 3,
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        component="h4"
        variant="h4"
        color="textPrimary"
        gutterBottom
        style={{ marginTop: "1rem" }}
      >
        <strong>You have no insights at the moment</strong>
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        It's time to create one!
      </Typography>
      <NewSurveyButton />
    </Box>
  );
};

export default EmptyData;
