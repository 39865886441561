import {
  faCircleA,
  faCircleB,
  faCircleC,
  faCircleD,
  faCircleE,
  faCircleF,
  faCircleG,
  faCircleH,
  faCircleI,
  faCircleJ,
  faCircleK,
  faCircleL,
  faCircleM,
  faCircleN,
  faCircleO,
  faCircleP,
  faCircleQ,
  faCircleR,
  faCircleS,
  faCircleT,
  faCircleU,
  faCircleV,
  faCircleW,
  faCircleX,
  faCircleY,
  faCircleZ,
  faGripVertical,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { FastField, useStatus } from "formik";
import ChipInput from "material-ui-chip-input";
import { memo, useState } from "react";

const lettersIcons = [
  faCircleA,
  faCircleB,
  faCircleC,
  faCircleD,
  faCircleE,
  faCircleF,
  faCircleG,
  faCircleH,
  faCircleI,
  faCircleJ,
  faCircleK,
  faCircleL,
  faCircleM,
  faCircleN,
  faCircleO,
  faCircleP,
  faCircleQ,
  faCircleR,
  faCircleS,
  faCircleT,
  faCircleU,
  faCircleV,
  faCircleW,
  faCircleX,
  faCircleY,
  faCircleZ,
];

const useChipInputStyles = makeStyles((theme) => ({
  chipContainer: {
    minHeight: "auto",
    marginBottom: 0,
    "&:before": {
      borderBottomStyle: "dotted",
      borderColor: "inherit",
    },
    "&:after": {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const useChipStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: 0,
  },
  label: {
    padding: theme.spacing(0, 1, 0, 1),
    fontSize: "0.875rem",
    lineHeight: "1rem",
  },
  icon: {
    color: "inherit",
    fontSize: "1rem",
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
    "&:not(:first-child)": {
      marginTop: theme.spacing(0.25),
    },
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const CategoriesField = memo(({ name }) => {
  const chipInputClasses = useChipInputStyles();
  const chipClasses = useChipStyles();
  const inputLabelClasses = useInputLabelStyles();
  const inputClasses = useInputStyles();
  const inlineIconButtonClasses = useInlineIconButtonStyles();
  const [{ isEditing }] = useStatus();
  const [isEmpty, setIsEmpty] = useState(true);
  const [inputRef, setRef] = useState(null);
  return (
    <FastField name={name}>
      {({ field, meta: { error, touched } }) => (
        <ChipInput
          inputRef={setRef}
          blurBehavior="ignore"
          clearInputValueOnChange
          chipRenderer={(
            {
              value,
              isFocused,
              isDisabled,
              isReadOnly,
              handleClick,
              handleDelete,
              className,
            },
            key
          ) => {
            const index = field.value.findIndex((v) => v === value);
            return (
              <Chip
                key={key}
                className={className}
                classes={chipClasses}
                variant="outlined"
                style={{
                  pointerEvents: isDisabled || isReadOnly ? "none" : undefined,
                  backgroundColor: isFocused ? "lightgrey" : "transparent",
                }}
                icon={
                  <FontAwesomeIcon
                    fixedWidth
                    icon={
                      field.value.length > 22 || index === -1
                        ? faGripVertical
                        : lettersIcons[index]
                    }
                  />
                }
                deleteIcon={
                  <FontAwesomeIcon
                    fixedWidth
                    icon={faTimes}
                    style={{ fontSize: "1rem" }}
                  />
                }
                onClick={handleClick}
                onDelete={isEditing ? handleDelete : false}
                label={value}
              />
            );
          }}
          label="Options"
          placeholder="Type an answer option and press Enter to add it, or paste a list of answer options..."
          alwaysShowPlaceholder
          fullWidth
          fullWidthInput
          classes={chipInputClasses}
          disableUnderline
          InputProps={{
            classes: inputClasses,
            className: "MuiInput-underline",
            color: "secondary",
            style: {
              minWidth: "100%",
              display: isEditing ? "inline-flex" : "none",
            },
            readOnly: !isEditing,
            disabled: !isEditing,
            error: Boolean(error),
            onPaste: (event) => {
              const newValues = (event.clipboardData || window.clipboardData)
                .getData("text")
                .split("\n")
                .map((value) => value.split(" "))
                .flat();
              if (newValues.length > 1) {
                field.onChange([
                  ...new Set([
                    ...field.value,
                    ...newValues.map((value) => value.trim()),
                  ]),
                ]);
                setIsEmpty(true);
                event.preventDefault();
              }
            },
            endAdornment: (
              <Box
                sx={{
                  display: "flex",
                  marginTop: -0.5,
                  marginLeft: 0.25,
                  marginBottom: -0.5,
                }}
              >
                {isEditing && !isEmpty && (
                  <Tooltip
                    title={
                      <Box>
                        <Box>Add an option</Box>
                        <Box style={{ opacity: 0.5 }}>Return</Box>
                      </Box>
                    }
                    placement="top"
                  >
                    <IconButton
                      color="inherit"
                      classes={inlineIconButtonClasses}
                      TouchRippleProps={{
                        center: false,
                      }}
                      onClick={() => {
                        field.onChange([
                          ...new Set([...field.value, inputRef.value.trim()]),
                        ]);
                        setIsEmpty(true);
                      }}
                    >
                      <FontAwesomeIcon fixedWidth icon={faPlus} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ),
          }}
          InputLabelProps={{
            color: "secondary",
            shrink: true,
            required: true,
            disabled: false,
            classes: inputLabelClasses,
            error: Boolean(error),
          }}
          helperText={error}
          FormHelperTextProps={{
            error: Boolean(error),
            style: { marginBottom: 0 },
          }}
          newChipKeyCodes={[13]}
          newChipKeys={["Enter"]}
          onAdd={(newValue, index) => {
            field.onChange([...new Set([...field.value, newValue.trim()])]);
            setIsEmpty(true);
          }}
          onDelete={(newValue, index) => {
            field.onChange([
              ...new Set([
                ...field.value.slice(0, index),
                ...field.value.slice(index + 1),
              ]),
            ]);
          }}
          onUpdateInput={(event) => setIsEmpty(!event.target.value?.trim())}
          {...field}
        />
      )}
    </FastField>
  );
});

export default CategoriesField;
