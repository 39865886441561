import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Main } from "layouts";

const useAccordionSummaryStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0, 3, 0),
    order: 1,
  },
  expandIconWrapper: {
    margin: 0,
    order: 2,
  },
}));

const FaqPage = () => {
  const accordionSummaryClasses = useAccordionSummaryStyles();

  const onEmailClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.open("mailto:hello@confidentialinsights.com");
  };

  return (
    <Main>
      <Box sx={{ pt: 10, pb: 10 }}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Frequently asked questions</strong>
          </Typography>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>Who is Swisscom?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                <strong>Swisscom</strong> is the leading ICT provider in
                Switzerland, offering mobile telecommunications, fixed network,
                Internet and digital TV solutions for business and residential
                customers. We are also part of the Confidential Computing
                Computing Consortium and one of the largest providers of IT
                services in Switzerland. We develop and maintain the wireless
                and wireline network infrastructure, distribute broadcasting
                signals and are also active in the banking, energy,
                entertainment, advertising and healthcare sectors. Swisscom is
                51% Confederation-owned and is one of Switzerland’s most
                sustainable and innovative companies.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>Who is Decentriq?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                <strong>Decentriq</strong> guarantees the security of
                Confidential Insights and guarantees the functioning of the
                Confidential Computing environment. Decentriq is a Swiss-based
                startup born in 2018 to change how the world accesses data,
                enabling risk-free data ecosystems. With a deep expertise in
                Cryptography and Privacy Enhancing Technologies, we work in
                partnership with our clients to ensure that they leverage their
                most sensitive data securely and seamlessly. We leverage Trusted
                Execution Environments to build our products and we are a
                founding member of the Confidential Computing Consortium.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  Why should I use Confidential Insights instead of a normal
                  survey tool?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                If you can use a normal survey tool, you don't need us. What
                Confidential Insights allows is not possible with any other
                survey tool: analysing and aggregating sensitive information
                without involving third parties.<br></br>Confidential Insights
                is necessary when nobody wants to disclose what they think, but
                everybody wants to know what the others think. You should use
                Confidential Insights if you want to collaborate with your peers
                on sensitive or strategic information, guaranteeing that private
                information will not be accessed by anyone.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  Why should I trust Confidential Insights to give my sensitive
                  data?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Technically, you do not have to trust us. You are not giving us
                your data, because you are protecting your data using
                cryptography when you submit your data, and only you have the
                only key. Those data are processed in a secure enclave, that is
                an unaccessible trusted execution environment able to handle
                encrypted data. Our Confidential Computing workflow guarantees
                that we, as anyone else, are no able to access your sensitive
                data. You receive the proof of it on your device every time you
                access our platform. To know more about the workflow, visit{" "}
                <Link
                  href="https://software.intel.com/content/www/us/en/develop/topics/software-guard-extensions/attestation-services.html"
                  target="blank"
                >
                  this page of Intel website.
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>How can I analyze the answers?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Confidential Insights is not a simple survey tool. Privacy and
                anonymity of the input are essential for us. This is why the
                analyses that can be run on the submitted data are decided
                upfront, when the Insight is created. What happens to the
                answers is shown and accepted by all the participants when they
                answer to the questions. The result will be computed only once
                the Insight is closed.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>Who can access the results?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="div">
                When the Insight is created, there are 3 options:
                <ul>
                  <li>
                    publishing the result, making it accessible to third parties
                  </li>
                  <li>
                    Making it accessible to the survey creator and all the
                    participants
                  </li>
                  <li>Making it accessible only to the survey creator</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>What is the percentile filtering?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Since the raw data are not accessible, to avoid unexpected
                results it is possible to compute them without considering the
                most extreme results, or outliers. This process is called
                'trimming' or 'Winsorization'.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>When is the result published?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                When the deadline of an Insight is reached, it does not accept
                any more submission and the result is published.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  Is it possible to understand what I answered to the questions
                  from the result?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                We guarantee anonymity of the submission both aggregating and
                filtering the output. Only aggregating analytics can be run on
                the data. Then, a filtering algorithm called K-anonymity is
                applied to the result. It guarantees that at least a minimum
                number of input data (at least K data points, hence the name)
                are aggregated, otherwise the result is not accessible. The
                Insight creator defines the K-parameter of the algorithm and
                responders have visibility on it when answering. Thus, they are
                able to decide if the level of anonymity is enough for them.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  How can Confidential Insights compute results if no one has
                  access to the data?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                A new technologic breakthrough allows computation on encrypted
                data. It is made possible by a new feature of Intel CPUs, called
                Intel Software Guard Extension (or Intel SGX), that makes
                possible to compute encrypted data in a secure environment of
                execution, called Secure Enclave.{" "}
                <Link
                  href="https://www.intel.co.uk/content/www/uk/en/architecture-and-technology/software-guard-extensions.html"
                  target="blank"
                >
                  Here you can find more information from Intel website.
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>Can information be somehow hacked?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                The only place where data are not encrypted is in your computer
                when answering the questions. Before that any data flow leaves
                your premises, it is encrypted. It will stay encrypted
                throughout the whole process and even while being computed,
                thanks to Intel SGX. Thus, even though encrypted data can be
                accessed or damaged, they cannot be read in clear text or
                modified.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  What is Confidential Computing and why are you naming it so
                  often?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Confidential Computing is the technology that makes possible to
                guarantee the anonymity of the answers without involving third
                parties. Confidential Computing is the computation in a Trusted
                Execution Environment, in our case made possible by Intel
                Software Guard Extension (Intel SGX). It is a hardware
                capability that is able to compute data that are encrypted in
                memory. The peculiarity of Confidential Computing, and its major
                added value, is that data can stay encrypted during transfer,
                while they are stored in the cloud and also while processed. It
                allows many new use cases where more security guarantees are
                required. Indeed, one of them is Confidential Insights.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  You talk of encryption. Who holds the keys to decrypt the
                  data?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Each user receives a public key - certified by Intel - when they
                access an Insight in Confidential Insights. It is stored on
                premises, in your computer, and it is used to create a secure
                communication with the secure enclave (the trusted execution
                environment). Intel certifies via digital signature that each
                public key that the user receives actually comes from the
                expected secure enclave. This avoids man-in-the-middle attacks.
                No one else has access to those keys, not even Confidential
                Insights.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>Is Confidential Insights GDPR compliant?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                We guarantee that individual answers are not accessible nor are
                leaked by the Insight result. This means that also personal data
                are not accessible by unauthorized parties. Moreover,
                Confidential Insights is considered a data processor. Thus, if
                you have a legal basis to hold and compute the data you upload,
                you are not breaching GDPR using Confidential Insights.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  What do you mean by saying that the security is 'Secured by
                  Intel SGX'?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Confidential Insights security guarantees are based on a
                hardware - Intel Software Guard Extension (Intel SGX) - that
                makes it possible and is our root of trust. When you access an
                insight with your account, your client receives the confirmation
                (called remote attestation) from an Intel SGX instance that the
                program works as expected and all data are encrypted throughout
                the whole process. This confirmation is digitally signed by
                Intel, that guarantees that you are communicating with a real
                Intel SGX enclave and information are reliable.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>What data is stored and where?</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Our servers are hosted in the EU. Apart from the submission
                data, that stays encrypted and not accessible and are deleted
                after that an Insight expires, Confidential Insights collects
                only essential account information like email, name and
                organization and Insight metadata, necessary to provide the
                required functionalities. This data is deleted when is not
                necessary anymore, and used only as stated in our T&C.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              onClick={(event) => event.stopPropagation()}
              expandIcon={
                <IconButton color="inherit">
                  <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                </IconButton>
              }
            >
              <Typography component="h2" variant="h5" color="textPrimary">
                <strong>
                  Why are the participants to an Insight made visible?
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary" component="p">
                Confidential Insights is thought as a peer benchmarking tool.
                Thus, full transparency among participants is a key element to
                create trust among participants. Moreover, it helps the
                management of the Insight by the survey creator, allows
                coordination across the participants and helps creating
                expectations of the result and its quality.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" color="textPrimary" component="p">
              If you did not find an answer to your question, please send an
              email to{" "}
              <Link
                color="primary"
                href="mailto:hello@confidentialinsights.com"
                target="_blank"
                rel="noopener noreferrer"
                onClick={onEmailClicked}
              >
                hello@confidentialinsights.com
              </Link>
              .
            </Typography>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default FaqPage;
