import { Box } from "@material-ui/core";
import { Surveys } from "containers";
import { Main } from "layouts";

const SurveysPage = () => {
  return (
    <Main>
      <Box sx={{ p: 3 }}>
        <Surveys />
      </Box>
    </Main>
  );
};

export default SurveysPage;
