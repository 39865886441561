import { questionKind } from "constants/index";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { orange, teal } from "@material-ui/core/colors";
import { useFieldError, useFieldValue, useStatus } from "formik";
import { memo } from "react";
import {
  AggregationsField,
  CategoriesField,
  DescriptionField,
  InputField,
  MaxValueValidationField,
  MinimumNumberOfValidSubmissionsField,
  MinValueValidationField,
  NameField,
  QuestionActions,
  QuestionKindSwitch,
  RequiredField,
  Result,
  SelectField,
  UnitField,
} from "components";
import { surveySubmissionErrorPresentationMap } from "containers/Survey/SurveyConstants";

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 8, 1, 8),
    borderTopWidth: "1px",
    borderColor: theme.palette.action.selected,
    borderBottomWidth: "1px",
    borderRadius: "0 !important",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    marginTop: "-1px !important",
    "& .question-actions": {
      opacity: 0,
      visibility: "hidden",
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      "& .question-actions": {
        opacity: 1,
        visibility: "visible",
      },
    },
    "&:before": {
      display: "none",
    },
  },
}));

const useAccordionSummaryStyles = makeStyles((theme) => ({
  focusVisible: {
    backgroundColor: "transparent !important",
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const getQuestionTooltipByError = (error) => {
  if (surveySubmissionErrorPresentationMap.has(error?.name)) {
    const errorPresentation = surveySubmissionErrorPresentationMap.get(
      error?.name
    );
    return (
      <Box>
        <Box>{errorPresentation.title}</Box>
        <Box sx={{ fontWeight: 400 }}>{errorPresentation.description}</Box>
      </Box>
    );
  }
  return error?.message ? (
    <Box sx={{ fontWeight: 400 }}>{error.message}</Box>
  ) : (
    ""
  );
};

const Question = memo(
  ({
    question,
    questionIndex,
    submission,
    isSelected,
    toggle,
    result,
    onDelete = () => {},
  }) => {
    const accordionClasses = useAccordionStyles();
    const accordionSummaryClasses = useAccordionSummaryStyles();
    const inputLabelClasses = useInputLabelStyles();
    const [{ isEditing, isViewing, canSubmit, hasResult }] = useStatus();
    const [answerError] = useFieldError(`answers.${questionIndex}`);
    const [answer] = useFieldValue(`answers.${questionIndex}`);
    const {
      description,
      aggregations,
      allowMultiAnswer,
      isRequired,
      kind,
      maxValueValidation,
      minimumNumberOfValidSubmissions,
      minValueValidation,
    } = question;
    const hasDescription = Boolean(description);
    const isCategorial = kind === questionKind.CATEGORICAL;
    const isNumerical = kind === questionKind.NUMERICAL;
    const { loading: isAnswerLoading = false, hasSubmitted = false } =
      submission || {};
    const onClickStopPropagation = (event) => event.stopPropagation();
    const helperText = [
      isCategorial
        ? allowMultiAnswer
          ? "Select one or more options"
          : "Select one option"
        : isNumerical
        ? [
            "Input a number",
            typeof minValueValidation === "number"
              ? `at least ${minValueValidation}`
              : null,
            typeof maxValueValidation === "number"
              ? `at most ${maxValueValidation}`
              : null,
          ]
            .filter(Boolean)
            .join(", ")
        : null,
      !isRequired
        ? "Optional, yet in order to receive results you have to submit an answer"
        : null,
    ]
      .filter(Boolean)
      .join(" — ");
    return (
      <Accordion
        classes={accordionClasses}
        expanded={isSelected}
        elevation={0}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          classes={accordionSummaryClasses}
          expandIcon={null}
          onClick={onClickStopPropagation}
        >
          <Grid
            container
            justifyContent="center"
            spacing={1}
            onClick={onClickStopPropagation}
          >
            <Grid item xs={12}>
              <NameField
                label={
                  <span>
                    Question {questionIndex + 1} — With at least{" "}
                    {minimumNumberOfValidSubmissions || "..."} submission
                    {(minimumNumberOfValidSubmissions || 0) === 1 ? " " : "s "}
                    {isCategorial ? (
                      <strong>count per choice</strong>
                    ) : isNumerical ? (
                      Array.isArray(aggregations) && aggregations.length > 0 ? (
                        <strong>{aggregations.join(", ")}</strong>
                      ) : (
                        "..."
                      )
                    ) : null}{" "}
                    will be calculated
                  </span>
                }
                name={`questions.${questionIndex}.name`}
                startAdornment={
                  canSubmit || hasSubmitted ? (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "100%",
                        top: 0,
                        marginRight: "0.5rem",
                        height: "100%",
                        padding: "0.25rem 0",
                        lineHeight: "1.25rem",
                      }}
                    >
                      {isAnswerLoading ? (
                        <CircularProgress
                          size={16}
                          color="inherit"
                          thickness={3}
                        />
                      ) : hasResult && !result?.error ? null : (
                        <Tooltip
                          title={getQuestionTooltipByError(result?.error)}
                          placement="top"
                        >
                          <Box>
                            <FontAwesomeIcon
                              fixedWidth
                              icon={
                                hasResult
                                  ? result?.error
                                    ? faExclamationTriangle
                                    : undefined
                                  : hasSubmitted ||
                                    (!answerError &&
                                      (Array.isArray(answer?.value)
                                        ? answer.value.length > 0
                                        : Boolean(answer?.value) ||
                                          answer?.value === 0))
                                  ? faCheckCircle
                                  : faCircle
                              }
                              color={
                                hasResult
                                  ? result?.error
                                    ? orange["500"]
                                    : "inherit"
                                  : hasSubmitted
                                  ? teal["A400"]
                                  : "inherit"
                              }
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  ) : null
                }
                endAdornment={
                  <QuestionActions
                    onDelete={onDelete.bind(null, questionIndex)}
                    isSelected={isSelected}
                    toggle={toggle}
                  />
                }
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {isEditing ? (
            <Grid
              container
              justifyContent="center"
              spacing={1}
              sx={{ marginTop: 0 }}
            >
              <Grid item xs={12}>
                <DescriptionField
                  name={`questions.${questionIndex}.description`}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    color="secondary"
                    shrink
                    required
                    classes={inputLabelClasses}
                  >
                    Answer {questionIndex + 1}
                  </InputLabel>
                  <Box sx={{ pl: 1.5, pr: 1 }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ lineHeight: 1 }}
                    >
                      —
                    </Typography>
                  </Box>
                  <QuestionKindSwitch
                    question={question}
                    questionIndex={questionIndex}
                  />
                  <Box sx={{ pl: 0.5, pr: 1 }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ lineHeight: 1 }}
                    >
                      —
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "-0.25rem",
                      marginBottom: "-0.25rem",
                    }}
                  >
                    <RequiredField
                      name={`questions.${questionIndex}.isRequired`}
                    />
                  </Box>
                </Box>
              </Grid>
              {isCategorial && (
                <Grid item xs={12}>
                  <CategoriesField
                    name={`questions.${questionIndex}.submissionOptions`}
                  />
                </Grid>
              )}
              {isNumerical && (
                <>
                  <Grid item xs={12} md={2}>
                    <UnitField name={`questions.${questionIndex}.unit`} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MinValueValidationField
                      name={`questions.${questionIndex}.minValueValidation`}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MaxValueValidationField
                      name={`questions.${questionIndex}.maxValueValidation`}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <AggregationsField
                      name={`questions.${questionIndex}.aggregations`}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <MinimumNumberOfValidSubmissionsField
                  name={`questions.${questionIndex}.minimumNumberOfValidSubmissions`}
                />
              </Grid>
            </Grid>
          ) : null}
          {isViewing || canSubmit || hasSubmitted ? (
            <Grid container justifyContent="center" spacing={1}>
              {hasDescription ? (
                <Grid item xs={12}>
                  <DescriptionField
                    name={`questions.${questionIndex}.description`}
                  />
                </Grid>
              ) : null}
              {hasSubmitted ? null : (
                <Grid item xs={12}>
                  {isNumerical ? (
                    <InputField
                      name={`answers.${questionIndex}.value`}
                      question={question}
                      helperText={helperText}
                    />
                  ) : isCategorial ? (
                    <SelectField
                      name={`answers.${questionIndex}.value`}
                      question={question}
                      helperText={helperText}
                    />
                  ) : null}
                </Grid>
              )}
            </Grid>
          ) : null}
          {hasResult ? (
            <Result questionIndex={questionIndex} kind={kind} result={result} />
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default Question;
