// import { FEATURE_DARK_THEME } from "constants/index";
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { useEffect, useMemo } from "react";
// import { useFeature } from "@optimizely/react-sdk";
import AvenirBlack from "assets/fonts/Avenir-Black.woff2";
import AvenirHeavy from "assets/fonts/Avenir-Heavy.woff2";
import AvenirMedium from "assets/fonts/Avenir-Medium.woff2";
import AvenirRoman from "assets/fonts/Avenir-Roman.woff2";
import { useMain } from "contexts";

const avenirRoman = {
  fontFamily: "Avenir",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Avenir'),
    local('Avenir-Roman'),
    url(${AvenirRoman}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const avenirMedium = {
  fontFamily: "Avenir",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    local('Avenir'),
    local('Avenir-Medium'),
    url(${AvenirMedium}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const avenirHeavy = {
  fontFamily: "Avenir",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
    local('Avenir'),
    local('Avenir-Heavy'),
    url(${AvenirHeavy}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const avenirBlack = {
  fontFamily: "Avenir",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
    local('Avenir'),
    local('Avenir-Black'),
    url(${AvenirBlack}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const CssOverride = withStyles((theme) => ({
  "@global": {
    ".svg-inline--fa.fa-fw": {
      width: "1em",
    },
    ":focus": {
      outline: "none",
    },
  },
}))(() => null);

const StylesWrapper = ({ children }) => {
  // const [isDarkThemeEnabled] = useFeature(FEATURE_DARK_THEME);
  const isDarkThemeEnabled = false;
  const prefersColorSchemeDark = useMediaQuery("(prefers-color-scheme: dark)");
  const { setPrefersColorSchemeDark } = useMain();
  useEffect(() => {
    setPrefersColorSchemeDark(isDarkThemeEnabled && prefersColorSchemeDark);
  }, [setPrefersColorSchemeDark, isDarkThemeEnabled, prefersColorSchemeDark]);
  const theme = useMemo(() => {
    document
      .getElementsByTagName("body")[0]
      .classList[isDarkThemeEnabled ? "remove" : "add"]("no-dark-color-scheme");
    const theme = createTheme({});
    return createTheme({
      typography: {
        fontFamily: `"Avenir", "Helvetica Neue", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              padding: theme.spacing(0.5, 1),
              lineHeight: 1.7142857,
              textTransform: "none",
              letterSpacing: 0,
              fontWeight: 600,
            },
            text: {
              padding: theme.spacing(0.5, 1),
            },
            outlined: {
              padding: theme.spacing(0.375, 1),
              lineHeight: 1.7142857,
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              fontSize: "1rem",
              padding: theme.spacing(1),
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:hover::after": {
                content: "''",
                backgroundColor: "currentColor",
                display: "block",
                height: "100%",
                width: "100%",
                position: "absolute",
                left: 0,
                top: 0,
                borderRadius: "inherit",
                opacity: 0.125,
              },
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "0.75rem",
              fontWeight: 700,
              lineHeight: "1rem",
              maxWidth: theme.spacing(40),
              color: theme.palette.common.white,
              backgroundColor: theme.palette.common.black,
              boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            },
            tooltipPlacementTop: {
              margin: theme.spacing(0.5, 0),
              [theme.breakpoints.up("md")]: {
                margin: theme.spacing(0.5, 0),
              },
            },
            tooltipPlacementBottom: {
              margin: theme.spacing(0.5, 0),
              [theme.breakpoints.up("md")]: {
                margin: theme.spacing(0.5, 0),
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              "&:not(:first-of-type)": {
                marginTop: 1,
              },
              "&.Mui-expanded": {
                margin: 0,
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              padding: 0,
              cursor: "auto !important",
              minHeight: "auto",
              "&.Mui-expanded": {
                minHeight: "auto",
              },
              "&$focusVisible": {
                backgroundColor: "initial !important",
              },
            },
            content: {
              margin: 0,
              alignSelf: "stretch",
              flexDirection: "column",
              "&.Mui-expanded": {
                margin: 0,
              },
            },
            expandIconWrapper: {
              margin: theme.spacing(1.5, 1, 0, 0),
              alignSelf: "flex-start",
              "&.Mui-expanded": {
                transform: "rotate(90deg)",
              },
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: theme.spacing(0, 0, 0, 0),
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            docked: {
              width: "25%",
              minWidth: "480px",
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            root: {
              transition: "all 0.2s ease-in-out",
              padding: 0,
              "&:not(:hover)": {
                color: "inherit",
              },
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                color: theme.palette.text.secondary,
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                color: theme.palette.text.primary,
              },
            },
            input: {
              height: "1.1428575em",
              lineHeight: "1.1428575em",
              "&.Mui-disabled": {
                color: theme.palette.text.primary,
                textFillColor: "currentColor",
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            underline: {
              "&:before": {
                borderBottomStyle: "dotted",
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              paddingTop: "1rem",
              borderStyle: "none",
              border: "none",
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottomStyle: "solid",
                },
              },
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottomStyle: "solid",
                  borderBottomWidth: "2px",
                },
              },
            },
            notchedOutline: {
              borderStyle: "none",
              borderBottomStyle: "dotted",
              borderRadius: 0,
              borderWidth: "1px",
              borderColor: "#0000006b",
              "&:hover": {
                borderColor: theme.palette.common.black,
                borderBottomStyle: "solid",
                borderBottomWidth: "2px",
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              "&.Mui-disabled": {
                cursor: "auto",
                userSelect: "auto",
              },
            },
          },
        },
      },
      palette: {
        primary: teal,
        secondary: teal,
        mode: isDarkThemeEnabled && prefersColorSchemeDark ? "dark" : "light",
        background: {
          default:
            isDarkThemeEnabled && prefersColorSchemeDark
              ? "#303030"
              : "#ffffff",
        },
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "@font-face": [avenirRoman, avenirMedium, avenirHeavy, avenirBlack],
          },
        },
      },
    });
  }, [prefersColorSchemeDark, isDarkThemeEnabled]);
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CssOverride />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default StylesWrapper;
