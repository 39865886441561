import { FormControlLabel, makeStyles } from "@material-ui/core";
import { FastField } from "formik";
import { memo } from "react";
import FontAwesomeCheckbox from "components/base/Select/FontAwesomeCheckbox";

const useRadioboxFormControlLabelStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  label: {
    fontSize: "0.75rem",
    lineHeight: 1,
    marginLeft: 0,
  },
}));

const RequiredField = memo(({ name }) => {
  const radioboxFormControlLabelClasses = useRadioboxFormControlLabelStyles();
  return (
    <FormControlLabel
      classes={radioboxFormControlLabelClasses}
      control={
        <FastField
          name={name}
          component={FontAwesomeCheckbox}
          style={{
            fontSize: "0.875rem",
            padding: "0.25rem",
            color: "inherit",
          }}
          type="checkbox"
        />
      }
      label="Required"
      labelPlacement="end"
    />
  );
});

export default RequiredField;
