import { actionType, questionKind } from "constants/index";
import {
  faFileDownload,
  faFileUpload,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { useFieldValue } from "formik";
import { cloneDeep, pick } from "lodash";
import { memo } from "react";
import { Actions } from "components";
import {
  categoricalQuestionCommonInitialValues,
  numericalQuestionInitialValues,
} from "containers/Survey/SurveyInitialValues";

const QuestionsActions = memo(
  ({ inline = false, actions = [], filter = () => true }) => {
    const [name] = useFieldValue("name");
    const [questions, setQuestions] = useFieldValue("questions");
    const onExportQuestionsClick = () => {
      // Note: Remove lowerPercentileFilter, upperPercentileFilter,
      // and rejectionFeedback for the time being
      const content = cloneDeep(questions);
      content.map((question) => {
        if (question.kind === questionKind.NUMERICAL) {
          delete question["lowerPercentileFilter"];
          delete question["upperPercentileFilter"];
          delete question["rejectionFeedback"];
        }
        return question;
      });
      const file = new File(
        [JSON.stringify(content, null, 2)],
        `${name} — Questions.json`,
        {
          type: "application/octet-stream;charset=utf-8",
        }
      );
      saveAs(file);
    };
    const onImportQuestionsClick = (event) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".json";
      input.onchange = (event) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          try {
            // Note: Add lowerPercentileFilter, upperPercentileFilter,
            // and rejectionFeedback if those are missing
            const content = JSON.parse(event.target.result);
            const questions = content.map((question) => {
              if (question.kind === questionKind.NUMERICAL) {
                return pick(
                  {
                    ...numericalQuestionInitialValues,
                    ...question,
                  },
                  Object.keys(numericalQuestionInitialValues)
                );
              } else {
                return pick(
                  {
                    ...categoricalQuestionCommonInitialValues,
                    ...question,
                  },
                  Object.keys(categoricalQuestionCommonInitialValues)
                );
              }
            });
            setQuestions(questions);
          } catch (error) {
            console.error(error);
          }
        };
        const file = event.target.files[0];
        reader.readAsText(file);
        event.target.value = "";
      };
      input.click();
    };
    const onDeleteAllClick = () => {
      setQuestions([]);
    };
    const allActions = [
      {
        type: actionType.IMPORT_FROM_JSON,
        icon: <FontAwesomeIcon fixedWidth icon={faFileUpload} />,
        title: "Import from JSON",
        handle: onImportQuestionsClick,
        isPrimary: true,
        isEnabled: true,
      },
      {
        type: actionType.EXPORT_TO_JSON,
        icon: <FontAwesomeIcon fixedWidth icon={faFileDownload} />,
        title: "Export to JSON",
        handle: onExportQuestionsClick,
        isPrimary: true,
        isEnabled: true,
      },
      {
        type: actionType.DELETE_ALL,
        icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
        title: "Delete all",
        handle: onDeleteAllClick,
        isPrimary: true,
        isEnabled: true,
        isRed: true,
      },
    ]
      .concat(actions)
      .filter(typeof filter === "function" ? filter : () => true);
    return <Actions actions={allActions} inline={inline} />;
  }
);

export default QuestionsActions;
