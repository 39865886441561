import { surveyMode, surveyResultVisibility } from "constants/index";
import { useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Survey as EnclaveSurvey } from "@decentriq/survey";
import { DisWaConnector } from "@decentriq/survey/lib/api";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { useDebounceFn } from "ahooks";
import { isBefore, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyFormik } from "components";
import { SURVEY, UPDATE_SURVEY } from "gqls";
import { chainPromises } from "utils";
import {
  surveyAnswersValidationSchema,
  surveyValidationSchema,
} from "./SurveyValidationSchemas";

const getClient = async (email, token) => {
  const client = DisWaConnector.withUserToken(
    token,
    email,
    process.env["REACT_APP_BACKEND_HOST"],
    parseInt(process.env["REACT_APP_BACKEND_PORT"]),
    process.env["REACT_APP_USE_SSL"] === "true"
  );
  // TODO: remove verificationOptions in production
  await client.connect({
    verificationOptions: {
      acceptDebug: true,
      acceptGroupOutOfDate: true,
      acceptConfigurationNeeded: true,
    },
  });
  return client;
};

const useEnclaveSurvey = ({ survey, dataRoomHash }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, getAccessTokenSilently } = useAuth0();
  const [enclaveSurvey, setEnclaveSurvey] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchEnclaveSurvey = useCallback(async () => {
    try {
      setLoading(true);
      const { email } = user;
      const token = getAccessTokenSilently();
      const client = await getClient(email, await token);
      const enclaveSurvey = EnclaveSurvey.fromDb(survey, client);
      setEnclaveSurvey(enclaveSurvey);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar("Can't fetch survey from the enclave 😔", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, getAccessTokenSilently, survey, user]);
  useEffect(() => {
    if (dataRoomHash && survey) {
      fetchEnclaveSurvey();
    }
  }, [dataRoomHash, survey, fetchEnclaveSurvey]);
  return { enclaveSurvey, loading };
};

const useBackdropStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

const Survey = () => {
  const backdropClasses = useBackdropStyles();
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user, getAccessTokenSilently } = useAuth0();
  // Fetch survey from the database
  const {
    data,
    loading: isSurveyLoading,
    error,
  } = useQuery(SURVEY, {
    variables: {
      surveyId,
    },
    onError: () => {
      enqueueSnackbar("Can't fetch survey 😔", { variant: "error" });
    },
  });
  const { survey } = data || {};
  // Navigate away if survey wasn't found
  if (!isSurveyLoading && !survey) {
    navigate("/");
  }
  // Parse survey
  const {
    submissionEnd,
    resultsAccessedBy = "OWNER_AND_PARTICIPANTS",
    questions = [],
    dataRoomHash = null,
    isPublished = false,
    isOwner = false,
    isParticipant = false,
    surveyShareId = null,
    hasSubmitted = false,
    hasParticipants = false,
    haveAllSubmitted = false,
  } = survey || {};
  const submissionEndDate = parseISO(submissionEnd);
  // Figure out survey mode
  const mode = isPublished
    ? isParticipant
      ? !haveAllSubmitted && isBefore(new Date(), submissionEndDate)
        ? surveyMode.SUBMIT
        : surveyMode.RESULTS
      : isOwner
      ? surveyMode.RESULTS
      : undefined // So it's published, but you are not a participant or an owner? Nah, can't be
    : isOwner
    ? surveyMode.CREATE
    : undefined; // So it's not yet published, but you are not an owner? Nah, can't be
  // Navigate away if tries to edit published survey or not an owner or any other unrecognized situation
  useEffect(() => {
    if (!isSurveyLoading && mode === undefined) navigate("/");
  }, [isSurveyLoading, mode, navigate]);
  // Fetch survey from the enclave
  const { enclaveSurvey, loading: isEnclaveSurveyLoading } = useEnclaveSurvey({
    dataRoomHash,
    survey,
  });
  // Update survey mutation
  const [updateSurveyMutation] = useMutation(UPDATE_SURVEY, {
    onError: () => {
      enqueueSnackbar("Can't update survey 😒", { variant: "error" });
    },
    refetchQueries: ["surveys"],
  });
  // Update survey
  const updateSurvey = useCallback(
    (values) => {
      const patch = { ...values };
      if (patch.participants) {
        if (patch.participants.length > 0) {
          patch.surveyShares = {
            deleteOthers: true,
            create: patch.participants.map((userEmail) => ({
              userEmail,
            })),
          };
        }
        delete patch.participants;
      }
      if (patch.answers) {
        delete patch.answers;
      }
      return updateSurveyMutation({
        variables: {
          input: {
            patch,
            surveyId,
          },
        },
      });
    },
    [surveyId, updateSurveyMutation]
  );
  // Publish survey
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const publishSurvey = useCallback(async () => {
    // If not yet published and has participants,
    // then set up the enclave, get dataRoomHash, and save it
    if (!isPublished && hasParticipants) {
      setIsPublishLoading(true);
      try {
        const { email } = user;
        const token = getAccessTokenSilently();
        const client = await getClient(email, await token);
        const surveyClass = EnclaveSurvey.fromDb(survey, client, true);
        const surveyResult = await surveyClass.createSurvey();
        if (surveyResult.dataRoomHash !== undefined) {
          await updateSurveyMutation({
            variables: {
              input: {
                patch: {
                  dataRoomHash: surveyResult.dataRoomHash.toString(),
                },
                surveyId,
              },
            },
          });
        }
        return surveyResult;
      } catch (error) {
        Sentry.captureException(error);
        enqueueSnackbar("Can't publish survey 😔", { variant: "error" });
      } finally {
        setIsPublishLoading(false);
      }
    } else {
      console.error("Already published or no participants");
    }
  }, [
    enqueueSnackbar,
    getAccessTokenSilently,
    hasParticipants,
    isPublished,
    survey,
    surveyId,
    updateSurveyMutation,
    user,
  ]);
  // Submit answers
  const [submissions, setSubmissions] = useState(
    hasSubmitted ? questions.map(() => ({ hasSubmitted: true })) : []
  );
  useEffect(() => {
    setSubmissions(
      hasSubmitted ? questions.map(() => ({ hasSubmitted: true })) : []
    );
  }, [hasSubmitted, questions]);
  const submitAnswers = useCallback(
    async (values) => {
      try {
        const {
          survey: { questions },
        } = enclaveSurvey;
        const { answers } = values;
        const ingestions = [];
        setSubmissions(
          questions.map((question, index) => {
            const answer = answers[index];
            if (answer?.value?.length > 0) {
              ingestions.push(
                enclaveSurvey
                  .ingestData(question, answer)
                  .then((result) => {
                    setSubmissions((submissions) => {
                      const newSubmissions = [...submissions];
                      newSubmissions[index] = {
                        loading: false,
                        hasSubmitted: true,
                        error: null,
                      };
                      return newSubmissions;
                    });
                    return result;
                  })
                  .catch((error) => {
                    Sentry.captureException(error);
                    setSubmissions((submissions) => {
                      const newSubmissions = [...submissions];
                      newSubmissions[index] = {
                        loading: false,
                        hasSubmitted: false,
                        error,
                      };
                      return newSubmissions;
                    });
                    throw error;
                  })
              );
            }
            return {
              loading: answer?.value?.length > 0,
              hasSubmitted: false,
              error: null,
            };
          })
        );
        return await Promise.all(ingestions).then(() =>
          updateSurveyMutation({
            variables: {
              input: {
                patch: {
                  surveyShares: {
                    updateBySurveyShareId: [
                      {
                        patch: {
                          hasSubmitted: true,
                        },
                        surveyShareId,
                      },
                    ],
                  },
                },
                surveyId,
              },
            },
          })
        );
      } catch (error) {
        Sentry.captureException(error);
        enqueueSnackbar("Can't submit data 😩", { variant: "error" });
        setSubmissions((submissions) =>
          submissions.map((submission) => ({
            ...submission,
            loading: false,
            error,
          }))
        );
      }
    },
    [
      enclaveSurvey,
      enqueueSnackbar,
      surveyId,
      surveyShareId,
      updateSurveyMutation,
    ]
  );
  // Get results (only when in RESULTS mode)
  const showResults =
    resultsAccessedBy === surveyResultVisibility.ALL ||
    (resultsAccessedBy === surveyResultVisibility.OWNER && isOwner) ||
    (resultsAccessedBy === surveyResultVisibility.OWNER_AND_PARTICIPANTS &&
      (isOwner || isParticipant)) ||
    (resultsAccessedBy === surveyResultVisibility.PARTICIPANTS &&
      isParticipant);
  const [results, setResults] = useState([]);
  const getResults = useCallback(async () => {
    try {
      const survey = enclaveSurvey;
      const token = getAccessTokenSilently();
      const client = await getClient(user.email, await token);
      const surveyClass = survey.cloneNewClient(client);
      const queries = [];
      setResults(
        surveyClass.survey.questions.map((question, index) => {
          const result = { loading: true, data: null, error: null };
          queries.push({ question, index });
          return result;
        })
      );
      const queryResults = await chainPromises(
        queries,
        (v) =>
          enclaveSurvey
            .requestResult(v.question)
            .then(({ data, error }) => {
              setResults((results) => {
                const newResults = [...results];
                newResults[v.index] = {
                  loading: false,
                  data: showResults ? data : null,
                  error: error,
                };
                return newResults;
              });
            })
            .catch((error) => {
              Sentry.captureException(error);
              setResults((results) => {
                const newResults = [...results];
                newResults[v.index] = {
                  loading: false,
                  data: null,
                  error,
                };
                return newResults;
              });
              throw error;
            }),
        false
      );
      if (
        queryResults.length &&
        queryResults.every(({ isSuccess }) => !isSuccess)
      ) {
        enqueueSnackbar("Can't get results 😔", { variant: "error" });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [
    enclaveSurvey,
    enqueueSnackbar,
    getAccessTokenSilently,
    showResults,
    user.email,
  ]);
  useEffect(() => {
    if (mode === surveyMode.RESULTS && enclaveSurvey) {
      getResults();
    }
  }, [mode, enclaveSurvey, getResults]);
  // Debounced updateSurvey
  const { run: debouncedUpdateSurvey } = useDebounceFn(updateSurvey, {
    wait: 2000,
    leading: true,
    trailing: true,
  });
  // Figure out validation schema based on mode
  const validationSchema =
    mode === surveyMode.CREATE
      ? surveyValidationSchema
      : mode === surveyMode.SUBMIT
      ? surveyAnswersValidationSchema
      : undefined;
  // Figure out form actions based on mode
  const onChange =
    mode === surveyMode.CREATE ? debouncedUpdateSurvey : undefined;
  const onSubmit =
    mode === surveyMode.CREATE
      ? publishSurvey
      : mode === surveyMode.SUBMIT
      ? submitAnswers
      : undefined;
  // Render
  const loading = isSurveyLoading || isPublishLoading || isEnclaveSurveyLoading;
  return loading ? (
    <Backdrop classes={backdropClasses} open={loading} invisible>
      <CircularProgress color="inherit" thickness={1} size="2.5rem" />
    </Backdrop>
  ) : error ? null : (
    <SurveyFormik
      mode={mode}
      survey={survey}
      submissions={submissions}
      results={results}
      isOwner={isOwner}
      hasSubmitted={hasSubmitted}
      validationSchema={validationSchema}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};

export default Survey;
