import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { environment, sentryDsn } from "configs";

Sentry.init({
  dsn: sentryDsn,
  environment,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const SentryWrapper = ({ children }) => {
  return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
};

export default SentryWrapper;
