import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import { useBoolean } from "ahooks";

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const PasswordField = () => {
  const inlineIconButtonClasses = useInlineIconButtonStyles();
  const [showPassword, { toggle: toggleShowPassword }] = useBoolean(false);
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="password" color="secondary" shrink>
        Password
      </InputLabel>
      <Input
        id="password"
        placeholder="Password"
        color="secondary"
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              classes={inlineIconButtonClasses}
              TouchRippleProps={{
                center: false,
              }}
              onClick={toggleShowPassword}
              onMouseDown={(event) => event.preventDefault()}
            >
              <FontAwesomeIcon
                fixedWidth
                icon={showPassword ? faEye : faEyeSlash}
                style={{ fontSize: "1rem" }}
              />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordField;
