import {
  faExclamationTriangle,
  faLockAlt,
  faLockOpenAlt,
} from "@fortawesome/pro-light-svg-icons";
import {
  faExclamationTriangle as faExclamationTriangleRegular,
  faLockAlt as faLockAltRegular,
  faLockOpenAlt as faLockOpenAltRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Portal, Tooltip, Typography } from "@material-ui/core";
import { useBoolean } from "ahooks";
import { format, isValid, parseISO } from "date-fns";
import { memo } from "react";
import { PublishedAndProtectedDialog } from "components";

const getSurveyStatusIconAndTooltip = ({ isPublished, updatedAt, strong }) => {
  const updatedAtDate = parseISO(updatedAt);
  const formatUpdatedAt = isValid(updatedAtDate)
    ? format(updatedAtDate, "yyyy-MM-dd HH:mm:ss.SSSxxx")
    : null;
  switch (true) {
    case isPublished === false:
      return {
        status: "Draft",
        icon: strong ? faLockOpenAltRegular : faLockOpenAlt,
        tooltip: formatUpdatedAt ? `Last saved ${formatUpdatedAt}` : "",
      };
    case isPublished === true:
      return {
        status: "Published and protected",
        icon: strong ? faLockAltRegular : faLockAlt,
        tooltip: formatUpdatedAt ? `Published on ${formatUpdatedAt}` : "",
      };
    default:
      return {
        status: "Unknown",
        icon: strong ? faExclamationTriangleRegular : faExclamationTriangle,
        tooltip: formatUpdatedAt ? `Last saved ${formatUpdatedAt}` : "",
      };
  }
};

const Status = memo(({ isPublished, updatedAt, strong = false }) => {
  const { status, icon, tooltip } = getSurveyStatusIconAndTooltip({
    isPublished,
    updatedAt,
    strong,
  });
  const [
    isPublishedAndProtectedDialogOpen,
    {
      setTrue: openPublishedAndProtectedDialog,
      setFalse: closePublishedAndProtectedDialog,
    },
  ] = useBoolean(false);
  return (
    <>
      <Tooltip title={tooltip} placement={strong ? "top" : "top-start"}>
        <Button
          type="button"
          color="inherit"
          style={{
            padding: "4px",
            minWidth: "auto",
          }}
          onClick={isPublished ? openPublishedAndProtectedDialog : () => {}}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              color={strong ? "textPrimary" : "textSecondary"}
              component="div"
              style={{ lineHeight: 1 }}
            >
              <FontAwesomeIcon
                fixedWidth
                icon={icon}
                style={{ fontSize: "1rem", marginRight: "0.125em" }}
              />
            </Typography>
            <Typography
              color={strong ? "textPrimary" : "textSecondary"}
              component="div"
              style={{
                borderRadius: "2px",
                display: "inline",
                fontSize: "0.75rem",
                lineHeight: 1,
                padding: "2px",
                letterSpacing: "0.25px",
                textTransform: "uppercase",
              }}
            >
              {strong ? <strong>{status}</strong> : status}
            </Typography>
          </Box>
        </Button>
      </Tooltip>
      <Portal>
        <PublishedAndProtectedDialog
          open={isPublishedAndProtectedDialogOpen}
          onClose={closePublishedAndProtectedDialog}
        />
      </Portal>
    </>
  );
});

export default Status;
