import { Box, Container, Typography } from "@material-ui/core";
import { Main } from "layouts";

const TermsAndConditionsPage = () => {
  return (
    <Main>
      <Box sx={{ pt: 10, pb: 10 }}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Terms and conditions</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            Confidential Insights is an initiative by Swisscom (Schweiz) AG and
            dq technologies AG (hereinafter referred to as Confidential
            Insights).
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            If you access information, details and documents on the Confidential
            Insights websites (hereinafter: CI websites), you declare that you
            agree to the following conditions. The corresponding provisions also
            apply to individual services and products. In the event of a
            contradiction, the respective special provisions apply.
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            Confidential Insights reserves the right, at any time and at its own
            discretion, to change, add to or delete the content of the CI
            websites and these provisions in whole or in part at any time. This
            also applies to improvements and / or changes to the information or
            products and services described. Please check these provisions at
            regular intervals for any changes. If you continue to use the CI
            websites after changes to these terms have been posted, you will be
            deemed to have accepted the changes.
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Non-binding offer</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            The information, tariffs, price lists and the alike information
            published on the CI websites do not constitute an invitation or
            recommendation to purchase products and services or to carry out
            other transactions or to conclude any legal transaction. Exceptions
            are specially highlighted.
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            Despite all reasonable precautions, technical inaccuracies,
            omissions or typographical errors in the content of the CI websites
            cannot be ruled out. Confidential Insights cannot accept any
            liability for the correctness, completeness and topicality of the
            information, tariffs, price lists and the like published on the CI
            websites.
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Use of the CI websites</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            The entire content (text, graphics, images, etc.) of the CI websites
            is protected by copyright and, unless otherwise specified, belongs
            exclusively and comprehensively to Confidential Insights and their
            partners. This also and especially applies to the word, image and /
            or acoustic brands and logos shown on the CI websites. These
            websites may also contain references to third party property rights
            and usage rights, which must also be observed.
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            Reproduction (in full or in part), transmission (electronically or
            by other means), modification, linking or use of the information and
            services of all kinds contained in the CI websites is only permitted
            with the consent of Confident Insights.
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Data processing</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            When using the website and services, Confidential Insights collects
            and processes personal data as described in the Confidential
            Insights data protection declaration and in accordance with
            applicable data protection laws.
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Disclaimer of warranty and liability</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            Confidential Information and its partners exclude without limitation
            any liability for loss or damage of any kind that you or third
            parties may incur in connection with accessing or using the CI
            websites or parts thereof or from links to third party websites.
            Visiting the CI websites, using the information contained therein
            and using the offers listed there is at your own risk and
            responsibility.
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            Liability is also excluded for lost profit, for malfunctions of the
            Internet of all kinds (e.g. business interruption, malfunctions,
            viruses, harmful components, terrorist acts ... etc), misuse by
            third parties (viewing of data, copies, etc.) as well as loss of
            programs or other data in your information systems. This also
            applies if the possibility of such damage is indicated on the CI
            websites.
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Linked websites</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            Some links on the CI websites lead to third party websites. These
            are no longer under the influence of Confident Insights.
            Confidential Insights and its partners therefore assume no
            responsibility for the correctness, completeness and legality of the
            content contained there and the links to other websites as well as
            for any offers, products and (services) provided there. This also
            applies if these websites contain a reference to Confidential
            Insights or another protected designation. The use of linked
            websites is at your own risk.
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Applicable Law and Jurisdiction</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            gutterBottom
          >
            The public courts of Zurich are exclusively responsible for all
            disputes between you as a visitor and user of the CI websites, which
            arise from the operation or visit of the CI websites. Only Swiss law
            is applicable.
          </Typography>
        </Container>
      </Box>
    </Main>
  );
};

export default TermsAndConditionsPage;
