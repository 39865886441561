export const chainPromises = (
  payloads,
  promiseCreator,
  shouldCancelOnError = true
) =>
  new Promise((res, rej) => {
    const results = [];
    let isRejected = false;
    payloads
      .reduce(
        (acc, v, i) =>
          acc.then(() =>
            !isRejected
              ? promiseCreator(v, i)
                  .then((r) => {
                    results[i] = {
                      isSuccess: true,
                      result: r,
                    };
                  })
                  .catch((error) => {
                    results[i] = {
                      isSuccess: false,
                      result: null,
                      error,
                    };
                    if (shouldCancelOnError) {
                      isRejected = true;
                      rej(results);
                    }
                  })
              : Promise.resolve()
          ),
        Promise.resolve()
      )
      .then(() => {
        res(results);
      });
  });
