import { Container, makeStyles } from "@material-ui/core";
import { Footer, Mainbar } from "components";

const useMainStyles = makeStyles((theme) => ({
  mainContainer: {
    // height: "100%",
    // maxHeight: "100%",
    // overflow: "auto",
    // display: "flex",
    // flexWrap: "nowrap",
    // flexDirection: "column",
    background: theme.palette.background.default,
    minHeight: "100vh",
    // paddingBottom: theme.spacing(8),
    marginBottom: theme.spacing(6.25),
  },
  viewContainer: {
    // flex: 1,
    // overflow: "auto",
    // display: "flex",
    // flexWrap: "nowrap",
    // flexDirection: "column",
  },
}));

const Main = ({ children }) => {
  const mainClasses = useMainStyles();
  return (
    <Container
      maxWidth={false}
      disableGutters
      className={mainClasses.mainContainer}
    >
      <Mainbar />
      <Container
        maxWidth={false}
        disableGutters
        className={mainClasses.viewContainer}
      >
        {children}
      </Container>
      <Footer />
    </Container>
  );
};

export default Main;
