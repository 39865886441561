import { useMutation } from "@apollo/client";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { add } from "date-fns";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CREATE_SURVEY } from "gqls";

const useCircularProgressStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
}));

const NewSurveyButton = (props) => {
  const circularProgressClasses = useCircularProgressStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // Create survey mutation
  const [createSurvey, { loading: isCreateSurveyLoading }] = useMutation(
    CREATE_SURVEY,
    {
      onCompleted: (data) => {
        const { survey } = data.createSurvey;
        const { surveyId } = survey;
        navigate(`/insights/${surveyId}`);
      },
      onError: () => {
        enqueueSnackbar("Can't create new survey", { variant: "error" });
      },
      refetchQueries: ["surveys"],
    }
  );
  // Create survey
  const onIconButtonClick = () => {
    const survey = {
      name: "Untitled",
      description: "",
      questions: [],
      submissionStart: new Date(),
      submissionEnd: add(new Date(), {
        days: 1,
      }),
      surveyAccessedBy: "AUTHENTICATED",
      surveyMinimumResponses: 1,
      resultsComputedAfter: "SUBMISSION_END_REACHED",
      resultsAccessedBy: "OWNER_AND_PARTICIPANTS",
    };
    return createSurvey({
      variables: {
        input: {
          survey,
        },
      },
    });
  };
  return isCreateSurveyLoading ? (
    <CircularProgress
      color="inherit"
      thickness={2}
      size="2rem"
      classes={circularProgressClasses}
      {...props}
    />
  ) : (
    <Tooltip title="New insight" placement="bottom">
      <IconButton
        color="inherit"
        to="/insights/new"
        onClick={onIconButtonClick}
        {...props}
      >
        <FontAwesomeIcon fixedWidth icon={faPlus} />
      </IconButton>
    </Tooltip>
  );
};

export default NewSurveyButton;
