import { useMutation } from "@apollo/client";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Portal } from "@material-ui/core";
import { useBoolean } from "ahooks";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Actions, SurveyDeleteDialog } from "components";
import { DELETE_SURVEY_BY_ID } from "gqls";

const SurveyActions = memo(
  ({
    id,
    name,
    inline = true,
    filter = ({ isAvailable }) => isAvailable,
    isOwner,
  }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    // Delete
    const [
      isDeleteDialogOpen,
      { setTrue: openDeleteDialog, setFalse: closeDeleteDialog },
    ] = useBoolean(false);
    const [deleteSurveyById, { loading: isDeleteSurveyByIdLoading }] =
      useMutation(DELETE_SURVEY_BY_ID, {
        variables: {
          input: {
            id,
          },
        },
        update: (cache) => {
          cache.modify({
            fields: {
              surveys: (existingSurveys) => ({
                nodes: (existingSurveys?.nodes || []).filter(
                  (survey) => survey.id !== id
                ),
              }),
            },
          });
        },
        onCompleted: () => {
          closeDeleteDialog();
          navigate("/insights");
        },
        onError: () => {
          enqueueSnackbar("Can't delete survey 😔", { variant: "error" });
        },
        refetchQueries: ["surveys"],
      });
    const actions = [
      {
        icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
        title: "Delete",
        handle: openDeleteDialog,
        isPrimary: true,
        isEnabled: true,
        isRed: true,
        isAvailable: isOwner,
      },
    ].filter(filter);
    return (
      <Actions actions={actions} inline={inline}>
        <Portal>
          <SurveyDeleteDialog
            name={name}
            open={isDeleteDialogOpen}
            loading={isDeleteSurveyByIdLoading}
            onCancel={closeDeleteDialog}
            onConfirm={deleteSurveyById}
          />
        </Portal>
      </Actions>
    );
  }
);

export default SurveyActions;
