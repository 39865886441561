import { questionKind } from "constants/index";
import { FormControlLabel, makeStyles, RadioGroup } from "@material-ui/core";
import { useSetFieldValue } from "formik";
import { memo } from "react";
import FontAwesomeRadiobox from "components/base/Select/FontAwesomeRadiobox";
import {
  multiChoiceQuestionInitialValues,
  numericalQuestionInitialValues,
  singleChoiceQuestionInitialValues,
} from "containers/Survey/SurveyInitialValues";

const useRadioboxFormControlLabelStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  label: {
    fontSize: "0.75rem",
    lineHeight: 1,
    marginLeft: 0,
  },
}));

const QuestionKindSwitch = memo(({ question, questionIndex }) => {
  const setFieldValue = useSetFieldValue();
  const radioboxFormControlLabelClasses = useRadioboxFormControlLabelStyles();
  const getQuestionType = (questionIndex) => {
    switch (question.kind) {
      case questionKind.NUMERICAL:
        return "Number";
      case questionKind.CATEGORICAL:
        return question.allowMultiAnswer ? "Multiple choice" : "Single choice";
      default:
        return "Single choice";
    }
  };
  return (
    <RadioGroup
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "-0.25rem",
        marginBottom: "-0.25rem",
      }}
      value={getQuestionType(questionIndex)}
      onChange={(event) => {
        const { value } = event.target;
        const {
          name,
          description,
          minimumNumberOfValidSubmissions,
          isRequired,
        } = question;
        if (value === "Number") {
          setFieldValue(`questions.${questionIndex}`, {
            ...numericalQuestionInitialValues,
            name,
            description,
            minimumNumberOfValidSubmissions,
            isRequired,
          });
        } else if (value === "Single choice") {
          setFieldValue(`questions.${questionIndex}`, {
            ...singleChoiceQuestionInitialValues,
            name,
            description,
            minimumNumberOfValidSubmissions,
            isRequired,
          });
        } else if (value === "Multiple choice") {
          setFieldValue(`questions.${questionIndex}`, {
            ...multiChoiceQuestionInitialValues,
            name,
            description,
            minimumNumberOfValidSubmissions,
            isRequired,
          });
        }
      }}
    >
      <FormControlLabel
        classes={radioboxFormControlLabelClasses}
        control={
          <FontAwesomeRadiobox
            style={{
              fontSize: "0.875rem",
              padding: "0.25rem",
              color: "inherit",
            }}
          />
        }
        value="Number"
        label="Number"
        labelPlacement="end"
      />
      <FormControlLabel
        classes={radioboxFormControlLabelClasses}
        control={
          <FontAwesomeRadiobox
            style={{
              fontSize: "0.875rem",
              padding: "0.25rem",
              color: "inherit",
            }}
          />
        }
        value="Single choice"
        label="Single choice"
        labelPlacement="end"
      />
      <FormControlLabel
        classes={radioboxFormControlLabelClasses}
        control={
          <FontAwesomeRadiobox
            style={{
              fontSize: "0.875rem",
              padding: "0.25rem",
              color: "inherit",
            }}
          />
        }
        value="Multiple choice"
        label="Multiple choice"
        labelPlacement="end"
      />
    </RadioGroup>
  );
});

export default QuestionKindSwitch;
