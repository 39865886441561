"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uint8ArrayToBinaryString = exports.hmac_sha512 = exports.hkdf = exports.SmartVec = void 0;
const forge = __importStar(require("node-forge"));
class SmartVec {
    constructor(buffer) {
        this.buffer = buffer;
        this.currentOffset = 0;
    }
    static withSize(size) {
        const buffer = new Uint8Array(size);
        return new SmartVec(buffer);
    }
    extend(other) {
        const newLength = this.currentOffset + other.length;
        if (newLength > this.buffer.byteLength) {
            const oldBuffer = this.buffer;
            this.buffer = new Uint8Array(newLength * 2);
            this.buffer.set(oldBuffer);
        }
        this.buffer.set(other, this.currentOffset);
        this.currentOffset += other.length;
    }
    reset() {
        this.currentOffset = 0;
    }
    view(copy = false) {
        const unwrapped = this.buffer.subarray(0, this.currentOffset);
        if (copy) {
            return unwrapped.slice();
        }
        return unwrapped;
    }
}
exports.SmartVec = SmartVec;
function uint8ArrayToBinaryString(data) {
    let result = "";
    for (let index = 0; index < data.length; index++) {
        result += String.fromCharCode(data[index]);
    }
    return result;
}
exports.uint8ArrayToBinaryString = uint8ArrayToBinaryString;
function hmac_sha512(key, data) {
    const hmac = forge.hmac.create();
    hmac.start("sha512", forge.util.binary.raw.encode(key));
    hmac.update(forge.util.binary.raw.encode(data));
    return forge.util.binary.raw.decode(hmac.digest().bytes());
}
exports.hmac_sha512 = hmac_sha512;
function hkdf(len, inputKeyMaterial, salt, info) {
    if (salt.length == 0) {
        salt = new Uint8Array(64);
    }
    const prk = hmac_sha512(salt, inputKeyMaterial);
    let t = new Uint8Array();
    let okm = new Uint8Array();
    for (let i = 0; i < Math.ceil(len / 64); i++) {
        const m = new Uint8Array([i + 1]);
        t = hmac_sha512(prk, new Uint8Array([...t, ...info, ...m]));
        okm = new Uint8Array([...okm, ...t]);
    }
    return okm.slice(0, len);
}
exports.hkdf = hkdf;
