import { useFormikContext } from "formik";
import { memo, useEffect } from "react";

const SurveyFormAutoSave = memo(({ onChange }) => {
  const formik = useFormikContext();
  const { isValidating, isValid, values } = formik;
  useEffect(() => {
    if (!isValidating && isValid) {
      onChange(values);
    }
  }, [isValidating, isValid, values, onChange]);
  return null;
});

export default SurveyFormAutoSave;
