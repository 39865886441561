import { Surveys } from "components";
import { Data } from "containers";
import { SURVEYS } from "gqls";

const SurveysContainer = ({
  filterBy = ["name"],
  dataIdKey = "surveyId",
  dataKey = "surveys",
  query = SURVEYS,
  variables = {
    orderBy: ["UPDATED_AT_DESC"],
  },
  ...rest
}) => (
  <Data
    DataView={Surveys}
    filterBy={filterBy}
    dataIdKey={dataIdKey}
    dataKey={dataKey}
    query={query}
    variables={variables}
    {...rest}
  />
);

export default SurveysContainer;
