import { actionType } from "constants/index";
import { faCompressAlt, faExpandAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, InputLabel, makeStyles } from "@material-ui/core";
import { useStatus } from "formik";
import { memo, useCallback } from "react";
import { QuestionsActions } from "components";

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const QuestionsLabelAndActions = memo(
  ({ questions, allSelected, toggleAll }) => {
    const [{ isEditing, isOwner }] = useStatus();
    const inputLabelClasses = useInputLabelStyles();
    const actionsFilter = useCallback(
      (action) =>
        isEditing
          ? true
          : [
              actionType.TOGGLE_ALL,
              ...(isOwner ? [actionType.EXPORT_TO_JSON] : []),
            ].includes(action.type),
      [isEditing, isOwner]
    );

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "2rem",
          pr: 8,
          pl: 8,
        }}
      >
        <InputLabel
          color="secondary"
          shrink
          required={isEditing}
          classes={inputLabelClasses}
        >
          Questions ({questions.length})
        </InputLabel>
        <Box sx={{ mt: -0.5, mb: -0.5 }}>
          <QuestionsActions
            inline
            actions={[
              {
                type: actionType.TOGGLE_ALL,
                icon: (
                  <FontAwesomeIcon
                    fixedWidth
                    icon={allSelected ? faCompressAlt : faExpandAlt}
                  />
                ),
                title: allSelected ? "Collapse all" : "Expand all",
                handle: toggleAll,
                isPrimary: true,
                isEnabled: true,
                isRed: false,
              },
            ]}
            filter={actionsFilter}
          />
        </Box>
      </Box>
    );
  }
);

export default QuestionsLabelAndActions;
