import { faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FastField } from "formik";
import { memo } from "react";

const useSelectIconStyles = makeStyles({
  selectIcon: {
    margin: "0.375rem 0.25rem",
    position: "absolute",
    top: 0,
    right: 0,
    pointerEvents: "none",
  },
});

const SelectIcon = memo(() => {
  const selectIconClasses = useSelectIconStyles();
  return (
    <FontAwesomeIcon
      fixedWidth
      icon={faCaretDown}
      className={selectIconClasses.selectIcon}
    />
  );
});

const useSelectStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  select: {
    padding: theme.spacing(0.5, 0),
    paddingBottom: "3px",
    minHeight: "1.25rem !important",
    lineHeight: "1.25rem !important",
    backgroundColor: "transparent !important",
  },
}));

const useFormHelperTextStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    lineHeight: "1rem",
    marginTop: "0.25rem",
  },
}));

const SelectField = memo(({ name, helperText, question, ...props }) => {
  const selectClasses = useSelectStyles();
  const formHelperTextClasses = useFormHelperTextStyles();
  const { allowMultiAnswer, submissionOptions } = question || {};
  return (
    <FastField name={name}>
      {({ field: { value, ...field }, meta: { touched, error } }) => (
        <FormControl fullWidth>
          <Select
            color="secondary"
            classes={selectClasses}
            IconComponent={SelectIcon}
            multiple={allowMultiAnswer}
            value={value || (allowMultiAnswer ? [] : "")}
            error={touched && Boolean(error)}
            {...props}
            {...field}
          >
            {submissionOptions.map((submissionOption, index) => (
              <MenuItem key={index} value={submissionOption}>
                {submissionOption}
              </MenuItem>
            ))}
          </Select>
          {touched && !error && value?.length > 0 ? null : (
            <FormHelperText
              error={touched && Boolean(error)}
              classes={formHelperTextClasses}
            >
              {touched && Boolean(error) ? error : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
});

export default SelectField;
