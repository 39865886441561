"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionPool = exports.Session = void 0;
const api_1 = require("./api");
const authentication_1 = require("./authentication");
const utils_1 = require("./utils");
const protos_1 = require("../lib/proto/protos");
const forge = __importStar(require("node-forge"));
const tweetnacl_1 = require("tweetnacl");
const sgx_ias_verifier_1 = require("sgx-ias-verifier");
const minimal_1 = require("protobufjs/minimal");
class SessionPool {
    constructor(pool) {
        this.pool = pool;
    }
    use(cb) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.pool.use(cb);
        });
    }
}
exports.SessionPool = SessionPool;
class Session {
    constructor(client, sessionId, enclaveIdentifier, auth, fatquote, quote) {
        this.client = client;
        this.sessionId = sessionId;
        this.enclaveIdentifier = enclaveIdentifier;
        this.auth = auth;
        this.keypair = tweetnacl_1.box.keyPair();
        this.fatquote = fatquote;
        this.quote = quote;
    }
    static create(client, sessionId, enclaveIdentifier, auth, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = api_1.Endpoints.SESSION_FATQUOTE.replace(":sessionId", sessionId);
            const resBody = (yield client.api.get(url)).data;
            const signatureLen = resBody.signature.data.length;
            const signature = new Uint8Array(signatureLen);
            for (let i = 0; i < signatureLen; i += 1) {
                signature[i] = resBody.signature.data[i] & 255;
            }
            const fatquote = {
                certificate: resBody.certificate,
                response: resBody.response,
                signature,
            };
            const verificationOptions = options.verificationOptions;
            const iasVerfication = new sgx_ias_verifier_1.Verification()
                .withExpectedMeasurement(enclaveIdentifier)
                .withAcceptDebug(verificationOptions.acceptDebug)
                .withAcceptConfigurationNeeded(verificationOptions.acceptConfigurationNeeded)
                .withAcceptGroupOutOfDate(verificationOptions.acceptGroupOutOfDate);
            const quote = iasVerfication.verify(fatquote.certificate, fatquote.response, fatquote.signature);
            return new Session(client, sessionId, enclaveIdentifier, auth, fatquote, quote);
        });
    }
    getSqlQueryResponsesRaw(request, auth) {
        return __awaiter(this, void 0, void 0, function* () {
            const responses = yield this.sendAndParseMessageManyResponses(request, auth);
            return responses.map((resp) => {
                if (resp.waterfrontResponse !== "sqlQueryResponse" ||
                    resp.sqlQueryResponse === null ||
                    resp.sqlQueryResponse === undefined) {
                    throw new Error(`Request failed, got ${resp}`);
                }
                return resp.sqlQueryResponse;
            });
        });
    }
    concatenateSqlQueryResponses(responses) {
        if (responses.length == 1) {
            return responses[0];
        }
        const size = responses.reduce((size, response) => {
            if (Object.prototype.hasOwnProperty.call(response, "finished")) {
                return size + response.finished.data.byteLength;
            }
            else {
                throw new Error(`Expected finished sql response`);
            }
        }, 0);
        let offset = 0;
        const final_response = protos_1.waterfront.SqlQueryResponse.create({
            finished: protos_1.waterfront.SqlQueryFinished.create({
                data: new Uint8Array(size),
                header: []
            })
        });
        for (const response of responses) {
            final_response.finished.data.set(response.finished.data, offset);
            offset += response.finished.data.byteLength;
            final_response.finished.header = response.finished.header;
        }
        return final_response;
    }
    makeSqlQuery(dataRoomHash, queryName, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [roleName, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                sqlQueryRequest: {
                    queryName,
                    dataRoomHash,
                    auth: {
                        role: roleName,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const responses = yield this.getSqlQueryResponsesRaw(req, auth);
            return this.concatenateSqlQueryResponses(responses);
        });
    }
    getJobStatus(jobId, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [roleName, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                jobStatusRequest: {
                    jobId,
                    auth: {
                        role: roleName,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "jobStatusResponse" ||
                response.jobStatusResponse === null ||
                response.jobStatusResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            return response.jobStatusResponse;
        });
    }
    getQueryResults(jobId, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [roleName, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                getResultsRequest: {
                    jobId,
                    auth: {
                        role: roleName,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const responses = yield this.getSqlQueryResponsesRaw(req, auth);
            return this.concatenateSqlQueryResponses(responses).finished;
        });
    }
    createDataRoom(dataRoom, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                createDataRoomRequest: {
                    dataRoom,
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "createDataRoomResponse" ||
                response.createDataRoomResponse === null ||
                response.createDataRoomResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            return response.createDataRoomResponse;
        });
    }
    retrieveDataRoom(dataRoomHash, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [roleName, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                retrieveDataRoomRequest: {
                    dataRoomHash,
                    auth: {
                        role: roleName,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "retrieveDataRoomResponse" ||
                response.retrieveDataRoomResponse === null ||
                response.retrieveDataRoomResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            return response.retrieveDataRoomResponse.dataRoom;
        });
    }
    publishDatasetToDataRoom(manifestHash, dataRoomHash, dataRoomTableName, key, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [roleName, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                publishDatasetToDataRoomRequest: {
                    manifestHash,
                    dataRoomHash,
                    dataRoomTableName,
                    encryptionKey: {
                        material: key.material,
                        salt: key.salt,
                    },
                    auth: {
                        role: roleName,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "publishDatasetToDataRoomResponse" ||
                response.publishDatasetToDataRoomResponse === null ||
                response.publishDatasetToDataRoomResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
        });
    }
    retrieveAuditLog(dataRoomHash, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [roleName, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                retrieveAuditLogRequest: {
                    dataRoomHash,
                    auth: {
                        role: roleName,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "retrieveAuditLogResponse" ||
                response.retrieveAuditLogResponse === null ||
                response.retrieveAuditLogResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            if (response.retrieveAuditLogResponse.data === null) {
                return null;
            }
            else {
                return new TextDecoder("utf-8").decode(response.retrieveAuditLogResponse.data);
            }
        });
    }
    validateDataset(manifestHash, key, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                validateDatasetRequest: {
                    manifestHash,
                    encryptionKey: {
                        material: key.material,
                        salt: key.salt,
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "validateDatasetResponse" ||
                response.validateDatasetResponse === null ||
                response.validateDatasetResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            return response.validateDatasetResponse;
        });
    }
    retrieveDataRoomStatus(dataRoomHash, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [role_name, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                retrieveDataRoomStatusRequest: {
                    dataRoomHash,
                    auth: {
                        role: role_name,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "retrieveDataRoomStatusResponse" ||
                response.retrieveDataRoomStatusResponse === null ||
                response.retrieveDataRoomStatusResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            return response.retrieveDataRoomStatusResponse;
        });
    }
    updateDataRoomStatus(dataRoomHash, status, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [role_name, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                updateDataRoomStatusRequest: {
                    dataRoomHash,
                    auth: {
                        role: role_name,
                        passwordSha256: auth.getAccessToken(),
                    },
                    status,
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "updateDataRoomStatusResponse" ||
                response.updateDataRoomStatusResponse === null ||
                response.updateDataRoomStatusResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            return response.updateDataRoomStatusResponse;
        });
    }
    retrieveProvisionedDatasets(dataRoomHash, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [role_name, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                retrievePublishedDatasetRequest: {
                    dataRoomHash,
                    auth: {
                        role: role_name,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "retrievePublishedDatasetResponse" ||
                response.retrievePublishedDatasetResponse === null ||
                response.retrievePublishedDatasetResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
            return response.retrievePublishedDatasetResponse;
        });
    }
    removePublishedDataset(manifestHash, dataRoomHash, dataRoomTableName, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const [roleName, auth] = this.getAuthForRole(role);
            const req = protos_1.waterfront.WaterfrontRequest.create({
                removePublishedDatasetRequest: {
                    manifestHash,
                    dataRoomHash,
                    dataRoomTableName,
                    auth: {
                        role: roleName,
                        passwordSha256: auth.getAccessToken(),
                    },
                },
            });
            const response = yield this.sendAndParseMessage(req, auth);
            if (response.waterfrontResponse !== "removePublishedDatasetResponse" ||
                response.removePublishedDatasetResponse === null ||
                response.removePublishedDatasetResponse === undefined) {
                throw new Error(`Request failed, got ${response}`);
            }
        });
    }
    encryptAndEncode(data, auth) {
        const nonce = tweetnacl_1.randomBytes(tweetnacl_1.box.nonceLength);
        const encrypted = tweetnacl_1.box(data, nonce, this.getEnclavePubkey(), this.keypair.secretKey);
        const publicKeys = new Uint8Array([
            ...this.keypair.publicKey,
            ...this.getEnclavePubkey(),
        ]);
        const signature = auth.sign(publicKeys);
        const sharedKey = tweetnacl_1.scalarMult(this.keypair.secretKey, this.getEnclavePubkey());
        const macKey = utils_1.hkdf(64, sharedKey, new Uint8Array(0), forge.util.binary.raw.decode("IdP KDF Context"));
        const macTag = utils_1.hmac_sha512(macKey, new TextEncoder().encode(auth.getUserId()));
        const sigmaAuth = new authentication_1.Sigma(signature, macTag, auth);
        return dataNoncePubkeyToMessage(encrypted, nonce, this.keypair.publicKey, sigmaAuth);
    }
    decodeAndDecrypt(data) {
        const dataNoncePubkey = messageToDataNoncePubkey(data);
        const decrypted = tweetnacl_1.box.open(dataNoncePubkey.data, dataNoncePubkey.nonce, this.getEnclavePubkey(), this.keypair.secretKey);
        return decrypted;
    }
    sendAndParseMessageManyResponses(message, auth) {
        return __awaiter(this, void 0, void 0, function* () {
            const responseSerialized = yield this.sendMessageRaw(protos_1.waterfront.WaterfrontRequest.encodeDelimited(message).finish(), auth);
            const reader = minimal_1.Reader.create(responseSerialized);
            const responses = [];
            while (reader.pos < reader.len) {
                const responseContainer = protos_1.delta_enclave_api.Response.decodeDelimited(reader);
                if (responseContainer.response === "unsuccessfulResponse") {
                    throw new Error(`${responseContainer.unsuccessfulResponse}`);
                }
                const successfulResponse = responseContainer.successfulResponse;
                const result = this.decodeAndDecrypt(successfulResponse);
                if (result == null) {
                    throw new Error("Response decryption failed");
                }
                const response = protos_1.waterfront.WaterfrontResponse.decodeDelimited(result);
                if (response.failure) {
                    throw new Error(`${response.failure}`);
                }
                responses.push(response);
            }
            return responses;
        });
    }
    sendAndParseMessage(message, auth) {
        return __awaiter(this, void 0, void 0, function* () {
            const responseSerialized = yield this.sendMessage(protos_1.waterfront.WaterfrontRequest.encodeDelimited(message).finish(), auth);
            const response = protos_1.waterfront.WaterfrontResponse.decodeDelimited(responseSerialized);
            if (response.waterfrontResponse === "failure") {
                throw new Error(`${response.failure}`);
            }
            return response;
        });
    }
    sendMessageRaw(data, auth) {
        return __awaiter(this, void 0, void 0, function* () {
            const encrypted = this.encryptAndEncode(data, auth);
            const request = protos_1.delta_enclave_api.Request.create({
                avatoRequest: encrypted,
            });
            const serializedRequest = protos_1.delta_enclave_api.Request.encodeDelimited(request).finish();
            const enclaveMessage = {
                data: forge.util.binary.base64.encode(serializedRequest),
            };
            const url = api_1.Endpoints.SESSION_MESSAGES.replace(":sessionId", this.sessionId);
            const resBody = (yield this.client.api.post(url, enclaveMessage)).data;
            const uint8ResBody = forge.util.binary.base64.decode(resBody.data);
            return uint8ResBody;
        });
    }
    sendMessage(data, auth) {
        return __awaiter(this, void 0, void 0, function* () {
            const uint8ResBody = yield this.sendMessageRaw(data, auth);
            const responseContainer = protos_1.delta_enclave_api.Response.decodeDelimited(uint8ResBody);
            if (responseContainer.response === "unsuccessfulResponse") {
                throw new Error(`${responseContainer.unsuccessfulResponse}`);
            }
            const response = responseContainer.successfulResponse;
            const result = this.decodeAndDecrypt(response);
            if (result == null) {
                throw new Error("Response decryption failed");
            }
            return result;
        });
    }
    getAuthForRole(role) {
        const keys = Object.keys(this.auth);
        if (keys.length === 0) {
            throw new Error("No auth objects");
        }
        if (role === undefined) {
            if (keys.length > 1) {
                throw new Error("No role specified but multiple auths");
            }
            else {
                return [keys[0], this.auth[keys[0]]];
            }
        }
        else {
            if (Object.prototype.hasOwnProperty.call(this.auth, role)) {
                return [role, this.auth[role]];
            }
            else {
                throw new Error(`No auth found for specified role "${role}" in [${keys}]`);
            }
        }
    }
    getEnclavePubkey() {
        // The first 256 bits in the quote report data correspond
        // to the randomly generated enclave public key
        const publicKey = this.quote.reportData.slice(0, 32);
        return publicKey;
    }
}
exports.Session = Session;
function dataNoncePubkeyToMessage(encryptedData, nonce, pubkey, sigmaAuth) {
    const message = protos_1.delta_enclave_api.DataNoncePubkey.create({
        data: encryptedData,
        nonce: nonce,
        pubkey: pubkey,
        auth: {
            pki: {
                certChain: sigmaAuth.getCertChain(),
                signature: sigmaAuth.getSignature(),
                idMac: sigmaAuth.getMacTag(),
            },
        },
    });
    return protos_1.delta_enclave_api.DataNoncePubkey.encodeDelimited(message).finish();
}
function messageToDataNoncePubkey(bytes) {
    const message = protos_1.delta_enclave_api.DataNoncePubkey.decodeDelimited(bytes);
    return {
        data: message.data,
        nonce: message.nonce,
        pubkey: message.pubkey,
    };
}
