import { Survey } from "containers";
import { Main } from "layouts";

const SurveyPage = () => {
  return (
    <Main>
      <Survey />
    </Main>
  );
};

export default SurveyPage;
