import { gql } from "@apollo/client";
import { SURVEY_EVERYTHING } from "gqls";

export const CREATE_SURVEY = gql`
  mutation createSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      clientMutationId
      survey {
        ...SurveyEverything
      }
    }
  }
  ${SURVEY_EVERYTHING}
`;
