import { Box, Link, makeStyles } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

const useMainStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 2.5),
    height: theme.spacing(6.26),
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: darken(theme.palette.background.default, 0.05),
    zIndex: -1,
  },
  footerBlock: {
    display: "flex",
  },
  footerLink: {
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    "&:hover": {
      textDecoration: "#1de9b6 wavy underline",
      textDecorationSkipInk: "none",
    },
  },
}));

const Footer = () => {
  const mainClasses = useMainStyles();
  return (
    <Box className={mainClasses.footer}>
      <Box className={mainClasses.footerBlock}>
        {/* TODO: Jump to the start of the page after navigation */}
        <Link
          component={RouterLink}
          to="/about"
          color="inherit"
          className={mainClasses.footerLink}
        >
          <strong>About</strong>
        </Link>
        <Link
          component={RouterLink}
          to="/faq"
          color="inherit"
          className={mainClasses.footerLink}
        >
          <strong>FAQ</strong>
        </Link>
        <Link
          component={RouterLink}
          to="/terms-and-conditions"
          color="inherit"
          className={mainClasses.footerLink}
        >
          <strong>Terms and conditions</strong>
        </Link>
        <Link
          component={RouterLink}
          to="/data-privacy-policy"
          color="inherit"
          className={mainClasses.footerLink}
        >
          <strong>Data privacy policy</strong>
        </Link>
        <Box>Copyright © 2021 Confidential Insights. All rights reserved.</Box>
      </Box>
    </Box>
  );
};

export default Footer;
