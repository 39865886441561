import { questionKind } from "constants/index";
import {
  Box,
  Grid,
  makeStyles,
  Skeleton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import anychart from "anychart";
import AnyChart from "anychart-react";
import { memo, useEffect, useState } from "react";
import LogoImage from "assets/logos/confidential-insights-logo.svg";

const CategorialPie = ({ questionIndex, data }) => {
  const [credits, setCredits] = useState({ enabled: false });
  useEffect(() => {
    window.anychart.licenseKey("confidential-insights-5c68c344-cd11c3f5");
    setCredits({
      enabled: false,
      url: "https://confidentialinsights.com/",
      logoSrc: LogoImage,
      text: "Confidential Insights",
      alt: "Confidential Insights",
    });
  }, []);
  return data.map(({ resultValue }, index) => (
    <AnyChart
      key={index}
      id={`question-${questionIndex}`}
      credits={credits}
      data={resultValue.map(({ category: x, value }) => ({
        x,
        value,
      }))}
      type="pie"
      innerRadius="30%"
      width="100%"
      height={320}
      background={{ fill: "transparent" }}
      palette={anychart.palettes["turquoise"]}
    />
  ));
};

const truncateResultValue = (value) => {
  const truncateLimit = 3;
  if (Number.isInteger(value) || value < 0.01) {
    return {
      withTooltip: false,
      value,
      fullValue: value,
    };
  }
  const parts = value.toString().split(".");
  const base = parts[0];
  const decimals = parts[1];
  const truncatedDecimals =
    decimals.length > truncateLimit
      ? decimals.substring(0, truncateLimit)
      : decimals;
  return {
    withTooltip: decimals.length > truncatedDecimals.length,
    value: `${base}.${truncatedDecimals}`,
    fullValue: value,
  };
};

const AggregatesGrid = ({ data }) => {
  return (
    <Grid container justifyContent="center" spacing={4}>
      {data.map(
        ({ aggregation, resultValue: { value: originalValue } }, index) => {
          const { fullValue, value, withTooltip } =
            truncateResultValue(originalValue);
          return (
            <Grid item key={index}>
              <Typography
                variant="h4"
                component="div"
                style={{
                  lineHeight: 1,
                  letterSpacing: -1,
                  color: "rgb(0, 131, 143)",
                }}
              >
                {withTooltip ? (
                  <Tooltip title={fullValue}>
                    <strong>{value}</strong>
                  </Tooltip>
                ) : (
                  <strong>{value}</strong>
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                color="textSecondary"
                style={{ lineHeight: 1 }}
              >
                {aggregation}
              </Typography>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

const useSkeletonStyles = makeStyles((theme) => ({
  rectangular: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const QuestionResultLoading = memo(({ kind }) => {
  const skeletonClasses = useSkeletonStyles();
  return (
    <Box>
      {kind === questionKind.CATEGORICAL && (
        <Box
          sx={{
            mt: 2,
            mb: 2,
            height: 320,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            classes={skeletonClasses}
            variant="circular"
            height={243}
            width={243}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {[...Array(Math.floor(Math.random() * (6 - 2 + 1) + 2)).keys()].map(
              (index) => (
                <Box key={index} sx={{ pl: 1, pr: 1, pt: 1 }}>
                  <Skeleton
                    classes={skeletonClasses}
                    variant="rectangular"
                    height={15}
                    width={Math.floor(Math.random() * (100 - 30 + 1) + 30)}
                  />
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
      {kind === questionKind.NUMERICAL && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container justifyContent="center" spacing={4}>
            {[...Array(Math.floor(Math.random() * (5 - 2 + 1) + 2)).keys()].map(
              (index) => (
                <Grid item key={index}>
                  <Box>
                    <Skeleton
                      classes={skeletonClasses}
                      variant="rectangular"
                      height={32}
                      width={Math.floor(Math.random() * (100 - 60 + 1) + 60)}
                    />
                  </Box>
                  <Box sx={{ marginTop: "2px" }}>
                    <Skeleton
                      classes={skeletonClasses}
                      variant="rectangular"
                      height={14}
                      width={Math.floor(Math.random() * (100 - 30 + 1) + 30)}
                    />
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
});

const Result = memo(({ questionIndex, kind, result }) => {
  const { loading = false, data = null, error = null } = result || {};
  return loading ? (
    <QuestionResultLoading kind={kind} />
  ) : error ? null : data ? (
    <Box sx={{ mt: 2, mb: 2 }}>
      {kind === questionKind.CATEGORICAL && (
        <CategorialPie questionIndex={questionIndex} data={data} />
      )}
      {kind === questionKind.NUMERICAL && (
        <AggregatesGrid questionIndex={questionIndex} data={data} />
      )}
    </Box>
  ) : null;
});

export default Result;
