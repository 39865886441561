import { gql } from "@apollo/client";
import { SURVEY_EVERYTHING } from "gqls";

export const SURVEYS = gql`
  query surveys(
    $filter: SurveyFilter
    $first: Int
    $offset: Int
    $orderBy: [SurveysOrderBy!]
  ) {
    surveys(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      totalCount
      nodes {
        ...SurveyEverything
      }
    }
  }
  ${SURVEY_EVERYTHING}
`;
