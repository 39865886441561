import { surveyComputing } from "constants/index";
import { faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FastField, useStatus } from "formik";
import { memo } from "react";

const useSelectIconStyles = makeStyles({
  selectIcon: {
    margin: "0.375rem 0.25rem",
    position: "absolute",
    top: 0,
    right: 0,
    pointerEvents: "none",
  },
});

const SelectIcon = memo(() => {
  const selectIconClasses = useSelectIconStyles();
  return (
    <FontAwesomeIcon
      fixedWidth
      icon={faCaretDown}
      className={selectIconClasses.selectIcon}
    />
  );
});

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const ResultsComputedAfterField = () => {
  const [{ isEditing }] = useStatus();
  const inputLabelClasses = useInputLabelStyles();
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="results-computed-after"
        color="secondary"
        shrink
        required
        disabled={!isEditing}
        classes={inputLabelClasses}
      >
        Process responses and results
      </InputLabel>
      <FastField name={`resultsComputedAfter`}>
        {({ field }) => (
          <Select
            color="secondary"
            IconComponent={SelectIcon}
            SelectDisplayProps={{ style: { minHeight: "1.1428575em" } }}
            disableUnderline={!isEditing}
            readOnly={!isEditing}
            disabled={!isEditing}
            {...field}
          >
            <MenuItem value={surveyComputing.SURVEY_MINIMUM_RESPONSES_REACHED}>
              When minimum submissions is reached
            </MenuItem>
            <MenuItem value={surveyComputing.SUBMISSION_END_REACHED}>
              When insight date expire
            </MenuItem>
          </Select>
        )}
      </FastField>
    </FormControl>
  );
};

export default ResultsComputedAfterField;
