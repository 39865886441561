import { gql } from "@apollo/client";
import { SURVEY_EVERYTHING } from "gqls";

export const SURVEY = gql`
  query survey($surveyId: UUID!) {
    survey(surveyId: $surveyId) {
      ...SurveyEverything
    }
  }
  ${SURVEY_EVERYTHING}
`;
