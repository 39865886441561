import { DEFAULT_REFERER } from "constants/index";
import { useAuth0 } from "@auth0/auth0-react";
import { faGripHorizontal, faSignOut } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  makeStyles,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ReactComponent as ConfidentialInsightsLogo } from "assets/logos/confidential-insights-logo.svg";
import { AccountMenu, NewSurveyButton } from "components";

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    margin: theme.spacing(2, 2.5),
    minHeight: "auto",
  },
  menuLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      "& > *:first-child": {
        fontSize: "1rem",
      },
    },
  },
}));

const Mainbar = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const toolbarClasses = useToolbarStyles();
  const buttonClasses = useButtonStyles();
  const location = useLocation();
  const authButtonClick = () =>
    loginWithRedirect({
      appState: { referer: location || DEFAULT_REFERER },
    });
  return (
    <AppBar color="transparent" position="static" elevation={0}>
      <Toolbar disableGutters className={toolbarClasses.root}>
        <Box className={toolbarClasses.menuLeft}>
          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            style={{ display: "flex", textDecoration: "none" }}
          >
            <ConfidentialInsightsLogo style={{ height: "2rem" }} />
          </Link>
        </Box>
        <Box>
          {isLoading ? null : isAuthenticated ? (
            <Box sx={{ display: "flex" }}>
              <Tooltip title="All insights" placement="bottom">
                <IconButton
                  color="inherit"
                  component={Link}
                  href="/insights"
                  style={{ marginRight: "0.5rem" }}
                >
                  <FontAwesomeIcon fixedWidth icon={faGripHorizontal} />
                </IconButton>
              </Tooltip>
              <NewSurveyButton style={{ marginRight: "0.5rem" }} />
              <AccountMenu />
            </Box>
          ) : (
            <Box>
              <Button
                classes={buttonClasses}
                color="inherit"
                variant="text"
                onClick={authButtonClick}
                startIcon={<FontAwesomeIcon fixedWidth icon={faSignOut} />}
              >
                Sign in
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Mainbar;
