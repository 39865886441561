import {
  ApolloWrapper,
  Auth0Wrapper,
  MainWrapper,
  OptimizelyWrapper,
  PagesWrapper,
  RouterWrapper,
  SentryWrapper,
  SnackbarWrapper,
  StylesWrapper,
  WorkersWrapper,
} from "wrappers";

const App = () => {
  return (
    <SentryWrapper>
      <RouterWrapper>
        <MainWrapper>
          <Auth0Wrapper>
            <OptimizelyWrapper>
              <ApolloWrapper>
                <WorkersWrapper>
                  <StylesWrapper>
                    <SnackbarWrapper>
                      <PagesWrapper />
                    </SnackbarWrapper>
                  </StylesWrapper>
                </WorkersWrapper>
              </ApolloWrapper>
            </OptimizelyWrapper>
          </Auth0Wrapper>
        </MainWrapper>
      </RouterWrapper>
    </SentryWrapper>
  );
};

export default App;
