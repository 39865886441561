import { useAuth0 } from "@auth0/auth0-react";
import {
  faCheckCircle,
  faQuestionCircle,
  faUserCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  CardContent,
  Link,
  makeStyles,
  Skeleton,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Status, SurveyActions } from "components";

const useCardClasses = makeStyles((theme) => ({
  root: {
    border: "1px solid transparent",
    transitionProperty: "all",
    "&:not(:hover)": {
      boxShadow: "none",
      border: "1px solid rgba(0,0,0,0.12)",
    },
  },
}));

const useLinkClasses = makeStyles((theme) => ({
  root: {
    transition: "all 0.2s ease-in-out",
    "&:not(:hover)": {
      color: "inherit",
    },
  },
}));

const useSkeletonStyles = makeStyles((theme) => ({
  rectangular: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const SurveyCard = ({ loading = false, survey = {} }) => {
  const cardClasses = useCardClasses();
  const linkClasses = useLinkClasses();
  const skeletonClasses = useSkeletonStyles();
  const {
    user: { email: currentUserEmail },
  } = useAuth0();

  const {
    id,
    surveyId,
    name,
    description,
    questions,
    // submissionStart,
    // submissionEnd,
    // surveyAccessedBy,
    // surveyMinimumResponses,
    // resultsComputedAfter,
    // resultsAccessedBy,
    isPublished,
    updatedAt,
    // createdAt,
    surveyShares,
    ownerEmail,
  } = survey || {};
  const isOwner = ownerEmail?.toLowerCase() === currentUserEmail?.toLowerCase();

  const submissionsTotalCount =
    surveyShares?.nodes?.filter(({ hasSubmitted }) => hasSubmitted).length || 0;
  return loading ? (
    <Card elevation={12} classes={cardClasses}>
      <CardContent>
        <Box sx={{ marginBottom: "5.6px" }}>
          <Skeleton
            classes={skeletonClasses}
            variant="rectangular"
            height={25.25}
            width="100%"
          />
        </Box>
        <Box sx={{ marginBottom: "8.4px" }}>
          <Skeleton
            classes={skeletonClasses}
            variant="rectangular"
            height={32}
            width="100%"
          />
        </Box>
        <Box sx={{ marginTop: "4.9px" }}>
          <Skeleton
            classes={skeletonClasses}
            variant="rectangular"
            height={20}
            width="100%"
          />
        </Box>
      </CardContent>
    </Card>
  ) : (
    <Card elevation={12} classes={cardClasses}>
      <CardContent>
        <Box sx={{ marginBottom: "0.35em" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Status isPublished={isPublished} updatedAt={updatedAt} />
            <SurveyActions name={name} inline id={id} isOwner={isOwner} />
          </Box>
        </Box>
        <Typography variant="h5" component="h1" gutterBottom>
          <Link
            component={RouterLink}
            color="secondary"
            classes={linkClasses}
            to={`/insights/${surveyId}`}
            underline="none"
            style={{
              fontWeight: 900,
              letterSpacing: "-0.5px",
            }}
          >
            {name}
          </Link>
        </Typography>
        <Typography variant="subtitle2" component="div" gutterBottom>
          {description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            color="textSecondary"
            variant="body2"
            component={Box}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={faQuestionCircle}
              style={{ fontSize: "1rem", marginRight: "0.25em" }}
            />
            <Box>{questions?.length} questions</Box>
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
            component={Box}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={faUserCircle}
              style={{ fontSize: "1rem", marginRight: "0.25em" }}
            />
            <Box>{surveyShares?.nodes.length} participants</Box>
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
            component={Box}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={faCheckCircle}
              style={{ fontSize: "1rem", marginRight: "0.25em" }}
            />
            <Box>{submissionsTotalCount} submissions</Box>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SurveyCard;
