import { gql } from "@apollo/client";
import { SURVEY_EVERYTHING } from "gqls";

export const UPDATE_SURVEY = gql`
  mutation updateSurvey($input: UpdateSurveyInput!) {
    updateSurvey(input: $input) {
      clientMutationId
      survey {
        ...SurveyEverything
      }
    }
  }
  ${SURVEY_EVERYTHING}
`;
