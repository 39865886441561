"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEncryptedProtobufObjectChunk = exports.createEncryptedJsonObjectChunk = exports.createCsvChunks = exports.StorageCipher = exports.ColumnType = exports.Schema = exports.Key = void 0;
const protos_1 = require("../lib/proto/protos");
const utils_1 = require("./utils");
const forge = __importStar(require("node-forge"));
const tweetnacl_1 = require("tweetnacl");
const argon2 = __importStar(require("argon2-browser"));
const node_sql_parser_1 = require("node-sql-parser");
function sqlDataTypeToColumnType(sqlDataType, nullable) {
    let primitiveType;
    switch (sqlDataType.dataType) {
        case "TEXT":
        case "CHAR":
        case "VARCHAR":
            primitiveType = protos_1.column_type.PrimitiveType.STRING;
            break;
        case "FLOAT":
        case "REAL":
        case "DOUBLE":
            primitiveType = protos_1.column_type.PrimitiveType.FLOAT64;
            break;
        case "SMALLINT":
        case "INT":
        case "BIGINT":
            primitiveType = protos_1.column_type.PrimitiveType.INT64;
            break;
        default:
            throw new Error(`Unsupported data type ${sqlDataType.dataType}`);
    }
    return protos_1.column_type.ColumnType.create({
        primitiveType: primitiveType,
        nullable: nullable,
    });
}
/**
 * Key to be used for encrypting the datasets
 */
class Key {
    constructor(material, salt, id) {
        this.material = material;
        this.salt = salt;
        this.id = id;
    }
    static create(material, salt) {
        return __awaiter(this, void 0, void 0, function* () {
            let keyBytes;
            if (material === undefined) {
                keyBytes = tweetnacl_1.randomBytes(32);
            }
            else {
                if (material.length != 32) {
                    throw new Error("Invalid key length, must be 32 bytes");
                }
                keyBytes = material;
            }
            let saltBytes;
            if (salt === undefined) {
                saltBytes = tweetnacl_1.randomBytes(16);
            }
            else {
                if (salt.length != 16) {
                    throw new Error("Invalid salt length, must be 16 bytes");
                }
                saltBytes = salt;
            }
            const keyId = yield argon2.hash({
                pass: keyBytes,
                salt: saltBytes,
                type: argon2.ArgonType.Argon2id,
                time: 2,
                mem: 15360,
                hashLen: 32,
                parallelism: 1,
            });
            return new Key(keyBytes, saltBytes, keyId.hash);
        });
    }
}
exports.Key = Key;
/**
 * Schema describes the format of a dataset
 */
class Schema {
    /**
     * Parses a CREATE TABLE to produce a Schema
     *
     * @param createTableStatement - SQL CREATE TABLE statement
     *
     */
    constructor(createTableStatement) {
        const ast = new node_sql_parser_1.Parser().astify(createTableStatement, {
            database: "transactsql",
        });
        if (ast == null) {
            throw new Error("Invalid CREATE TABLE statement");
        }
        let statement;
        if (Array.isArray(ast)) {
            if (ast.length !== 1) {
                throw new Error("Single CREATE TABLE statement expected");
            }
            statement = ast[0];
        }
        else {
            statement = ast;
        }
        if (statement.type !== "create") {
            throw new Error("CREATE TABLE statement expected");
        }
        if (statement.keyword !== "table") {
            throw new Error("CREATE TABLE statement expected");
        }
        if (statement.table.length !== 1) {
            throw new Error("Expect one table name in CREATE TABLE statement");
        }
        const tableName = statement.table[0].table;
        const columns = statement.create_definitions;
        if (columns.length == 0) {
            throw new Error("No columns in CREATE TABLE statement");
        }
        const usedColumnNames = new Set();
        const namedColumns = [];
        for (const column of columns) {
            if (column.resource !== "column") {
                throw new Error(`Invalid create defition, expected column got ${column.resource}`);
            }
            const columnName = column.column.column;
            if (usedColumnNames.has(columnName)) {
                throw new Error(`Multiple definitions of column ${columnName}`);
            }
            let isNotNull = false;
            if (column.nullable !== null) {
                if (column.nullable.type === "not null") {
                    isNotNull = true;
                }
            }
            if (column.default_val !== null) {
                throw new Error(`Column option DEFAULT for column ${columnName} is not supported`);
            }
            if (column.auto_increment !== null) {
                throw new Error(`Column option AUTO_INCREMENT for column ${columnName} is not supported`);
            }
            if (column.unique_or_primary !== null) {
                throw new Error(`Column option UNIQUE or PRIMARY KEY for column ${columnName} is not supported`);
            }
            usedColumnNames.add(columnName);
            const namedColumn = protos_1.waterfront.NamedColumn.create({
                name: columnName,
                columnType: sqlDataTypeToColumnType(column.definition, !isNotNull),
            });
            namedColumns.push(namedColumn);
        }
        this.tableName = tableName;
        this.protoSchema = protos_1.waterfront.TableSchema.create({
            namedColumns,
        });
    }
}
exports.Schema = Schema;
const createCsvChunkHeader = (extraEntropy) => {
    const chunkHeader = protos_1.delta_enclave_api.ChunkHeader.create({
        extraEntropy,
        formatIdentifier: "CsvTable",
    });
    const chunkHeaderBytes = protos_1.delta_enclave_api.ChunkHeader.encodeDelimited(chunkHeader).finish();
    return chunkHeaderBytes;
};
const createVersionHeader = () => {
    const versionHeader = protos_1.delta_enclave_api.VersionHeader.create({
        version: 0,
    });
    const versionHeaderBytes = protos_1.delta_enclave_api.VersionHeader.encodeDelimited(versionHeader).finish();
    return versionHeaderBytes;
};
const createJsonChunkHeader = (extraEntropy) => {
    const chunkHeader = protos_1.delta_enclave_api.ChunkHeader.create({
        extraEntropy,
        formatIdentifier: "JsonObject",
    });
    const chunkHeaderBytes = protos_1.delta_enclave_api.ChunkHeader.encodeDelimited(chunkHeader).finish();
    return chunkHeaderBytes;
};
const createProtobufChunkHeader = (extraEntropy) => {
    const chunkHeader = protos_1.delta_enclave_api.ChunkHeader.create({
        extraEntropy,
        formatIdentifier: "ProtobufObject",
    });
    const chunkHeaderBytes = protos_1.delta_enclave_api.ChunkHeader.encodeDelimited(chunkHeader).finish();
    return chunkHeaderBytes;
};
const createEncryptedJsonObjectChunk = (keyId, key, extraEntropy, object) => {
    const versionHeader = createVersionHeader();
    const chunkHeader = createJsonChunkHeader(extraEntropy);
    const objectJson = new TextEncoder().encode(JSON.stringify(object));
    const chunk = new Uint8Array([
        ...versionHeader,
        ...chunkHeader,
        ...objectJson,
    ]);
    const chunkHasher = forge.md.sha256.create();
    chunkHasher.update(utils_1.uint8ArrayToBinaryString(chunk));
    const chunkHash = forge.util.binary.raw.decode(chunkHasher.digest().bytes());
    const cipher = new StorageCipher(key, keyId);
    const encryptedChunk = cipher.encrypt(chunk);
    return [chunkHash, encryptedChunk];
};
exports.createEncryptedJsonObjectChunk = createEncryptedJsonObjectChunk;
const createEncryptedProtobufObjectChunk = (keyId, key, extraEntropy, serializedProtobufObj) => {
    const versionHeader = createVersionHeader();
    const chunkHeader = createProtobufChunkHeader(extraEntropy);
    const chunk = new Uint8Array([
        ...versionHeader,
        ...chunkHeader,
        ...serializedProtobufObj,
    ]);
    const chunkHasher = forge.md.sha256.create();
    chunkHasher.update(utils_1.uint8ArrayToBinaryString(chunk));
    const chunkHash = forge.util.binary.raw.decode(chunkHasher.digest().bytes());
    const cipher = new StorageCipher(key, keyId);
    const encryptedChunk = cipher.encrypt(chunk);
    return [chunkHash, encryptedChunk];
};
exports.createEncryptedProtobufObjectChunk = createEncryptedProtobufObjectChunk;
function createCsvChunks(csvFileContent, csvColumnTypes, chunkSize) {
    const content = new TextDecoder().decode(csvFileContent);
    const csvFileLines = content.split(/\r?\n/);
    const chunksContent = [];
    const currentChunkBytes = utils_1.SmartVec.withSize(chunkSize);
    const lineEncoder = new TextEncoder();
    for (const line of csvFileLines) {
        if (line) {
            const lineBytes = lineEncoder.encode(line.concat("\n"));
            currentChunkBytes.extend(lineBytes);
            if (currentChunkBytes.currentOffset > chunkSize) {
                chunksContent.push(currentChunkBytes.view(true));
                currentChunkBytes.reset();
            }
        }
    }
    // Push last chunk
    if (currentChunkBytes.currentOffset > 0) {
        chunksContent.push(currentChunkBytes.view(true));
    }
    const chunks = [];
    for (const content of chunksContent) {
        const versionHeaderBytes = createVersionHeader();
        const chunkHeaderBytes = createCsvChunkHeader(tweetnacl_1.randomBytes(16));
        const csvTableFormat = protos_1.csv_table_format.CsvTableFormat.create({
            columnTypes: csvColumnTypes,
        });
        const csvTableFormatBytes = protos_1.csv_table_format.CsvTableFormat.encodeDelimited(csvTableFormat).finish();
        const headerBytes = new Uint8Array([
            ...versionHeaderBytes,
            ...chunkHeaderBytes,
            ...csvTableFormatBytes,
        ]);
        const chunk = new Uint8Array([...headerBytes, ...content]);
        const chunkHasher = forge.md.sha256.create();
        chunkHasher.update(utils_1.uint8ArrayToBinaryString(chunk));
        const chunkHash = forge.util.binary.raw.decode(chunkHasher.digest().bytes());
        chunks.push({
            hash: chunkHash,
            content: chunk,
        });
    }
    return chunks;
}
exports.createCsvChunks = createCsvChunks;
const ColumnType = protos_1.column_type.ColumnType;
exports.ColumnType = ColumnType;
class StorageCipher {
    constructor(symmetricKey, keyId) {
        this.encKey = symmetricKey;
        this.encKeyId = keyId;
    }
    encrypt(data) {
        const nonce = tweetnacl_1.randomBytes(tweetnacl_1.secretbox.nonceLength);
        const encryptedData = tweetnacl_1.secretbox(data, nonce, this.encKey);
        const encryptionHeader = protos_1.delta_enclave_api.EncryptionHeader.create({
            chilyKey: {
                keyId: this.encKeyId,
                encryptionNonce: nonce,
            },
        });
        const serializedEncryptionHeader = protos_1.delta_enclave_api.EncryptionHeader.encodeDelimited(encryptionHeader).finish();
        const encryptedDataWithHeader = new Uint8Array([
            ...serializedEncryptionHeader,
            ...encryptedData,
        ]);
        return encryptedDataWithHeader;
    }
}
exports.StorageCipher = StorageCipher;
