import { Box, Container, Grid, makeStyles, Paper } from "@material-ui/core";
import { Form, useFieldValue, useStatus } from "formik";
import { memo } from "react";
import {
  DescriptionField,
  ParticipantsField,
  Questions,
  ResultsAccessedByField,
  SubmissionEndField,
  TitleField,
} from "components";

const usePaperClasses = makeStyles((theme) => ({
  root: {
    // border: "1px solid transparent",
    // transitionProperty: "all",
    // "&:not(:hover)": {
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.12)",
    // },
  },
}));

const SurveyForm = memo(
  ({
    submissions,
    results,
    participantsWithSubmission,
    Toolbar,
    Actions,
    children,
  }) => {
    const paperClasses = usePaperClasses();
    const [{ isEditing }] = useStatus();
    const [description] = useFieldValue("description");
    const hasDescription = Boolean(description);
    return (
      <Form noValidate>
        <Container maxWidth="md" sx={{ pt: 8, pb: 8 }}>
          <Paper elevation={24} classes={paperClasses}>
            <Box sx={{ pt: 8, pb: 8 }}>
              <Grid
                container
                justifyContent="center"
                spacing={2}
                sx={{ pr: 8, pl: 8 }}
              >
                <Grid item xs={12}>
                  {Toolbar}
                </Grid>
                <Grid item xs={12}>
                  <TitleField />
                </Grid>
                {isEditing || hasDescription ? (
                  <Grid item xs={12}>
                    <DescriptionField name="description" />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={6}>
                  <SubmissionEndField />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ResultsAccessedByField />
                </Grid>
                <Grid item xs={12}>
                  <ParticipantsField
                    participantsWithSubmission={participantsWithSubmission}
                  />
                </Grid>
              </Grid>
              <Questions results={results} submissions={submissions} />
            </Box>
            {children}
            {Actions}
          </Paper>
        </Container>
      </Form>
    );
  }
);

export default SurveyForm;
