import { Box } from "@material-ui/core";
import { useSelections } from "ahooks";
import { useFieldValue } from "formik";
import { memo } from "react";
import { QuestionsLabelAndActions, QuestionsList } from "components";

const Questions = memo(({ results, submissions }) => {
  const [questions] = useFieldValue("questions");
  const questionIds = questions.map((value, index) => index);
  const { isSelected, allSelected, toggle, toggleAll } = useSelections(
    questionIds,
    questionIds
  );
  return (
    <Box sx={{ mt: 2 }}>
      <QuestionsLabelAndActions
        questions={questions}
        allSelected={allSelected}
        toggleAll={toggleAll}
      />
      <QuestionsList
        isSelected={isSelected}
        toggle={toggle}
        results={results}
        submissions={submissions}
      />
    </Box>
  );
});

export default Questions;
