import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  Observable,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { print } from "graphql";
import { createClient } from "graphql-ws";
import { relayGraphqlUrl, relayWebsocketUrl } from "configs";

class GraphQLErrors extends Error {
  constructor(message) {
    super(message);
    this.name = "GraphQLErrors";
  }
}

class NetworkError extends Error {
  constructor(message) {
    super(message);
    this.name = "NetworkError";
  }
}

class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
  }
  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (error) => {
            if (error instanceof Error) {
              sink.error(error);
            } else if (error instanceof CloseEvent) {
              sink.error(
                new NetworkError(
                  `Socket closed with event ${error.code}` + error.reason
                    ? `: ${error.reason}`
                    : ""
                )
              );
            } else {
              sink.NetworkError(
                new Error(error.map(({ message }) => message).join(", "))
              );
            }
          },
        }
      );
    });
  }
}

const ApolloWrapper = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const httpLink = new HttpLink({
    uri: relayGraphqlUrl?.replace(/\/$/, ""),
    credentials: "include",
  });
  const webSocketLink = new WebSocketLink({
    url: relayWebsocketUrl?.replace(/\/$/, ""),
  });
  const errorLink = onError(
    ({ operation: { operationName }, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        Sentry.withScope((scope) => {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(
            new GraphQLErrors(
              graphQLErrors.map(({ message }) => message).join("\n\n")
            ),
            {
              tags: {
                "graphQL.operationName": operationName,
              },
              contexts: {
                GraphQL: {
                  Errors: graphQLErrors
                    .map(({ message }) => message)
                    .join("\n"),
                },
              },
            }
          );
        });
      }
      if (networkError) {
        Sentry.withScope((scope) => {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(new NetworkError(networkError.message));
        });
      }
    }
  );
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    webSocketLink,
    httpLink
  );
  const authLink = setContext(async (_, { headers, ...context }) => {
    const accessToken = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
      ...context,
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(link),
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
