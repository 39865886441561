import { makeStyles, TextField } from "@material-ui/core";
import { FastField, useStatus } from "formik";

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const SurveyMinimumResponsesField = (props) => {
  const [{ isEditing }] = useStatus();
  const inputLabelClasses = useInputLabelStyles();
  const inputClasses = useInputStyles();
  return (
    <FastField name={`surveyMinimumResponses`}>
      {({ field, meta: { error, touched } }) => (
        <TextField
          id="survey-minimum-responses"
          variant="standard"
          color="secondary"
          fullWidth
          label="Min. submissions"
          InputLabelProps={{
            htmlFor: "survey-minimum-responses",
            required: isEditing,
            shrink: true,
            disabled: !isEditing,
            classes: inputLabelClasses,
          }}
          InputProps={{
            type: "number",
            disableUnderline: !isEditing,
            readOnly: !isEditing,
            // disabled: !isEditing,
            disabled: true,
            classes: inputClasses,
          }}
          error={Boolean(error)}
          helperText={error}
          {...field}
          {...props}
        />
      )}
    </FastField>
  );
};

export default SurveyMinimumResponsesField;
