import {
  faCompressAlt,
  faExpandAlt,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useStatus } from "formik";
import { memo } from "react";

const useInlineIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const QuestionActions = memo(({ onDelete, isSelected, toggle }) => {
  const [{ isEditing }] = useStatus();
  const inlineIconButtonClasses = useInlineIconButtonStyles();
  return (
    <Box
      className="question-actions"
      sx={{
        display: "flex",
        marginTop: -0.5,
        marginLeft: 0.25,
        marginBottom: -0.5,
      }}
    >
      {isEditing && (
        <Tooltip title="Delete" placement="top">
          <IconButton
            classes={inlineIconButtonClasses}
            style={{ color: "red" }}
            onClick={onDelete}
            TouchRippleProps={{
              center: false,
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faTrashAlt} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={isSelected ? "Collapse" : "Expand"} placement="top">
        <IconButton
          classes={inlineIconButtonClasses}
          color="inherit"
          onClick={toggle}
          TouchRippleProps={{
            center: false,
          }}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={isSelected ? faCompressAlt : faExpandAlt}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
});

export default QuestionActions;
