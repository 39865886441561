"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.strHasher = exports.surveyToDataroom = void 0;
const survey_1 = require("./survey");
const types_1 = require("./types");
const core_1 = require("@decentriq/core");
const forge = __importStar(require("node-forge"));
function createDqPki(rootCaCert) {
    const dqPki = new core_1.Proto.data_room.TrustedPki();
    dqPki.rootCertificate = rootCaCert;
    return dqPki;
}
function surveyToDataroom(s, rootCaCert) {
    const dataroom = new core_1.Proto.data_room.DataRoom();
    // Set dataroom id to survey id
    dataroom.id = s.surveyId;
    // Defining data ingestor role
    const participantRole = new core_1.Proto.data_room.Role();
    participantRole.roleName = "participantRole";
    const participants = s.participants.map((p) => escapeRegex(p));
    switch (s.surveyAccess) {
        // This hardcodes the participants and verifies in the enclave
        case types_1.SurveyAccessedByType.All:
            {
                participantRole.emailRegex = ".*";
            }
            break;
        case types_1.SurveyAccessedByType.Authenticated:
            {
                participantRole.emailRegex = "(" + participants.join("|") + ")";
            }
            break;
        default:
            throw new Error("CRUD Access Type unknown");
    }
    {
        const authMethod = new core_1.Proto.data_room.AuthenticationMethod();
        authMethod.trustedPki = createDqPki(rootCaCert);
        if (s.passwordAuth) {
            authMethod.passwordSha256 = s.passwordAuth;
        }
        participantRole.authenticationMethod = authMethod;
    }
    const participantPerms = [];
    // Defining owner role
    const ownerRole = new core_1.Proto.data_room.Role();
    ownerRole.roleName = "ownerRole";
    ownerRole.emailRegex = escapeRegex(s.ownerID);
    {
        const authMethod = new core_1.Proto.data_room.AuthenticationMethod();
        authMethod.trustedPki = createDqPki(rootCaCert);
        if (s.passwordAuth) {
            authMethod.passwordSha256 = s.passwordAuth;
        }
        ownerRole.authenticationMethod = authMethod;
    }
    const ownerPerms = new Array();
    // Needed due to JSON defintion in `SurveyInput`
    const questions = s.questions;
    for (const q of questions) {
        // Define Query and Table
        const tables = q.getDataRoomTables();
        // add global submission constraints
        let submissionConstraints = new Array();
        if (s.submissionStart) {
            submissionConstraints.push(core_1.Proto.data_room.TimeConstraint.create({
                timestamp: Math.round(s.submissionStart.getTime() / 1000),
                before: false,
            }));
        }
        if (s.submissionEnd) {
            submissionConstraints.push(core_1.Proto.data_room.TimeConstraint.create({
                timestamp: Math.round(s.submissionEnd.getTime() / 1000),
                before: true,
            }));
        }
        tables.map((table) => (table.submissionConstraints = submissionConstraints));
        dataroom.tables.push(...tables);
        // add table roles
        {
            const tableCrudPerm = new core_1.Proto.data_room.TableCrudPermission();
            tableCrudPerm.tableName = `${strHasher(q.name)}_table`;
            const perm = new core_1.Proto.data_room.Permission();
            perm.tableCrudPermission = tableCrudPerm;
            participantPerms.push(perm);
        }
        // define queries, constraints and assign to roles
        const querySqlStatement = q.getQuerySqlStatement();
        // add some queries and permissions to the owner role if allowed
        if (s.resultsAccess === types_1.ResultsAccessedByType.OwnerAndParticipants ||
            s.resultsAccess === types_1.ResultsAccessedByType.All) {
            const queryExpired = core_1.Proto.data_room.Query.create({
                queryName: `${strHasher(q.name)}_query`,
                sqlSelectStatement: querySqlStatement,
                constraints: [
                    getUserHasSubmittedConstraint(q.name),
                    q.getMinSubmissionsConstraint(q.minimumNumberOfValidSubmissions),
                ],
            });
            if (s.submissionEnd !== null) {
                queryExpired.constraints.push(getQuestionExpiredConstraint(s.submissionEnd, q.name));
            }
            const queryComplete = core_1.Proto.data_room.Query.create({
                queryName: `${strHasher(q.name)}_query_complete`,
                sqlSelectStatement: querySqlStatement,
                constraints: [
                    getUserHasSubmittedConstraint(q.name),
                    q.getMinSubmissionsConstraint(s.participants.length),
                ],
            });
            // add these queries to participants role
            [queryExpired, queryComplete].forEach((q) => {
                const permission = core_1.Proto.data_room.Permission.create({
                    submitQueryPermission: core_1.Proto.data_room.SubmitQueryPermission.create({
                        queryName: q.queryName,
                    }),
                });
                participantPerms.push(permission);
                dataroom.queries.push(q);
            });
        }
        // add some queries and permissions to the owner role if allowed
        if (s.resultsAccess === types_1.ResultsAccessedByType.OwnerAndParticipants ||
            s.resultsAccess === types_1.ResultsAccessedByType.All ||
            s.resultsAccess === types_1.ResultsAccessedByType.Owner) {
            const queryExpired = core_1.Proto.data_room.Query.create({
                queryName: `${strHasher(q.name)}_query_owner`,
                sqlSelectStatement: querySqlStatement,
                constraints: [
                    q.getMinSubmissionsConstraint(q.minimumNumberOfValidSubmissions),
                ],
            });
            if (s.submissionEnd !== null) {
                queryExpired.constraints.push(getQuestionExpiredConstraint(s.submissionEnd, q.name));
            }
            const queryComplete = core_1.Proto.data_room.Query.create({
                queryName: `${strHasher(q.name)}_query_complete_owner`,
                sqlSelectStatement: querySqlStatement,
                constraints: [q.getMinSubmissionsConstraint(s.participants.length)],
            });
            [queryComplete, queryExpired].forEach((q) => {
                const permission = core_1.Proto.data_room.Permission.create({
                    submitQueryPermission: core_1.Proto.data_room.SubmitQueryPermission.create({
                        queryName: q.queryName,
                    }),
                });
                ownerPerms.push(permission);
                dataroom.queries.push(q);
            });
            ownerRole.permissions = ownerPerms;
        }
    }
    dataroom.roles.push(ownerRole);
    participantRole.permissions = participantPerms;
    dataroom.roles.push(participantRole);
    return dataroom;
}
exports.surveyToDataroom = surveyToDataroom;
function strHasher(s) {
    const md = forge.md.sha256.create();
    md.update(s);
    return md.digest().toHex();
}
exports.strHasher = strHasher;
function escapeRegex(s) {
    return s.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
}
function getUserHasSubmittedConstraint(name) {
    const userHasSubmittedSql = `
    SELECT 'passing'
    FROM "${strHasher(name)}_table"
    WHERE _$uploader_user_id = _$query_user_id;`;
    const useHasSubmittedConstraint = core_1.Proto.data_room.Constraint.create({
        description: `Constraint:${survey_1.ConstraintError.UserHasNotSubmitted} - Participant did not submit data..`,
        sqlConstraint: core_1.Proto.data_room.SqlConstraint.create({
            statement: userHasSubmittedSql,
        }),
    });
    return useHasSubmittedConstraint;
}
function getQuestionExpiredConstraint(ts, name) {
    const questionExpiredConstraint = core_1.Proto.data_room.Constraint.create({
        description: `Constraint:${survey_1.ConstraintError.QuestionNotExpired} - Not yet expired.`,
        timeConstraint: core_1.Proto.data_room.TimeConstraint.create({
            timestamp: Math.round(ts.getTime() / 1000),
            before: false,
        }),
    });
    return questionExpiredConstraint;
}
