"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurveysOrderBy = exports.SurveySharesOrderBy = exports.SurveyAccessedByType = exports.SessionEventType = exports.ResultsComputedAfterType = exports.ResultsAccessedByType = exports.DataroomsOrderBy = exports.DataroomSharesOrderBy = void 0;
/** Methods to use when ordering `DataroomShare`. */
var DataroomSharesOrderBy;
(function (DataroomSharesOrderBy) {
    DataroomSharesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    DataroomSharesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    DataroomSharesOrderBy["DataroomIdAsc"] = "DATAROOM_ID_ASC";
    DataroomSharesOrderBy["DataroomIdDesc"] = "DATAROOM_ID_DESC";
    DataroomSharesOrderBy["DataroomShareIdAsc"] = "DATAROOM_SHARE_ID_ASC";
    DataroomSharesOrderBy["DataroomShareIdDesc"] = "DATAROOM_SHARE_ID_DESC";
    DataroomSharesOrderBy["HasSubmittedAsc"] = "HAS_SUBMITTED_ASC";
    DataroomSharesOrderBy["HasSubmittedDesc"] = "HAS_SUBMITTED_DESC";
    DataroomSharesOrderBy["Natural"] = "NATURAL";
    DataroomSharesOrderBy["OwnerEmailAsc"] = "OWNER_EMAIL_ASC";
    DataroomSharesOrderBy["OwnerEmailDesc"] = "OWNER_EMAIL_DESC";
    DataroomSharesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    DataroomSharesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
    DataroomSharesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    DataroomSharesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    DataroomSharesOrderBy["UserEmailAsc"] = "USER_EMAIL_ASC";
    DataroomSharesOrderBy["UserEmailDesc"] = "USER_EMAIL_DESC";
})(DataroomSharesOrderBy = exports.DataroomSharesOrderBy || (exports.DataroomSharesOrderBy = {}));
/** Methods to use when ordering `Dataroom`. */
var DataroomsOrderBy;
(function (DataroomsOrderBy) {
    DataroomsOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    DataroomsOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    DataroomsOrderBy["DataroomHashAsc"] = "DATAROOM_HASH_ASC";
    DataroomsOrderBy["DataroomHashDesc"] = "DATAROOM_HASH_DESC";
    DataroomsOrderBy["DataroomIdAsc"] = "DATAROOM_ID_ASC";
    DataroomsOrderBy["DataroomIdDesc"] = "DATAROOM_ID_DESC";
    DataroomsOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    DataroomsOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    DataroomsOrderBy["IsPublishedAsc"] = "IS_PUBLISHED_ASC";
    DataroomsOrderBy["IsPublishedDesc"] = "IS_PUBLISHED_DESC";
    DataroomsOrderBy["NameAsc"] = "NAME_ASC";
    DataroomsOrderBy["NameDesc"] = "NAME_DESC";
    DataroomsOrderBy["Natural"] = "NATURAL";
    DataroomsOrderBy["OwnerEmailAsc"] = "OWNER_EMAIL_ASC";
    DataroomsOrderBy["OwnerEmailDesc"] = "OWNER_EMAIL_DESC";
    DataroomsOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    DataroomsOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
    DataroomsOrderBy["QueriesAsc"] = "QUERIES_ASC";
    DataroomsOrderBy["QueriesDesc"] = "QUERIES_DESC";
    DataroomsOrderBy["TablesAsc"] = "TABLES_ASC";
    DataroomsOrderBy["TablesDesc"] = "TABLES_DESC";
    DataroomsOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    DataroomsOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
})(DataroomsOrderBy = exports.DataroomsOrderBy || (exports.DataroomsOrderBy = {}));
var ResultsAccessedByType;
(function (ResultsAccessedByType) {
    ResultsAccessedByType["All"] = "ALL";
    ResultsAccessedByType["Owner"] = "OWNER";
    ResultsAccessedByType["OwnerAndParticipants"] = "OWNER_AND_PARTICIPANTS";
    ResultsAccessedByType["Participants"] = "PARTICIPANTS";
})(ResultsAccessedByType = exports.ResultsAccessedByType || (exports.ResultsAccessedByType = {}));
var ResultsComputedAfterType;
(function (ResultsComputedAfterType) {
    ResultsComputedAfterType["SubmissionEndReached"] = "SUBMISSION_END_REACHED";
    ResultsComputedAfterType["SurveyMinimumResponsesReached"] = "SURVEY_MINIMUM_RESPONSES_REACHED";
})(ResultsComputedAfterType = exports.ResultsComputedAfterType || (exports.ResultsComputedAfterType = {}));
var SessionEventType;
(function (SessionEventType) {
    SessionEventType["Command"] = "COMMAND";
    SessionEventType["Creation"] = "CREATION";
})(SessionEventType = exports.SessionEventType || (exports.SessionEventType = {}));
var SurveyAccessedByType;
(function (SurveyAccessedByType) {
    SurveyAccessedByType["All"] = "ALL";
    SurveyAccessedByType["Authenticated"] = "AUTHENTICATED";
})(SurveyAccessedByType = exports.SurveyAccessedByType || (exports.SurveyAccessedByType = {}));
/** Methods to use when ordering `SurveyShare`. */
var SurveySharesOrderBy;
(function (SurveySharesOrderBy) {
    SurveySharesOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    SurveySharesOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    SurveySharesOrderBy["HasSubmittedAsc"] = "HAS_SUBMITTED_ASC";
    SurveySharesOrderBy["HasSubmittedDesc"] = "HAS_SUBMITTED_DESC";
    SurveySharesOrderBy["Natural"] = "NATURAL";
    SurveySharesOrderBy["OwnerEmailAsc"] = "OWNER_EMAIL_ASC";
    SurveySharesOrderBy["OwnerEmailDesc"] = "OWNER_EMAIL_DESC";
    SurveySharesOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    SurveySharesOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
    SurveySharesOrderBy["SurveyIdAsc"] = "SURVEY_ID_ASC";
    SurveySharesOrderBy["SurveyIdDesc"] = "SURVEY_ID_DESC";
    SurveySharesOrderBy["SurveyShareIdAsc"] = "SURVEY_SHARE_ID_ASC";
    SurveySharesOrderBy["SurveyShareIdDesc"] = "SURVEY_SHARE_ID_DESC";
    SurveySharesOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    SurveySharesOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
    SurveySharesOrderBy["UserEmailAsc"] = "USER_EMAIL_ASC";
    SurveySharesOrderBy["UserEmailDesc"] = "USER_EMAIL_DESC";
})(SurveySharesOrderBy = exports.SurveySharesOrderBy || (exports.SurveySharesOrderBy = {}));
/** Methods to use when ordering `Survey`. */
var SurveysOrderBy;
(function (SurveysOrderBy) {
    SurveysOrderBy["CreatedAtAsc"] = "CREATED_AT_ASC";
    SurveysOrderBy["CreatedAtDesc"] = "CREATED_AT_DESC";
    SurveysOrderBy["DataRoomHashAsc"] = "DATA_ROOM_HASH_ASC";
    SurveysOrderBy["DataRoomHashDesc"] = "DATA_ROOM_HASH_DESC";
    SurveysOrderBy["DescriptionAsc"] = "DESCRIPTION_ASC";
    SurveysOrderBy["DescriptionDesc"] = "DESCRIPTION_DESC";
    SurveysOrderBy["NameAsc"] = "NAME_ASC";
    SurveysOrderBy["NameDesc"] = "NAME_DESC";
    SurveysOrderBy["Natural"] = "NATURAL";
    SurveysOrderBy["OwnerEmailAsc"] = "OWNER_EMAIL_ASC";
    SurveysOrderBy["OwnerEmailDesc"] = "OWNER_EMAIL_DESC";
    SurveysOrderBy["PrimaryKeyAsc"] = "PRIMARY_KEY_ASC";
    SurveysOrderBy["PrimaryKeyDesc"] = "PRIMARY_KEY_DESC";
    SurveysOrderBy["QuestionsAsc"] = "QUESTIONS_ASC";
    SurveysOrderBy["QuestionsDesc"] = "QUESTIONS_DESC";
    SurveysOrderBy["ResultsAccessedByAsc"] = "RESULTS_ACCESSED_BY_ASC";
    SurveysOrderBy["ResultsAccessedByDesc"] = "RESULTS_ACCESSED_BY_DESC";
    SurveysOrderBy["ResultsComputedAfterAsc"] = "RESULTS_COMPUTED_AFTER_ASC";
    SurveysOrderBy["ResultsComputedAfterDesc"] = "RESULTS_COMPUTED_AFTER_DESC";
    SurveysOrderBy["SubmissionEndAsc"] = "SUBMISSION_END_ASC";
    SurveysOrderBy["SubmissionEndDesc"] = "SUBMISSION_END_DESC";
    SurveysOrderBy["SubmissionStartAsc"] = "SUBMISSION_START_ASC";
    SurveysOrderBy["SubmissionStartDesc"] = "SUBMISSION_START_DESC";
    SurveysOrderBy["SurveyAccessedByAsc"] = "SURVEY_ACCESSED_BY_ASC";
    SurveysOrderBy["SurveyAccessedByDesc"] = "SURVEY_ACCESSED_BY_DESC";
    SurveysOrderBy["SurveyIdAsc"] = "SURVEY_ID_ASC";
    SurveysOrderBy["SurveyIdDesc"] = "SURVEY_ID_DESC";
    SurveysOrderBy["SurveyMinimumResponsesAsc"] = "SURVEY_MINIMUM_RESPONSES_ASC";
    SurveysOrderBy["SurveyMinimumResponsesDesc"] = "SURVEY_MINIMUM_RESPONSES_DESC";
    SurveysOrderBy["UpdatedAtAsc"] = "UPDATED_AT_ASC";
    SurveysOrderBy["UpdatedAtDesc"] = "UPDATED_AT_DESC";
})(SurveysOrderBy = exports.SurveysOrderBy || (exports.SurveysOrderBy = {}));
