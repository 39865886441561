import { SurveysCards } from "components";

const Surveys = ({ as, ...rest }) => {
  switch (as) {
    case "cards":
    default:
      return <SurveysCards {...rest} />;
  }
};

Surveys.Cards = (props) => <SurveysCards {...props} />;

export default Surveys;
