import {
  numericalQuestionAggregation,
  questionKind,
  surveyComputing,
  surveyParticipation,
  surveyResultVisibility,
} from "constants/index";
import { add, parseISO } from "date-fns";

export const surveyInitialValues = {
  id: null,
  name: "",
  description: "",
  questions: [],
  submissionStart: null,
  submissionEnd: null,
  surveyAccessedBy: surveyParticipation.AUTHENTICATED,
  surveyMinimumResponses: 1,
  resultsComputedAfter: surveyComputing.SUBMISSION_END_REACHED,
  resultsAccessedBy: surveyResultVisibility.OWNER_AND_PARTICIPANTS,
  participants: [],
};

export const parseSurveySubmissionStartDateToIso = (submissionStart) =>
  parseISO(submissionStart || new Date());

export const parseSurveySubmissionEndDateToIso = (submissionEnd) =>
  parseISO(submissionEnd || add(new Date(), { days: 1 }));

export const numericalQuestionInitialValues = {
  kind: questionKind.NUMERICAL,
  name: "",
  description: "",
  minimumNumberOfValidSubmissions: 1,
  isRequired: false,
  unit: "",
  minValueValidation: null,
  maxValueValidation: null,
  lowerPercentileFilter: 0,
  upperPercentileFilter: 1,
  rejectionFeedback: 0,
  aggregations: [
    numericalQuestionAggregation.COUNT,
    numericalQuestionAggregation.SUM,
    numericalQuestionAggregation.AVG,
    numericalQuestionAggregation.MIN,
    numericalQuestionAggregation.MAX,
  ],
};

export const categoricalQuestionCommonInitialValues = {
  kind: questionKind.CATEGORICAL,
  name: "",
  description: "",
  minimumNumberOfValidSubmissions: 1,
  isRequired: false,
  submissionOptions: [],
  allowMultiAnswer: false,
};

export const singleChoiceQuestionInitialValues =
  categoricalQuestionCommonInitialValues;

export const multiChoiceQuestionInitialValues = {
  ...categoricalQuestionCommonInitialValues,
  allowMultiAnswer: true,
};
