import { gql } from "@apollo/client";

export const DELETE_SURVEY_BY_ID = gql`
  mutation deleteSurveyById($input: DeleteSurveyByIdInput!) {
    deleteSurveyById(input: $input) {
      survey {
        id
        surveyId
      }
      clientMutationId
    }
  }
`;
