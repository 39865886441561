import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "components";
import {
  AboutPage,
  DataPrivacyPolicyPage,
  FaqPage,
  FourOFourPage,
  HomePage,
  SurveyPage,
  SurveysPage,
  TermsAndConditionsPage,
} from "pages";

const PagesWrapper = ({ children }) => {
  return (
    <Routes>
      <PrivateRoute path="/insights/:surveyId" element={<SurveyPage />} />
      <PrivateRoute path="/insights" element={<SurveysPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      />
      <Route path="/data-privacy-policy" element={<DataPrivacyPolicyPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<FourOFourPage />} />
    </Routes>
  );
};

export default PagesWrapper;
