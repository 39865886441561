"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisWaConnector = void 0;
const dataroom_1 = require("./dataroom");
const core_1 = require("@decentriq/core");
class DisWaConnector {
    constructor(client, email) {
        this.client = client;
        this.userId = email.toLowerCase();
        this.session = null;
    }
    static withToken(token, email, host, port, useTLS, clientId = "GZn14cpIzBZGjc1Dx7CQpkNh9nNKS9oj") {
        const client = new core_1.Client({
            type: "app",
            value: token
        }, clientId, host, port, useTLS);
        return new DisWaConnector(client, email.toLowerCase());
    }
    static withUserToken(token, email, host, port, useTLS, clientId = "GZn14cpIzBZGjc1Dx7CQpkNh9nNKS9oj") {
        const client = new core_1.Client({
            type: "user",
            value: token
        }, clientId, host, port, useTLS);
        return new DisWaConnector(client, email.toLowerCase());
    }
    connect(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const enclaveIdentifier = (yield this.client.getEnclaveIdentifiers())[0];
            const auth = yield this.client.createAuth(this.userId);
            const session = yield this.client.createSession(enclaveIdentifier, { 'participantRole': auth, 'ownerRole': auth }, options);
            this.session = session;
        });
    }
    getRootCaCert() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getCARootCertificate();
        });
    }
    createDataRoom(dr) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Session must be set");
            const response = yield this.session.createDataRoom(dr, "ownerRole");
            return response;
        });
    }
    ingestData(q, answer, dataRoomHash) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Instance must bet set");
            let sql = q.getSqlDatasetCreate();
            let schema = new core_1.Schema(sql);
            let key = yield core_1.Key.create();
            const valuesCsv = Array.isArray(answer.value)
                ? answer.value.map((x) => `"${x}"`).join("\n")
                : `"${answer.value}"`;
            const data = new TextEncoder().encode(valuesCsv + "\n");
            const manifestHash = yield this.client.uploadDataset(this.userId, schema.tableName, data, schema, key);
            yield this.session.publishDatasetToDataRoom(manifestHash, dataRoomHash, `${dataroom_1.strHasher(q.name)}_table`, key, "participantRole");
        });
    }
}
exports.DisWaConnector = DisWaConnector;
