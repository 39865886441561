"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
const session_1 = require("./session");
const api_1 = require("./api");
const storage_1 = require("./storage");
const authentication_1 = require("./authentication");
const protos_1 = require("../lib/proto/protos");
const forge = __importStar(require("node-forge"));
const tweetnacl_1 = require("tweetnacl");
const generic_pool_1 = require("generic-pool");
class Client {
    constructor(token, clientId = "MHyVW112w7Ql95G96fn9rnLWkYuOLmdk", host = "api.decentriq.ch", port = 443, useTLS = true) {
        this.api = new api_1.API(token, clientId, host, port, useTLS);
    }
    getEnclaveIdentifiers() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = api_1.Endpoints.SYSTEM_ENCLAVE_IDENTIFIERS;
            const res = (yield this.api.get(url)).data;
            return res.enclaveIdentifiers;
        });
    }
    createAuth(userEmail, accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const keypair = yield authentication_1.generateKey();
            const csr = authentication_1.generateCsrPem(userEmail, keypair);
            const url = api_1.Endpoints.USER_CERTIFICATE.replace(":userId", userEmail);
            const reqBody = {
                csrPem: csr,
            };
            const resBody = (yield this.api.post(url, reqBody)).data;
            const certChain = new TextEncoder().encode(resBody.certChainPem);
            return new authentication_1.Auth(certChain, keypair, userEmail, accessToken);
        });
    }
    createSession(enclaveIdentifier, auth, options = {
        verificationOptions: {
            acceptDebug: false,
            acceptGroupOutOfDate: false,
            acceptConfigurationNeeded: false,
        },
    }) {
        return __awaiter(this, void 0, void 0, function* () {
            const reqBody = {
                enclaveIdentifier: enclaveIdentifier.enclaveIdentifier,
            };
            const url = api_1.Endpoints.SESSIONS;
            const resBody = (yield this.api.post(url, reqBody))
                .data;
            const session = yield session_1.Session.create(this, resBody.sessionId, resBody.enclaveIdentifier, auth, options);
            return session;
        });
    }
    createSessionPool(enclaveIdentifier, auth, options = {
        size: {
            min: 1,
            max: 3,
        },
        sessionOptions: {
            verificationOptions: {
                acceptDebug: false,
                acceptGroupOutOfDate: false,
                acceptConfigurationNeeded: false,
            },
        },
    }) {
        return __awaiter(this, void 0, void 0, function* () {
            const sessionsPoolFactory = {
                create: () => __awaiter(this, void 0, void 0, function* () {
                    return yield this.createSession(enclaveIdentifier, auth, options.sessionOptions);
                }),
                destroy: () => __awaiter(this, void 0, void 0, function* () {
                    return;
                }),
            };
            const pool = generic_pool_1.createPool(sessionsPoolFactory, {
                min: options.size.min,
                max: options.size.max,
                autostart: true,
            });
            return new session_1.SessionPool(pool);
        });
    }
    getCARootCertificate() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = api_1.Endpoints.SYSTEM_CERTIFICATE_AUTHORITY;
            const resBody = (yield this.api.get(url)).data;
            const certificate = new TextEncoder().encode(resBody.rootCertificate);
            return certificate;
        });
    }
    uploadDataset(email, name, fileContent, schema, key, chunkSize = 8 * Math.pow(1024, 2), parallelUploadsCount = 4) {
        return __awaiter(this, void 0, void 0, function* () {
            if (schema.protoSchema.namedColumns === null ||
                schema.protoSchema.namedColumns === undefined) {
                throw new Error("Empty schema for dataset");
            }
            const columnTypes = schema.protoSchema.namedColumns.map((col) => col.columnType);
            const createdChunks = storage_1.createCsvChunks(fileContent, columnTypes, chunkSize);
            const chunkHashes = createdChunks.map(({ hash }) => forge.util.binary.hex.encode(hash));
            const [digestHash, digestEncrypted] = storage_1.createEncryptedJsonObjectChunk(key.id, key.material, tweetnacl_1.randomBytes(16), chunkHashes);
            const manifest = protos_1.waterfront.DatasetManifest.create({
                digestHash,
                schema: schema.protoSchema,
            });
            const [manifestHash, manifestEncrypted] = storage_1.createEncryptedProtobufObjectChunk(key.id, key.material, tweetnacl_1.randomBytes(16), protos_1.waterfront.DatasetManifest.encodeDelimited(manifest).finish());
            const uploadDescription = yield this.createUpload(email);
            const uploadId = uploadDescription.uploadId;
            const totalChunks = createdChunks.length;
            let uploaded = 0;
            while (uploaded != totalChunks) {
                const chunksCountToUpload = Math.min(parallelUploadsCount, totalChunks - uploaded);
                const parallelUploads = createdChunks.slice(uploaded, uploaded + chunksCountToUpload);
                yield Promise.all(parallelUploads.map(({ hash, content }) => __awaiter(this, void 0, void 0, function* () {
                    yield this.encryptAndUploadChunk(hash, content, key.id, key.material, email, uploadId);
                })));
                uploaded += chunksCountToUpload;
            }
            yield this.uploadChunk(digestHash, digestEncrypted, email, uploadId);
            yield this.finalizeUpload(email, uploadId, name, manifestHash, manifestEncrypted, 
            // HACK!!! We include the digest hash as a "chunk".
            // This is temporary to avoid changes in the backend logic.
            chunkHashes.concat([forge.util.binary.hex.encode(digestHash)]));
            return manifestHash;
        });
    }
    encryptAndUploadChunk(chunkHash, chunkData, keyId, key, userId, uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            const cipher = new storage_1.StorageCipher(key, keyId);
            const chunkDataEncrypted = cipher.encrypt(chunkData);
            yield this.uploadChunk(chunkHash, chunkDataEncrypted, userId, uploadId);
        });
    }
    uploadChunk(chunkHash, chunkDataEncrypted, userId, uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = api_1.Endpoints.USER_UPLOAD_CHUNKS.replace(":userId", userId).replace(":uploadId", uploadId);
            const chunkWrapped = {
                hash: forge.util.binary.hex.encode(chunkHash),
                data: forge.util.binary.base64.encode(chunkDataEncrypted),
            };
            yield this.api.post(url, chunkWrapped);
        });
    }
    createUpload(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = api_1.Endpoints.USER_UPLOADS_COLLECTION.replace(":userId", userId);
            return (yield this.api.post(url, {})).data;
        });
    }
    finalizeUpload(userId, uploadId, name, manifestHash, manifestEncrypted, chunks) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = api_1.Endpoints.USER_FILES_COLLECTION.replace(":userId", userId);
            const payload = {
                uploadId,
                manifestHash: forge.util.binary.hex.encode(manifestHash),
                manifest: forge.util.binary.base64.encode(manifestEncrypted),
                name,
                chunks,
            };
            return (yield this.api.post(url, payload)).data;
        });
    }
}
exports.Client = Client;
