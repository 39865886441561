import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { auth0Audience, auth0ClientId, auth0Domain } from "configs";

const Auth0Wrapper = ({ children }) => {
  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      audience={auth0Audience}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      onRedirectCallback={(appState) => {
        navigate({ ...appState?.referer, pathname: "/" });
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
