import { surveyResultVisibility } from "constants/index";
import { faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FastField, useStatus } from "formik";
import { memo } from "react";

const useSelectIconStyles = makeStyles({
  selectIcon: {
    margin: "0.375rem 0.25rem",
    position: "absolute",
    top: "1rem",
    right: 0,
    pointerEvents: "none",
    display: (props) => (props.disabled ? "none" : "block"),
  },
});

const SelectIcon = memo(({ disabled }) => {
  const selectIconClasses = useSelectIconStyles({ disabled });
  return (
    <FontAwesomeIcon
      fixedWidth
      icon={faCaretDown}
      className={selectIconClasses.selectIcon}
    />
  );
});

const useSelectStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0.5, 0),
    paddingBottom: "3px",
    minHeight: "1.25rem !important",
    lineHeight: "1.25rem !important",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const ResultsAccessedByField = memo(() => {
  const [{ isEditing }] = useStatus();
  const inputLabelClasses = useInputLabelStyles();
  const selectClasses = useSelectStyles();
  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="survey-accessed-by"
        color="secondary"
        shrink
        required={isEditing}
        disabled={!isEditing}
        classes={inputLabelClasses}
      >
        Who can view the results of this insight?
      </InputLabel>
      <FastField name={`resultsAccessedBy`}>
        {({ field }) => (
          <Select
            color="secondary"
            IconComponent={() => <SelectIcon disabled={!isEditing} />}
            SelectDisplayProps={{ style: { minHeight: "1.1428575em" } }}
            disableUnderline={!isEditing}
            readOnly={!isEditing}
            disabled={!isEditing}
            classes={selectClasses}
            {...field}
          >
            {false && (
              <MenuItem value={surveyResultVisibility.ALL} disabled>
                Public results (not enforced by enclave)
              </MenuItem>
            )}
            <MenuItem value={surveyResultVisibility.OWNER}>
              Insight creator
            </MenuItem>
            <MenuItem value={surveyResultVisibility.OWNER_AND_PARTICIPANTS}>
              Insight creator & participants
            </MenuItem>
            {false && (
              <MenuItem value={surveyResultVisibility.PARTICIPANTS} disabled>
                Participants
              </MenuItem>
            )}
          </Select>
        )}
      </FastField>
    </FormControl>
  );
});

export default ResultsAccessedByField;
