import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  makeStyles,
  Portal,
  TextField,
  Typography,
} from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import { useBoolean } from "ahooks";
import { parse } from "query-string";
import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useLocation } from "react-router-dom";
import decentriqLogoImageSrc from "assets/images/decentriq-text-logo.svg";
import swisscomLogoImageSrc from "assets/images/swisscom-logo.png";
import { UnauthorizedDialog } from "components";
import { Main } from "layouts";

const SubscribeForm = ({ subscribe, status, message }) => {
  const [email, setEmail] = useState("");
  switch (status) {
    case "error":
      return (
        <Typography
          component="h1"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Something went wrong, please try again later.</strong>
        </Typography>
      );
    case "success":
      return (
        <Typography
          component="h1"
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          <strong>Thanks! You are now subscribed to our newsletter.</strong>
        </Typography>
      );
    case "sending":
      return (
        <Box sx={{ p: 1, display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" thickness={1} size="2.5rem" />
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2em",
          }}
        >
          <TextField
            type="email"
            label="Email"
            color="secondary"
            style={{ marginRight: "1em", width: "20em" }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{
              textTransform: "none",
              fontSize: "16px",
              padding: "14px 20px",
            }}
            onClick={() => subscribe({ EMAIL: email })}
          >
            Subscribe
          </Button>
        </Box>
      );
  }
};

const onEmailClicked = (event) => {
  event.preventDefault();
  event.stopPropagation();
  window.open("mailto:hello@confidentialinsights.com");
};

const useAboutPageStyles = makeStyles((theme) => ({
  dimmedBlock: {
    backgroundColor: darken(theme.palette.background.default, 0.025),
  },
}));

const AboutPage = () => {
  const aboutPageClasses = useAboutPageStyles();
  const location = useLocation();
  const searchParams = parse(location.search);
  const { error } = searchParams;
  const [isUnauthorizedDialogOpen, { setFalse: closeUnauthorizedDialog }] =
    useBoolean(error === "unauthorized");
  return (
    <Main>
      <Box sx={{ pt: 10, pb: 10 }} className={aboutPageClasses.dimmedBlock}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>
              Enabling collaborative data insights.
              <br />
              For you and your peers.
              <br />
              Provably confidential.
            </strong>
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textSecondary"
          >
            We help you to create your own benchmarks with your peers. No
            further mediation needed. Whatever benchmark you may need, with
            Confidential Insights you and your peers can create it in just few
            clicks.
          </Typography>
        </Container>
      </Box>
      <Box sx={{ pt: 10, pb: 10 }}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>What is Confidential Insights?</strong>
          </Typography>
          <Typography variant="body1" color="textPrimary" component="p">
            Have you ever wanted to compare sensitive data with your peers but
            were concerned about data privacy? Whether you would like to compare
            customer acquisition costs, pricing data, or gender pay gap,
            whatever the metric may be, we give you and your peers the tools to
            learn from one another in a privacy-preserving way, in just a few
            clicks.
          </Typography>
        </Container>
      </Box>
      <Box sx={{ pt: 10, pb: 10 }} className={aboutPageClasses.dimmedBlock}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>What makes Confidential Insights unique?</strong>
          </Typography>
          <Typography variant="body1" color="textPrimary" component="p">
            Your data submissions are never revealed. By using a novel
            technology called "Confidential Computing" you and your peers can
            compare business critical metrics while individual data submissions
            are kept secret. Nobody, including us, can see your data
            submissions. And with Confidential Computing we can prove to you
            that this is the case. Only aggregated results are returned to you.
            It's as simple as that.
          </Typography>
        </Container>
      </Box>
      <Box sx={{ pt: 10, pb: 10 }}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Who's behind Confidential Insights?</strong>
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <Card elevation={0}>
                <CardContent>
                  <Box
                    style={{
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <img
                      src={swisscomLogoImageSrc}
                      alt="swisscom"
                      style={{
                        marginLeft: "-10px",
                        width: "200px",
                      }}
                    />
                  </Box>
                  <Typography variant="body1" color="textPrimary" component="p">
                    <strong>Swisscom</strong> is the leading ICT provider in
                    Switzerland, offering mobile telecommunications, fixed
                    network, Internet and digital TV solutions for business and
                    residential customers. We are also part of the Confidential
                    Computing Consortium and one of the largest providers of IT
                    services in Switzerland. We develop and maintain the
                    wireless and wireline network infrastructure, distribute
                    broadcasting signals and are also active in the banking,
                    energy, entertainment, advertising and healthcare sectors.
                    Swisscom is 51% Confederation-owned and is one of
                    Switzerland’s most sustainable and innovative companies.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card elevation={0}>
                <CardContent>
                  <Box
                    style={{
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <img
                      src={decentriqLogoImageSrc}
                      alt="decentriq"
                      style={{
                        width: "200px",
                      }}
                    />
                  </Box>
                  <Typography variant="body1" color="textPrimary" component="p">
                    <strong>Decentriq</strong> is a Swiss-based startup born in
                    2018 to change how the world accesses data, enabling
                    risk-free data ecosystems. With a deep expertise in
                    Cryptography and Privacy Enhancing Technologies, we work in
                    partnership with our clients to ensure that they leverage
                    their most sensitive data securely and seamlessly. We
                    leverage Trusted Execution Environments to build our
                    products and we are a founding member of the Confidential
                    Computing Consortium.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ pt: 10, pb: 10 }} className={aboutPageClasses.dimmedBlock}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Want to learn more on Confidential Computing?</strong>
          </Typography>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              title="Confidential Insights"
              src="https://player.vimeo.com/video/475882711?title=0&byline=0&portrait=0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameBorder="0"
              allowFullScreen
              allow="autoplay; fullscreen"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Container>
      </Box>
      <Box sx={{ pt: 10, pb: 10 }}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Want to stay informed?</strong>
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            We are working on launching Confidential Insights soon. Sign up to
            stay up to date.
          </Typography>
          <MailchimpSubscribe
            url="https://confidentialinsights.us2.list-manage.com/subscribe/post?u=959321fac1cf7a7c75ed0cdef&amp;id=788c362781"
            render={({ subscribe, status, message }) => (
              <SubscribeForm
                subscribe={subscribe}
                status={status}
                message={message}
              />
            )}
          />
        </Container>
      </Box>
      <Box sx={{ pt: 10, pb: 10 }} className={aboutPageClasses.dimmedBlock}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <strong>Partner enquiries</strong>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            align="center"
            component="p"
          >
            If you are interested to shape with us the future of Confidential
            Insights,
            <br />
            please reach out to us at{" "}
            <Link
              color="secondary"
              href="mailto:hello@confidentialinsights.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={onEmailClicked}
            >
              <strong>hello@confidentialinsights.com</strong>
            </Link>
            .
          </Typography>
        </Container>
      </Box>
      <Portal>
        <UnauthorizedDialog
          open={isUnauthorizedDialogOpen}
          onClose={closeUnauthorizedDialog}
        />
      </Portal>
    </Main>
  );
};

export default AboutPage;
