"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultType = exports.Question = void 0;
class Question {
    constructor(name) {
        this.desciption = null;
        this.unit = null;
        this.minimumNumberOfValidSubmissions = 1;
        // Client feature: allows to skip current question
        this.isRequired = false;
        this.name = name;
    }
}
exports.Question = Question;
var ResultType;
(function (ResultType) {
    ResultType["VALID"] = "VALID";
    ResultType["OUTLIER"] = "OUTLIER";
    ResultType["OUTLIER_LOW"] = "OUTLIER_LOW";
    ResultType["OUTLIER_HIGH"] = "OUTLIER_HIGH";
})(ResultType = exports.ResultType || (exports.ResultType = {}));
