import { answerKind, questionKind, surveyMode } from "constants/index";
import { Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  SurveyForm,
  SurveyFormActions,
  SurveyFormAutoSave,
  SurveyFormToolbar,
} from "components";
import {
  parseSurveySubmissionEndDateToIso,
  parseSurveySubmissionStartDateToIso,
  surveyInitialValues,
} from "containers/Survey/SurveyInitialValues";

const defaultFormOptions = {
  isEditing: false,
  isViewing: false,
  canSubmit: false,
  hasResult: false,
};

const useFormOptions = (mode = surveyMode.CREATE, hasSubmitted, isOwner) => {
  const getFormOptions = useCallback(
    (mode) => ({
      ...defaultFormOptions,
      isEditing:
        mode === surveyMode.CREATE || !Object.values(surveyMode).includes(mode),
      canSubmit: mode === surveyMode.SUBMIT && !hasSubmitted,
      hasResult: mode === surveyMode.RESULTS,
      isOwner,
    }),
    [hasSubmitted, isOwner]
  );
  const [formOptions, setFormOptions] = useState(
    getFormOptions(mode, hasSubmitted)
  );
  useEffect(() => {
    setFormOptions(getFormOptions(mode, hasSubmitted));
  }, [mode, hasSubmitted, getFormOptions]);
  return formOptions;
};

const SurveyFormik = memo(
  ({
    mode = surveyMode.CREATE,
    survey = {},
    submissions,
    results,
    hasSubmitted,
    isOwner,
    validationSchema,
    onChange,
    onSubmit,
  }) => {
    const {
      id,
      name,
      description,
      questions,
      submissionStart,
      submissionEnd,
      surveyAccessedBy,
      surveyMinimumResponses,
      resultsComputedAfter,
      resultsAccessedBy,
      surveyShares,
      isPublished,
      updatedAt,
    } = survey || surveyInitialValues;
    const submissionStartDate =
      parseSurveySubmissionStartDateToIso(submissionStart);
    const submissionEndDate = parseSurveySubmissionEndDateToIso(submissionEnd);
    const participants =
      surveyShares?.nodes?.map(({ userEmail }) => userEmail) || [];
    const participantsWithSubmission = useMemo(() => {
      return isOwner
        ? new Set(
            (surveyShares?.nodes || [])
              .filter(({ hasSubmitted }) => hasSubmitted)
              .map(({ userEmail }) => userEmail)
          )
        : new Set();
    }, [surveyShares, isOwner]);
    const answers = (questions || []).map((question) => ({
      kind:
        question.kind === questionKind.NUMERICAL
          ? answerKind.NUMERICAL
          : answerKind.CATEGORICAL,
      value: question.allowMultiAnswer ? [] : undefined,
    }));
    // Initial status
    const initialStatus = useFormOptions(mode, hasSubmitted, isOwner);
    const { isEditing } = initialStatus;
    // Initial values
    const initialValues = useMemo(
      () => ({
        name,
        description,
        questions,
        answers,
        submissionStart: submissionStartDate,
        submissionEnd: submissionEndDate,
        surveyAccessedBy,
        surveyMinimumResponses,
        resultsComputedAfter,
        resultsAccessedBy,
        participants,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
    return (
      <Formik
        initialStatus={initialStatus}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={mode === surveyMode.CREATE}
        onSubmit={onSubmit}
      >
        <SurveyForm
          submissions={submissions}
          results={results}
          participantsWithSubmission={participantsWithSubmission}
          Toolbar={
            <SurveyFormToolbar
              id={id}
              name={name}
              isPublished={isPublished}
              updatedAt={updatedAt}
              isOwner={isOwner}
            />
          }
          Actions={
            <SurveyFormActions
              isOwner={isOwner}
              isPublished={isPublished}
              hasSubmitted={hasSubmitted}
              results={results}
            />
          }
        >
          {isEditing && typeof onChange === "function" ? (
            <SurveyFormAutoSave onChange={onChange} />
          ) : null}
        </SurveyForm>
      </Formik>
    );
  }
);

export default SurveyFormik;
