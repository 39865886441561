"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstraintError = exports.SurveyBuilder = exports.Survey = void 0;
const dataroom_1 = require("./dataroom");
const numeric_1 = require("./numeric");
const categoric_1 = require("./categoric");
const dataroom_2 = require("./dataroom");
function pollSqlQuery(session, dataRoomHash, queryName, role) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield session.makeSqlQuery(dataRoomHash, queryName, role);
        const decoder = new TextDecoder("utf-8");
        if (Object.prototype.hasOwnProperty.call(result, "finished")) {
            return decoder.decode(result.finished.data);
        }
        else {
            const jobId = result.inProgress.jobId;
            while (true) {
                const jobResult = yield session.getJobStatus(jobId, role);
                if (jobResult.completed) {
                    return decoder.decode((yield session.getQueryResults(jobId, role)).data);
                }
                yield new Promise((r) => setTimeout(r, 1000));
            }
        }
    });
}
class Survey {
    constructor(survey, client, dataRoomHash) {
        this.survey = survey;
        this.client = client;
        this.dataRoomHash = dataRoomHash;
    }
    cloneNewClient(client) {
        return new Survey(this.survey, client, this.dataRoomHash);
    }
    static fromDb(qls, client, isPublished) {
        var _a, _b, _c, _d;
        const participants = qls.surveyShares.nodes.map((x) => x === null || x === void 0 ? void 0 : x.userEmail);
        const questions = qls.questions.map((q) => {
            if (categoric_1.isCategoricalQuestion(q)) {
                const foo = new categoric_1.CategoricalQuestion(q.name, q.submissionOptions);
                return Object.assign(foo, q);
            }
            else if (numeric_1.isNumericQuestion(q)) {
                const foo = new numeric_1.NumericQuestion(q.name, q.aggregations);
                return Object.assign(foo, q);
            }
            else {
                throw new Error("Wrong Question Type");
            }
        });
        const builder = new SurveyBuilder(qls.name, qls.resultsComputedAfter, qls.resultsAccessedBy, questions, qls.surveyAccessedBy, participants, qls.surveyId);
        builder.description = (_a = qls.description) !== null && _a !== void 0 ? _a : "";
        builder.minimumNumberOfSurveyResponses = (_b = qls.surveyMinimumResponses) !== null && _b !== void 0 ? _b : 1;
        builder.submissionStart = (_c = new Date(qls.submissionStart)) !== null && _c !== void 0 ? _c : null;
        builder.submissionEnd = (_d = new Date(qls.submissionEnd)) !== null && _d !== void 0 ? _d : null;
        builder.ownerID = qls.ownerEmail;
        const dataRoomHashString = qls.dataRoomHash;
        const dataRoomHash = isPublished
            ? new Uint8Array(123)
            : Uint8Array.from(dataRoomHashString.split(","));
        const survey = new Survey(builder, client, dataRoomHash);
        return survey;
    }
    static fromBuilder(builder, client, dataRoomHash) {
        Object.freeze(builder);
        const survey = new Survey(builder, client, dataRoomHash);
        return survey;
    }
    ingestData(q, answers) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.client.ingestData(q, answers, this.dataRoomHash);
        });
    }
    requestResult(q) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.client.session === null)
                throw new Error("Session must bet set");
            try {
                let queryName = null;
                let role = null;
                if (this.survey.ownerID === this.client.userId) {
                    if (this.survey.submissionEnd !== null &&
                        new Date() < this.survey.submissionEnd) {
                        queryName = dataroom_2.strHasher(q.name) + "_query_complete_owner";
                    }
                    else {
                        queryName = dataroom_2.strHasher(q.name) + "_query_owner";
                    }
                    role = "ownerRole";
                }
                else {
                    if (this.survey.submissionEnd !== null &&
                        new Date() < this.survey.submissionEnd) {
                        queryName = dataroom_2.strHasher(q.name) + "_query_complete";
                    }
                    else {
                        queryName = dataroom_2.strHasher(q.name) + "_query";
                    }
                    role = "participantRole";
                }
                const response = yield pollSqlQuery(this.client.session, this.dataRoomHash, queryName, role);
                return q.processSqlReponse(response);
            }
            catch (error) {
                let msg = error.message;
                if (msg.includes(`Constraint:${ConstraintError.MinSubmissionsMissing}`)) {
                    return {
                        data: null,
                        error: {
                            name: ConstraintError.MinSubmissionsMissing,
                            message: msg,
                        },
                    };
                }
                else if (msg.includes(`Constraint:${ConstraintError.QuestionNotExpired}`)) {
                    return {
                        data: null,
                        error: {
                            name: ConstraintError.QuestionNotExpired,
                            message: msg,
                        },
                    };
                }
                else if (msg.includes(`Constraint:${ConstraintError.UserHasNotSubmitted}`)) {
                    return {
                        data: null,
                        error: {
                            name: ConstraintError.UserHasNotSubmitted,
                            message: msg,
                        },
                    };
                }
                else {
                    throw error;
                }
            }
        });
    }
    createSurvey() {
        return __awaiter(this, void 0, void 0, function* () {
            this.survey.ownerID = this.client.userId;
            const rootCaCert = yield this.client.getRootCaCert();
            const dataroom = dataroom_1.surveyToDataroom(this.survey, rootCaCert);
            const response = yield this.client.createDataRoom(dataroom);
            if (!response.dataRoomHash) {
                // TODO handle response.dataRoomValidationError gracefully
                throw new Error(`Expected data room hash, got ${response}`);
            }
            this.dataRoomHash = response.dataRoomHash;
            return this;
        });
    }
}
exports.Survey = Survey;
class SurveyBuilder {
    constructor(name, resultsComputedAfter, resultsAccess, questions, surveyAccess, participants, surveyId) {
        this.name = name;
        this.resultsComputedAfter = resultsComputedAfter;
        this.resultsAccess = resultsAccess;
        this.questions = questions;
        this.surveyAccess = surveyAccess;
        this.participants = participants;
        this.surveyId = surveyId;
        this.description = "";
        this.tags = null;
        this.minimumNumberOfSurveyResponses = 1;
        this.passwordAuth = null;
        this.submissionStart = null;
        this.submissionEnd = null;
        this.ownerID = "";
        this.participants = participants.map((email) => email.toLowerCase());
    }
    createSurvey(client) {
        return __awaiter(this, void 0, void 0, function* () {
            this.ownerID = client.userId;
            const rootCaCert = yield client.getRootCaCert();
            const dataroom = dataroom_1.surveyToDataroom(this, rootCaCert);
            const response = yield client.createDataRoom(dataroom);
            if (!response.dataRoomHash) {
                // TODO handle response.dataRoomValidationError gracefully
                throw new Error(`Expected data room hash, got ${response}`);
            }
            return Survey.fromBuilder(this, client, response.dataRoomHash);
        });
    }
}
exports.SurveyBuilder = SurveyBuilder;
var ConstraintError;
(function (ConstraintError) {
    ConstraintError["MinSubmissionsMissing"] = "MinSubmissionsMissing";
    ConstraintError["UserHasNotSubmitted"] = "UserHasNotSubmitted";
    ConstraintError["QuestionNotExpired"] = "QuestionNotExpired";
})(ConstraintError = exports.ConstraintError || (exports.ConstraintError = {}));
