import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  makeStyles,
} from "@material-ui/core";
import { FastField } from "formik";

const useFormHelperTextStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    lineHeight: "1rem",
    marginTop: "0.25rem",
  },
}));

const InputField = ({ name, question, helperText, ...props }) => {
  const formHelperTextClasses = useFormHelperTextStyles();
  return (
    <FastField name={name}>
      {({ field: { value, ...field }, meta: { error, touched } }) => (
        <FormControl fullWidth>
          <Input
            variant="standard"
            color="secondary"
            type="number"
            autoComplete="off"
            startAdornment={
              question?.unit ? (
                <Box sx={{ marginRight: "0.25rem" }}>
                  {question?.unit.toString().trim()}
                </Box>
              ) : null
            }
            onWheelCapture={(event) => event.target.blur()}
            value={value || ""}
            error={touched && Boolean(error)}
            {...props}
            {...field}
          />
          {touched && !error && value?.length > 0 ? null : (
            <FormHelperText
              error={touched && Boolean(error)}
              classes={formHelperTextClasses}
            >
              {touched && Boolean(error) ? error : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
};

export default InputField;
